import { useEffect, useState } from "react";
import { FaLocationDot } from "react-icons/fa6";
import { MdArrowBackIosNew } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import customToast from "../../../components/CustomToast";
import { viewUsersWithTps } from "../../../services/utilities";
import ChemistIcon from "../../public/img/SVG/DetailsPng/ChemistIcon.png";
import DoctorIcon from "../../public/img/SVG/DetailsPng/DoctorIcon.png";
import StockIcon from "../../public/img/SVG/DetailsPng/StockIcon.png";
const TourDetails = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const da = async () => {
      const { data } = await viewUsersWithTps();
      setData(data);
    };
    da();
  }, []);

  function exportToExcel() {
    setLoading(true);
    try {
      const workbook = XLSX.utils.book_new();
      const docsData = [];
      const chemsData = [];
      const stocksData = [];
      const tpsData = data.map((entry) => {
        const user = entry.user;
        const tp = entry.tps[0] || null;
        const docs = entry.docs || [];
        const chems = entry.chems || [];
        const stocks = entry.stocks || [];
        const docr = docs.map((entry) => {
          return {
            "Employee Name": user.name,
            Email: user.email,
            "Doctor Name": entry.target?.name || "",
            Date: entry.date,
            Time: new Date(entry.cdt).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
            Rating: entry.rating,
            "Area Type": entry.areaType,
            POB: entry.products.reduce(
              (sum, product) => sum + product?.amount,
              0
            ),
            Samples: entry.products.reduce(
              (acc, product) =>
                acc +
                " " +
                `${product.productName}: ${product.quantity}- Amount:${product.amount},`,
              ""
            ),
            Gifts: entry.gifts.reduce(
              (acc, product) =>
                acc +
                " " +
                `${product.giftName}: ${product.giftQuantity}- Amount:${product.giftAmount},`,
              ""
            ),
            "Worked With": entry.workedWith.reduce(
              (acc, el) =>
                acc + " " + (el?.firstName || "") + (el?.lastName || "") + ",",
              ""
            ),
            remarks: entry.remarks,
          };
        });

        const chemr = chems.map((entry) => {
          return {
            "Employee Name": user.name,
            Email: user.email,
            "Chemist Name": entry.target?.name || "",
            "Business Name": entry.target?.businessName || "",
            Date: entry.date,
            Time: new Date(entry.cdt).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
            Rating: entry.rating,
            "Area Type": entry.areaType,
            POB: entry.products.reduce(
              (sum, product) => sum + product?.amount,
              0
            ),
            Samples: entry.products.reduce(
              (acc, product) =>
                acc +
                " " +
                `${product.productName}: ${product.quantity}- Amount:${product.amount},`,
              ""
            ),
            Gifts: entry.gifts.reduce(
              (acc, product) =>
                acc +
                " " +
                `${product.giftName}: ${product.giftQuantity}- Amount:${product.giftAmount},`,
              ""
            ),
            "Worked With": entry.workedWith.reduce(
              (acc, el) =>
                acc + " " + (el?.firstName || "") + (el?.lastName || "") + ",",
              ""
            ),
            remarks: entry.remarks,
          };
        });

        const stockr = stocks.map((entry) => {
          return {
            "Employee Name": user.name,
            Email: user.email,
            "Stockist Name": entry.target?.name || "",
            "Business Name": entry.target?.businessName || "",
            Date: entry.date,
            Time: new Date(entry.cdt).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
            Rating: entry.rating,
            "Area Type": entry.areaType,
            POB: entry.products.reduce(
              (sum, product) => sum + product?.amount,
              0
            ),
            Samples: entry.products.reduce(
              (acc, product) =>
                acc +
                " " +
                `${product.productName}: ${product.quantity}- Amount:${product.amount},`,
              ""
            ),
            Gifts: entry.gifts.reduce(
              (acc, product) =>
                acc +
                " " +
                `${product.giftName}: ${product.giftQuantity}- Amount:${product.giftAmount},`,
              ""
            ),
            "Worked With": entry.workedWith.reduce(
              (acc, el) =>
                acc + " " + (el?.firstName || "") + (el?.lastName || "") + ",",
              ""
            ),
            remarks: entry.remarks,
          };
        });
        docsData.push(...docr);
        chemsData.push(...chemr);
        stocksData.push(...stockr);
        return {
          "Employee Name": user.name,
          Email: user.email,
          Date: tp?.dateString || "",
          Month: tp ? Number(tp.month) + 1 : "",
          Year: tp?.year || "",
          "Area Type": tp?.areaType || "",
          "Work Location":
            (tp &&
              tp.workAreas.reduce(
                (acc, el) => acc + (el.location?.name || "" + ", "),
                ""
              )) ||
            "",
          Activity: tp?.activity || "",
          "Tour Program Approval Status": tp
            ? tp.isApproved === 1
              ? "Approved"
              : "Pending"
            : "",
          "Approved By": tp?.authorizedBy?.fullName || "",
          "Doc Reports": entry.docreports,
          "Chem Reports": entry.chemreports,
          "Stoc Reports": entry.stocreports,
          "Is On Leave": entry.isLeave ? "Yes" : "No",
          "Leave Reason": entry.leaveReason,
        };
      });
      const tpSheet = XLSX.utils.json_to_sheet(tpsData);
      const docSheet = XLSX.utils.json_to_sheet(docsData);
      const chemSheet = XLSX.utils.json_to_sheet(chemsData);
      const stocSheet = XLSX.utils.json_to_sheet(stocksData);
      XLSX.utils.book_append_sheet(workbook, tpSheet, "Tour Programs");
      XLSX.utils.book_append_sheet(workbook, docSheet, "Doctor Call Reports");
      XLSX.utils.book_append_sheet(workbook, chemSheet, "Chemist Call Reports");
      XLSX.utils.book_append_sheet(
        workbook,
        stocSheet,
        "Stockist Call Reports"
      );

      XLSX.writeFile(
        workbook,
        `day_activity_${new Date().toISOString().split("T")[0]}.xlsx`
      );
      setLoading(false);
    } catch (err) {
      customToast.error("Failed To Download Report");
      setLoading(false);
    }
  }

  return (
    <div className="utility-inner-tp">
      <div className="row">
        <div className="web-app__heading d-flex justify-content-between">
          <div>
            <MdArrowBackIosNew
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <span style={{ display: "inline-block", margin: "0 1rem" }}>
              Today's Activity
            </span>
          </div>
          {/* <button
            type="button"
            disabled={loading}
            onClick={exportToExcel}
            className="button-blue-gradient"
          >
            {loading ? "processing..." : "Export Excel"}
          </button> */}
        </div>

        <div className="util-inner-details" style={{ marginTop: "3rem" }}>
          <div
            className="util-inner-info-box-container"
            style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}
          >
            {data.map((el) => (
              <div className="util-inner-info-box" key={el?.user?._id}>
                <Link
                  to={`./${el?.user?._id}`}
                  style={{ textDecoration: "none", width: "100%" }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <FaLocationDot
                          className="icon-color-error"
                          style={{ marginRight: "1rem" }}
                        />
                        <h3
                          style={{ marginRight: "1rem" }}
                        >{`${el?.user?.firstName} ${el?.user?.lastName}`}</h3>
                      </div>
                      {el?.tps.length > 0 && (
                        <div style={{ color: "white" }}>
                          {`(${el?.tps[0]?.areaType})`}
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                      }}
                    >
                      {el?.tps.length > 0 ? (
                        <p style={{ color: "yellow" }}>
                          {el?.tps.map((it) =>
                            it?.workAreas.map((z) => (
                              <div key={z?.location?.label}>
                                {z?.location?.label}
                              </div>
                            ))
                          )}
                        </p>
                      ) : el?.isLeave ? (
                        <p style={{ color: "skyBlue" }}>
                          Leave: {el?.leaveReason}
                        </p>
                      ) : (
                        <p style={{ color: "red" }}>No TP found</p>
                      )}
                      {el?.tps.length > 0 && (
                        <div className="report-container">
                          <div className="report-box">
                            <img
                              style={{ width: "3rem", height: "3rem" }}
                              src={DoctorIcon}
                              alt="Doctor Icon"
                            />
                            <span className="report-count">
                              {el?.docreports}
                            </span>
                          </div>
                          <div className="report-box">
                            <img
                              style={{ width: "3rem", height: "3rem" }}
                              src={ChemistIcon}
                              alt="Chemist Icon"
                            />

                            <span className="report-count">
                              {el?.chemreports}
                            </span>
                          </div>
                          <div className="report-box">
                            <img
                              style={{ width: "3rem", height: "3rem" }}
                              src={StockIcon}
                              alt="Stock Icon"
                            />

                            <span className="report-count">
                              {el?.stocreports}
                            </span>
                          </div>
                        </div>
                      )}
                      {el?.tps.length > 0 &&
                        el.tps.map((tpsItem) =>
                          tpsItem.workedWith &&
                          tpsItem.workedWith.length > 0 ? (
                            <div key={tpsItem._id}>
                              <p>Worked with:</p>
                              <ul>
                                {tpsItem.workedWith.map((workedWithItem) => (
                                  <li
                                    key={workedWithItem._id}
                                    style={{ color: "whitesmoke" }}
                                  >
                                    {workedWithItem.firstName}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ) : null
                        )}
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TourDetails;

//back to this 2
