import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseURL = `${apiURL}/client/utilities`;
const baseURLV1 = `${apiURL}/client/v1/utilities`;

export const viewUsersForUtilities = async (filters) => {
  const { data } = await axios.get(baseURLV1 + "/view-users-for-reports", {
    params: filters,
    headers: setAuth(),
  });

  return data;
};

export const viewUsersWithTps = async() =>{
  const {data} = await axios.get(baseURLV1 + "/view-users-with-tps",{
    headers : setAuth(),
  });
  return data;
}

export const viewMedRepresentatives = async (filters) => {
  const { data } = await axios.get(baseURLV1 + "/med-rep", {
    headers: setAuth(),
    params:filters
  });

  return data;
};

export const viewEmployeesForAdminUtil = async () => {
  const { data } = await axios.get(baseURL + "/view-employees-for-admin", {
    headers: setAuth(),
  });

  return data;
};

export const viewUtilTargets = async (type, details) => {
  const { data } = await axios.get(`${baseURLV1}/user-target-for-util/${type}`, {
    params: details,
    headers: setAuth(),
  });

  return data;
};

export const viewSampleGiftData = async (type, filter) => {
  // const { data } = await axios.get(baseURL + "/sample-gift-data/"+type, {
  const { data } = await axios.get(baseURLV1 + "/sample-gift-data/" + type, {
    params: filter,
    headers: setAuth(),
  });

  return data;
};

export const viewSampleData = async (filter) => {
  const { data } = await axios.get(baseURLV1 + "/sample-data/", {
    params: filter,
    headers: setAuth(),
  });

  return data;
}
export const viewSampleDetails = async (filter) => {
  const { data } = await axios.get(baseURLV1 + "/sample-details/", {
    params: filter,
    headers: setAuth(),
  });

  return data;
}

export const viewSampleGiftDetails = async (type,filter)=>{
  // const {data} = await axios.get(baseURL + "/sample-gift-details/"+type,{
  const {data} = await axios.get(baseURLV1 + "/sample-gift-details/" + type, {
    params:filter,
    headers:setAuth()
  })
  
  return data;
}

export const viewGiftData = async (type, filter) => {
  const { data } = await axios.get(baseURLV1 + "/gift-data/", {
    params: filter,
    headers: setAuth(),
  });
  return data;
}

export const viewGiftDetails = async (type, filter) => {
  const {data} = await axios.get(baseURLV1 + "/gift-details/", {
    params: filter,
    headers: setAuth(),
  });
  return data;
}
export const viewStockistWiseSales = async (filter) => {
  const { data } = await axios.get(baseURL + "/stk-wise-sales", {
    params: filter,
    headers: setAuth(),
  });

  return data;
};

export const viewMissedReports = async (type, filter) => {
  const { data } = await axios.get(baseURLV1 + "/missed-docs/" + type, {
    params: filter,
    headers: setAuth(),
  });
  
  return data;
};

export const viewUserwiseMissedReports = async (type, filter) => {
  const { data } = await axios.get(baseURLV1 + "/missed-userwise/" + type, {
    params: filter,
    headers: setAuth(),
  });
  
  return data;
};

export const viewMissedMonthReports = async (type, filter) => {
  const { data } = await axios.get(baseURLV1 + "/missed-monthly/" + type, {
    params: filter,
    headers: setAuth(),
  });
  
  return data;
};

export const viewRouteList = async (filter) =>{
  const {data} = await axios.get(baseURLV1+"/route-list/",{
    params:filter,
    headers:setAuth()
  })
  
  return data;
}
export const viewHeadquarterSS = async(filter)=>{
  const {data} = await axios.get(baseURLV1+"/ss-report/headquarter",{
    params:filter,
    headers:setAuth()
  })
  return data;
}
export const viewSTKWiseSales = async(filter)=>{
  const {data} = await axios.get(baseURLV1+"/ss-report/stockist" , {
    params:filter,
    headers:setAuth()
  })
  return data ;
}
export const viewSTKWiseSalesInventory = async(filter)=>{
  const {data} = await axios.get(baseURLV1+"/ss-report/stockist-inventory" , {
    params:filter,
    headers:setAuth()
  })
  return data ;
}
export const viewProductWiseSales = async(filter)=>{
  const {data} = await axios.get(baseURLV1+"/ss-report/product" , {
    params:filter,
    headers:setAuth()
  })
  return data ;
}
export const viewUserWiseSales = async(filter)=>{
  
  const {data} = await axios.get(baseURLV1+"/ss-report/user" , {
    params:filter,
    headers:setAuth()
  })
  return data ;
}
export const viewHQwisePrimarySales = async(filter)=>{
  
  const {data} = await axios.get(baseURLV1 + "/ps-report/headquarter" , {
    params:filter,
    headers:setAuth()
  })
  return data ;
}
export const viewSTKWisePrimarySales = async(filter)=>{
  
  const {data} = await axios.get(baseURLV1 + "/ps-report/stockist", {
    params:filter,
    headers:setAuth()
  })
  return data ;
}
export const viewUserWisePrimarySales = async(filter)=>{
  const {data} = await axios.get(baseURLV1 + "/ps-report/user", {
    params:filter,
    headers:setAuth()
  })
  return data ;
}
export const viewProductWisePrimarySales = async(filter)=>{
  const {data} = await axios.get(baseURLV1 +"/ps-report/product" , {
    params:filter,
    headers:setAuth()
  })
  return data ;
}
export const viewDateWisePrimarySales = async(filter)=>{
  const {data} = await axios.get(baseURLV1 +"/ps-report/date" , {
    params:filter,
    headers:setAuth()
  })
  return data ;
}

export const viewMonthlyReports = async (type,filter) => {
  const {data} = await axios.get(baseURLV1 + `/monthly-reports/${type}`, {
    params:filter,
    headers:setAuth(),
  })
  
  return data;
}

export const viewAnnualReports = async (filter) => {
  const {data} = await axios.get(baseURLV1 + `/annual-reports`, {
    params:filter,
    headers:setAuth(),
  })
  
  return data;
}

export const viewhqWiseDSCreports = async(filter) =>{
  const {data} = await axios.get(baseURLV1 + "/hq-wise-dcsreports",{
    params: filter,
    headers: setAuth(),
  });

  return data;
}

export const viewcityWiseDSCreports = async(filter) =>{
  const {data} = await axios.get(baseURLV1 + "/city-wise-dcsreports",{
    params: filter,
    headers: setAuth(),
  });

  return data;
}
export const viewlocalAreaWiseDSCreports = async(filter) =>{
  const {data} = await axios.get(baseURLV1 + "/localarea-wise-dcsreports",{
    params: filter,
    headers: setAuth(),
  });

  return data;
}

export const viewTodaysActivity = async (date) => {
  const { data } = await axios.get(baseURLV1 + "/todays-activity", {
    params: { date },
    headers: setAuth(),
  });

  return data;
};

export const viewUsersForUtilitiesWithTP = async (date) => {
  try {
    const { data } = await axios.get(baseURLV1 + "/view-users-with-tps", {
      params: { date },
      headers: setAuth(),
    });
    return data;
  } catch (e) {
    throw e;
  }
};


export const viewUsersForUtilitiesWithCR = async (date) => {
  try {
    const { data } = await axios.get(baseURL + "/view-users-with-crs", {
      params: { date },
      headers: setAuth(),
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const viewMonthlyPunchingReports= async (filters) => {
  try {
    const { data } = await axios.get(`${baseURLV1}/punching-report`, {
      params: filters,
      headers: setAuth(),
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const viewPunchingReportDetails= async (filters) => {
  try {
    const { data } = await axios.get(`${baseURLV1}/punching-report/details`, {
      params: filters,
      headers: setAuth(),
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const viewUsersForTarget= async (filters) => {
  try {
    const { data } = await axios.get(`${baseURLV1}/view-users-for-target`, {
      params: filters,
      headers: setAuth(),
    });
    return data;
  } catch (e) {
    throw e;
  }
};
