import { lazy, Suspense, useEffect, useMemo, useState } from "react";
import customToast from "../../../../components/CustomToast";
import PageTitle from "../../../../components/PageTitle";
import serialise, { generateSelectData } from "../../../../utils/serialiseResponse";
import Table from "../../../../components/Table";
import { viewAllPayment, viewDoctorSales, viewPayment, viewProductSaleReport, viewTargets } from "../../../../services/targets";
import { items } from "../../../../utils/helper";
import { returnMonthFromIndex } from "../../../../utils/helper";
import moment from "moment-timezone";
// import CascadingMenu from "../../CascadingMenu";
const CascadingMenu = lazy(() => import('../../CascadingMenu'));
const DatePickers = lazy(() => import("../../../../components/DatePicker"))

const DoctorSales = () => {

  const [tdata, setTdata] = useState([]);
  // const [docs, setDocs] = useState([]);
  // const doctorSelect = useMemo(() => generateSelectData(docs, "name"), [docs]);
  const [fromMonth, setFromMonth] = useState(moment().month());
  const [toMonth, setToMonth] = useState("");
  const [fromYear, setFromYear] = useState(moment().year());
  const [toYear, setToYear] = useState("");
  const [selectedRange, setSelectedRange] = useState({})
  const [totalRevenue, setTotalRevenue] = useState(0)
  const [totalRevenueShare, setTotalRevenueShare] = useState(0)
  const [paymentData, setPaymentData] = useState([])
  const [paidAmount, setPaidAmount] = useState(0)

  const handleMenuClick1 = ({ keyPath }) => {
    // console.log(keyPath)
    const [monthYearString] = keyPath;
    const [month, year] = monthYearString.split('-');
    setFromMonth(`${month}`);
    setFromYear(`${year}`);
    setSelectedRange({...selectedRange, fromMonth:month, fromYear:year})
  };
  const handleMenuClick2 = ({ keyPath }) => {
    // console.log(keyPath)
    const [monthYearString] = keyPath;
    const [month, year] = monthYearString.split('-');
    setToMonth(`${month}`);
    setToYear(`${year}`);
    setSelectedRange({...selectedRange, toMonth:month, toYear:year})
  };

  // useEffect(() => {
  //   viewTargets("doctor", { showApproved: true }).then((response) => {
  //     setDocs(response?.data);
  //   }).catch(e => console.log(e));
  // }, []);


  const groupDataByDoctor = (data, paymentData) => {
    const groupedData = {};
    const doctorPayments = {};
    
    // Iterate over the payment data and calculate total paid amount for each doctor
    paymentData.forEach((payment) => {
      const doctorId = payment._id;
      if (!doctorPayments[doctorId]) {
        console.log("hello")
        doctorPayments[doctorId] = {
          paidAmount: 0
        };
      }
      payment.payment.forEach((p) => {
        doctorPayments[doctorId].paidAmount += Number(p.amount);
      });
    });
    
    // Transform the data to group by doctor and calculate totals
      data.forEach((report) => {
        const doctorId = report.doctor._id;
        if (!groupedData[doctorId]) {
          groupedData[doctorId] = {
            _id: doctorId,
            doctor: report.doctor.name,
            totalQuantity: 0,
            totalFinalPrice: 0,
            totalRevenueShare: 0,
            paidAmount: doctorPayments[doctorId]?.paidAmount || 0, // Initialize with the total paid amount
            pendingAmount: 0
          };
        }
        report.products.forEach((product) => {
          groupedData[doctorId].totalQuantity += Number(product.quantity);
          groupedData[doctorId].totalFinalPrice += Number(product.finalPrice);
          groupedData[doctorId].totalRevenueShare += Number(product.revenueShare);
        });
      });

    Object.values(groupedData).forEach((doctorData) => {
      doctorData.pendingAmount = (doctorData.totalRevenueShare - doctorData.paidAmount).toFixed(2);
      doctorData.totalFinalPrice = (doctorData.totalFinalPrice).toFixed(2)
      doctorData.totalRevenueShare = (doctorData.totalRevenueShare).toFixed(2)
      doctorData.paidAmount = (doctorData.paidAmount).toFixed(2)
    });
  
      return Object.values(groupedData);
    };
  
    const transformedData = useMemo(() => groupDataByDoctor(tdata, paymentData), [tdata, paymentData]);


  const columns = useMemo(
    () => [
      {
        Header: "Doctor",
        accessor: "doctor",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Quantity",
        accessor: "totalQuantity",
        disableSortBy: true,
        disableFilters: true,
        // showTotal: true,
        // totalAccessor: "quantity",
      },
      {
        Header: "Final Price",
        accessor: "totalFinalPrice",
        disableFilters: true,
        disableSortBy: true,
        // minWidth: 80,
        // maxWidth: 90,
        // showTotal: true,
        // totalAccessor: "finalPrice",
      },
      {
        Header: "Revenue Share",
        accessor: "totalRevenueShare",
        disableFilters: true,
        disableSortBy: true,
        // minWidth: 80,
        // maxWidth: 90,
        // showTotal: true,
        // totalAccessor: "revenueShare",
      },
      {
        Header: "Paid Amount",
        accessor: "paidAmount",
        disableFilters: true,
        disableSortBy: true,
        // showTotal: true,
        // totalAccessor: "paidAmount",
      },
      {
        Header: "Pending Amount",
        accessor: "pendingAmount",
        disableFilters: true,
        disableSortBy: true,
        // showTotal: true,
        // totalAccessor: "pendingAmount",
      },
    ],
    []
  );

  // const tableData = useMemo(() => serialise(tdata), [tdata]);


  const SubmitHandler = async (e) => {
    try {
      e.preventDefault();

      if (fromMonth === "") {
        return customToast.error("Select the starting month");
      } else if (toMonth === "") {
        return customToast.error("Select the ending month");
      }
      // get data
      viewDoctorSales({range:{fromMonth, fromYear, toMonth, toYear}}).then((response) => {
        setTdata(response?.data);
        // console.log(response?.data)
      }).catch(e => console.log(e));
      viewAllPayment({range:{fromMonth, fromYear, toMonth, toYear}}).then((response) => {
        setPaymentData(response?.data);
        console.log(response?.data)
      }).catch(e => console.log(e));
    } catch (err) {
      return customToast.error("An unexpected error occured");
    }
  };


  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Doctor wise Sales" isCRM={true} />

        <section className="area-creation-content__info">
          <form onSubmit={SubmitHandler}>
            <div className="row">
              <div className="col-auto">
                <label htmlFor="stockist">From Month</label>
                <br />
                <Suspense>
                  <DatePickers
                    placeholder={(fromMonth && fromYear ) ? moment({ fromYear, fromMonth }).format('MMM, YYYY') : "Select Month"}
                    setMonth={setFromMonth}
                    setYear={setFromYear}
                  />
                </Suspense>
              </div>
              <div className="col-auto">
                <label htmlFor="stockist">To Month</label>
                <br />
                <Suspense>
                  <DatePickers
                    placeholder="Select Month"
                    setMonth={setToMonth}
                    setYear={setToYear}
                  />
                </Suspense>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-4 admin-creation-content__form-submit mt-5">
                <button type="submit" className="button-blue-gradient">
                  Submit
                </button>
              </div>
            </div>
          </form>

          {
            tdata.length > 0 ?
              (
                <div className="my-4">
                  {/* <h4 className="my-4">Docter's report For {month} 2024</h4> */}

                  <div className="filter-table pe-4">
                    {transformedData?.length > 0 && (
                      <h2 className="web-app__heading mb-4 ms-2">
                        Showing ({transformedData?.length}) Entries
                      </h2>
                    )}
                    <Table columns={columns} data={transformedData} />
                  </div>
                </div>
              ) :
              null
          }

        </section>
        {/* {showEdit ? <EditPopup /> : null} */}
      </div>
    </main>
  )

}

export default DoctorSales;