import { useCallback, useState, useMemo, useEffect, lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import {
  viewUsersForUtilities,
  viewUtilTargets,
} from "../../../../../services/utilities";
import Select from "react-select";
import {
  dcrStyles,
  selectUserStyles,
} from "../../../../public/stylesheets/selectStyles";
import { monthOptions, yearOptions } from "../../../../../utils/helper";
import {
  addIndex,
  generateSelectData,
  generateSelectUser,
  serialiseGift,
} from "../../../../../utils/serialiseResponse";
import Table from "../../../../../components/Table";
import { MdArrowBackIosNew, MdOutlineGroup } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import moment from "moment-timezone";
const DatePickers = lazy(() => import("../../../../../components/DatePicker"))

const UtilAllottedGifts = () => {
  const navigate = useNavigate();
  const loggedIn = useSelector(({ user }) => user);

  const [month, setMonth] = useState(moment().month());
  const [year, setYear] = useState(moment().year());
  const [gifts, setGifts] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [employees, setEmployees] = useState([]);
  const [employeeDataForExcel, setEmployeeDataForExcel] = useState();
  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );
  const employeeData = useMemo(
    () => generateSelectData(employees, "fullName"),
    [employees]
  );

  useEffect(() => {
    try {
      let tempdata = employeeData.filter((empData) => {
        if (empData.label === selectedUser.label) return true;
      })
      setEmployeeDataForExcel(tempdata[0]);
    } catch (error) {
      console.log(error);
    }
  }, [selectedUser]);

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Gifts",
        accessor: "gift.gift",
        minWidth: 80,
        maxWidth: 80,
        showTotal:true,
        placeholderTotal:"Total"
      },
      {
        Header: "Allotted",
        accessor: "issued",
        minWidth: 85,
        maxWidth: 85,
        showTotal:true,
        totalAccessor:"issued"
      },
    ],
    []
  );
  const columns2 = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Name",
        accessor: "user.firstName",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Allotted",
        accessor: "totalIssued",
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "View",
        accessor: "4",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 50,
        maxWidth: 50,
        Cell: (props) => {
          return (
            <div
              onClick={(e) => { createSelectedUserObject(props.row.original.user) }}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </div>
          );
        },
      },
    ],
    [year, month]
  );

  const createSelectedUserObject = async (userData) => {
    if (!userData) {
      return;
    }
    const selectedUserObject = {
      label: userData.firstName,
      value: userData._id,
      designation: userData.des,
    };
    setSelectedUser(selectedUserObject);
    const data = {
      month: month,
      year: Number(year),
      userId: userData._id,
    };
    const res = await viewUtilTargets("gift", data);
    setGifts([...res.data])
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (selectedUser) {
        let data = {
          userId: selectedUser?.value,
          month: month,
          year: Number(year),
        };

        await viewUtilTargets("gift", data).then((res) => setGifts(res.data));
      } else {
        let data = {
          month: month,
          year: Number(year),
        };
        await viewUtilTargets("allGifts", data).then((res) => setGifts(res.data));
      }
    },
    [month, year, selectedUser]
  );
  const tableData = useMemo(() => addIndex(gifts), [gifts]);
  
  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees(res?.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <section className="admin-creation-content__heading">
          <MdArrowBackIosNew
            className="admin-creation-content__back-button"
            onClick={() => navigate("/utilities")}
          />
          <h2 className="web-app__heading">Gifts Allotted</h2>
        </section>

        <form onSubmit={handleSubmit}>
          <div className="util-list-filters">
            <div className="util-tp-filter">
              <p className="mb-3">
                Select Month <span className="asterisk-imp">*</span>
              </p>
              <Suspense>
                <DatePickers
                  placeholder={(month && year ) ? moment({ year, month }).format('MMM, YYYY') : "Select Month"}
                  setMonth={setMonth}
                  setYear={setYear}
                />
              </Suspense>
            </div>

            {loggedIn?.user?.des < 121 ||
              loggedIn?.user?.designation?.priority > 1 ||
              loggedIn?.user?.isAdmin ? (
              <div className="util-tp-filter">
                <p className="util-select-user__label">Select User</p>
                <div className="util-select-user">
                  <MdOutlineGroup className="util-select-user__icon" />
                  <Select
                    styles={selectUserStyles}
                    options={employeeSelect}
                    placeholder="Select User"
                    className="mt-3"
                    value={selectedUser}
                    onChange={(e) => {
                      setSelectedUser({ ...e })
                      setGifts([])
                    }}
                  />
                </div>
              </div>
            ) : null}
            <div className="util-tp-filter">
              <button type="submit" className="button-blue-gradient mt-5">
                See Reports
              </button>
            </div>
          </div>
        </form>

        <section className="area-creation-content__info" style={{ marginTop: "-4rem" }}>
          <div className="filter-table">
            {tableData === undefined ? null : (
              tableData.length > 0 ? (
                <Table columns={selectedUser ? columns : columns2} data={tableData} fileSrc="Gifts Allotted" empl={selectedUser ? employeeDataForExcel : loggedIn?.user}
                month={month} />
              ) : (
                <h3 className="ms-2 no-results-message">No results found</h3>
              )
            )}
          </div>
        </section>
      </div>
    </main>
  );
};

export default UtilAllottedGifts;
