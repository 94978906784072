import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import customToast from "../../../../components/CustomToast";
import PageTitle from "../../../../components/PageTitle";
import { setCities } from "../../../../reducers/locations/cityReducer";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../../reducers/locations/hqReducer";
import {
  addDoctor,
  clearDoctors,
  setAllDoctors,
} from "../../../../reducers/targets/doctor";
import { viewEntityOptions } from "../../../../services/entityOptions";
import {
  viewMedRepresentatives,
  viewUsersForUtilities,
} from "../../../../services/utilities";
import {
  generateSelectData,
  generateSelectUser,
} from "../../../../utils/serialiseResponse";
import { adminStyles } from "../../../public/stylesheets/selectStyles";


const AdminCreateDoctor = () => {
  const dispatch = useDispatch();

  const loggedIn = useSelector(({ user }) => user);
  const headquarters = useSelector(({ headquarter }) => headquarter);
  const cities = useSelector(({ city }) => city);

  const [name, setName] = useState("");
  const [degree, setDegree] = useState([]);
  const [selectedDegree, setSelectedDegree] = useState([]);
  const [specialization, setSpecialization] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState(null);
  const [hospitals, setHospitals] = useState([]);
  const [selectedSpecialization, setSelectedSpecialization] = useState([]);

  const [birthday, setBirthday] = useState("");
  const [docAnniversary, setDocAnniversary] = useState("");
  const [mobile, setMobile] = useState("");
  const [contact, setContact] = useState("");

  const [doctorCode, setDoctorCode] = useState("");
  const [email, setEmail] = useState("");
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();

  const [address, setAddress] = useState("");

  const [extraInfo, setExtraInfo] = useState("");

  const [headquarter, setHeadquarter] = useState();
  const [city, setCity] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [employees, setEmployees] = useState([]);
  
  useEffect(() => {
    dispatch(setHeadquarters());
    return () => {dispatch(clearHeadquarters());}
  }, [dispatch]);

  useEffect(() => {
    if (!headquarter) return;
    dispatch(setCities(headquarter?.value));
  }, [dispatch, headquarter]);

  const hqSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );
  const citySelect = useMemo(() => {
    return generateSelectData(cities.data, "name");
  }, [cities]);
  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );
  const specializationSelect = useMemo(
    () => generateSelectData(specialization, "name"),
    [specialization]
  );
  const hospitalSelect = useMemo(
    ()=> generateSelectData(hospitals, "name"),
    [hospitals]
  );
  const degreeSelect = useMemo(
    () => generateSelectData(degree, "name"),
    [degree]
  );
  const categorySelect = useMemo(
    () => generateSelectData(category, "name"),
    [category]
  );

  // const doctors = useSelector(({ doctor }) => doctor);

  useEffect(() => {
    dispatch(setAllDoctors({ type: "creation" }));
    try {
      if(loggedIn?.user?.isAdmin){
        viewUsersForUtilities({includeMe : 'true'}).then((res) => {
        setEmployees([...res.data]);
        })
      } else {
        viewMedRepresentatives({includeMe : 'true'}).then((res) => {
        setEmployees([...res.data]);
        });
      }
      
      viewEntityOptions({type : "Specialization"}).then((res) => {
        setSpecialization([...res.data]);
      });
      viewEntityOptions({type : 'Degree'}).then((res) => {
        setDegree([...res.data]);
      });
      viewEntityOptions({type : 'Category'}).then((res) => {
        setCategory([...res.data]);
      });

      viewEntityOptions({type : 'Hospital'}).then((res) => {
        setHospitals([...res.data]);
      });
    } catch (error) {
      console.log(error);
      customToast.error("Something went wrong!");
    }

    return () => {
      dispatch(clearDoctors());
    };
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !name ||
      !mobile ||
      !city ||
      !headquarter ||
      !selectedCategory ||
      selectedDegree.length === 0 ||
      !selectedUser?.value ||
      selectedSpecialization.length === 0
    ) {
      customToast.error("Please fill all the Required Fields");
      return;
    }

    try {
      const data = {
        name,
        degree: selectedDegree
        .map(item => item.label) 
        .join(", "),
        specialization: selectedSpecialization.map(item => item.label) 
        .join(", "),
        birthday,
        anniversary: docAnniversary,
        email,
        mobile,
        contact,
        doctorCode,
        category: selectedCategory,
        address,
        medRepresentative: selectedUser?.value,
        city: headquarter?.value,
        workingArea: city?.value,
        hospital: selectedHospital?.label,
        extraInfo,
      };

      await dispatch(addDoctor(data));
      customToast.success("Doctor added Successfully");
      resetForm();
    } catch (e) {
      customToast.error("We Encountered an Error");
    }
  };

  const resetForm = () => {
    setDocAnniversary("");
    setName("");
    setSelectedDegree("");
    setSelectedSpecialization(""); // Fixed a typo in the state variable name
    setBirthday("");
    setMobile("");
    setContact("");
    setDoctorCode("");
    setEmail("");
    setAddress("");
    setHeadquarter(null);
    setCity(null);
    setSelectedUser(null);
    setSelectedCategory("");
    setSelectedHospital(null);
  };

  return (
    <main className="main-content admin-content">
      <div className="admin-creation-content">
        <PageTitle title="Create Doctor..." />

        <section className="admin-creation-content__form">
          <form onSubmit={handleSubmit}>
            <div className="admin-form">
              <div>
                <label htmlFor="name">
                  Name <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  className="me-5"
                  id="name"
                  placeholder="Enter Doctor's Name"
                  name="name"
                />
              </div>
              <div>
                <label htmlFor="degree">
                  Degree <span className="asterisk-imp">*</span>
                </label>
                <br />
                 <Select
                 isMulti
                  name="degree"
                  id="degree"
                  value={selectedDegree}
                  options={degreeSelect}
                  onChange={(e) =>
                    setSelectedDegree([...e])
                  }
                  styles={adminStyles}
                  placeholder="Select Degree"
                   className="multi-select-dropdown d-flex justify-content-center"
                />
              </div>
              <div>
                <label htmlFor="spacialization">Specialization <span className="asterisk-imp">*</span></label>
                <br />
                {/* <input
                  value={specialization}
                  onChange={(e) => setSpecialization(e.target.value)}
                  type="text"
                  className="me-5"
                  id="spacialization"
                  placeholder="Enter Specialization"
                  name="specialization"
                /> */}
                <Select
                  isMulti
                  name="specialization"
                  id="specialization"
                  value={selectedSpecialization}
                  options={specializationSelect}
                  onChange={(e) =>
                    setSelectedSpecialization([...e])
                  }
                  styles={adminStyles}
                  placeholder="Select Specialization"
                />
              </div>
              <div>
                <label htmlFor="hospital">Hospital</label>
                <br />
                <Select
                  name="hospital"
                  id="hospital"
                  value={selectedHospital}
                  options={hospitalSelect}
                  onChange={(e) =>
                    setSelectedHospital(e)
                  }
                  styles={adminStyles}
                  placeholder="Select Hospital"
                />
              </div>
              <div>
                <label htmlFor="birthday">Birthday</label>
                <br />
                <input
                  type="date"
                  className="me-5"
                  id="birthday"
                  onChange={(e) => setBirthday(e.target.value)}
                  value={birthday}
                  name="birthday"
                />
              </div>
              <div>
                <label htmlFor="birthday">Marriage Anniversary</label>
                <br />
                <input
                  type="date"
                  className="me-5"
                  id="birthday"
                  onChange={(e) => setDocAnniversary(e.target.value)}
                  value={docAnniversary}
                  name="birthday"
                />
              </div>
              <div>
                <label htmlFor="mobNumber">
                  Mobile Number <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  type="phone"
                  maxLength={10}
                  pattern="[0-9]*"
                  className="me-5"
                  id="mobNumber"
                  placeholder="Enter Mobile Number"
                  name="mobNumber"
                />
              </div>
              <div>
                <label htmlFor="contact">Clinic's Contact Number </label>
                <br />
                <input
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                  type="phone"
                  maxLength={10}
                  pattern="[0-9]*"
                  className="me-5"
                  id="contact"
                  placeholder="Enter Alternate Number"
                  name="clinicContact"
                />
              </div>
              <div>
                <label htmlFor="doctorCode">Doctor's Code</label>
                <br />
                <input
                  value={doctorCode}
                  onChange={(e) => setDoctorCode(e.target.value)}
                  type="text"
                  className="me-5"
                  id="doctorCode"
                  placeholder="Enter Doctor Code"
                  name="doctorCode"
                />
              </div>
              <div>
                <label htmlFor="email">Email</label>
                <br />
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  className="me-5"
                  id="email"
                  placeholder="Enter Email Address"
                  name="email"
                />
              </div>
              <div>
                <label htmlFor="doctorCategory">
                  Category <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="doctorCategory"
                  id="doctorCategory"
                  value={selectedCategory}
                  options={categorySelect}
                  onChange={(e) =>
                    setSelectedCategory({ value: e.value, label: e.label })
                  }
                  styles={adminStyles}
                  placeholder="Select Doctor Category"
                />
              </div>
              {loggedIn?.user?.des < 121 || loggedIn?.user?.isAdmin ? (
                <div>
                  <label htmlFor="hq">
                    Select user to Allot <span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <Select
                    name="mrAllot"
                    id="mrAllot"
                    value={selectedUser}
                    options={employeeSelect}
                    onChange={(e) => setSelectedUser({ ...e })}
                    styles={adminStyles}
                    placeholder="Select MR"
                  />
                </div>
              ) : null}

              <div>
                <label htmlFor="hq">
                  Select HQ <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="headquarter"
                  id="headquarter"
                  value={headquarter}
                  options={hqSelect}
                  onChange={
                    (e) => setHeadquarter({ value: e.value, label: e.label }) //
                    /////
                  }
                  styles={adminStyles}
                  placeholder="Select Headquarter"
                />
              </div>

              <div>
                <label htmlFor="hq">
                  Select Working Area <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="city"
                  id="city"
                  value={city}
                  options={citySelect}
                  onChange={(e) => setCity({ value: e.value, label: e.label })}
                  styles={adminStyles}
                  placeholder="Select Working Area"
                />
              </div>
              <div>
                <label htmlFor="address">Clinic's Address</label>
                <br />
                <textarea
                  name="address"
                  id="address"
                  cols="30"
                  rows="1"
                  placeholder="Enter Clinic Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                ></textarea>
              </div>
              <div>
                <label htmlFor="extra_info">Extra Information</label>
                <br />
                <textarea
                  value={extraInfo}
                  onChange={(e) => setExtraInfo(e.target.value)}
                  type="text"
                  className="me-5"
                  id="extra_info"
                  placeholder="Enter Extra Information "
                  name="extra_info"
                  cols={30}
                  rows={1}
                ></textarea>
              </div>
              <br />
              <div>
                <button type="submit" className="button-blue-gradient">
                  Add Doctor
                </button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </main>
  );
};

export default AdminCreateDoctor;
