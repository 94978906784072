import Select from "react-select";
import { useState, useCallback, useMemo, useEffect, Suspense, lazy } from "react";
import { viewSampleGiftData } from "../../../services/utilities";
import {
  generateSelectUser,
  serialiseSSG,
} from "../../../utils/serialiseResponse";
import Table from "../../../components/Table";
import { dcrStyles, selectUserStyles } from "../../public/stylesheets/selectStyles";
import { MdOutlineGroup } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { clearUsers, setUsersForUtilities } from "../../../reducers/users/employeeReducer";
import { formatDate } from "../../../utils/helper";
import customToast from "../../../components/CustomToast";
const DatePickers = lazy(() => import("../../../components/DatePicker"))


const Sample = () => {
  const dispatch = useDispatch();
  
  const [month, setMonth] = useState(moment().month());
  const [year, setYear] = useState(moment().year());
  const [reports, setReports] = useState([]);
  // const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const loggedIn = useSelector(({ user }) => user);
  const employees = useSelector(({ employee }) => employee);
  
  const showArr = [
    { value: "productReport", label: "Products Report" },
    { value: "userWiseReport", label: "Userwise Report" },
    { value: "detailedSampleReport", label: "Detailed Report" },
  ]

  const [reportType, setReportType] = useState(showArr[0])
  console.log(reportType)
  
  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );
  const columns = useMemo(
    () => [
      
      {
        Header: "Sample",
        accessor: "productName",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Alotted Quantity",
        accessor: "issued",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Distributed Quantity",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
        Cell: (props) => {
          return (
            <span>
              {props?.row?.original?.issued - props?.row?.original?.balance
                ? props?.row?.original?.issued - props?.row?.original?.balance
                : 0}
            </span>
          );
        },
      },
      {
        Header: "Available Quantity",
        accessor: "balance",
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "View",
        accessor: "4",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 50,
        maxWidth: 50,
        Cell: (props) => {
          return (
            <Link
              to={`/sample-management/${props?.row?.original?.range?._id}`}
              state={props?.row?.original}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      },
    ],
    []
  );
  const columns2 = useMemo(
    () => [
      
      {
        Header: "Name",
        accessor: "user.firstName",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Alotted Quantity",
        accessor: "totalIssued",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Distributed Quantity",
        accessor: "totalDistributed",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Available Quantity",
        accessor: "totalBalance",
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "View",
        accessor: "4",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 50,
        maxWidth: 50,
        Cell: (props) => {
          return (
            <div
              onClick={(e) => { createSelectedUserObject(props.row.original.user) }}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </div>
          );
        },
      },
    ],
    [month, year]
  );
  const column3 = useMemo(() => [
      {
        Header: "Date",
        accessor: "date",
        disableFilters: true,
        Cell: (props) => {
          return ( formatDate(props?.row?.original?.date))
        },
      },
      {
        Header: "Employee Name",
        accessor: "employeeName",
      },
      {
        Header: "Name",
        accessor: "entityName",
      },
      {
        Header: "Type",
        accessor: "type",
        Cell: (props) => {
          const type = props?.row?.original?.type
          return type === "doc" ? <span className='approved-text'>Doctor</span> :
            type === "chem" ? <span className='pending-text'>Chemist</span> :
              type === "stk" ? <span className='rejected-text'>Stockist</span> :
                "N/A"
        },
      },
      {
        Header: "Product",
        accessor: "productName",
      },
      {
        Header: "Sample Quantity",
        accessor: "sampleQuantity",
        disableFilters: true,
        disableSortBy: true,
        showTotal:true,
        totalAccessor:"sampleQuantity"
      },
    ],[month, year])
  
  const createSelectedUserObject = async (userData) => {
    if (!userData) {
      return;
    }
    const selectedUserObject = {
      label: userData.firstName,
      value: userData._id,
      designation: userData.des,
    };
    setSelectedUser(selectedUserObject);
    const data = {
      month: month,
      year: Number(year),
      selectedUser: userData._id,
    };
    const res = await viewSampleGiftData("sample", data);
    setReports([...res.data]);
  };

  // useEffect(() => {
  //   try {
  //     viewUsersForUtilities().then((res) => {
  //       setEmployees([...res.data]);
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, []);

  useEffect(() => {
    dispatch(setUsersForUtilities({ includeMe: true }))
    return () => {
      dispatch(clearUsers());
    };
  }, [dispatch]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if(reportType.value === "productReport"){
        let data = {
        month: Number(month),
        year: Number(year),
        selectedUser: selectedUser?.value,
      };
      await viewSampleGiftData("sample", data)
      .then((res) => setReports(res.data))
      .catch((err) => customToast("Error fetching report"))
    } else if(reportType.value === "userWiseReport"){
        let data = {
          month: Number(month),
          year: Number(year),
        };
        await viewSampleGiftData("allSamples", data)
        .then((res) => setReports(res.data))
        .catch((err) => customToast("Error fetching report"))
      } else if(reportType.value === "detailedSampleReport") {
        let data = {
          month: Number(month),
          year: Number(year),
        };
        await viewSampleGiftData("detailedSampleReport", data)
        .then((res) => setReports(res.data))
        .catch((err) => customToast("Error fetching report"))
      }
    },
    [month, year, selectedUser, reportType]
  );
  const tableData = useMemo(() => serialiseSSG(reports), [reports]);

  return (
    <main className="main-content">
      <div className="expense">
        <div className="row">
          <h3 className="web-app__heading">Samples Distributed</h3>

          <form onSubmit={handleSubmit}>

            <div className="util-tp-query d-flex justify-content-between">

              <div className="d-flex gap-4">
                <div className="util-tp-filter">
                  <p className="mb-3">Select Report Type</p>
                  <Select
                    options={showArr}
                    styles={dcrStyles}
                    value={reportType}
                    onChange={(e) => {
                      setReportType(e)
                      setReports([])
                      setSelectedUser(null)
                    }}
                  />
                </div>
                <div className="util-tp-filter">
                  <p className="mb-3">
                    Select Month
                  </p>
                  <Suspense>
                    <DatePickers
                      placeholder={(month && year ) ? moment({ year, month }).format('MMM, YYYY') : "Select Month"}
                      setMonth={setMonth}
                      setYear={setYear}
                    />
                  </Suspense>
                </div>
              </div>

              <div className="d-flex align-items-center">
                {(loggedIn?.user?.des < 121 ||
                loggedIn?.user?.isAdmin ) && reportType.value === "productReport" ? (
                  <div className="util-tp-filter me-5">
                    <p className="util-select-user__label">Select User <span className="asterisk-imp">*</span></p>
                    <div className="util-select-user">
                      <MdOutlineGroup className="util-select-user__icon" />
                      <Select
                        styles={selectUserStyles}
                        options={employeeSelect}
                        placeholder="Select User"
                        className="mt-3"
                        value={selectedUser}
                        onChange={(e) => setSelectedUser({ ...e })}
                      />
                    </div>
                  </div>
                ) : null}

                <div className="util-tp-filter mt-4">
                  <button type="submit" className="button-blue-gradient mt-3">
                    See Reports
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className="filter-table">
          {reports.length > 0 && (
            <h2 className="web-app__heading mb-4 ms-2">
              Showing ({reports.length}) Entries
            </h2>
          )}
          <Table columns={(reportType?.value === "productReport" || selectedUser?.value) ? columns : (reportType?.value === "userWiseReport" ? columns2 : column3)} data={tableData} />
        </div>
      </div>
    </main>
  );
};

export default Sample;
