import {
  viewTargets,
  newTarget,
  editTarget,
  deleteTarget,
  viewTargetsForApproval,
  viewAllTargets,
  newTargetForApproval,
  approveTarget,
  rejectTarget,
  uploadTarget,
  viewAllTargetsV1,
  newTargetV1,
} from "../../services/targets.js";

const initialState = {
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CHEMISTS":
      return action.payload;

    case "SET_CHEMIST_FOR_APPROVAL":
      return action.payload;

    case "SET_CHEMIST_ALL":
      return action.payload;

    case "CREATE_CHEMIST":
      return { data: [...state.data, action.payload.data.data] };

    case "UPLOAD_CHEMIST":
      return { data: [...state.data, action.payload.data.data] };

    case "CREATE_CHEMIST_FOR_APPROVAL":
      return { data: [...state.data, action.payload.data.data] };

    case "CLEAR_CHEMISTS":
      return initialState;

    case "EDIT_CHEMISTS":
      return { ...state, newState: action.payload };

    case "DELETE_CHEMISTS":
      return action.payload;

    case "APPROVE_CHEMIST":
      return {data:state.data};

    case "REJECT_CHEMIST":
      return {data:state.data};

    default:
      return state;
  }
};

export const setChemists = (filters) => {
  return async (dispatch) => {
    const { data } = await viewTargets("chemist", filters);

    dispatch({
      type: "SET_CHEMISTS",
      payload: { data: data },
    });
  };
};

export const setChemistForApproval = (filters) => {
  return async (dispatch) => { 
    const { data } = await viewTargetsForApproval("chemist", filters);

    dispatch({
      type: "SET_CHEMIST_FOR_APPROVAL",
      payload: { data: data },
    });
  };
};

export const setAllChemists = (filters) => {
  return async (dispatch) => {
    const { data } = await viewAllTargets("chemist", filters);
    // const { data } = await viewAllTargetsV1("chemist", filters);

    dispatch({
      type: "SET_CHEMIST_ALL",
      payload: { data: data },
    });
  };
};

export const uploadChemist = (data, userId) => {
  return async (dispatch) => {
    try {
      data = await uploadTarget("chemist", data, userId);
    } catch (e) {
      throw e.response;
    }

    dispatch({
      type: "UPLOAD_CHEMIST",
      payload: { data: data },
    });
    return data ;
  };
};

export const addChemist = (data) => {
  return async (dispatch) => {
    try {
      // data = await newTarget("chemist", data);
      data = await newTargetV1("chemist", data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "CREATE_CHEMIST",
      payload: { data: data },
    });
  };
};

export const addChemistForApproval = (data) => {
  return async (dispatch) => {
    try {
      data = await newTargetForApproval("chemist", data);
    } catch (e) {
      if (e) throw e;
    }

    dispatch({
      type: "CREATE_CHEMIST_FOR_APPROVAL",
      payload: { data: data },
    });
  };
};

export const clearChemists = () => {
  return {
    type: "CLEAR_CHEMISTS",
  };
};

export const editChemist = (id, updatedTarget) => {
  return async (dispatch) => {
    try {
      updatedTarget = await editTarget("chemist", id, updatedTarget);
    } catch (e) {
      if (updatedTarget.error) throw updatedTarget.error;
    }

    dispatch({
      type: "EDIT_CHEMISTS",
      payload: { data: updatedTarget },
    });
    dispatch(setChemists());
  };
};

export const deleteChemist = (id , data) => {
  return async (dispatch) => {
    try {
      data = await deleteTarget("chemist",id, data);
    } catch (e) {
      if (data.error) throw data.error;
    }
    dispatch({
      type: "DELETE_CHEMISTS",
      payload: { data: data },
    });
    dispatch(setChemists());
  };
};

export const approveChemist = (id, data) => {
  return async (dispatch) => {
    try {
      data = await approveTarget("chemist", id, data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "APPROVE_CHEMIST",
      payload: { data: data },
    });
    dispatch(setChemistForApproval());
  };
};

export const rejectChemist = (id, data) => {
  return async (dispatch) => {
    try {
      data = await rejectTarget("chemist", id, data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "REJECT_CHEMIST",
      payload: { data: data },
    });
    dispatch(setChemistForApproval());
  };
};

export default reducer;
