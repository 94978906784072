import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import customToast from "../../../../../components/CustomToast";
import PageTitle from "../../../../../components/PageTitle";
import { setCities } from "../../../../../reducers/locations/cityReducer";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../../../reducers/locations/hqReducer";
import { deleteDoctor, editDoctor } from "../../../../../reducers/targets/doctor";

import { BsCheck2Circle, BsXCircle } from "react-icons/bs";
import { RiCloseCircleLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import { viewEntityOptions } from "../../../../../services/entityOptions";
import { formatDate } from "../../../../../utils/helper";
import { generateSelectData } from "../../../../../utils/serialiseResponse";
import { adminStyles } from "../../../../public/stylesheets/selectStyles";

const AdminEditDoctor = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const headquarters = useSelector(({ headquarter }) => headquarter);
  const cities = useSelector(({ city }) => city);

  const [name, setName] = useState("");
  const [degree, setDegree] = useState([]);
  const [selectedDegree, setSelectedDegree] = useState([]);
  const [specialization, setSpecialization] = useState([]);
  const [selectedSpecialization, setSelectedSpecialization] = useState([]);

  const [birthday, setBirthday] = useState();
  const [docAnniversary, setDocAnniversary] = useState();
  const [mobile, setMobile] = useState("");
  const [contact, setContact] = useState("");

  const [doctorCode, setDoctorCode] = useState("");
  const [email, setEmail] = useState("");
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedHospital, setSelectedHospital] = useState(null);
  const [hospitals, setHospitals] = useState([]);
  const [address, setAddress] = useState("");

  const [headquarter, setHeadquarter] = useState();
  const [city, setCity] = useState();
  const [showEdit, setShowEdit] = useState(false);

  const [extraInfo, setExtraInfo] = useState("");

  useEffect(() => {
    setName(location.state?.name);
    if(degree.length> 0 ) {
      const degreeString = location.state?.degree; 
      const degreeArray = degreeString.split(",").map((d, index) => {
        const matchedDegree = degree.find((item) => d.trim() === item.name)
        return {
        label: d.trim(),
        value: matchedDegree?.value
      }});
      setSelectedDegree(degreeArray);
    }
    if( specialization.length > 0 ){
      const specializationString = location.state?.specialization; 
    const specializationArray = specializationString.split(",").map((s, index) => {
      const matchedSpecialization = specialization.find((item) => s.trim() === item.name)
      return {
        label: s.trim(),
        value: matchedSpecialization?.value
      }
    });
    setSelectedSpecialization(specializationArray);
    }
    // setSelectedDegree(location.state?.degree);
    // setSelectedSpecialization(location.state?.specialization);
    setBirthday(location.state?.birthday);
    setDocAnniversary(location.state?.anniversary);
    setMobile(location.state?.mobile);
    setContact(location.state?.contact);
    setDoctorCode(location.state?.doctorCode);
    setEmail(location.state?.email);
    setSelectedCategory(location.state?.category);
    setAddress(location.state?.address);
    setHeadquarter({value:location?.state?.city?._id, label: location?.state?.city?.name});
    setCity({value:location?.state?.workingArea?._id, label: location?.state?.workingArea?.name});
    setExtraInfo(location?.state?.extraInfo);
    setSelectedHospital({value:1, label:location?.state?.hospital});
  }, [degree, specialization]);


  useEffect(() => {
    dispatch(setHeadquarters());
    return ()=> {dispatch(clearHeadquarters());}
  }, [dispatch]);

  useEffect(() => {
    if (!headquarter) return;

    dispatch(setCities(headquarter?.value));
  }, [dispatch, headquarter]);

  useEffect(() => {
   
    try {
      viewEntityOptions({type : "Specialization"}).then((res) => {
        setSpecialization([...res.data]);
      });
      viewEntityOptions({type : 'Degree'}).then((res) => {
        setDegree([...res.data]);
      });
      viewEntityOptions({type : 'Category'}).then((res) => {
        setCategory([...res.data]);
      });
      viewEntityOptions({type : 'Hospital'}).then((res) => {
        setHospitals([...res.data]);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const hqSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );
  const citySelect = useMemo(
    () => generateSelectData(cities.data, "name"),
    [cities]
  );
  const specializationSelect = useMemo(
    () => generateSelectData(specialization, "name"),
    [specialization]
  );
  const hospitalSelect = useMemo(
    ()=> generateSelectData(hospitals, "name"),
    [hospitals]
  );
  const degreeSelect = useMemo(
    () => generateSelectData(degree, "name"),
    [degree]
  );
  const categorySelect = useMemo(
    () => generateSelectData(category, "name"),
    [category]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(name === "" || name === null){
      return customToast.error("Enter proper name");
    }

    let error = false;
    try {

      if (
        !name ||
        !mobile ||
        !headquarter ||
        !selectedCategory ||
        selectedDegree.length === 0 ||
        !city ||
        selectedSpecialization.length === 0
      )
      return customToast.error("Please fill all the required details");

      let data = {
        name,
         degree: selectedDegree
        .map(item => item.label) 
        .join(", "),
        specialization: selectedSpecialization.map(item => item.label) 
        .join(", "),
        birthday,
        anniversary: docAnniversary,
        email,
        mobile,
        contact,
        doctorCode,
        category: selectedCategory,
        address,
        city: headquarter?.value,
        workingArea: city?.value,
        hospital: selectedHospital?.label,
        extraInfo,
      };

      await dispatch(editDoctor(location.state._id, data));
    } catch (e) {
      error = true;
      customToast.error("We Encountered an Error");
    }

    if (!error) {
      customToast.success("Doctor edited Successfully");

      setName("");
      setSelectedDegree("");
      setSelectedSpecialization("");
      setBirthday("");
      setMobile("");
      setContact("");
      setDoctorCode("");
      setEmail("");
      setAddress("");
      setHeadquarter({value:headquarter?.value, label: headquarter?.label});
      setCity({value:city?.value, label: city?.label});
      setSelectedCategory("");
      setExtraInfo("")
      setSelectedHospital(null);
      navigate(-1);
    }
  };
  const EditPopup = () => {
    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form>
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  Are you sure you want to delete?
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button
                type="button"
                onClick={() => {
                  dispatch(deleteDoctor(location?.state?._id));
                  navigate(-1);
                }}
                className="button-delete mt-4"
              >
                <span className="button-delete__icon-container">
                  <BsXCircle className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Delete</span>
              </button>
              <button
                type="button"
                onClick={() => setShowEdit(false)}
                className="button-submit-green mt-4"
              >
                <span className="button-submit-green__icon-container">
                  <BsCheck2Circle className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Cancel</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };
  return (
    <main className="main-content admin-content">
      <div className="admin-creation-content">
        <PageTitle
          title={`Edit Doctor ${location?.state?.name}`}
          hierarchy={true}
        />

        <section className="admin-creation-content__form">
          <form onSubmit={handleSubmit}>
            <div className="admin-form">
              <div>
                <label htmlFor="name">Name</label>
                <br />
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  className="me-5"
                  id="name"
                  placeholder={`${location?.state?.name}`}
                  name="name"
                />
              </div>
              <div>
                <label htmlFor="degree">Degree</label>
                <br />
                {/* <input
                  value={degree}
                  onChange={(e) => setDegree(e.target.value)}
                  type="text"
                  className="me-5"
                  id="degree"
                  placeholder={`${location.state?.degree}`}
                  name="degree"
                /> */}
                 <Select
                 isMulti
                  name="degree"
                  id="degree"
                  value={selectedDegree}
                  options={degreeSelect}
                  onChange={(e) =>
                    setSelectedDegree([...e])
                  }
                  styles={adminStyles}
                  placeholder="Select Degree"
                  className="multi-select-dropdown d-flex justify-content-center"
                />
              </div>
              <div>
                <label htmlFor="specialization">Specialization <span className="asterisk-imp">*</span></label>
                <br />
                {/* <input
                  value={specialization}
                  onChange={(e) => setSpecialization(e.target.value)}
                  type="text"
                  className="me-5"
                  id="specialization"
                  placeholder={`${location.state?.specialization}`}
                  name="specialization"
                /> */}
                <Select
                isMulti
                  name="specialization"
                  id="specialization"
                  value={selectedSpecialization}
                  options={specializationSelect}
                  onChange={(e) =>
                    setSelectedSpecialization([...e])
                  }
                  styles={adminStyles}
                  placeholder="Select Specialization"
                  className="multi-select-dropdown d-flex justify-content-center"
                />
              </div>
              <div>
                <label htmlFor="hospital">Hospital</label>
                <br />
                <Select
                  name="hospital"
                  id="hospital"
                  value={selectedHospital}
                  options={hospitalSelect}
                  onChange={(e) =>
                    setSelectedHospital(e)
                  }
                  styles={adminStyles}
                  placeholder="Select Hospital"
                />
              </div>
              <div>
                <label htmlFor="birthday">
                  Birthday{" "}
                  <span className="admin-highlighted-span">
                    {formatDate(location?.state?.birthday)}
                  </span>
                </label>
                <br />
                <input
                  type="date"
                  className="me-5"
                  id="birthday"
                  onChange={(e) => setBirthday(e.target.value)}
                  value={birthday}
                  name="birthday"
                />
              </div>
              <div>
                <label htmlFor="birthday">
                  Marriage Anniversary
                  <span className="admin-highlighted-span">
                    {formatDate(location?.state?.anniversary)}
                  </span>
                </label>
                <br />
                <input
                  type="date"
                  className="me-5"
                  id="birthday"
                  onChange={(e) => setDocAnniversary(e.target.value)}
                  value={docAnniversary}
                  name="birthday"
                />
              </div>
              <div>
                <label htmlFor="mobNumber">Mobile Number</label>
                <br />
                <input
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  type="phone"
                  maxLength={10}
                  pattern="[0-9]*"
                  className="me-5"
                  id="mobNumber"
                  placeholder={`${location.state?.mobile}`}
                  name="mobNumber"
                />
              </div>
              <div>
                <label htmlFor="contact">Clinic's Contact Number </label>
                <br />
                <input
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                  type="phone"
                  maxLength={10}
                  pattern="[0-9]*"
                  className="me-5"
                  id="contact"
                  placeholder={`${location.state?.contact}`}
                  name="clinicContact"
                />
              </div>
              <div>
                <label htmlFor="doctorCode">Doctor's Code</label>
                <br />
                <input
                  value={doctorCode}
                  onChange={(e) => setDoctorCode(e.target.value)}
                  type="text"
                  className="me-5"
                  id="doctorCode"
                  placeholder={`${location.state?.doctorCode}`}
                  name="doctorCode"
                />
              </div>
              <div>
                <label htmlFor="email">Email</label>
                <br />
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  className="me-5"
                  id="email"
                  placeholder={`${location.state?.email}`}
                  name="email"
                />
              </div>
              <div>
                <label htmlFor="doctorCategory">Category</label>
                <br />
                <Select
                  name="doctorCategory"
                  id="doctorCategory"
                  value={selectedCategory}
                  options={categorySelect}
                  onChange={(e) =>
                    setSelectedCategory({ value: e.value, label: e.label })
                  }
                  styles={adminStyles}
                  placeholder={`${location.state?.category?.label}`}
                />
              </div>
              {/* {loggedIn?.user?.des < 121 ||
              loggedIn?.user?.isAdmin ? (
                <div>
                  <label htmlFor="hq">
                    Select MR to Allot 
                  </label>
                  <br />
                  <Select
                    name="mrAllot"
                    id="mrAllot"
                    value={selectedUser}
                    options={employeeSelect}
                    onChange={(e) => setSelectedUser({ ...e })}
                    styles={adminStyles}
                    placeholder="Select MR"
                  />
                </div>
              ) : null} */}
              <div>
                <label htmlFor="hq">
                  Current HQ:{" "}
                  <span className="admin-highlighted-span">
                    {location.state?.city?.name}
                  </span>
                </label>
                <br />
                <Select
                  name="headquarter"
                  id="headquarter"
                  value={headquarter}
                  options={hqSelect}
                  onChange={(e) =>
                    setHeadquarter({ value: e.value, label: e.label })
                  }
                  styles={adminStyles}
                  placeholder={`${location.state?.city?.name}`}
                />
              </div>
              <div>
                <label htmlFor="hq">
                  Current Working Area:{" "}
                  <span className="admin-highlighted-span">
                    {location.state?.workingArea?.name}
                  </span>
                </label>
                <br />
                <Select
                  name="city"
                  id="city"
                  value={city}
                  options={citySelect}
                  onChange={(e) => setCity({ value: e.value, label: e.label })}
                  styles={adminStyles}
                  placeholder={`${location.state?.workingArea?.name}`}
                />
              </div>
              <div>
                <label htmlFor="address">Clinic's Address</label>
                <br />
                <textarea
                  name="address"
                  id="address"
                  cols="30"
                  rows="1"
                  placeholder={`${location.state?.address}`}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                ></textarea>
              </div>

              <div>
                <label htmlFor="extra_info">Extra Information</label>
                <br />
                <textarea
                  value={extraInfo}
                  onChange={(e) => setExtraInfo(e.target.value)}
                  type="text"
                  className="me-5"
                  id="extra_info"
                  placeholder="Enter Extra Information "
                  name="extra_info"
                  cols={30}
                  rows={1}
                ></textarea>
              </div>

              <br />
            </div>
            <div className="d-flex flex-wrap justify-content-between">
              <button type="submit" className="button-submit-green me-5 mt-5">
                <span className="button-submit-green__icon-container">
                  <BsCheck2Circle className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Edit</span>
              </button>
              <button
                type="button"
                onClick={() => setShowEdit(true)}
                className="button-delete mt-5"
              >
                <span className="button-delete__icon-container">
                  <BsXCircle className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Delete</span>
              </button>
            </div>
          </form>
        </section>
        {showEdit ? <EditPopup /> : null}
      </div>
    </main>
  );
};

export default AdminEditDoctor;
