import { useEffect, useMemo, useState } from "react";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import Select from "react-select";
import customToast from "../../../../components/CustomToast";
import PageTitle from "../../../../components/PageTitle";
import Table from "../../../../components/Table";
import { addEntityOption, removeEntityOption, viewEntityOptions } from "../../../../services/entityOptions";
import serialise from "../../../../utils/serialiseResponse";
import { dcrStyles } from "../../../public/stylesheets/selectStyles";

const DoctorControls = () => {
  const [type, setType] = useState({ label: "Specialization", value: "specialization" });
  const [data, setData] = useState();
  const [option, setOption] = useState("");


  
  const typeOptions = useMemo(()=>[
    { label: "Specialization", value: "specialization" },
    { label: "Degree", value: "degree" },
    { label: "Category", value: "category" },
    { label:"Hospital", value:"hospital"}
  ],[]);
  
  const fetchEntityOptions = async (typeValue) => {
    try {
      const entityTypeMap = {
        specialization: "Specialization",
        degree: "Degree",
        category: "Category",
        hospital: "Hospital"
      };
  
      const typeLabel = entityTypeMap[typeValue];
      if (!typeLabel) customToast.error("Invalid type");
  
      const response = await viewEntityOptions({ type: typeLabel, allOptions: "true" });
      if (response) {
        setData(response.data);
      }
    } catch (error) {
      console.log(error);
      customToast.error("An unexpected error occurred!");
    }
  };
  
  const handleCheckboxClick = async (data) => {
    try {
      await removeEntityOption({
        type: type?.label,
        optionId: data?._id,
        enabled: !data?.isEnabled,
      });
  
      await fetchEntityOptions(type?.value);
    } catch (error) {
      customToast.error("An unexpected error occurred!");
    }
  };
  
  
  
  const handleAdd = async (e) => {
    e.preventDefault();
  
    if (!type) {
      customToast.error("Please select type");
      return;
    }
  
    try {
      await addEntityOption({ type: type?.label, option });
      await fetchEntityOptions(type?.value);
      setOption("");
    } catch (error) {
      customToast.error("An unexpected error occurred!");
    }
  };
  

  const columns = useMemo(() =>  [
      {
        Header: "Name",
        accessor: "name",
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Action",
        accessor: "",
        Cell: (props) => {

           return props?.row?.original?.isEnabled === true ? (
                <ImCheckboxChecked 
                className="icon-color-tertiary cursor-pointer"
                onClick={(e) => {
                    e.preventDefault();
                    handleCheckboxClick(props?.row?.original);
                  }}
                />
              ) : (
                <ImCheckboxUnchecked
                className="icon-color-tertiary cursor-pointer"
                onClick={(e) => {
                    e.preventDefault();
                    handleCheckboxClick(props?.row?.original);
                  }}
                />
              );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
     [type]);

  const tableData = useMemo(() => serialise(data), [data]);

  useEffect(() => {
    fetchEntityOptions(type?.value);
  }, [type])

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content ">
        <PageTitle title="Doctor Controls" />
        <section className="">
          <form onSubmit={handleAdd}>
            <div className="d-flex justify-content-between py-4 px-5 w-100" >
                <div className="d-flex justify-content-between w-100" >
                  <div className="util-tp-filter me-5">
                    <p className="mb-3">
                      Select Type <span className="asterisk-imp">*</span>
                    </p>
                    <Select
                      styles={dcrStyles}
                      placeholder="Select Type"
                      options={typeOptions}
                      value={type}
                      onChange={(selectedOption) => {
                        setType(selectedOption)
                        setData([]);
                      }}
                    />
                  </div>

                 {
                  !(type?.label === "Category") ?  <div className="d-flex">
                  <div className="util-tp-filter me-5">
                    <p className="mb-3">
                      Enter {type?.label} <span className="asterisk-imp">*</span>
                    </p>
                    <input
                      value={option}
                      onChange={({ target }) => setOption(target.value)}
                      type="text"
                      className="me-5"
                      id="option"
                      placeholder="Enter Option"
                    />
                  </div>
                  <div className="admin-content-submit-button">
                    <button type="submit" className="button-blue-gradient">
                      Add
                    </button>
                  </div>
                </div> : null
                 }

                </div>
            </div>
          </form>
        </section>
      </div>
      <section className=" table-container" style={{ marginRight: "2rem" }}>
        <div className="filter-table">
          {data?.length > 0 && (
            <h2 className="web-app__heading mb-4 ps-3">
              Showing ({data?.length}) Entries
            </h2>)
          }
          {tableData?.length > 0 ? (
            <Table columns={columns} data={tableData} />
          ) : (
            <h3 className="mt-2 no-results-message">Zero results found</h3>
          )}
        </div>
      </section>
    </main>
  );
};

export default DoctorControls;