import { MdArrowBackIosNew, MdOutlineGroup } from "react-icons/md";
import { useNavigate, Link } from "react-router-dom";
import Select from "react-select";
import { monthOptions, yearOptions } from "../../../../utils/helper";
import {
  dcrStyles,
  selectUserStyles,
} from "../../../public/stylesheets/selectStyles";
import { useCallback, useState, useMemo, useEffect, lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { generateSelectUser } from "../../../../utils/serialiseResponse";
import { viewUsersForUtilities } from "../../../../services/utilities";
import Table from "../../../../components/Table";
import { viewProdWisePrimaryReports } from "../../../../services/analytics";
import { FaEye } from "react-icons/fa";
import serialise from "../../../../utils/serialiseResponse";
import customToast from "../../../../components/CustomToast";
import { GiUpgrade } from "react-icons/gi";
import moment from "moment-timezone";
const DatePickers = lazy(() => import("../../../../components/DatePicker"))

const ProdWisePrimarySales = () => {
  const navigate = useNavigate();

  const [month, setMonth] = useState(moment().month());
  const [year, setYear] = useState(moment().year());
  const [emonth, setEmonth] = useState();
  const [eyear, setEyear] = useState();
  const [loading,setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [type , setType] = useState({label:"Product"});
  const [advance, setAdvance] = useState(false);
  const typeOptions = [{label:"Product"} , {label:"Stockist"} , {label:"Headquarter"}] ;


  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   minWidth:50,
      //   maxWidth:50,
      //   disableFilters:true,
      // },
      {
        Header: "Product",
        accessor: "product",
        showTotal:true,
        placeholderTotal:"Total",
      },
      {
        Header: "Free Stocks",
        accessor: "freeStocks",
        showTotal:true,
        totalAccessor:"freeStocks",
        disableFilters:true,
      },
      {
        Header: "Total Quantity",
        accessor: "totalQuantity",
        showTotal:true,
        totalAccessor:"totalQuantity",
        disableSortBy:true,
      },
      {
        Header: "Total Value",
        accessor: "totalValue",
        showTotal:true,
        totalAccessor:"totalValue",
        disableFilters:true,
        Cell:(props) => {
          return <div>
            {Number(props?.row?.original?.totalValue).toFixed(2)}
          </div>
        }
      },
      {
        Header: "View",
        accessor: "details",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <Link
              to={props?.row?.original?._id}
              state={{ data: props?.row?.original?._id,id:props?.row?.original?._id, 
                prodId:props?.row?.original?.prodId,
                month:month, year:year, type:type
              }}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      },
    ],
    [month,year,type]
  );
  const columnsSTK = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   minWidth:50,
      //   maxWidth:50,
      //   disableFilters:true,
      // },
      {
        Header: "Product",
        accessor: "product",
        showTotal:true,
        placeholderTotal:"Total",
      },
      {
        Header:"Stockist",
        accessor:"stockist",
      },
      {
        Header:"Headquarter",
        accessor:"headquarter",
      },
      {
        Header: "Free Stocks",
        accessor: "freeStocks",
        showTotal:true,
        totalAccessor:"freeStocks",
        disableFilters:true,
      },
      {
        Header: "Total Quantity",
        accessor: "totalQuantity",
        showTotal:true,
        totalAccessor:"totalQuantity",
        disableFilters:true,
      },
      {
        Header: "Total Value",
        accessor: "totalValue",
        showTotal:true,
        totalAccessor:"totalValue",
        disableFilters:true,
        Cell:(props) => {
          return <div>
            {Number(props?.row?.original?.totalValue).toFixed(2)}
          </div>
        }
      },
      {
        Header: "View",
        accessor: "details",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <Link
              to={props?.row?.original?._id}
              state={{ data: props?.row?.original?._id,id:props?.row?.original?._id, 
                prodId:props?.row?.original?.prodId,
                month:month, year:year, type:type
              }}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      },
    ],
    [month,year,type]
  );
  const columnsHQ = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   minWidth:50,
      //   maxWidth:50,
      //   disableFilters:true,
      // },
      {
        Header: "Product",
        accessor: "product",
        showTotal:true,
        placeholderTotal:"Total"
      },
      {
        Header:"Headquarter",
        accessor:"headquarter",
      },
      {
        Header: "Free Stocks",
        accessor: "freeStocks",
        showTotal:true,
        totalAccessor:"freeStocks",
        disableFilters:true,
      },
      {
        Header: "Total Quantity",
        accessor: "totalQuantity",
        showTotal:true,
        totalAccessor:"totalQuantity",
        disableFilters:true,
      },
      {
        Header: "Total Value",
        accessor: "totalValue",
        showTotal:true,
        totalAccessor:"totalValue",
        disableFilters:true,
        Cell:(props) => {
          return <div>
            {Number(props?.row?.original?.totalValue).toFixed(2)}
          </div>
        }
      },
      {
        Header: "View",
        accessor: "details",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <Link
              to={props?.row?.original?._id}
              state={{ data: props?.row?.original?._id,id:props?.row?.original?._id, 
                prodId:props?.row?.original?.prodId,
                month:month, year:year, type:type
              }}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      },
    ],
    [month,year,type]
  );
  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if(loading)return ;
      if(!year)return customToast.error("Please select month and year") ;
      if(advance && (!eyear))return customToast.error("Please select end month and year")
      setLoading(true);
      let filter = {
        month: month,
        year: year,
        endYear:eyear,
        endMonth:emonth,
        filterType:type?.label,
        advance
      };
      try{
        const {data} = await viewProdWisePrimaryReports(filter);
        setReports(data);
      }catch(err){
        customToast.error("error occured please try again")
      }
      setLoading(false);
    },
    [month, year,loading,type,eyear,emonth,advance]
  );

  
  const tableData = useMemo(() => serialise(reports), [reports]);
  return (
    <div className="expense">
      <div className="row">
        <h2
          className="web-app__heading"
          style={{ display: "flex", alignItems: "center" }}
        >
          <MdArrowBackIosNew
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <span style={{ display: "inline-block", margin: "0 1rem" }}>
            Product Wise Primary Sales
          </span>
        </h2>

        <form onSubmit={handleSubmit}>
          <div className="util-tp-query d-flex justify-content-between flex-wrap gap-4">
            <div className="d-flex gap-2">
              <div className="util-tp-filter me-3">
                <p className="mb-3">
                {advance ? "Select Start Month" : "Select Month"} <span className="asterisk-imp">*</span>
                </p>
                <Suspense>
                  <DatePickers
                    placeholder={(month && year ) ? moment({ year, month }).format('MMM, YYYY') : "Select Month"}
                    setMonth={setMonth}
                    setYear={setYear}
                  />
                </Suspense>
              </div>
              {advance && <div className="d-flex gap-2">
              
              <div className="util-tp-filter me-3">
                <p className="mb-3">
                  Select End Month <span className="asterisk-imp">*</span>
                </p>
                  <Suspense>
                    <DatePickers
                      placeholder={(month && year ) ? moment({ year, month }).format('MMM, YYYY') : "Select Month"}
                      setMonth={setEmonth}
                      setYear={setEyear}
                    />
                  </Suspense>
              </div>
            </div>}
              <div className="util-tp-filter me-3">
                <p>
                  Select Filter Type <span className="asterisk-imp">*</span>
                </p>
                <Select
                  styles={dcrStyles}
                  placeholder="Select Type"
                  className="mt-3"
                  options={typeOptions}
                  value={type}
                  onChange={(e) => {
                    setType({...e});
                    setReports([]);   
                  }}
                />
              </div>
            </div>
            {/* <div className="d-flex"> */}
              <div className="util-tp-filter">
                <GiUpgrade onClick={()=> setAdvance(!advance)} className="me-3 d-inline icon-color-tertiary cursor-pointer" />
                <button type="submit" className="button-blue-gradient mt-5 d-inline">
                  See Reports
                </button>
              </div>
            {/* </div> */}
          </div>
        </form>

        <div className="filter-table">
          { loading && <h3 className="mt-2 fetching-results-message">Hold on fetching reports for you...</h3>}
          {tableData.length > 0 && (
            <>
              <h2 className="web-app__heading mb-4 ps-3 d-flex justify-content-between">
                Showing ({reports.length}) Entries
              </h2>
              {type.label === "Product" && <Table columns={columns} data={tableData} fileSrc="Product wise primary sales (Product)" />}
              {type.label === "Stockist" && <Table columns={columnsSTK} data={tableData} fileSrc="Product wise primary sales (Stockist)" />}
              {type.label === "Headquarter" && <Table columns={columnsHQ} data={tableData} fileSrc="Product wise primary sales (Headquarter)" />}
            </>
            ) 
          }
          {
          !loading && tableData.length === 0 && (<h3 className="mt-2 no-results-message">No results found</h3>)
          }
        </div>
      </div>
    </div>
  );
};

export default ProdWisePrimarySales;
