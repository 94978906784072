import React, { useEffect, useMemo, useState } from "react";
import { MdArrowBackIosNew } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Table from "../../../../components/Table";
import { viewSampleGiftDetails } from "../../../../services/utilities";
import { formatDate } from "../../../../utils/helper";
import { addIndex } from "../../../../utils/serialiseResponse";
import customToast from "../../../../components/CustomToast";

const GiftDetails = () => {
    const { GiftDetails } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [reports, setReports] = useState([]);
    const columns = useMemo(
      () => [
        // {
        //   Header: "Sr. No",
        //   accessor: "sr",
        //   disableFilters: true,
        //   disableSortBy: true,
        // },
        {
          Header: "Name / Business Name",
          accessor: "target",
          disableSortBy: true,
          minWidth: 300,
          maxWidth: 300,
          Cell: (props) => {
            const target = props.row.original.target;
            console.log("Target Debug:", target);
            return target?.businessName || target?.name || "N/A";
          }
          
        },  
        {
          Header: "Type",
          accessor: "",
          disableFilters: true,
          disableSortBy: true,
          minWidth: 200,
          maxWidth: 300,
          Cell:(props)=>{return props?.row?.original?.target.accessDoctor?"Doctor":props?.row?.original?.target.accessChemist?"Chemist":props?.row?.original?.target.accessStockist?"Stockist":null}
        },
        {
          Header: "Distributed Quantity",
          accessor: "dq",
          disableFilters: true,
          disableSortBy: true,
          minWidth: 200,
          maxWidth: 300,
          Cell:(props)=>{    
            let filteredSample=props?.row?.original?.gifts.find((e)=>e.focusGift.toString()===GiftDetails.toString())
            return filteredSample.giftQuantity?filteredSample.giftQuantity:0}
        },
        {
          Header: "Date",
          accessor: "",
          disableFilters: true,
          disableSortBy: true,
          minWidth: 200,
          maxWidth: 300,
          Cell: (props) => {
            let date = new Date(props?.row?.original?.cdt);
            date = date.toLocaleDateString();
            return date;
          }
        },
      ],
      []
    );
  
    const fetchSampleGiftDetails = async () => {
      let data = {
        month: Number(location?.state?.month),
        year: Number(location?.state?.year),
        selectedUser: location?.state?.user,
        productId: GiftDetails,
      };
      try {
        let result = await viewSampleGiftDetails("gift", data);
        setReports(result.data.data);
      } catch (error) {
        return customToast.error("Something went wrong !");
      }
    };
     
    useEffect(() => {
      fetchSampleGiftDetails();
    }, [location.state]);
    const tableData = useMemo(() => addIndex(reports), [reports]);
    console.log(location.state)
    return (
      <div className="expense">
        <div className="row">
          <h2
            className="web-app__heading"
            style={{ display: "flex", alignItems: "center" }}
          >
            <MdArrowBackIosNew
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <span style={{ display: "inline-block", margin: "0 1rem" }}>
             Gift Details...
            </span>
          </h2>
  
          <div className="filter-table">
          {reports.length > 0 ? (
            <>
             <h2 className="web-app__heading mb-4 ms-2">
              Showing ({reports.length}) Entries
            </h2>
            <Table columns={columns} data={tableData} />
            </>
           
          ):(
            <h3 className=" no-results-message">No results found</h3>
          )}
          </div>
        </div>
      </div>
    );
  
};

export default GiftDetails;
