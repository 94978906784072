import {
  viewLocations,
  newLocation,
  approveLocation,
  rejectLocation,
  viewLocationsForApproval,
  viewAllLocation,
  deleteLocation,
  viewLocationsForReporting,
  viewLocationsForReporting2,
  editLocation,
  fetchLocationV1,
  editLocationV1,
  addLocationV1,
  deleteLocationV1,
} from "../../services/locations.js";

const initialState = {
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOCATION_ROUTE":
      return action.payload;

    case "SET_LOCATION_ROUTE_REPORTING":
      return action.payload;

    case "SET_LOCATION_ROUTE_All":
      return action.payload;

    case "SET_LOCATION_ROUTE_FOR_APPROVAL":
      return action.payload;

    case "CREATE_LOCATION_ROUTE":
      return { data: [action.payload] };

    case "CREATE_LOCATION_ROUTE_FOR_APPROVAL":
      return { data: [action.payload] };

    case "CLEAR_LOCATION_ROUTE":
      return initialState;

    case "EDIT_LOCATION_ROUTE_FOR_APPROVAL":
      return { data: [action.payload] };

    case "APPROVE_ROUTE":
      return {data:state.data};

    case "REJECT_ROUTE":
      return {data:state.data};

    case "DELETE_LOCATION_ROUTE":
      return {data:[action.payload]};

    default:
      return state;
  }
};

export const setRoutes = (headquarterId) => {
  return async (dispatch) => {
    // const { data } = await viewLocations("route", headquarterId);
    const {data} = await fetchLocationV1('route', headquarterId);         // mongoose route for Routes
    dispatch({
      type: "SET_LOCATION_ROUTE",
      payload: { data: data },
    });
  };
};

export const setRoutesForApproval = (filters) => {
  return async (dispatch) => {
    const { data } = await viewLocationsForApproval("route", filters);
    
    dispatch({
      type: "SET_LOCATION_ROUTE",
      payload: { data: data},
    });
  };
};

export const setRoutesForReporting = (filter) => {
  return async (dispatch) => {
    const { data } = await viewLocationsForReporting("route", filter);
    dispatch({
      type: "SET_LOCATION_ROUTE_REPORTING",
      payload: { data: data },
    });
  };
};
export const setRoutesForReportingOnly = (filter) => {
  return async (dispatch) => {
    const { data } = await viewLocationsForReporting2("route/reporting-route", filter);
    dispatch({
      type: "SET_LOCATION_ROUTE_REPORTING",
      payload: { data: data },
    });
  };
};

export const setCreatedRoutes = (filter) => {
  return async (dispatch) => {
    const { data } = await viewAllLocation("route", filter);
    // const {data} = await fetchLocationV1("route");

    dispatch({
      type: "SET_LOCATION_ROUTE_All",
      payload: { data: data },
    });
  };
};

export const clearRoutes = () => {
  return {
    type: "CLEAR_LOCATION_ROUTE",
  };
};

export const addRoutes = (data) => {
  return async (dispatch) => {
    try {
      // data = await newLocation("route", data);
       data = await addLocationV1("route", data);
    } catch (e) {
      if (data.error) throw data.error;
    }
    dispatch({
      type: "CREATE_LOCATION_ROUTE",
      payload: { data: data },
    });
    dispatch(setRoutes());
  };
};

export const addRouteForApproval = (data) => {
  return async (dispatch) => {
    try {
      // data = await newLocation("route", data);
      data = await addLocationV1("route", data);
    } catch (e) {
      if (data.error) throw data.error;
    }
    dispatch({
      type: "CREATE_LOCATION_ROUTE_FOR_APPROVAL",
      payload: { data: data },
    });
    dispatch(setCreatedRoutes({isStatus: "true"}));
  };
};

export const editRoute = (id, data) => {
  return async (dispatch) => {
    try {
      // data = await editLocation("route", id, data);
      data = await editLocationV1("route", id, data);       // mongoose edit route 
    } catch (e) {
      if (data.error) throw data.error;
    }
    dispatch({
      type: "EDIT_LOCATION_ROUTE_FOR_APPROVAL",
      payload: { data: data },
    });
    dispatch(setRoutes());
  };
};

/*  Approve / Reject */

export const approveRoutes = (id, data) => {
  return async (dispatch) => {
    try {
      data = await approveLocation("route", id, data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "APPROVE_ROUTE",
      payload: { data: data },
    });
    dispatch(setRoutesForApproval("route"));
  };
};

export const rejectRoutes = (id, data) => {
  return async (dispatch) => {
    try {
      data = await rejectLocation("route", id, data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "REJECT_ROUTE",
      payload: { data: data },
    });
    dispatch(setRoutesForApproval("route"));
  };
};

// Delete Routes

export const deleteRoutes = (data) => {
  return async (dispatch) => {
    try {
      // data = await deleteLocation("route", data);
      data = await deleteLocationV1("route", data);
    } catch (e) {
      console.log("data error --->", data?.error)
      if (data.error) throw data.error;
    }
    dispatch({
      type: "DELETE_LOCATION_ROUTE",
      payload: { data: data },
    });
    dispatch(setRoutes());
  };
};

export default reducer;
