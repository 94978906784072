import { useEffect, useMemo, useState } from "react";
import { FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PageTitle from "../../../../components/PageTitle";
import Table from "../../../../components/Table";
import {
  clearUsers,
  setUsers,
  setUsersForUtilities,
} from "../../../../reducers/users/employeeReducer";
import { getCampaign } from "../../../../services/campaign";
import serialise from "../../../../utils/serialiseResponse";

const UserControls = () => {
  const dispatch = useDispatch();

  const userData = useSelector(({ employee }) => employee);
  const [campaigns, setCampaigns] = useState([]);
  useEffect(() => {
    dispatch(setUsersForUtilities({ includeMe: true }))
    getCampaign().then(({data}) => setCampaigns(data)).catch((err)=> console.log(err));
    return () => {
      dispatch(clearUsers());
    };
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "View",
        accessor: "details",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 60,
        maxWidth: 60,
        Cell: (props) => {
          return (
            <Link
              to={`./${props?.row?.original?._id}`}
              state={{
                userData: props?.row?.original, 
                campaigns
              }}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      },
    ],
    [campaigns]
  );
  const tableData = useMemo(() => serialise(userData), [userData]);

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="User Controls" />

        <section className="area-creation-content__info">
          {tableData.length > 0 && (
            <h2 className="web-app__heading mb-4 ms-2">
              Showing ({tableData.length}) Entries
            </h2>
          )}
          <div className="filter-table">
            <Table columns={columns} data={tableData} />
          </div>
        </section>
      </div>
    </div>
  );
};

export default UserControls;
