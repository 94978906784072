import { MdArrowBackIosNew , MdOutlineGroup} from "react-icons/md";
import Select from "react-select";
import { useSelector , useDispatch } from "react-redux";
import {
  dcrStyles,
} from "../../../public/stylesheets/selectStyles";
import {
  viewUsersForUtilities,
} from "../../../../services/utilities";
import {
  generateSelectUser,
} from "../../../../utils/serialiseResponse";
import DateRange from "../../../../components/DateRange";
import { useCallback, useState, useMemo, useEffect } from "react";
import { selectUserStyles } from "../../../public/stylesheets/selectStyles";
import { useNavigate } from "react-router-dom";
import { viewHQwisePrimarySales, viewUserWisePrimarySales, viewDateWisePrimarySales} from "../../../../services/utilities";
import customToast from "../../../../components/CustomToast";
import StockistPS from "./Types/Stockist";
import HeadquarterPS from "./Types/Headquarter";
import UserPS from "./Types/User";
import DatePS from "./Types/Date";
import { setDateRangePSR, setTypePSR } from "../../../../reducers/reports/primarySalesReducer";

const PrimarySalesReports = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loggedIn = useSelector(({ user }) => user);
  
  const dateRange = useSelector(({primarySalesReports})=>primarySalesReports.dateRange);
  const type = useSelector(({primarySalesReports})=>primarySalesReports.type);
  const [reports, setReports] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [employees, setEmployees] = useState([]);
  const [render,setRender] = useState(false);

  const typeOptions = [{label:"Stockist"} , {label:"Headquarter"} , {label:"User"}, {label:"Date"}] ;
  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );
  useEffect(() => {
    try {
      viewUsersForUtilities({includeMe: true}).then((res) => {
        setEmployees(res?.data);
      });
    } catch (error) {
      customToast.error("error occured Please Try Again");
    }
  }, []);
  
  
  const fetchUser = useCallback(
    async()=>{
      try {
          const filter = {
            id:selectedUser?.value,
            startDate: new Date(dateRange?.startDate).toISOString(),
            endDate: new Date(dateRange?.endDate).toISOString()
          };
          const {data} = await viewUserWisePrimarySales(filter);
          setReports(data)
      } catch (error) {
        customToast.error("error occured Please Try Again");
      }
    },[selectedUser,dateRange]
  )
  const fetchDate = useCallback(
    async()=>{
      try {
        const filter = {
          startDate: new Date(dateRange?.startDate).toISOString(),
          endDate: new Date(dateRange?.endDate).toISOString()
        };
        const {data} = await viewDateWisePrimarySales(filter);
        setReports(data);
      } catch (error) {
        customToast.error("error occured Please Try Again");
      }
    },[dateRange]
  )
  const handleSubmit = useCallback((e)=>{
    e.preventDefault();
    if(!type)return customToast.error("Please select all the necessary fields");
    
    if(type.label === "User")fetchUser();
    else if(type.label === "Date") fetchDate();
    else setRender(!render);
  },[type, setRender,render,fetchUser,fetchDate]);

  
  
  return (
    <div className="expense">
        <h2
          className="web-app__heading mb-5"
          style={{ display: "flex", alignItems: "center" }}
        >
          <MdArrowBackIosNew
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <span style={{ display: "inline-block", margin: "0 1rem" }}>
            Primary Sales Reports
          </span>
        </h2>
        <form onSubmit={handleSubmit}>
            <div className="date-range-container my-3">
              <DateRange dateRange={dateRange} setDateRange={(n)=>dispatch(setDateRangePSR(n))} />
            </div>
            <div className="util-tp-query d-flex justify-content-between">
                <div className="d-flex">
                    <div className="util-tp-filter me-4">
                        <p>
                            Select Type <span style={{ color: "#e74c3caa" }}>*</span>
                        </p>
                        <Select
                            styles={dcrStyles}
                            placeholder="Select Type"
                            className="mt-3"
                            options={typeOptions}
                            value={type}
                            onChange={(e) => {
                              dispatch(setTypePSR({ ...e }));
                              setReports([]);
                            }}
                        />
                    </div>
                    {(type?.label === "User") && (loggedIn?.user?.des < 121 ||
                        loggedIn?.user?.designation?.priority > 1 ||
                        loggedIn?.user?.isAdmin) && (
                        <div className="util-tp-filter me-4">
                            <p >Select User</p>
                            <div className="util-select-user">
                                <MdOutlineGroup className="util-select-user__icon" />
                                <Select
                                    styles={selectUserStyles}
                                    options={employeeSelect}
                                    placeholder="Select User"
                                    className="mt-3"
                                    value={selectedUser}
                                    onChange={(e) => {
                                      setSelectedUser({ ...e });
                                      setReports([]);
                                    }}
                                />
                            </div>
                        </div>) 
                    }
                    <div className="util-tp-filter">
                        <button type="submit" className="button-blue-gradient mt-5">
                            See Reports
                        </button>
                    </div>
                </div>
            </div>
        </form>
        <p className="text-info text-muted">We use caching on this page click see reports in case you don't see updated data.</p>
        {type?.label === "Headquarter" && <HeadquarterPS render={render}></HeadquarterPS>}
        {type?.label === "Stockist" && <StockistPS hideHeader={true} render={render} />}
        {type?.label === "User" && <UserPS dateRange={dateRange} reports={reports} user={selectedUser}></UserPS>}
        {type?.label === "Date" && <DatePS reports={reports}></DatePS>}
    </div>
  );
};

export default PrimarySalesReports;
