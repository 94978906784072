import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { RiCloseCircleLine } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import customToast from "../../../../../components/CustomToast";
import PageTitle from "../../../../../components/PageTitle";
import { getDoctorDetails } from "../../../../../services/doctor";
import { formatDate } from "../../../../../utils/helper";

const AdminViewDCSDetails = () => {
  const location = useLocation();

  const [doctor, setDoctor] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    getDoctorDetails(location?.state?._id)
    .then(({data})=>{
      setDoctor(data);
      // customToast.success("Doctor fetched successfully");
    })
    .catch(err => customToast.error("Sorry! failed to load doctor details"));
  }, [location]);

  const AddtionalDetails = ({ data }) => {
  
    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Additional Details</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowPopup(false)}
            />
          </div>

          <form>
            <div className="edit-form">

            <section className="">
              <div className="util-inner-info-box d-flex justify-content-between">
              <div>
                <h3>Created At</h3>
                <p>{formatDate(doctor?.createdAt)}</p>
                </div>
              </div>
            </section>
              <div className="user-detail-box">
                {/* <h4>Visible To</h4> */}
                <Accordion className="mt-4" alwaysOpen>
                <Accordion.Item eventKey="0">
                  <Accordion.Header><h4>Visible To</h4></Accordion.Header>
                  <Accordion.Body className="custom-scrollbar custom-scrollable" style={{backgroundColor: `var(--color-primary-dark)`, maxHeight: '30vh'}}>
                    {doctor?.accessDoctor?.map((e, i) => (
                      <div key={i} className="acc-body-card mt-2">
                        <p className="d-flex align-items-center px-4">{e.firstName} {e.lastName}</p>
                      </div>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
                {/* {
                  location?.state?.accessDoctor?.map((item, idx) => (
                    <p key={idx}>{item.firstName} {item.lastName}</p>
                  ))
                } */}
              </div>
            </div>
          </form>
        </div>
      </section>
    );
  };

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Doctor Details" hierarchy={true} isMoreInfo={true} toggle={setShowPopup}/>
      </div>
      <section className="user-detail-container user-detail-container-green-border">
        <div className="user-detail-box">
          <h4>Name</h4>
          <p>{doctor?.name}</p>
        </div>

        <div className="user-detail-box">
          <h4>Degree</h4>
          <p>{doctor?.degree}</p>
        </div>

        <div className="user-detail-box">
          <h4>Specialization</h4>
          <p>{doctor?.specialization}</p>
        </div>

        <div className="user-detail-box">
          <h4>Mobile Number</h4>
          <p>{doctor?.mobile}</p>
        </div>
      </section>
      <section className="user-detail-container user-detail-container-yellowDark-border">
      <div className="user-detail-box">
          <h4>BirthDay</h4>
          <p>{formatDate(doctor?.birthday)}</p>
        </div>

        <div className="user-detail-box">
          <h4>Marriage Anniversary</h4>
          <p>{formatDate(doctor?.anniversary)}</p>
        </div>

        <div className="user-detail-box">
          <h4>HeadQuarter</h4>
          <p>{doctor?.city?.name}</p>
        </div>

        <div className="user-detail-box">
          <h4>Working Area</h4>
          <p>{doctor?.workingArea?.name}</p>
        </div>
      </section>
      <section className="user-detail-container user-detail-container-green-border">
        <div className="user-detail-box">
          <h4>Clinic Contact Number</h4>
          <p>{doctor?.contact}</p>
        </div>

        <div className="user-detail-box">
          <h4>Doctor's Code</h4>
          <p>{doctor?.doctorCode}</p>
        </div>

        <div className="user-detail-box">
          <h4>Email</h4>
          <p>{doctor?.email}</p>
        </div>

        <div className="user-detail-box">
          <h4>Category</h4>
          <p>{doctor?.category.label}</p>
        </div>
      </section>

      <section className="user-detail-container user-detail-container-yellowDark-border">
      <div className="user-detail-box">
          <h4>Clinic's Address</h4>
          <p>{doctor?.address}</p>
      </div>

      <div className="user-detail-box">
          <h4>Extra Info</h4>
          <p>{doctor?.extraInfo}</p>
      </div>

      <div className="user-detail-box">
          <h4>Hospital</h4>
          <p>{doctor?.hospital}</p>
      </div>
      </section>

      {showPopup && <AddtionalDetails />}
    </div>
  );
};

export default AdminViewDCSDetails;
