import { useState, useCallback, useMemo, useEffect, lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import {
  dcrStyles,
  selectUserStyles,
} from "../../../../public/stylesheets/selectStyles";
import { monthOptions, yearOptions } from "../../../../../utils/helper";
import {
  viewUsersForUtilities,
  viewUtilTargets,
} from "../../../../../services/utilities";
import serialise, {
  addIndex,
  generateSelectData,
  generateSelectUser,
} from "../../../../../utils/serialiseResponse";
import Table from "../../../../../components/Table";
import { MdArrowBackIosNew, MdOutlineGroup } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import moment from "moment-timezone";
const DatePickers = lazy(() => import("../../../../../components/DatePicker"))

const UtilAllottedSamples = () => {
  const navigate = useNavigate();
  const loggedIn = useSelector(({ user }) => user);

  const [month, setMonth] = useState(moment().month());
  const [year, setYear] = useState(moment().year());
  const [reports, setReports] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [employees, setEmployees] = useState([]);
  const [employeeDataForExcel, setEmployeeDataForExcel] = useState();
  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );
  const employeeData = useMemo(
    () => generateSelectData(employees, "fullName"),
    [employees]
  );

  useEffect(() => {
    try {
      let tempdata = employeeData.filter((empData) => {
        if (empData.label === selectedUser.label) return true;
      })
      setEmployeeDataForExcel(tempdata[0]);
    } catch (error) {
      console.log(error);
    }
  }, [selectedUser]);

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Product",
        accessor: "productName",
        minWidth: 80,
        maxWidth: 80,
        showTotal:true,
        placeholderTotal:"Total"
      },
      {
        Header: "Allotted",
        accessor: "issued",
        minWidth: 85,
        maxWidth: 85,
        showTotal:true,
        totalAccessor:"issued"
      },
    ],
    []
  );
  const columns2 = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "User",
        accessor: "user.firstName"
      },
      {
        Header: "Balanced",
        accessor: "totalBalance"
      },
      {
        Header: "Issued",
        accessor: "totalIssued"
      },
      {
        Header: "View",
        accessor: "4",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 50,
        maxWidth: 50,
        Cell: (props) => {
          return (
            <div
              onClick={(e) => { createSelectedUserObject(props.row.original.user) }}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </div>
          );
        },
      },
    ],
    [month ,year]
  );
  const createSelectedUserObject = async (userData) => {
    if (!userData) {
      return;
    }
    const selectedUserObject = {
      label: userData.firstName,
      value: userData._id,
      designation: userData.des,
    };
    setSelectedUser(selectedUserObject);
    const data = {
      month: month,
      year: Number(year),
      userId: userData._id,
    };
    await viewUtilTargets("sample", data).then((res) => setReports(res.data));
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (selectedUser) {
        let data = {
          userId: selectedUser?.value,
          month: month,
          year: Number(year),
        };

        await viewUtilTargets("sample", data).then((res) => setReports(res.data));
      } else {
        let data = {
          month: month,
          year: Number(year),
        };

        await viewUtilTargets("allSamples", data).then((res) => setReports(res.data));
      }
    },
    [month, year, selectedUser]
  );
  const tableData = useMemo(() => addIndex(reports), [reports]);

  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees(res?.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <section className="admin-creation-content__heading">
          <MdArrowBackIosNew
            className="admin-creation-content__back-button"
            onClick={() => navigate(-1)}
          />
          <h2 className="web-app__heading">Samples Allotted</h2>
        </section>

        <form onSubmit={handleSubmit}>
          <div className="util-list-filters">
            <div className="util-tp-filter">
              <p className="mb-3">
                Select Month <span className="asterisk-imp">*</span>
              </p>
              <Suspense>
                <DatePickers
                  placeholder={(month && year ) ? moment({ year, month }).format('MMM, YYYY') : "Select Month"}
                  setMonth={setMonth}
                  setYear={setYear}
                />
              </Suspense>
            </div>

            {loggedIn?.user?.des < 121 ||
            loggedIn?.user?.designation?.priority > 1 ||
            loggedIn?.user?.isAdmin ? (
              <div className="util-tp-filter">
                <p className="util-select-user__label">Select User</p>
                <div className="util-select-user">
                  <MdOutlineGroup className="util-select-user__icon" />
                  <Select
                    styles={selectUserStyles}
                    options={employeeSelect}
                    placeholder="Select User"
                    className="mt-3"
                    value={selectedUser}
                    onChange={(e) => {setSelectedUser({ ...e })
                    setReports([])}}
                  />
                </div>
              </div>
            ) : null}
            <div className="util-tp-filter">
              <button type="submit" className="button-blue-gradient mt-5">
                See Reports
              </button>
            </div>
          </div>
        </form>

        <section className="area-creation-content__info" style={{ marginTop: "-4rem" }}>
          <div className="filter-table">
          {tableData.length > 0 && (
              <h2 className="web-app__heading mb-4 ms-2">
                Showing ({tableData.length}) Entries
              </h2>
            )}
          
            {tableData === undefined ? null : (
                tableData.length > 0 ? (
                  <Table columns={selectedUser? columns : columns2} data={tableData} fileSrc="Samples Allotted" empl={selectedUser ? employeeDataForExcel : loggedIn?.user}
                  month={month} />
                ) : (
                  <h3 className="ms-2 no-results-message">No results found</h3>
                )
            )}
          </div>
        </section>
      </div>
    </main>
  );
};

export default UtilAllottedSamples;
