import { newLocation, viewLocations } from "../../services/locations.js";

const initialState = {
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOCATION_AREA":
      return action.payload;

    case "CREATE_LOCATION_AREA":
      return { data: [...state.data, action.payload.data.data] };

    case "CLEAR_LOCATION_AREA":
      return initialState;

    default:
      return state;
  }
};

export const setAreas = (hqId) => {
  return async (dispatch) => {
    const { data } = await viewLocations("area", hqId);
    dispatch({
      type: "SET_LOCATION_AREA",
      payload: { data: data },
    });
  };
};

export const addArea = (data) => {
  return async (dispatch) => {
    try {
      data = await newLocation("area", data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "CREATE_LOCATION_AREA",
      payload: { data: data },
    });
  };
};

export const clearAreas = () => {
  return {
    type: "CLEAR_LOCATION_AREA",
  };
};

export default reducer;
