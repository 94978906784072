import { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import { generateSelectData } from "../../../../utils/serialiseResponse";
import {
  fetchDependents,
  transferDataFromLocation,
} from "../../../../services/locations";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../../reducers/locations/hqReducer";
import {
  clearCities,
  setCities,
} from "../../../../reducers/locations/cityReducer";
import customToast from "../../../../components/CustomToast";
import PageTitle from "../../../../components/PageTitle";

const AdminCityDataTransfer = () => {
  const dispatch = useDispatch();
  const headquarters = useSelector(({ headquarter }) => headquarter);
  const cities = useSelector(({ city }) => city);
  const [citiesInSelectedHq, setCitiesInSelectedHq] = useState([]);
  const [headquarter, setHeadquarter] = useState();
  const [fromArea, setFromArea] = useState(null);
  const [toArea, setToArea] = useState(null);

  const [dependents, setDependents] = useState([]);

  const citySelect = useMemo(() => {
    return generateSelectData(cities.data, "name");
  }, [cities]);

  const hqSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );
  const fetchDependencies = useCallback(async (Id) => {
    try {
      const response = await fetchDependents(Id, "city");
      setDependents(response?.data);
    } catch (error) {
      customToast.error("Sorry! We Encountered an Error");
    }
  }, []);

  useEffect(() => {
    if (fromArea) fetchDependencies(fromArea.value);
  }, [fromArea]);

  useEffect(() => {
    if (headquarter) {
      let data = cities?.data?.filter((e) => {
        return e?.headquarter?._id === headquarter.value;
      });
      setCitiesInSelectedHq(generateSelectData(data, "name"));
    }
  }, [headquarter, cities]);

  useEffect(() => {
    dispatch(setCities());
    dispatch(setHeadquarters());
    return () => {
      dispatch(clearHeadquarters());
      dispatch(clearCities());
    };
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!headquarter || !toArea || !fromArea)
      return customToast.error("Please fill all details");
    await transferDataFromLocation({
      cityOrArea: toArea.value,
      hq: headquarter.value,
      fromCityArea: fromArea.value,
    });
    customToast.success("Data Transferred Successfully");
    setDependents({});
    try {
    } catch (e) {
      customToast.error("Sorry! We Encountered an Error");
    }
  };

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Transfer DCS" hierarchy="true"/>

        <section className="area-creation-content__form">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">
                  From Local Area <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="localArea"
                  id="localArea"
                  value={fromArea}
                  options={citySelect}
                  onChange={(e) =>
                    setFromArea({ value: e.value, label: e.label })
                  }
                  styles={adminStyles}
                  placeholder="Select Local Area"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">
                  To Headquarter <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="headquarter"
                  id="headquarter"
                  value={headquarter}
                  options={hqSelect}
                  onChange={(e) =>
                    setHeadquarter({ value: e.value, label: e.label })
                  }
                  styles={adminStyles}
                  placeholder="Select Headquarter"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">
                  To Local Area <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="localArea"
                  id="localArea"
                  value={toArea}
                  options={citiesInSelectedHq}
                  onChange={(e) =>
                    setToArea({ value: e.value, label: e.label })
                  }
                  styles={adminStyles}
                  placeholder="Select Local Area"
                />
              </div>
            </div>
            {dependents?.totalCount > 0 ? (
              <div>
                <h2 className="text-danger my-4">
                  {dependents?.totalCount} Dependencies
                </h2>
                <div>Stockist ({dependents.stockistCount})</div>
                <div>Chemist ({dependents.chemistCount})</div>
                <div>Doctors ({dependents.doctorCount})</div>
              </div>
            ) : (
              <h2
                className="my-4"
                style={{ fontSize: "18px", color: "#2ecc71" }}
              >
                No Dependencies
              </h2>
            )}
            {dependents?.totalCount > 0 && (
              <div className="col-sm-12 col-md-3 col-lg-3 mt-5 admin-content-submit-button-left">
                <button type="submit" className="button-blue-gradient">
                  Transfer Data
                </button>
              </div>
            )}
          </form>
        </section>
      </div>
    </div>
  );
};

export default AdminCityDataTransfer;
