import React, { useCallback, useEffect, useState } from "react";
import styles from "./wallet.module.css";
import { createWalletPayment, vairfyWalletPayment, verifyWalletPayment, viewWalletDetails } from "../../../services/wallet";
import customToast from "../../../components/CustomToast";
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from "react-icons/io";
import {formatDate} from '../../../utils/helper'
import { MdArrowBackIosNew , MdFileDownload } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {adminStyles} from "../../../views/public/stylesheets/selectStyles.js";
import { validate } from "uuid";
import { useDispatch } from "react-redux";
import { createPayment, getInvoices } from "../../../services/payment";
import { PDFDownloadLink } from "@react-pdf/renderer";
import TransactionPdfDownloader from "../../../components/TransactionPDFDownloader/index"
import { getCompanyName } from "../../../services/settings";



const Wallet = () => {

  const [walletData, setWalletData] = useState({});
  const [isSection, SetIsSection] = useState(false)
  const navigate =  useNavigate()
  const dispatch =  useDispatch()
  const [invoices, setInvoices] = useState([]) ;
  const [amount, setAmount] = useState("")
  const [compName, setCompName] = useState({})
  // console.log(walletData)
  // console.log(walletData.walletHistory)
  console.log(walletData)
  console.log(walletData.walletHistory)


  useEffect(() => {
    viewWalletDetails()
    .then(res => setWalletData(res?.data))
    .catch(err => customToast.error("Error Fetching Wallet Details"))
  },[])
  useEffect(() => {
    getCompanyName().then(({data}) => {
      setCompName(data.res);
    })
  }, [])
  console.log("compname:",compName)
  

  const handleRecharge = (e) => {
    e.preventDefault()
    if (!amount) {
      console.log("Please enter an amount");
      return;
    }
    createWalletPayment({amount: amount}); 
    paymentHandler({
      amount: amount,
      // month: new Date().getMonth() + 1,
      // year: new Date().getFullYear(),
  });
    
  }
  const paymentHandler = useCallback(async (data) => {
    console.log(data)
    function loadScript(src) {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => resolve(true);
        script.onerror = () => resolve(false);
        document.body.appendChild(script);
      });
    }
  
    // Load Razorpay script
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
    if (!res) {
      alert("Payment Gateway unavailable. Please check your Internet connection.");
      return;
    }
  
    try {
      // Call API to create payment order
      const paymentData = await createWalletPayment({ amount: data.amount });
      console.log(paymentData)
      if (!paymentData || !paymentData.data) {
        alert("Server error! Payment data not received.");
        return;
      }
  
      const { amount, order_id, currency } = paymentData.data;
  
      // Razorpay options
      const options = {
        key: "rzp_live_jkhhql8mjXpxUS",
        amount: amount,
        currency: currency,
        name: "Medorn_Ventures",
        order_id: order_id,
        // prefill: {
        //   name: companyName?.companyName || "User",
        //   email: companyName?.email || "user@example.com",
        //   contact: companyName?.mobile || "9999999999",
        // },
        handler: async function (response) {
          console.log("Payment Successful:", response);
  
          try {
            // Call API to verify payment
            const { data } = await verifyWalletPayment({
              orderCreationId: order_id,
              paymentFor: "ERP Subscription",
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
              amount:data.amount,
            
            });
  
            alert(data.message);
  
            // Refresh invoices after payment success
            getInvoices({ year: new Date().getFullYear() })
              .then(({ data }) => setInvoices(data))
              .catch(() => customToast.error("Failed to fetch Invoices."));
          } catch (err) {
            console.error("Payment verification failed:", err);
            alert("Some error occurred while verifying payment!");
          }
        },
        theme: { color: "teal" },
        notes: { paymentFor: "MOCK" },
      };
  
      // Open Razorpay UI
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error("Error in paymentHandler:", error);
      alert("Payment process failed. Please try again.");
    }
  }, []);
  

  
  return (
    <div className={styles.mainContent}>
      <h2 className={`${styles.webAppHeading} ${styles.dcrHeading}`} onClick={() => navigate(-1)} > <MdArrowBackIosNew /> WALLETS</h2>
      <div className={styles.walletsContent}>
        <div className={styles.rowBg2}>
          <p className={styles.mb2}>TOTAL AVAILABLE AMOUNT</p>
          <div className={styles.amountContainer}>
            <p className={styles.ammount} style={{ fontFamily: "'Orbitron', sans-serif" }}>₹ {walletData?.wallet}</p>
          </div>
        </div>


        {/* <div className={`${styles.walletsTagReContainer} d-flex mt-4 gap-md-3 gap-lg-5`}>
          <div className={styles.walletsTags}>
            <h4>🟢 Credited</h4>
            <h4>🔴 Debited</h4>
          </div>
          <div className={styles.walletsRecharge}>
            <h4>Recharge Wallets </h4>
            <h4>
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 512 512"
                className="icon-color-green ms-auto cursor-pointer expense-calculation__container-icon-money"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M48 256c0 114.9 93.1 208 208 208s208-93.1 208-208S370.9 48 256 48 48 141.1 48 256zm289.1-43.4c7.5-7.5 19.8-7.5 27.3 0 3.8 3.8 5.6 8.7 5.6 13.6s-1.9 9.9-5.7 13.7l-94.3 94c-7.6 6.9-19.3 6.7-26.6-.6l-95.7-95.4c-7.5-7.5-7.6-19.7 0-27.3 7.5-7.5 19.7-7.6 27.3 0l81.1 81.9 81-79.9z"></path>
              </svg>
            </h4>
          </div>
        </div> */}

              <div className="tp-tags d-flex mt-4 gap-md-3 gap-lg-5">
                  <h4>
                      <span className="tp-indicator-9 me-2"></span>Approved
                  </h4>
                  <h4>
                      <span className="tp-indicator-10 me-2"></span>Rejected
                  </h4>
                  {
                      !isSection ? (
                          <div className="ms-auto d-flex align-items-center gap-2">
                              <h4>Recharge Wallet</h4>
                              <h4>
                                  <IoIosArrowDropdownCircle
                                      className="icon-color-green ms-auto cursor-pointer expense-calculation__container-icon-money"
                                      onClick={() => SetIsSection(true)}
                                  />
                              </h4>
                          </div>
                      ) : (
                          <div className="ms-auto d-flex align-items-center gap-2">
                              <h4>Recharge Wallet</h4>
                              <h4>
                                  <IoIosArrowDropupCircle
                                      className="icon-color-green ms-auto cursor-pointer expense-calculation__container-icon-money"
                                      onClick={() => SetIsSection(false)}
                                  />
                              </h4>
                          </div>
                      )
                  }
              </div>
              <main className="admin-content">
              {isSection && (
                <form >
                <div className="primarysales-filter">
                  <div className="util-tp-filter">
                    <p className="mb-2"> Enter Amount  <span className="asterisk-imp">*</span></p>
                    <input
                     type="text"
                     className=""
                     placeholder="Enter Amount"
                     style={adminStyles}
                     value={amount}
                     onChange={(e)=>{setAmount(e.target.value)}}
                    />
                 </div>
                 <button type="button" className="button-blue-gradient mt-5"
                 onClick={(e) => handleRecharge(e) }
                 >
                  Recharge
                </button>
                </div>
                </form>
              )}
              </main>

        <div className={styles.yourTrans}>
          <h4 className={styles.recentWallets}>RECENT WALLETS TRANSACTIONS</h4>
          <div>
            {walletData && walletData.walletHistory && Array.isArray(walletData.walletHistory) && walletData.walletHistory.map((transaction, index) => (
              <div key={index} className={styles.transData}>
                <div id={styles.redStrips} style={{ backgroundColor: transaction.color, width: "5px" }}></div>
                <div className={`${styles.transDataData} flex flex-wrap gap-8`}>
                  <div className={styles.fixSapceDynamicValue}>
                    <h3 className={`${transaction.status === "DEBIT" ? styles.debCre : styles.debCgr} ${styles.addFontWeight}`}>
                      {transaction.status}
                    </h3>
                  </div>
                  <h3>Amount: {transaction.amount}</h3>
                  <h3>Date: {formatDate(transaction.date)}</h3>
                  {/* <h3>Time: {transaction.time}</h3> */}
                </div>
                {transaction.status === "CREDIT" && (
                  <div className="bg-red-300">
                   <PDFDownloadLink
                     document={<TransactionPdfDownloader billObj={transaction} paymentDetails={transaction.paymentDetails} compDetails={compName} />}
                     fileName="transaction.pdf"
                    >
                      {({ loading }) =>
                      loading ? 'Generating PDF...' : <MdFileDownload className={styles.blueImg} style={{ color: "#36ABF9" }} />
                      }
                    </PDFDownloadLink>
                         
                
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
