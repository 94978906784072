import { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../../components/PageTitle";
import customToast from "../../../../components/CustomToast";
import { BsCheck2Circle, BsXCircle } from "react-icons/bs";
import { MdClear, MdDoneAll, MdCheckBoxOutlineBlank } from "react-icons/md";
import {ImCheckboxUnchecked, ImCheckboxChecked} from "react-icons/im" ;
import { MdDelete } from "react-icons/md";
import { RiCloseCircleLine } from "react-icons/ri";
import Select from "react-select";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import serialise, {
  addIndex,
  generateSelectData,
} from "../../../../utils/serialiseResponse";
import {
  clearStates,
  setStates,
} from "../../../../reducers/locations/stateReducer";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../../reducers/locations/hqReducer";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineEdit } from "react-icons/ai";
import { viewTargets } from "../../../../services/targets";
import Table from "../../../../components/Table";
import { RiEdit2Fill } from "react-icons/ri";
import { clearDoctors, deleteDoctor, setDoctors } from "../../../../reducers/targets/doctor";
import { deleteChemist } from "../../../../reducers/targets/chemist";
import { deleteStockist } from "../../../../reducers/targets/stockist";

const typeOptions = [
  { value: 1, label: "Doctor" },
  { value: 2, label: "Chemist" },
  { value: 3, label: "Stockist" },
];

const EditDeleteDCS = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const states = useSelector(({ state }) => state);
  const headquarters = useSelector(({ headquarter }) => headquarter);
  const doctors = useSelector(({ doctor }) => doctor);

  const [state, setState] = useState(null);
  const [headquarter, setHeadquarter] = useState(null);
  const [type, setType] = useState(typeOptions[0]);
  const [dcsData, setDcsData] = useState([]);
  const [deleteMany, setDeleteMany] = useState(false);
  const [allSelected, setAllSelected] = useState(false);  
  const [showEdit, setShowEdit] = useState(false);

  const SelectColumnFilter = useCallback(
    ({ column, columns }) =>{
      return (
        <div className="search-filter__container" onClick={(e)=>{e.stopPropagation()}}>
          {<span className="d-flex gap-3 align-items-center">{column.render("Header")}
            {allSelected ? (
                <ImCheckboxChecked onClick={(e) => {
                  e.preventDefault();
                  column?.filteredRows?.forEach((el,idx)=>{
                    el.original.isSelected = false ;
                  });
                  setAllSelected(false);
                }}/>
              ):(
                <ImCheckboxUnchecked onClick={(e) => {
                  e.preventDefault();
                  column?.filteredRows?.forEach((el,idx)=>{
                    el.original.isSelected = true ;
                  });
                  setAllSelected(true);
                }}/>
              )
              }
          </span>
          }
        </div>
  );},[allSelected])

  const asyncFetch = useCallback(async () => {
    await viewTargets(type?.label?.toLowerCase(), {
      hqId: headquarter?.value,
      state: state?.value,
      dcr: true,
      showApproved: true
    }).then((res) => setDcsData(res.data));
  }, [type, headquarter])

  useEffect(() => {
    asyncFetch();
  }, [type, headquarter]);
  
  const handleDelete = async (data) => {
    if(data.length === 0)return customToast.error(`Please select atleast one ${type?.label}`);
    const payload = {ids:data , deleteTyp:"bulk"} ;
    if(type?.label === "Doctor")
      await dispatch(deleteDoctor(1, payload));
    else if(type?.label === "Chemist")
      await dispatch(deleteChemist(1, payload));
    else if(type?.label === "Stockist")
      await dispatch(deleteStockist(1, payload));

    setAllSelected(false);
    setShowEdit(false);
    setDeleteMany(false);
    customToast.success(`${type?.label} Deleted Successfully`);
    
    asyncFetch();

  };

  const tableData = useMemo(() => addIndex(dcsData), [dcsData]);
  const EditPopup = () => {
    const data = [];
    tableData.forEach((el,idx)=>{
      if(el?.isSelected){
        data.push(el?._id);
      }
    })
    return (
      <section className="edit-popup" onClick={(e) =>
        showEdit
          ? e.target.className === "edit-popup"
            ? setShowEdit(false)
            : null
          : null
      }>
      {/* <section className="edit-popup"> */}
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              setShowEdit(false);
            }}
          >
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  {`Are you sure you want to delete ${data.length} ${type?.label}`}
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button
                type="button"
                className="button-delete mt-4"
                onClick={() => handleDelete(data)}
              >
                <span className="button-delete__icon-container">
                  <BsXCircle className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Delete</span>
              </button>
              <button
                type="button"
                className="button-submit-green mt-4"
                onClick={() => setShowEdit(false)}
              >
                <span className="button-submit-green__icon-container">
                  <BsCheck2Circle className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Cancel</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };
  const columnData = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Name",
        accessor: "name",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Degree",
        accessor: "degree",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Specialization",
        accessor: "specialization",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Mobile Number",
        accessor: "mobile",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "HQ",
        accessor: "city.name",
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: deleteMany ? "Select" : "Actions",
        accessor: "",
        Cell: (props) => {
          const [local,setLocal] = useState(props?.row?.original?.isSelected || false);
          return deleteMany ? (
            <span
              className="react-table-view-link display-6"
            >    
              {local ? (
                <ImCheckboxChecked onClick={() => {
                  props.row.original.isSelected = false ;
                  setLocal(false); 
                }}/>
              ):(
                <MdCheckBoxOutlineBlank onClick={() => {
                  props.row.original.isSelected = true ;
                  setLocal(true);
                }}/>
              )
              }
            </span>
          ):(
            <span
              className="react-table-view-link"
              onClick={() => {
                navigate(
                  `/admin-panel/dcs/edit-delete/${type?.label?.toLowerCase()}`,
                  { state: props?.row?.original }
                );
              }}
            >
              <RiEdit2Fill className="icon-color-green" />
            </span>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: deleteMany ? false : true,
        disableSortBy: true,
        Filter:SelectColumnFilter,
      },
    ],
    [type, navigate, deleteMany, SelectColumnFilter,tableData]
  );

  const chemColumnData = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Business Name",
        accessor: "businessName",
        maxWidth: 80,
      },
      {
        Header: "Propreitor Name",
        accessor: "name",
        maxWidth: 80,
      },
      {
        Header: "Address",
        accessor: "address",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Mobile Number",
        accessor: "chemistContact",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "HQ",
        accessor: "city.name",
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: deleteMany ? "Select" : "Actions",
        accessor: "",
        Cell: (props) => {
          const [local,setLocal] = useState(props?.row?.original?.isSelected || false);
          return deleteMany ? (
            <span
              className="react-table-view-link display-6"
            >    
              {local ? (
                <ImCheckboxChecked onClick={() => {
                  props.row.original.isSelected = false ;
                  setLocal(false); 
                }}/>
              ):(
                <MdCheckBoxOutlineBlank onClick={() => {
                  props.row.original.isSelected = true ;
                  setLocal(true);
                }}/>
              )
              }
            </span>
          ):(
            <span
              className="react-table-view-link"
              onClick={() => {
                navigate(
                  `/admin-panel/dcs/edit-delete/${type?.label?.toLowerCase()}`,
                  { state: props?.row?.original }
                );
              }}
            >
              <RiEdit2Fill className="icon-color-green" />
            </span>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: deleteMany ? false : true,
        disableSortBy: true,
        Filter:SelectColumnFilter,
      },
    ],
    [type, navigate, deleteMany, SelectColumnFilter,tableData]
  );

  const stkColumnData = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Business Name",
        accessor: "businessName",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Propreitor Name",
        accessor: "name",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Address",
        accessor: "address",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Mobile Number",
        accessor: "stockistContact",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "HQ",
        accessor: "city.name",
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: deleteMany ? "Select" : "Actions",
        accessor: "",
        Cell: (props) => {
          const [local,setLocal] = useState(props?.row?.original?.isSelected || false);
          return deleteMany ? (
            <span
              className="react-table-view-link display-6"
            >    
              {local ? (
                <ImCheckboxChecked onClick={() => {
                  props.row.original.isSelected = false ;
                  setLocal(false); 
                }}/>
              ):(
                <MdCheckBoxOutlineBlank onClick={() => {
                  props.row.original.isSelected = true ;
                  setLocal(true);
                }}/>
              )
              }
            </span>
          ):(
            <span
              className="react-table-view-link"
              onClick={() => {
                navigate(
                  `/admin-panel/dcs/edit-delete/${type?.label?.toLowerCase()}`,
                  { state: props?.row?.original }
                );
              }}
            >
              <RiEdit2Fill className="icon-color-green" />
            </span>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: deleteMany ? false : true,
        disableSortBy: true,
        Filter:SelectColumnFilter,
      },
    ],
    [type, navigate, deleteMany, SelectColumnFilter,tableData]
  );

  const stateSelect = useMemo(
    () => generateSelectData(states.data, "name"),
    [states]
  );
  const headquarterSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );
  useEffect(() => {
    dispatch(setStates());
    return () => {
      dispatch(clearStates());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!state) return;

    dispatch(setHeadquarters(state.value));
    return () => {
      dispatch(clearHeadquarters());
    };
  }, [dispatch, state]);

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Edit / Delete" />

        <section className="area-creation-content__info">
          <form>
            <div className="row">
              <div className="col-sm-12 col-md-3 col-lg-3">
                <label htmlFor="state">Select State</label>
                <br />
                <Select
                  name="state"
                  id="state"
                  value={state}
                  options={stateSelect}
                  onChange={(e) => setState({ ...e })}
                  styles={adminStyles}
                  placeholder="Select State"
                />
              </div>

              <div className="col-sm-12 col-md-3 col-lg-3">
                <label htmlFor="headquarter">Select HQ</label>
                <br />
                <Select
                  name="headquarter"
                  id="headquarter"
                  value={headquarter}
                  options={headquarterSelect}
                  onChange={(e) => setHeadquarter({ ...e })}
                  styles={adminStyles}
                  placeholder="Select Headquarter"
                />
              </div>

              <div className="col-sm-12 col-md-3 col-lg-3">
                <label htmlFor="type">Select Type</label>
                <br />
                <Select
                  name="type"
                  id="type"
                  value={type}
                  options={typeOptions}
                  onChange={(e) => {setType({ ...e });setDeleteMany(false);setAllSelected(false)}}
                  styles={adminStyles}
                  placeholder="Doc / Chem / Stk"
                />
              </div>
              <div className="col-sm-12 col-md-3 col-lg-3 align-self-end">
                {deleteMany ? (
                  <div className="d-flex justify-content-between align-items-end flex-row-reverse">
                  <span
                    className="react-table-view-link"
                    onClick={() => {
                     setDeleteMany(false);
                    }}
                  >
                    <RiEdit2Fill className="icon-color-green" />
                  </span>
                  <button
                    type="submit"
                    className="button-delete"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowEdit(true);
                    }}
                  >
                    <span className="button-delete__icon-container">
                      <BsXCircle className="button-delete__icon" />
                    </span>
                    <span className="button-delete__text">Delete</span>
                  </button></div>
                ):(
                  <span className="icon-color-error h-100 d-flex justify-content-end">
                    <MdDelete className="cursor-pointer" onClick={(e)=>{
                      e.preventDefault();
                      setDeleteMany(true);
                    }}/>
                  </span>
                )}
              </div>
            </div>
          </form>

          <div className="filter-table">
            {/* <MaterialTable
              editable={{
                onRowDelete: () => {},
              }}
              actions={[
                {
                  icon: () => (
                    <AiOutlineEdit className="mui-table-approve-button" />
                  ),
                  onClick: (e, data) =>
                    navigate(
                      `/admin-panel/dcs/edit-delete/${type?.label?.toLowerCase()}`,
                      {
                        state: data,
                      }
                    ),
                },
              ]}
              options={{
                exportButton: true,
                emptyRowsWhenPaging: false,
                actionsColumnIndex: -1,
                pageSizeOptions: [5, 10, 20, 50, 100],
              }}
              columns={
                type?.label?.toLowerCase() === "doctor"
                  ? columnData
                  : type?.label?.toLowerCase() === "chemist"
                  ? chemColumnData
                  : stkColumnData
              }
              data={dcsData}
              title="Doctors"
            /> */}
            <Table
              columns={
                type?.label?.toLowerCase() === "doctor"
                  ? columnData
                  : type?.label?.toLowerCase() === "chemist"
                  ? chemColumnData
                  : stkColumnData
              }
              data={tableData}
            />
          </div>
        </section>
        {showEdit ? <EditPopup /> : null}
      </div>
    </main>
  );
};

export default EditDeleteDCS;
