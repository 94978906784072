import {
  deleteUserInfo,
  modifyUserInfo,
  newUser,
  viewUser,
  viewUsers,
  viewUsersForCreation,
  viewUsersForUtil,
  viewUserCount,
  updateUserInfo,
  viewUsersV1,
  viewUsersForCreationV1
} from "../../services/users.js";
import { viewUsersForUtilities } from "../../services/utilities.js";

const initialState = [];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USERS":
      return action.payload.data;

    case "SET_USERS_UTIL":
      return action.payload.data;

    case "SET_USERS_DURING_CREATION":
      return action.payload.data;

    case "SET_USER":
      return action.payload;

    case "CREATE_USER":
      return [...state, action.payload.data];

    case "CLEAR_USERS":
      return initialState;

    case "EDIT_USERS":
      return { ...state, newState: action.payload };

    case "EDIT_USERS_PROFILE":
      return { ...state, newState: action.payload };

    case "DELETE_USERS":
      return action.payload;
      
    case "SET_USER_COUNT" :
        return action.payload.data ;
    default:
      return state;
  }
};

export const setUser = (id) => {
  return async (dispatch) => {
    const { data } = await viewUser();

    dispatch({
      type: "SET_USER",
      payload: { data: data },
    });
  };
};

export const setUsers = (type, details) => {
  return async (dispatch) => {
    // let { data } = await viewUsers(type, details);
    let { data } = await viewUsersV1(type, details);
    

    dispatch({
      type: "SET_USERS",
      payload: { data: data },
    });
  };
};

export const setUsersForUtilities = (filters) => {
  return async (dispatch) => {
    const { data } = await viewUsersForUtilities(filters);

    dispatch({
      type: "SET_USERS_UTIL",
      payload: { data: data },
    });
  };
};

export const setUserDuringCreation = (details) => {
  return async (dispatch) => {
    // const { data } = await viewUsersForCreation(details);
    const {data} = await viewUsersForCreationV1(details);

    dispatch({
      type: "SET_USERS_DURING_CREATION",
      payload: { data: data },
    });
  };
};

export const addUser = (data) => {
  return async (dispatch) => {
    let response;

    try {
      response = await newUser(data);
    } catch (e) {
      if (response.error) throw response.error;
    }

    dispatch({
      type: "CREATE_USER",
      payload: { data: response },
    });
  };
};

export const clearUsers = () => {
  return {
    type: "CLEAR_USERS",
  };
};

export const editUser = (id, data) => {
  return async (dispatch) => {
    try {
      
      data = await modifyUserInfo("user", id, data);
      
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "EDIT_USERS",
      payload: { data: data },
    });
    
    dispatch(setUsers());
  };
};


export const editUserProfile = (id, data) => {
 
  return async (dispatch) => {
    try {
      data = await updateUserInfo("user", id, data);
      // console.log(first)
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "EDIT_USERS_PROFILE",
      payload: { data: data },
    });
    dispatch(setUsers());
  };
};


export const deleteUser = (data, details) => {
  return async (dispatch) => {
    try {
      data = await deleteUserInfo("user", data);
    } catch (e) {
      if (data.error) throw data.error;
    }
    dispatch({
      type: "DELETE_USERS",
      payload: { data: data },
    });
    dispatch(setUsers("user", details));
  };
};
export const setUserCount = () => {
  return async (dispatch) => {
    try {
      const {data}  = await viewUserCount();
      dispatch({
        type: "SET_USER_COUNT",
        payload: { data: data },
      });
    } catch (e) {
      console.log(e.message);
    }
  };
};

export default reducer;
