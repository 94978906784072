import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { MdArrowBackIos } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { adminStyles, selectStyles } from '../../../public/stylesheets/selectStyles';
import Select from "react-select";
import * as XLSX from "xlsx";
import { useDispatch, useSelector } from 'react-redux';
import { viewProducts, viewProductV1 } from '../../../../services/products';
import customToast from '../../../../components/CustomToast';
import { clearHeadquarters, setHeadquarters } from '../../../../reducers/locations/hqReducer';
import { clearStockists, setStockists } from '../../../../reducers/targets/stockist';
import { addIndex, generateSelectData } from '../../../../utils/serialiseResponse';
import Table from '../../../../components/Table';
import { IoMdSave } from "react-icons/io";
import { RiArrowDropDownLine } from "react-icons/ri";
import { addPrimarySale } from '../../../../reducers/Sales/primarySales';
import { viewInventoryAlerts } from '../../../../reducers/products/MinimumStockReducer';
import { setProductRanges } from '../../../../reducers/products/rangeReducer';

const dropdownOptions =
  [{ label: "MRP", value: "mrp" }, { label: "PTS", value: "pts" }, { label: "PTR", value: "ptr" }, { label: "CUSTOM", value: "custom" }]

const headerSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "none",
    color: "#9e9ad0",
    fontSize: "1.5rem",
    fontWeight: "500",
    width: "100%",
    cursor: "pointer",
    outline: "none",
    border: "none"
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    color: "#9E9AD1",
    fontFamily: "inherit",
    fontSize: "1.5rem",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",

  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",

  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
    outline: "none",
    border: "none",

  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",
    "&:hover": {
      color: "#9E9AD1",
    },
    backgroundColor: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "5px",
    height: "5px",
    padding: "0",
    "&:first-child": {
      width: "100% !important",
      height: "100% !important"
    }
  }),
};

const UploadPrimarySales = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputRef = useRef();
  const stockists = useSelector(({ stockist }) => stockist);
  const hqs = useSelector(({ headquarter }) => headquarter);
  const [date, setDate] = useState();
  const [headquarter, setHeadquarter] = useState();
  const [stk, setStk] = useState();
  const [uploadData, setUploadData] = useState([]);
  const [prodArray, setProdArray] = useState();
  const [priceHeader, setPriceHeader] = useState({ label: "PTS", value: "pts" }); // to set price label for all rows
  const [customPrices, setCustomPrices] = useState({}); // to store custom prices for all rows
  const [editPrice, setEditPrice] = useState(false);  // to store edit price-state for all rows so that when CUSTOM is selected, then the text box opens for that particular row only;
  const [finalPrices, setFinalPrices] = useState({}); // to store the final prices of all rows
  const [prodPrice, setProdPrice] = useState({});  // to store the product price of all row
  const [division, setDivision] = useState(null);

  const loggedIn = useSelector(({ user }) => user);
  const divisionData = useSelector(({ division }) => division);
  
  
  const divisionSelect = useMemo(
    () => generateSelectData(divisionData, "name"),
    [divisionData]
  );


  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await viewProductV1({divisionId: division ? division.value : loggedIn?.user?.division});
        setProdArray(res?.data);
      } catch (error) {
        customToast.error("Error occurred while fetching products");
        console.log(error);
      }
    };
    fetchData();
  }, [division]);

  useEffect(() => {
    dispatch(setHeadquarters());
    dispatch(setStockists());

    return () => {
      dispatch(clearHeadquarters());
      dispatch(clearStockists());
    }
  }, [dispatch]);

  const stkFilterData = useMemo(() => {
    return stockists?.data?.filter((el) => headquarter?._id == el?.city?._id);
  }, [headquarter]);

  const stkOptions = useMemo(
    () => generateSelectData(stkFilterData, "businessName"),
    [stkFilterData]
  );

  const hqOptions = useMemo(
    () => generateSelectData(hqs?.data, "name"),
    [hqs]
  );

  const exportProducts = (e) => {
    e.preventDefault();
    if(loggedIn?.user?.des === 101 && !division) {
      return customToast.error("Please select division");
    }
    const data = prodArray?.map((item, idx) => {
      const obj = {};
      obj.productName = item.name;
      obj.uid = item.uid;
      obj.ptr = item.ptr;
      obj.pts = item.pts;
      obj.mrp = item.mrp;
      obj.quantity = '';
      obj.freeStocks = '';
      obj.discount = '';

      return obj;
    });

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "UploadPSFormat.xlsx");
  };


  const columns = useMemo(
    () => [
      {
        Header: "Product",
        accessor: "product.name",
        showTotal: true,
        placeholderTotal: "Total"
      },
      {
        Header: "Price",
        accessor: "product.mrp",
        Cell: (props) => {
          let data = props?.row?.original?.product;
          let rowIdx = props?.row?.original?.sr;
          let costLabel = priceHeader[rowIdx] ? priceHeader[rowIdx] : priceHeader;
          let price = costLabel?.label === "PTR" ? data?.ptr
            : costLabel?.label === "PTS" ? data?.pts
              : costLabel?.label === "MRP" ? data?.mrp
                : costLabel?.label === "CUSTOM" ? customPrices[rowIdx]
                  : null;

          useEffect(() => {
            setProdPrice((prev) => ({
              ...prev,
              [rowIdx]: Number(price)
            }));
          }, [priceHeader, editPrice])

          return <div>
            <Select
              options={dropdownOptions}
              styles={headerSelectStyles}
              value={priceHeader[rowIdx] ? priceHeader[rowIdx] : priceHeader}
              onChange={(e) => {
                if (e?.label === "CUSTOM") {
                  setEditPrice((prev) => ({
                    ...prev,
                    [rowIdx]: !editPrice
                  }))
                }
                setPriceHeader((prev) => ({
                  ...prev,
                  [rowIdx]: e
                }))
              }}
              components={{ IndicatorsContainer: () => <RiArrowDropDownLine size={25} className='me-auto' /> }}
            />
            {costLabel?.label === "CUSTOM" && editPrice[rowIdx] ?
              (
                <div>
                  <input
                    type='number'
                    className='sales-table__input h-25'
                    onChange={(e) => {
                      setCustomPrices((prev) => ({
                        ...prev,
                        [rowIdx]: e?.target?.value
                      }));
                    }} />
                  <IoMdSave className='icon-color-green cursor-pointer' onClick={() => setEditPrice(!editPrice)} />
                </div>
              )
              :
              (
                <div>{price}</div>
              )}
          </div>
        }
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        showTotal: true,
        totalAccessor: "quantity"
      },
      {
        Header: "Free Stocks",
        accessor: "freeStocks",
        showTotal: true,
        totalAccessor: "freeStocks"
      },
      {
        Header: "Total Quantity",
        accessor: "totalQuantity",
        showTotal: true,
        totalAccessor: "totalQuantity",
        Cell: (props) => {
          let data = props?.row?.original;
          let totalQt = Number(data?.quantity) + Number(data?.freeStocks);
          data.totalQuantity = totalQt;
          return <div>
            {totalQt}
          </div>
        }
      },
      {
        Header: "Discount %",
        accessor: "discount"
      },
      {
        Header: "Final Price",
        accessor: "finalPrice",
        showTotal: true,
        totalAccessor: "finalPrice",
        Cell: (props) => {
          let data = props?.row?.original
          let product = data?.product;
          let rowIdx = data?.sr;
          let costLabel = priceHeader[rowIdx] ? priceHeader[rowIdx] : priceHeader;
          let price = costLabel?.label === "PTR" ? product?.ptr
            : costLabel?.label === "PTS" ? product?.pts
              : costLabel?.label === "MRP" ? product?.mrp
                : costLabel?.label === "CUSTOM" ? customPrices[rowIdx]
                  : 0;

          let finalPrice = (price * data?.quantity * (1 - data?.discount / 100)).toFixed(2);
          data.finalPrice = finalPrice;
          useEffect(() => {
            setFinalPrices((prev) => ({
              ...prev,
              [rowIdx]: Number(finalPrice)
            }));
          }, [priceHeader, customPrices, data?.quantity, data?.discount]);

          return <div>
            {`₹ ${finalPrice}`}
          </div>
        }
      },
    ],
    [priceHeader, editPrice]
  );

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      if (file) fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const workbook = XLSX.read(bufferArray, { type: "buffer" });
        const wsName = workbook.SheetNames[0];
        const ws = workbook.Sheets[wsName];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (err) => {
        reject(err);
      };
    });

    promise.then((d) => {
      if (d.length === 0) {
        inputRef.current.value = null;
        return customToast.error("Please upload a filled excel file");
      }

      const filterData = [];
      d?.forEach((el) => {
        prodArray?.forEach((item) => {
          if (item?.name === el?.productName &&
            el?.quantity !== undefined && el?.quantity !== ''
            // el?.freeStocks !== undefined && el?.freeStocks !== '' &&
            // el?.discount !== undefined && el?.discount !== ''
      ) {
            let obj = {
              product: item,
              quantity: el?.quantity,
              freeStocks: el?.freeStocks || 0,
              discount: el?.discount || 0
            };
            filterData.push(obj);
          }
        })
      });
      setUploadData(filterData);

    });
    promise.catch((err) => {
      return customToast.error("Encountered an error");
    });
  };
  const tableData = useMemo(() => addIndex(uploadData), [uploadData]);

  const resetForm = () => {
    setUploadData([]);
    setHeadquarter(null);
    setStk(null);
    setDate(null);
    inputRef.current.value = null;
  };

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    try {
      let arr = [];
      tableData?.forEach((item) => {
        let obj = {
          ...item,
          finalPrice: finalPrices[item?.sr],
          price: prodPrice[item?.sr],
          priceType: priceHeader[item?.sr] ? priceHeader[item?.sr]?.value : priceHeader?.value
        };
        arr.push(obj);
      });

      const payload = {
        date,
        headquarter,
        stockist: stk,
        products: arr,
        images: '',
        divisionId: division ? division.value : loggedIn?.user?.division,
      };

      dispatch(addPrimarySale(payload));
      dispatch(viewInventoryAlerts());
      resetForm();
      return customToast.success("Sales added successfully !");

    } catch (error) {
      console.log(error)
      customToast.error("Something went wrong !");
    }
  }, [tableData, finalPrices, priceHeader, dispatch, headquarter, stk, date, customPrices, prodPrice]);



  return (
    <div className='main-content admin-content m-3'>
      <div className='area-creation-content'>
        <section className='admin-creation-content__heading'>
          <MdArrowBackIos
            className="cursor-pointer"
            onClick={() => navigate(-1)} />
          <h2 className="web-app__heading">Upload Primary Sales</h2>
          {/* <div className="ms-3" onClick={exportProducts}>
            <button className='button-blue-gradient2'>
              Download Format
            </button>
          </div> */}
          <a href="#" className='ms-auto' style={{ textDecoration: "none", color: "var(--color-tertiary)" }}>How to upload ?</a>
        </section>

        <section>
          <form>
            <div className='primarysales-filter'>

            {
                loggedIn?.user?.des === 101 &&
                <div className="util-tp-filter">
                  <p className="mb-2">
                    Select Division <span className="asterisk-imp">*</span>
                  </p>
                  <Select
                    name="division"
                    id="division"
                    value={division}
                    options={divisionSelect}
                    onChange={({ value, label }) => setDivision({ value, label })}
                    styles={adminStyles}
                    placeholder="Select Division"
                  />
                </div>
              }

              <div className="util-tp-filter">
                <p className="mb-2">
                  Date <span className="asterisk-imp">*</span>
                </p>
                <input
                  type="date"
                  className=""
                  id="date-input"
                  placeholder="Date"
                  style={selectStyles}
                  value={date}
                  onChange={({ target }) => setDate(target.value)}
                />
              </div>

              <div className="util-tp-filter">
                <p className="mb-2">
                  Select Headquarter <span className="asterisk-imp">*</span>
                </p>
                <Select
                  name="headquarter"
                  id="headquarter"
                  value={headquarter}
                  options={hqOptions}
                  onChange={(e) => setHeadquarter({ ...e })}
                  styles={adminStyles}
                  placeholder="Select Headquarter"
                />
              </div>

              <div className="util-tp-filter">
                <p className="mb-2">
                  Select Stockist <span className="asterisk-imp">*</span>
                </p>
                <Select
                  styles={adminStyles}
                  options={stkOptions}
                  placeholder="Select Stockist"
                  value={stk}
                  onChange={(e) => setStk({ ...e })}
                />
              </div>

              <div className="util-tp-filter">
                <p className="mb-2">Upload File</p>
                <input
                  ref={inputRef}
                  type="file"
                  placeholder="you can upload image, excel or pdf"
                  onChange={(e) => {
                    setUploadData([]);
                    const file = e?.target?.files[0];
                    readExcel(file);
                  }}
                />
              </div>

              <div className="util-tp-filter mt-5" onClick={exportProducts}>
                <button className='button-blue-gradient'>
                  Download Format
                </button>
              </div>
               
            </div>
            
            <div className='d-flex justify-content-between align-items-end' style={{padding: "3rem"}}>
              
              </div>
          </form>
        </section>
      </div>
      <h2 className="web-app__heading mb-4 ms-2">
        Showing ({tableData?.length}) Entries
      </h2>
      <div className='filter-table'>
        <Table columns={columns} data={tableData ? tableData : []} />
      </div>

      {tableData?.length > 0 && (
        <div className='d-flex justify-content-between my-5'>
          <button className='button-blue-gradient' onClick={handleSubmit}>
            Upload data
          </button>
          <button className='button-red-gradient' onClick={() => setUploadData([])}>
            Cancel Upload
          </button>
        </div>
      )}

    </div>
  )
}

export default UploadPrimarySales;