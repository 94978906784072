import { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import { adminStyles } from "../../../../public/stylesheets/selectStyles";
import serialise, {
  generateSelectData,
} from "../../../../../utils/serialiseResponse";
import {
  clearStates,
  setStates,
} from "../../../../../reducers/locations/stateReducer";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../../../reducers/locations/hqReducer";
import {
  clearCities,
  setCities,
} from "../../../../../reducers/locations/cityReducer";
import customToast from "../../../../../components/CustomToast";
import PageTitle from "../../../../../components/PageTitle";
import {
  addRouteForApproval,
  clearRoutes,
  setCreatedRoutes,
} from "../../../../../reducers/locations/routeReducer";
import Table from "../../../../../components/Table";

const areaTypeOptions = [
  { value: 1, label: "Ex-Station" },
  { value: 2, label: "Out-Station" },
];

const CreationRoute = () => {
  const dispatch = useDispatch();
  const states = useSelector(({ state }) => state);
  const headquarters = useSelector(({ headquarter }) => headquarter);
  const cities = useSelector(({ city }) => city);
  const routes = useSelector(({ route }) => route);

  const stateSelect = useMemo(
    () => generateSelectData(states.data, "name"),
    [states]
  );
  const hqSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );
  const citySelect = useMemo(
    () => generateSelectData(cities.data, "name"),
    [cities]
  );

  const [state, setState] = useState(null);
  const [headquarter, setHeadquarter] = useState();
  const [fromCity, setFromCity] = useState();
  const [toCity, setToCity] = useState();
  const [areaType, setAreaType] = useState();
  const [distance, setDistance] = useState(0);

  const filteredFromCity = citySelect.filter(
    (el) => el?.headquarter?.name === headquarter?.label
  );
  

  useEffect(() => {
    dispatch(setStates());
    dispatch(setCities( "","city"));
    dispatch(setCreatedRoutes({isStatus: "true"}));

    return () => {
      dispatch(clearStates());
      dispatch(clearRoutes());
      dispatch(clearCities());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!state) return;

    dispatch(setHeadquarters(state.value));
    return () => {
      dispatch(clearHeadquarters());
    };
  }, [dispatch, state]);

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   minWidth: 60,
      //   maxWidth: 60,
      // },
      {
        Header: "Area Type",
        accessor: "areatype",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "From City",
        accessor: "fromCity",
      },
      {
        Header: "To City",
        accessor: "toCity",
      },
      {
        Header: "HQ",
        accessor: "headquarter",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "State",
        accessor: "state",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Distance",
        accessor: "distance",
      },
      {
        Header: "Status",
        accessor: "isApproved",
        Cell: (props) => {
          return props.row.original?.isApproved === 1 ? (
            <span className="approved-text">Approved</span>
          ) : props.row.original?.isApproved === 0 ? (
            <span className="pending-text">Pending</span>
          ) : (
            <span className="rejected-text">Rejected</span>
          );
        },
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !state ||
      !headquarter ||
      !fromCity ||
      !toCity ||
      !areaType ||
      !distance
    )
      return customToast.error("Please fill all details");
      var index = tableData.findIndex(p => (p.fromCity).toLocaleLowerCase() === fromCity.label.toLocaleLowerCase() && (p.toCity).toLocaleLowerCase() === toCity.label.toLocaleLowerCase()  && (p.isApproved === 0 || p.isApproved === 1) );
      // let index = 0;
     
    if(index === -1 && fromCity.label !== toCity.label){
      try {
        await dispatch(
          addRouteForApproval({
            stateId: state.value,
            headquarterId: headquarter.value,
            fromCityId: fromCity.value,
            toCityId: toCity.value,
            areatype: areaType.label,
            distance,
          })
        );
        customToast.success("Route Successfully Sent for Approval");
        setState(null);
        setHeadquarter(null);
        setFromCity(null);
        setToCity(null);
        setAreaType(null);
        setDistance(0);
      } catch (e) {
        customToast.error("We Encountered an Error");
      }
    }
    else{
      if(fromCity.label === toCity.label){
        customToast.error("From City And To City Must Be Different.");
      }
      else if(tableData[index].isApproved){
        customToast.error("Route Already Exists!");
      }
      else{
        customToast.error("Route Awaiting Approval");
      }
    }
    
  };

  const tableData = useMemo(() => serialise(routes.data), [routes]);
  
  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle isCreation title="Create Route" />

        <div className="admin-panel__alert" style={{ marginTop: "1rem", marginBottom: "2rem" }}>
          {/* <h2>Edit without hesitation</h2> */}
          <p>
            Note: If the distance of a route is edited, the updated distance will not be reflected in the tour programs. However, the new distance will be applied to newly created tour programs.
          </p>
        </div>

        <section className="area-creation-content__form">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="admin-form">
              <div className="admin-form__content">
                <label htmlFor="state">
                  Select State <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="state"
                  id="state"
                  value={state}
                  options={stateSelect}
                  onChange={(e) => setState({ value: e.value, label: e.label })}
                  styles={adminStyles}
                  placeholder="Select State"
                />
              </div>
              <div className="admin-form__content">
                <label htmlFor="headquarter">
                  Select HQ <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="headquarter"
                  id="headquarter"
                  value={headquarter}
                  options={hqSelect}
                  onChange={(e) =>
                    setHeadquarter({ value: e.value, label: e.label })
                  }
                  styles={adminStyles}
                  placeholder="Select Headquarter"
                />
              </div>
              <div className="admin-form__content">
                <label htmlFor="fromCity">
                  From City <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="fromCity"
                  id="fromCity"
                  value={fromCity}
                  options={filteredFromCity}
                  onChange={(e) =>
                    setFromCity({ value: e.value, label: e.label })
                  }
                  styles={adminStyles}
                  placeholder="Select From City"
                />
              </div>
              <div className="admin-form__content">
                <label htmlFor="toCity">
                  To City <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="toCity"
                  id="toCity"
                  value={toCity}
                  options={citySelect}
                  onChange={(e) =>
                    setToCity({ value: e.value, label: e.label })
                  }
                  styles={adminStyles}
                  placeholder="Select To City"
                />
              </div>
              <div className="admin-form__content">
                <label htmlFor="areaType">
                  Select Area Type <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="areaType"
                  id="areaType"
                  value={areaType}
                  options={areaTypeOptions}
                  onChange={(e) =>
                    setAreaType({ value: e.value, label: e.label })
                  }
                  styles={adminStyles}
                  placeholder="Select Area Type"
                />
              </div>
              <div className="admin-form__content">
                <label htmlFor="distance">
                  Enter Distance <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  value={distance}
                  onChange={(e) => setDistance(e.target.value)}
                  type="number"
                  className="me-5"
                  id="distance"
                  placeholder="Enter Distance"
                  min={0}
                />
              </div>
              <button type="submit" className="button-blue-gradient">
                Add Route
              </button>
            </div>
          </form>
        </section>

        <section className="area-creation-content__info">
          {tableData.length > 0 && (
            <h2 className="web-app__heading mb-4 ms-2">
              Showing ({tableData.length}) Entries
            </h2>
          )}
          <div className="filter-table">
            <Table columns={columns} data={tableData} />
          </div>
        </section>
      </div>
    </div>
  );
};

export default CreationRoute;
