import { useEffect, useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import { RiCloseCircleLine, RiEdit2Fill } from "react-icons/ri";

import PageTitle from "../../../../../components/PageTitle";
import Table from "../../../../../components/Table";
import { adminStyles } from "../../../../public/stylesheets/selectStyles";

import serialise, { addIndex } from "../../../../../utils/serialiseResponse";

import { viewTargets } from "../../../../../services/targets";
import { BsCheck2Circle, BsXCircle } from "react-icons/bs";
import { MdCheckBoxOutlineBlank, MdDelete } from "react-icons/md";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import customToast from "../../../../../components/CustomToast";
import { useDispatch, useSelector } from "react-redux";
import { deleteDoctor } from "../../../../../reducers/targets/doctor";
import { deleteChemist } from "../../../../../reducers/targets/chemist";
import { deleteStockist } from "../../../../../reducers/targets/stockist";
import { userPreferences } from "../../../../../services/settings";

const typeOptions = [
  { value: 1, label: "Doctor" },
  { value: 2, label: "Chemist" },
  { value: 3, label: "Stockist" },
];

const CreationEditDeleteDCS = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [type, setType] = useState(typeOptions[0]);
  const [dcsData, setDcsData] = useState([]);
  const [deleteMany, setDeleteMany] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [allSelected, setAllSelected] = useState(false);  
  const tableData = useMemo(() => addIndex(dcsData), [dcsData]);
  const [deletionRequest, setDeletionRequest] = useState(false);
  const loggedIn = useSelector(({ user }) => user);


  useEffect(() => {
    userPreferences({ selectedUser: loggedIn?._id })
      .then(({ data }) => {
        if (data?.deletionRequest) setDeletionRequest(data.deletionRequest);
      })
      .catch((err) => customToast.error("Unable to load preferences"));
  }, []);

  const SelectColumnFilter = useCallback(
    ({ column, columns }) =>{
      return (
        <div className="search-filter__container" onClick={(e)=>{e.stopPropagation()}}>
          {<span className="d-flex gap-3 align-items-center">{column.render("Header")}
            {allSelected ? (
                <ImCheckboxChecked onClick={(e) => {
                  e.preventDefault();
                  column?.filteredRows?.forEach((el,idx)=>{
                    el.original.isSelected = false ;
                  });
                  setAllSelected(false);
                }}/>
              ):(
                <ImCheckboxUnchecked onClick={(e) => {
                  e.preventDefault();
                  column?.filteredRows?.forEach((el,idx)=>{
                    el.original.isSelected = true ;
                  });
                  setAllSelected(true);
                }}/>
              )
              }
          </span>
          }
        </div>
  );},[allSelected])
  
const handleDelete = async (data) => {
  if(data.length === 0)return customToast.error(`Please select atleast one ${type?.label}`);
  const payload = {ids:data , deleteTyp:"bulk"} ;
  if(type?.label === "Doctor")
    await dispatch(deleteDoctor(1, payload));
  else if(type?.label === "Chemist")
    await dispatch(deleteChemist(1, payload));
  else if(type?.label === "Stockist")
    await dispatch(deleteStockist(1, payload));

  setAllSelected(false);
  setShowEdit(false);
  setDeleteMany(false);
  customToast.success(`${type?.label} Deleted Successfully`);
  
  asyncFetch();

};

  const asyncFetch = useCallback(async () => {
    await viewTargets(type?.label?.toLowerCase(), {
      dcr: true,
      showApproved: true
    }).then((res) => setDcsData(res.data));
  }, [type])

  useEffect(() => {
    asyncFetch();
  }, [type]);

  const EditPopup = () => {
    const data = [];
    tableData.forEach((el, idx) => {
      if (el?.isSelected) {
        data.push(el?._id);
      }
    })
    return (
      <section className="edit-popup" onClick={(e) =>
        showEdit
          ? e.target.className === "edit-popup"
            ? setShowEdit(false)
            : null
          : null
      }>
        {/* <section className="edit-popup"> */}
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              setShowEdit(false);
            }}
          >
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  {`Are you sure you want to delete ${data.length} ${type?.label}`}
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button
                type="button"
                className="button-delete mt-4"
                onClick={() => handleDelete(data)}
              >
                <span className="button-delete__icon-container">
                  <BsXCircle className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Delete</span>
              </button>
              <button
                type="button"
                className="button-submit-green mt-4"
                onClick={() => setShowEdit(false)}
              >
                <span className="button-submit-green__icon-container">
                  <BsCheck2Circle className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Cancel</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  const columnData = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Name",
        accessor: "name",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Degree",
        accessor: "degree",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Specialization",
        accessor: "specialization",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Mobile Number",
        accessor: "mobile",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "HQ",
        accessor: "city.name",
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: deleteMany ? "Select" : "Actions",
        accessor: "",
        Cell: (props) => {
          const [local,setLocal] = useState(props?.row?.original?.isSelected || false);
                    return deleteMany ? (
                      <span
                        className="react-table-view-link display-6"
                      >    
                        {local ? (
                          <ImCheckboxChecked onClick={() => {
                            props.row.original.isSelected = false ;
                            setLocal(false); 
                          }}/>
                        ):(
                          <MdCheckBoxOutlineBlank onClick={() => {
                            props.row.original.isSelected = true ;
                            setLocal(true);
                          }}/>
                        )
                        }
                      </span>
                    ):(
            <span
              className="react-table-view-link"
              onClick={() => {
                navigate(
                  `/creation-menu/dcs/edit-delete/${type?.label?.toLowerCase()}`,
                  { state: props?.row?.original }
                );
              }}
            >
              <RiEdit2Fill className="icon-color-green" />
            </span>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: deleteMany ? false : true,
        disableSortBy: true,
        Filter:SelectColumnFilter,
      },
    ],
    [type, navigate, deleteMany, SelectColumnFilter,tableData]
  );

  const chemColumnData = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Business Name",
        accessor: "businessName",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Propreitor Name",
        accessor: "name",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Address",
        accessor: "address",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Mobile Number",
        accessor: "chemistContact",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "HQ",
        accessor: "city.name",
        maxWidth: 85,
      },
      {
        Header: deleteMany ? "Select" : "Actions",
        accessor: "",
        Cell: (props) => {
          const [local,setLocal] = useState(props?.row?.original?.isSelected || false);
          return deleteMany ? (
            <span
              className="react-table-view-link display-6"
            >    
              {local ? (
                <ImCheckboxChecked onClick={() => {
                  props.row.original.isSelected = false ;
                  setLocal(false); 
                }}/>
              ):(
                <MdCheckBoxOutlineBlank onClick={() => {
                  props.row.original.isSelected = true ;
                  setLocal(true);
                }}/>
              )
              }
            </span>
          ):(
            <span
              className="react-table-view-link"
              onClick={() => {
                navigate(
                  `/creation-menu/dcs/edit-delete/${type?.label?.toLowerCase()}`,
                  { state: props?.row?.original }
                );
              }}
            >
              <RiEdit2Fill className="icon-color-green" />
            </span>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: deleteMany ? false : true,
        disableSortBy: true,
        Filter:SelectColumnFilter,
      },
    ],
    [type, navigate, deleteMany, SelectColumnFilter,tableData]
  );

  const stkColumnData = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Business Name",
        accessor: "businessName",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Propreitor Name",
        accessor: "name",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Address",
        accessor: "address",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Mobile Number",
        accessor: "stockistContact",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "HQ",
        accessor: "city.name",
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: deleteMany ? "Select" : "Actions",
        accessor: "",
        Cell: (props) => {
          const [local,setLocal] = useState(props?.row?.original?.isSelected || false);
          return deleteMany ? (
            <span
              className="react-table-view-link display-6"
            >    
              {local ? (
                <ImCheckboxChecked onClick={() => {
                  props.row.original.isSelected = false ;
                  setLocal(false); 
                }}/>
              ):(
                <MdCheckBoxOutlineBlank onClick={() => {
                  props.row.original.isSelected = true ;
                  setLocal(true);
                }}/>
              )
              }
            </span>
          ):(
            <span
              className="react-table-view-link"
              onClick={() => {
                navigate(
                  `/creation-menu/dcs/edit-delete/${type?.label?.toLowerCase()}`,
                  { state: props?.row?.original }
                );
              }}
            >
              <RiEdit2Fill className="icon-color-green" />
            </span>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: deleteMany ? false : true,
        disableSortBy: true,
        Filter:SelectColumnFilter,
      },
    ],
    [type, navigate, deleteMany, SelectColumnFilter,tableData]
  );

  // useEffect(() => {
  //   const asyncFetch = async () => {
  //     await viewTargets(type?.label?.toLowerCase(), {
  //       dcr: true,
  //       showApproved: true
  //     }).then((res) => setDcsData(res.data));
  //   };

  //   asyncFetch();
  // }, [type]);

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Edit / Delete" />

        <section className="area-creation-content__info">
          <form>
            <div className="row">
              <div className="col-sm-12 col-md-3 col-lg-3">
                <label htmlFor="type">Select Type</label>
                <br />
                <Select
                  name="type"
                  id="type"
                  value={type}
                  options={typeOptions}
                  onChange={(e) => setType({ ...e })}
                  styles={adminStyles}
                  placeholder="Doc / Chem / Stk"
                />
              </div>
              <div className="col-sm-12 col-md-3 col-lg-3 align-self-end">
                {deleteMany ? (
                  <div className="d-flex justify-content-between align-items-end">
                    
                    <button
                      type="submit"
                      className="button-delete"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowEdit(true);
                      }}
                    >
                      <span className="button-delete__icon-container">
                        <BsXCircle className="button-delete__icon" />
                      </span>
                      <span className="button-delete__text">Delete</span>
                    </button></div>
                ) : null}
              </div>
            </div>
          </form>

          <div className="filter-table">
            {/* <MaterialTable
              editable={{
                onRowDelete: () => {},
              }}
              actions={[
                {
                  icon: () => (
                    <AiOutlineEdit className="mui-table-approve-button" />
                  ),
                  onClick: (e, data) =>
                    navigate(
                      `/creation-menu/dcs/edit-delete/${type?.label?.toLowerCase()}`,
                      {
                        state: data,
                      }
                    ),
                },
              ]}
              options={{
                exportButton: true,
                emptyRowsWhenPaging: false,
                actionsColumnIndex: -1,
                pageSizeOptions: [5, 10, 20, 50, 100],
              }}
              columns={
                type?.label?.toLowerCase() === "doctor"
                  ? columnData
                  : type?.label?.toLowerCase() === "chemist"
                  ? chemColumnData
                  : stkColumnData
              }
              data={dcsData}
              title="Doctors"
            /> */}
            {tableData.length > 0 && (
              <div className="d-flex justify-content-between">
              <h2 className="web-app__heading mb-4 ms-2">
                Showing ({tableData.length}) Entries
              </h2>
              
                {
                  deleteMany ? (
                    <span
                  className="react-table-view-link"
                  onClick={() => {
                    setDeleteMany(false);
                  }}
                >
                  <RiEdit2Fill className="icon-color-green" />
                </span>

               
                  ) : 
                    deletionRequest && 
                    ( 
                      <span className="icon-color-error h-100 d-flex justify-content-end">
                        <MdDelete className="cursor-pointer" onClick={(e) => {
                          e.preventDefault();
                          setDeleteMany(true);
                        }} />
                      </span>
                  )
                }
              </div>
            )}

            {tableData.length > 0 ? (
              <Table
                columns={
                  type?.label?.toLowerCase() === "doctor"
                    ? columnData
                    : type?.label?.toLowerCase() === "chemist"
                    ? chemColumnData
                    : stkColumnData
                }
                data={tableData}
              />
            ) : (
              <h3 className="mt-2 no-results-message">No results found</h3>
            )}
          </div>
        </section>
        {showEdit ? <EditPopup /> : null}
      </div>
    </main>
  );
};

export default CreationEditDeleteDCS;
