import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../../components/PageTitle";
import { Link } from "react-router-dom";
import serialise, { generateSelectUser } from "../../../../utils/serialiseResponse";
import { RiCloseCircleLine } from "react-icons/ri";
import Table from "../../../../components/Table";
import { MdDelete, MdDeleteOutline } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { allDeletionRequest, deleteDeletionRequest, rejectDeletionRequest } from "../../../../reducers/deletion request/deletionRequestReducer";
import { selectUserStyles } from "../../../../views/public/stylesheets/selectStyles.js";
import Select from "react-select";
import { viewUsersForUtilities } from "../../../../services/utilities";
import {MdOutlineGroup} from "react-icons/md";
import customToast from "../../../../components/CustomToast";

const DeletionRequest = () => {

  const dispatch = useDispatch();

  const [showEdit, setShowEdit] = useState(false);
  const [data, setData] = useState({});
  const [deleteName, setDeleteName] = useState("");
  const [deleteType, setDeleteType] = useState("");
  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)
  const requests = useSelector(({ deletionRequest }) => deletionRequest);

  const userSelect = useMemo(
    () => generateSelectUser(users, "fullName"),
    [users]
  );
  
  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setUsers(res?.data);
      });
    } catch (error) {
      customToast.error("error occured Please Try Again");
    }
  }, []);

  useEffect(() => {
    dispatch(allDeletionRequest({selectedUser:selectedUser?.value}));
  }, [dispatch, selectedUser])

  const handleDelete = async () => {
    await dispatch(deleteDeletionRequest(data));
    setShowEdit(false);
  };
  const handleCancel = async () => {
    await dispatch(rejectDeletionRequest(data));
    setShowEdit(false);
  };

  const tableData = useMemo(() => serialise(requests.data), [requests]);

  const EditPopup = () => {
    const data = [];
    tableData?.forEach((el,idx)=>{
      if(el?.isSelected){
        data.push(el?._id);
      }
    })
    return (
      <section className="edit-popup" onClick={(e) =>
        showEdit
          ? e.target.className === "edit-popup"
            ? setShowEdit(false)
            : null
          : null
      }>
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Confirm Details</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              setShowEdit(false);
            }}
          >
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  {`Do you want to delete ${deleteName} (${deleteType})'s data ?`}
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button
                type="submit"
                className="button-delete mt-4"
                onClick={() => handleDelete(data)}
                // onClick={() => console.log('delete data')}
              >
                <span className="button-delete__icon-container">
                  <MdDeleteOutline className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Delete</span>
              </button>

              <div className="admin-content-submit-button" 
                onClick={() => handleCancel(data)}
                // onClick={() => console.log('cancel data')}
              >
                <button className="button-blue-gradient border-0">
                  Reject
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    );
  };
  
  const columns = useMemo(
    () => [
      {
        Header: "Type",
        accessor: "entityType",
        Cell:(props) => <span>{String(props.row.original.entityType).charAt(0).toUpperCase() + String(props.row.original.entityType).slice(1)}</span>
      },
      {
        Header: "Name",
        accessor: "entityName",
      },
      {
        Header: "Requested By",
        accessor: "user",
      },
      {
        Header: "View",
        accessor: "details",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 60,
        maxWidth: 60,
        Cell: (props) => {
          const type = props?.row?.original?.entityType;
          if (type === "doctor"){
            return (
              <Link
                to={"/admin-panel/dcs/view-dcs/details"}
                state={{
                  _id: props?.row?.original?.entityId,
                  type: type,
                }}
                className="util-table-view-details-link"
              >
                <FaEye className="icon-color-tertiary" />
              </Link>
            );
          } else if (type === "stockist"){
            return (
              <Link
                to={"/admin-panel/dcs/view-dcs/details-stockist"}
                state={{
                  _id: props?.row?.original?.entityId,
                  type: type,
                }}
                className="util-table-view-details-link"
              >
                <FaEye className="icon-color-tertiary" />
              </Link>
            );
          } else if (type === "chemist"){
            return (
              <Link
                to={"/admin-panel/dcs/view-dcs/details-chemist"}
                state={{
                  _id: props?.row?.original?.entityId,
                  type: type,
                }}
                className="util-table-view-details-link"
              >
                <FaEye className="icon-color-tertiary" />
              </Link>
            );
          }
        },
      },
      {
        Header: "Delete",
        accessor: "none",
        Cell: (props) => {
          return (
            <span
              className="react-table-view-link display-6"
              onClick={(e) => {
                e.preventDefault();
                setShowEdit(true);
                setData({
                  requestId: props?.row?.original?._id,
                  entityId: props?.row?.original?.entityId,
                  entityType: props?.row?.original?.entityType,
                })
                setDeleteName(props?.row?.original?.entityName);
                setDeleteType(props?.row?.original?.entityType);
              }}
            >    
              <MdDelete className="icon-color-error"/>
            </span>
          );
        },
        minWidth: 80,
        maxWidth: 80,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [tableData]
  );

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Deletion Request" />

        <section className="area-creation-content__info">
        <div className="col-md-4 col-lg-3 util-tp-filter">
              <div>
                <p className="util-select-user__label mb-2">Select User</p>
                <div className="util-select-user">
                  <MdOutlineGroup className="util-select-user__icon" />
                  <Select
                    styles={selectUserStyles}
                    options={userSelect}
                    value={selectedUser}
                    onChange={(selectedUser)=>setSelectedUser(selectedUser)}
                    placeholder="Select User"
                  />
                </div>
              </div>
          </div>
          {tableData?.length > 0 && (
            <h2 className="web-app__heading mb-4 ms-2 mt-5">
              Showing ({tableData?.length ? tableData?.length : 0}) Entries
            </h2>
          )}
          <div className="filter-table">
            <Table columns={columns} data={tableData} />
          </div>
          {showEdit ? <EditPopup /> : null}
        </section>
      </div>
    </div>
  );
};

export default DeletionRequest;
