import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseURL = `${apiURL}/client/v1/wallet`;

export const createWalletPayment = async (value) => {
  const { data } = await axios.post(baseURL + "/create-payment", value, {
    headers: setAuth(),
  });

  return data;
};
export const verifyWalletPayment = async (value) => {
  const { data } = await axios.post(baseURL + "/verify-payment", value, {
    headers: setAuth(),
  });

  return data;
};


export const viewWalletDetails = async () => {
  const { data } = await axios.get(baseURL + "/fetch-wallet-details", {
    headers: setAuth(),
  });

  return data;
};