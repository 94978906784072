import { MdArrowBackIosNew, MdOutlineGroup } from "react-icons/md";
import Select from "react-select";
import {  monthOptions, yearOptions } from "../../../../utils/helper";
import {
  dcrStyles,
  selectUserStyles,
} from "../../../public/stylesheets/selectStyles";
import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  viewMissedMonthReports,
  viewUsersForUtilities,
} from "../../../../services/utilities";
import Table from "../../../../components/Table";
import serialise, { generateSelectData, generateSelectUser } from "../../../../utils/serialiseResponse";
import * as XLSX from "xlsx";
import customToast from "../../../../components/CustomToast";

const typeOptions = [
  { value: 1, label: "Doctor" },
  // { value: 2, label: "Chemist" },
  // { value: 3, label: "Stockist" },
];
const MonthWiseReports = ({showArr, reportType, setReportType}) => {
  const loggedIn = useSelector(({ user }) => user);
  const [stMonth, setStMonth] = useState();
  // const [stYear, setStYear] = useState(yearOptions[0]);
  const [enMonth, setEnmonth] = useState();
  const [enYear, setEnyear] = useState(yearOptions[0]);
  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [type, setType] = useState({ value: 1, label: "Doctor" });
  const [reports, setReports] = useState([]);
  const [cusCols, setCols] = useState([]) ;
  const [employeeDataForExcel, setEmployeeDataForExcel] = useState();
  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );
  const employeeData = useMemo(
    () => generateSelectData(employees, "fullName"),
    [employees]
  );


  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees(res?.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);
  useEffect(() => {
    try {
      let tempdata = employeeData.filter((empData) => {
        if (empData.label === selectedUser.label) return true;
      })
      setEmployeeDataForExcel(tempdata[0]);
    } catch (error) {
      console.log(error);
    }
  }, [selectedUser]); 
  const handleSubmit = (e)=>{
    e.preventDefault();
    if(!stMonth || !type || !enMonth || !enYear)return customToast.error("Please select all fields");
    viewMissedMonthReports(type, {
      stMonth:stMonth?.value,
      enMonth:enMonth?.value,
      year:enYear?.label,
      selectedUser:selectedUser?.value
    }).then(({data})=>{
      setReports(data);
    }).catch((err)=> customToast.error("Sorry Something Went Wrong!"));
  }
  
  const columns = useMemo(
    () => [
      {
        Header: "Employee",
        accessor: "username",
        minWidth: 130,
        maxWidth: 130,
        Cell: (props) => {
          return <span >{selectedUser ? selectedUser.label : props.row.original.userName}</span>;
        }
      },
      {
        Header: "Met/Missed",
        accessor: "",
        minWidth: 120,
        maxWidth: 120,
        Cell: (props) => {
          
            if (props.row.original?.visits >= props.row.original?.expected ) {
              return <span className="approved-text">Met</span>;
            } else {
              return <span className="rejected-text">Missed</span>;
            }
          
        },
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Doctor Name",
        accessor: "name",
        minWidth: 120,
        maxWidth: 120,
      },
      {
        Header: "Degree",
        accessor: "degree",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 100,
        maxWidth: 100,
      },
      {
        Header: "Category",
        accessor: "category.label",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 120,
        maxWidth: 120,
      },
      {
        Header: "Expected Visit",
        accessor: "expected",
        showTotal:'true',
        totalAccessor:"expected",
        // Cell: (props) => {
        //   if (
        //     props?.row?.original?.target?.category?.label &&
        //     props?.row?.original?.target?.category?.label
        //       .toLowerCase()
        //       .substring(0, 1) === "s"
        //   ) {
        //     return <span>3</span>;
        //   } else if (
        //     props?.row?.original?.target?.category?.label &&
        //     props?.row?.original?.target?.category?.label
        //       .toLowerCase()
        //       .substring(0, 1) === "c"
        //   ) {
        //     return <span>2</span>;
        //   } else {
        //     return <span>1</span>;
        //   }
        // },
        disableFilters: true,
        disableSortBy: true,
        minWidth: 100,
        maxWidth: 100,
      },
      {
        Header: "Actual Visits",
        accessor: "visits",
        disableFilters: true,
        disableSortBy: true,
        showTotal:'true',
        totalAccessor:"visits",
        minWidth: 100,
        maxWidth: 100,
      },
    ],
    [selectedUser]
  );
  useEffect(()=>{
    while(columns.length > 6)columns.pop();
    monthOptions.map((el,idx)=>{

      if(el?.value <= enMonth?.value && el?.value >= stMonth?.value ){
        
        columns.push({
          Header: `${el?.label}`,
          accessor: "",
          Cell: (props) => {
            return (
              <>
                {props?.row?.original?.[el?.label]?.map((data,idx) => {
                  return (
                    <span style={{wordWrap:'break-word'}} className="w-90" key={idx}>
                     {data}_
                    </span>
                  );
                })}
              </>
            );
          },
          minWidth: 100,
          maxWidth: 100,
          disableFilters: true,
          disableSortBy: true,
        })
      }
    })
    setCols([...columns]);
  },[stMonth, enMonth, columns]);
  const tableData = useMemo(()=> serialise(reports),[reports]) ;
  const exportEXCEL = () => {
    const processedReports = reports.map((report,idx) => {
      const processedReport = { sr:idx+1, ...report };

      // Iterate over keys of the report object
      Object.keys(processedReport).forEach(key => {
        // Check if the value is an array
        if (Array.isArray(processedReport[key])) {
          // Convert the array to a string representation
          processedReport[key] = processedReport[key].join('_'); // Adjust separator as needed
        }
      });
      
      return processedReport;
    });
    const worksheet = XLSX.utils.json_to_sheet(processedReports);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "report.xlsx");
  }
  return (
    <><form onSubmit={handleSubmit}>
      <div className="util-tp-query d-flex  flex-wrap gap-4">

      <div className="util-tp-filter">
                  <p className="mb-3">Select Report Type</p>
                  <Select
                    options={showArr}
                    // className="mt-3"
                    styles={dcrStyles}
                    value={reportType}
                    onChange={(e) => {
                      setReportType(e)
                      // setPunchingReports([])
                    }}
                  />
                </div>
        
        <div className="util-tp-filter me-3">
          <p>
            Select Start Month<span className="asterisk-imp">*</span>
          </p>
          <Select
            styles={dcrStyles}
            placeholder="Select Month"
            className="mt-3"
            options={monthOptions}
            value={stMonth}
            onChange={(e) => setStMonth({ ...e })}
          />
        </div>
        <div className="util-tp-filter me-3">
          <p>
            Select End Month <span className="asterisk-imp">*</span>
          </p>
          <Select
            styles={dcrStyles}
            placeholder="Select Month"
            className="mt-3"
            options={monthOptions}
            value={enMonth}
            onChange={(e) => setEnmonth({ ...e })}
          />
        </div>

        <div className="util-tp-filter me-3">
          <p>
            Select  Year <span className="asterisk-imp">*</span>
          </p>
          <Select
            styles={dcrStyles}
            placeholder="Select Year"
            className="mt-3"
            options={yearOptions}
            value={enYear}
            onChange={(e) => setEnyear({ ...e })}
          />
        </div>
        <div className="util-tp-filter me-3">
          <p>
            Select Doc / Chem / Stk{" "}
            <span className="asterisk-imp">*</span>
          </p>
          <Select
            styles={dcrStyles}
            placeholder="Select Type"
            className="mt-3"
            options={typeOptions}
            value={type}
            onChange={(e) => setType({ ...e })}
          />
        </div>
        {(loggedIn?.user?.des !== 121 ) ? (
        <div className="util-tp-filter me-3">
          <p className="util-select-user__label">Select User</p>
          <div className="util-select-user">
            <MdOutlineGroup className="util-select-user__icon" />
            <Select
              styles={selectUserStyles}
              options={employeeSelect}
              placeholder="Select User"
              className="mt-3"
              value={selectedUser}
              onChange={(e) => setSelectedUser({ ...e })}
            />
          </div>
        </div>
        ) : null}
        
        <div className="util-tp-filter">
          <button type="submit" className="button-blue-gradient mt-5 d-inline">
            See Reports
          </button>
        </div>
      </div>
      
    </form>
    
    {tableData.length > 0 && (
        <h2 className="web-app__heading mb-4 ms-2 mt-5">
          Showing ({tableData.length}) Entries
        </h2>
      )}
      <div className="filter-table">
      
        <Table defaultPageSize={50} columns={cusCols} data={tableData} fileSrc="Monthly Met/Missed Report" month={stMonth} empl={selectedUser ? employeeDataForExcel : loggedIn?.user} callback={exportEXCEL} />
      </div>
     </>
  )
}

export default MonthWiseReports