import { lazy, Suspense, useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import customToast from "../../../../components/CustomToast";
import serialise, { generateSelectData } from "../../../../utils/serialiseResponse";
import Table from "../../../../components/Table";
import { adminStyles, dcrStyles } from "../../../public/stylesheets/selectStyles";
import { ViewLinkedCD, viewPayment, viewProductSaleReport } from "../../../../services/targets";
// import { items } from "../../../../utils/helper";
import { returnMonthFromIndex } from "../../../../utils/helper";
import styles from "./Business.module.css"
import { MdArrowBackIosNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
// const CascadingMenu = lazy(() => import('../../CascadingMenu'));
const DatePickers = lazy(() => import("../../../../components/DatePicker"))


const Business = () => {

  const [tdata, setTdata] = useState([]);
  const [doctor, setDoctor] = useState("");
  const [docs, setDocs] = useState([]);
  const [fromMonth, setFromMonth] = useState(moment().month());
  const [toMonth, setToMonth] = useState("");
  const [fromYear, setFromYear] = useState(moment().year());
  const [toYear, setToYear] = useState("");
  // const [totalRevenue, setTotalRevenue] = useState(0)
  // const [totalRevenueShare, setTotalRevenueShare] = useState(0)
  const [paymentData, setPaymentData] = useState([])
  // const [paidAmount, setPaidAmount] = useState(0)
  // const [pendingAmount, setPendingAmount] = useState(0)
  // const [selectedRange, setSelectedRange] = useState({})
  const doctorSelect = useMemo(() => generateSelectData(docs, "name"), [docs]);

  // const handleMenuClick1 = ({ keyPath }) => {
  //   // console.log(keyPath)
  //   // Assuming keyPath is ['0-2024']
  //   const [monthYearString] = keyPath;
  //   // Splitting '0-2024' into month and year
  //   const [month, year] = monthYearString.split('-');
  //   setFromMonth(`${month}`);
  //   setFromYear(`${year}`);
  //   setSelectedRange({ ...selectedRange, fromMonth: month, fromYear: year })
  // };
  // const handleMenuClick2 = ({ keyPath }) => {
  //   // console.log(keyPath)
  //   const [monthYearString] = keyPath;
  //   const [month, year] = monthYearString.split('-');
  //   setToMonth(`${month}`);
  //   setToYear(`${year}`);
  //   setSelectedRange({ ...selectedRange, toMonth: month, toYear: year })
  // };

  useEffect(() => {
    ViewLinkedCD().then((response) => {
       // Extract docArr from each item and merge into docs state
       const allDocs = response.data.flatMap(item => item.docArr);
        // Filter unique doctors
      const uniqueDocs = allDocs.reduce((acc, doc) => {
        if (!acc.some(d => d._id === doc._id)) {
          acc.push(doc);
        }
        return acc;
      }, []);
    
    setDocs(uniqueDocs);
  }).catch(err => customToast("Error fetching doctors"))
  ;
  }, []);

  const { totalRevenue, totalRevenueShare } = useMemo(() => {
    let totalRev = 0;
    let totalRevShare = 0;
    if (tdata.length > 0) {
      tdata.forEach(report => {
        report.products.forEach(product => {
          totalRev += Number(product.finalPrice);
          totalRevShare += Number(product.revenueShare);
        });
      });
    }
    return {
      totalRevenue: totalRev.toFixed(2),
      totalRevenueShare: totalRevShare.toFixed(2)
    };
  }, [tdata]);

  const { paidAmount, pendingAmount } = useMemo(() => {
    const total = paymentData.reduce((sum, payment) => sum + (payment.amount || 0), 0);
    const paid = total.toFixed(2);
    const pending = (Number(totalRevenueShare) - total).toFixed(2);

    return { paidAmount: paid, pendingAmount: pending };
  }, [paymentData, totalRevenueShare]);

  const flattenedData = useMemo(() => 
    tdata.flatMap(report =>
      report.products.map(product => ({
        _id: report._id,
        month: returnMonthFromIndex(Number(report.month)),
        year: report.year,
        stockist: report.stockist.businessName,
        chemist: report.chemist.businessName,
        doctor: report.doctor.name,
        product: product,
        price: product.price,
        quantity: product.quantity,
        finalPrice: product.finalPrice,
        revenueShare: product.revenueShare
      }))
    ), 
    [tdata]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Stockist",
        accessor: "stockist",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Chemist",
        accessor: "chemist",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Doctor",
        accessor: "doctor",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Product Name",
        accessor: "product",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        placeholderTotal: "Total",
        Cell: (props) => {
          return (
            props?.row?.original?.product?.product.isFixed ?
            <span>
              {props?.row?.original?.product?.product.name} <br />
              [{props?.row?.original?.product?.product.fixedAmount}]
            </span> :
            <span>
              {props?.row?.original?.product?.product.name} <br />
              [{props?.row?.original?.product?.product.percentage}%]
            </span>
          )
        },
      },
      {
        Header: "Month",
        accessor: "month",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Price",
        accessor: "product.price",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "price",
      },
      {
        Header: "Quantity",
        accessor: "product.quantity",
        disableSortBy: true,
        disableFilters: true,
        showTotal: true,
        totalAccessor: "quantity",
      },
      {
        Header: "Final Price",
        accessor: "product.finalPrice",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "finalPrice",
      },
      {
        Header: "Revenue Share",
        accessor: "product.revenueShare",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "revenueShare",
      },
    ],
    []
  );

  // const tableData = useMemo(() => serialise(tdata), [tdata]);


  const SubmitHandler = useCallback(async (e) => {
    e.preventDefault();
    
    if (!doctor || !fromYear || !toYear) {
      return customToast.error("Please fill all the fields");
    }

    try {
      const [productResponse, paymentResponse] = await Promise.all([
        viewProductSaleReport({
          docId: doctor._id, 
          range: { fromMonth, fromYear, toMonth, toYear }
        }),
        viewPayment({
          docId: doctor._id, 
          range: { fromMonth, fromYear, toMonth, toYear }
        })
      ]);

      setTdata(productResponse?.data || []);
      setPaymentData(paymentResponse?.data || []);
    } catch (err) {
      customToast.error("No result found");
    }
  }, [doctor, fromMonth, fromYear, toMonth, toYear]);

  // useEffect(() => {
  //   const calculateTotalPayments = (data) => {
  //     return data.reduce((total, payment) => {
  //       return total + (payment.amount || 0);
  //     }, 0);
  //   };
  //     let total = calculateTotalPayments(paymentData);
  //     setPaidAmount(total.toFixed(2))
  //     let totalPendingAmount = totalRevenueShare - total;
  //     setPendingAmount((totalPendingAmount).toFixed(2))
  // }, [paymentData, totalRevenueShare]);

  const navigate = useNavigate()

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
      <section className="admin-creation-content__heading">
          <MdArrowBackIosNew
          style={{cursor:"pointer"}}
          onClick={() => navigate("/crm")}/>
          <h2 className="web-app__heading">Doctor Revenue Share</h2>
          <div style={{color:"var(--color-tertiary)", marginLeft:"auto", cursor:"pointer"}} >
           <a href="https://youtu.be/HMa_5DZUVvs" style={{color:"var(--color-tertiary)",cursor:"pointer", textDecoration: "none"}} target="_blank"> How does it works ?</a>
            </div>
        </section>
        <section className="area-creation-content__info">
          <form onSubmit={SubmitHandler}>
            <div className="row">
              <div className="col-auto" >
                <label htmlFor="stockist">From Month</label>
                <br />
                <Suspense>
                  <DatePickers
                    placeholder={(fromMonth && fromYear ) ? moment({ fromYear, fromMonth }).format('MMM, YYYY') : "Select Month"}
                    setMonth={setFromMonth}
                    setYear={setFromYear}
                  />
                </Suspense>
              </div>
              <div className="col-auto">
                <label htmlFor="stockist">To Month</label>
                <br />
                <Suspense>
                  <DatePickers
                    placeholder="Select Month"
                    setMonth={setToMonth}
                    setYear={setToYear}
                  />
                </Suspense>
              </div>
              <div className="col-auto">
                <label htmlFor="doctor">Select Doctor</label>
                <br />
                <Select
                  name="doctor"
                  id="doctor"
                  value={doctor}
                  options={doctorSelect}
                  onChange={(e) => setDoctor({ ...e })}
                  styles={dcrStyles}
                  placeholder="Select Doctor"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-4 admin-creation-content__form-submit mt-5">
                <button type="submit" className="button-blue-gradient">
                  Submit
                </button>
              </div>
            </div>
          </form>

          {
            tdata.length > 0 ?
              (
                <div className="my-4">
                  <div className={styles.final_report_boxes}>

                    <div className={styles.final_report_box1}>
                      <div className={styles.row1}>{doctor.name}</div>

                      <div className={styles.row2}>
                        <div className={styles.left}>
                          <div className={styles.rowBold} >Rs. <br/> {totalRevenue}</div>
                          <div>TOTAL REVENUE</div>
                        </div>
                        <div className={styles.right}>
                          <div className={styles.rowBold} >Rs. <br/> {totalRevenueShare}</div>
                          <div>REVENUE SHARE</div>
                        </div>
                      </div>

                    </div>
                    <div className={`${styles.final_report_box1} ${styles.final_report_box2}`}>
                      <div className={styles.row1}>Payment History</div>

                      <div className={styles.row2}>
                        <div className={styles.left}>
                          <div className={styles.rowBold} >Rs. <br/> {paidAmount}</div>
                          <div>PAID AMOUNT</div>
                        </div>
                        <div className={styles.right}>
                          <div className={styles.rowBold} >Rs. <br/> {pendingAmount}</div>
                          <div>PENDING AMOUNT</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="filter-table pe-4">
                    {flattenedData?.length > 0 && (
                      <h2 className="web-app__heading mb-4 ms-2">
                        Showing ({flattenedData?.length}) Entries
                      </h2>
                    )}
                    <Table columns={columns} data={flattenedData} />
                  </div>
                </div>
              ) :
              null
          }

        </section>
      </div>
    </main>
  )

}

export default Business;