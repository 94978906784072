import PageTitle from "../../../components/PageTitle";
import AdminLink from "../../Admin/AdminIndex/Link";

const DCSListManagement = () => {
  return (
    <div className="main-content">
      <div className="admin-panel_content">
        <PageTitle title="Doc / Chem / Stk List Management"/>
        <div className="row">
          <div className="admin-panel__box-container">
            <AdminLink
              className={"admin-panel__cards"}
              title="Allot DCS"
              type="allot"
              link="allot-dcs"
            />
            <AdminLink
              className={"admin-panel__cards"}
              title="De-Allot DCS"
              type="de-allot"
              link="de-allot-dcs"
            />
             <AdminLink
              className={"admin-panel__cards"}
              title="Transfer DCS"
              type="transfer"
              link="transfer-dcs"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DCSListManagement;
