import React, { useEffect, useMemo, useState } from 'react'
import { getUsersWithCampaign } from '../../../../../services/campaign'
import PageTitle from '../../../../../components/PageTitle'
import Table from '../../../../../components/Table'

const UserCampaigns = () => {
  const [userData, setUserData] = useState([])

  useEffect(() => {
    const fetchData = async ()=>{
      try {
        const {data} = await getUsersWithCampaign();
        setUserData(data)
      } catch (error) {
        console.error("Error fetching campaigns of user:", error);
      }
    }
    fetchData()
    
  }, [])

  const columns = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "userName",
        minWidth: 80,
        maxWidth: 80,

      },
      {
        Header: "Campaign Count",
        accessor: "",
        minWidth: 85,
        maxWidth: 85,
        Cell: ({ row }) => row.original.campaigns.length
        
      },
      {
        Header: "Campaign",
        accessor: "campaigns",
        minWidth: 85,
        maxWidth: 85,
        Cell: ({ row }) => row.original.campaigns.join(", ")
      },
    ]})
  
  return (
    <div className="main-content admin-content">
    <div className="area-creation-content">
      <PageTitle title="User Data" />

      <section className="area-creation-content__info">
        {userData.length > 0 && (
          <h2 className="web-app__heading mb-4 ms-2">
            Showing ({userData.length}) Entries
          </h2>
        )}
        <div className="filter-table">
          <Table columns={columns} data={userData}/>
        </div>
      </section>
    </div>
  </div>
  )
}

export default UserCampaigns