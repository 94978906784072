import React,{ lazy, Suspense, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  adminStyles,
} from "../../../public/stylesheets/selectStyles";
import serialise, {
} from "../../../../utils/serialiseResponse";
import {
  formatDate,
  monthOptions,
  yearOptions,
  returnMonthFromIndex,
} from "../../../../utils/helper";
import Table from "../../../../components/Table";
import { FaEye } from "react-icons/fa";
import { AiFillFolder } from "react-icons/ai";
import { MdArrowBackIosNew, MdDoneAll, MdClear } from "react-icons/md";
import customToast from "../../../../components/CustomToast";
import { viewPrimarySale } from "../../../../reducers/Sales/primarySales";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
const DatePickers = lazy(() => import("../../../../components/DatePicker"))

const AllPrimarySales = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const primarySales = useSelector(({ primarySales }) => primarySales);
  const [month, setMonth] = useState(moment().month());
  const [year, setYear] = useState(moment().year());

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        disableFilters: true,
        showTotal: true,
        placeholderTotal: "Total",
        Cell: (props) => <span>{formatDate(props?.row?.original?.date)}</span>,
      },
      {
        Header: "Stockist",
        accessor: "stockist",
        disableSortBy: true,
      },
      {
        Header: "Headquarter",
        accessor: "headquarter",
        disableSortBy: true,
      },
      {
        Header: "Total",
        accessor: "total",
        disableFilters: true,
        disableSortBy: false,
        showTotal: true,
        totalAccessor: "total",
        Cell: (props) => {
          return <div>
            {`₹ ${props?.row?.original?.total}`}
          </div>
        }
      },
      {
        Header: "Return Sale",
        accessor: "isReturnSale",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => <span className="fs-2" >{props?.row?.original?.isReturnSale ? <MdDoneAll style={{ color: "#2ecc71" }} /> : <MdClear style={{ color: "#e74c3c" }} />}</span>,
        minWidth: 50,
        maxWidth: 50,
      },
      {
        Header: "View",
        accessor: "vi",
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <Link
              to={`${props?.row?.original?._id}`}
              state={props?.row?.original}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      }
    ],
    []
  );

  //   const handleSubmit = (e) => {
  //     e.preventDefault();
  //     if(!month || !year) return customToast.error("Please select month and year")
  //     dispatch(viewPrimarySale({month:month.value, year:year.label}));
  // };
  useEffect(() => {
    dispatch(viewPrimarySale({ month: month, year: year }));
  }, [month, year, dispatch]);


  const tableData = useMemo(() => serialise(primarySales?.data), [primarySales]);

  return (
    <main className="main-content admin-content">
      <div className="expense">
        <h3
          className="web-app__heading"
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
        >
          <div>
            <MdArrowBackIosNew
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <span style={{ display: "inline-block", margin: "0 1rem" }}>
              All Primary Sales
            </span>
          </div>
        </h3>
        <div className="admin-panel__alert" style={{ padding: "1rem", marginTop: "0" }}>
          <h2 style={{ marginBottom: "0" }}>Note</h2>
          <p>
            The below list shows only those Primary Sales which are submitted by the loggedIn user.
            In order to view the complete list, visit ---{'>'} Reports ---{'>'} Primary Sales Reports or {' '}
            <Link to="/utilities/primary-sales-reports">
              <button style={{ background: 'none', border: 'none', color: 'white' }}>
                CLICK HERE !
              </button>
            </Link>
          </p>
        </div>
        <form >
          <div className="primarysales-filter">
            <div className="util-tp-filter">
              <p className="mb-3">
                Select Month <span className="asterisk-imp">*</span>
              </p>
              <Suspense>
                <DatePickers
                  placeholder={(month && year ) ? moment({ year, month }).format('MMM, YYYY') : "Select Month"}
                  setMonth={setMonth}
                  setYear={setYear}
                />
              </Suspense>
            </div>
            {/* <div className="util-tp-filter">
              <div className="mb-2">
                <button type="submit" className="button-blue-gradient mt-5">Submit</button>
              </div>
            </div> */}
            <div className="util-tp-filter">
              <div className="mb-2">
                <button
                  className="button-submit-green mt-5 ms-auto"
                  onClick={() => { navigate("/primarysales") }}
                >
                  <span className="button-submit-green__icon-container">
                    <AiFillFolder className="button-submit-green__icon" />
                  </span>
                  <span className="button-submit-green__text ">
                    Close Primary Sales
                  </span>
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="filter-table" style={{ minHeight: "150px", overflow: "auto" }}>
          <Table columns={columns} data={tableData} />
        </div>
      </div>
    </main>
  );
};

export default AllPrimarySales;
