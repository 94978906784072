import {
  MdOutlineAccountCircle,
  MdOutlineInventory2,
  MdOutlineLocationCity,
  MdOutlineMedication,
  MdOutlineMonetizationOn,
} from "react-icons/md";
import { RiStethoscopeLine } from "react-icons/ri";
import { BsCheck2Circle } from "react-icons/bs";
import { AiOutlineSetting, AiOutlineUserAdd, AiOutlineUserDelete, AiOutlineUserSwitch } from "react-icons/ai";
import { Link } from "react-router-dom";
import { MdiReportTimeline , TablerReportMoney} from "../../../../components/CostIcon";

const AdminLink = ({ title, type, link, className }) => {
  const renderIcon = (type) => {
    if (type === "Areas") return <MdOutlineLocationCity />;
    else if (type === "Users") return <MdOutlineAccountCircle />;
    else if (type === "Products") return <MdOutlineMedication />;
    else if (type === "Expenses") return <MdOutlineMonetizationOn />;
    else if (type === "Doctors") return <RiStethoscopeLine />;
    else if (type === "Sample") return <MdOutlineInventory2 />;
    else if (type === "Approval") return <BsCheck2Circle />;
    else if (type === "Extra") return <AiOutlineSetting />;
    else if (type === "costCenter") return <MdiReportTimeline />;
    else if (type === "profitCenter") return <TablerReportMoney />;
    else if (type === "settings") return <AiOutlineSetting/>;
    else if (type === "report") return <TablerReportMoney />;
    else if (type === "allot") return <AiOutlineUserAdd />;
    else if (type === "de-allot") return <AiOutlineUserDelete  />;
    else if (type === "transfer") return <AiOutlineUserSwitch  />;
  };

  return (
    <Link className="admin-panel__cards-link" to={`${link}`}>
      <div className={className}>
        {renderIcon(type)}

        <h3 className="web-app__heading">{title}</h3>
      </div>
    </Link>
  );
};

export default AdminLink;
