import React, { useEffect, useMemo, useState } from 'react'
import { MdArrowBackIosNew } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom'
import serialise from '../../../../../utils/serialiseResponse';
import Table from '../../../../../components/Table';
import { formatDate } from '../../../../../utils/helper';
import { viewMonthlyPOBReports } from '../../../../../services/analytics';

const MonthlyPobWiseReportsDetail = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [report, setReport] = useState([])
    const propsData = location.state

    useEffect(() => {
        viewMonthlyPOBReports({
            detailedReport: true,
            selectedUser: propsData?.submitter?._id,
            month: Number(propsData?.month),
            year: Number(propsData?.year)
        })
        .then((res) => setReport(res.data))
        .catch((err) => console.log(err))
    }, [propsData])

    const tableData = useMemo(() => serialise(report), [report]);
    const columns = useMemo(() => [
        {
            Header: "Date",
            accessor: "date",
            disableFilters: true,
            Cell: (props) => {
                return formatDate(props.row.original.date)
            },
        },
        {
            Header: "Name",
            accessor: "entityName",
        },
        {
            Header: "Type",
            accessor: "type",
            Cell: (props) => {
                return props.row.original.type === "doc" ? <span className='approved-text'>Doctor</span> :
                    props.row.original.type === "chem" ? <span className='pending-text'>Chemist</span> :
                        props.row.original.type === "stk" ? <span className='rejected-text'>Stockist</span> :
                            "N/A"
            },
        },
        {
            Header: "Product",
            accessor: "productName",
        },
        {
            Header: "Total Quantity",
            accessor: "quantity",
            showTotal: true,
            totalAccessor:"quantity",
            disableFilters: true,
        },
        {
            Header: "Total Amount",
            accessor: "amount",
            showTotal: true,
            totalAccessor:"amount",
            disableFilters: true,
        },

    ], [])

    return (
        <div className="expense">
            <div className="row">
                <h2
                    className="web-app__heading"
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <MdArrowBackIosNew
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate(-1)}
                    />
                    <span style={{ display: "inline-block", margin: "0 1rem" }}>
                        POB Report Details
                    </span>
                </h2>

                <div className="filter-table">
                    {report?.length > 0 && (
                        <h2 className="web-app__heading mb-4">
                            Showing ({report.length}) Entries For 
                            "{propsData?.submitter?.name}"
                        </h2>
                    )}
                    {report?.length > 0 ? <Table
                        columns={columns}
                        data={tableData} /> : <h3 className="mt-2 no-results-message">No results found</h3>}
                </div>
            </div>
        </div>
    );
}

export default MonthlyPobWiseReportsDetail
