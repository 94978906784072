import { useEffect, useState, useRef, lazy, Suspense } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import PageTitle from "../../../../../components/PageTitle";
import customToast from "../../../../../components/CustomToast";
import { adminStyles, dcrStyles } from "../../../../public/stylesheets/selectStyles";
import { allotUserGifts, deleteUserGifts } from "../../../../../reducers/reports/giftAllotmentReducer";
import {
  monthOptions,
  returnMonthFromIndex,
  yearOptions,
} from "../../../../../utils/helper";
import {
  MdCheckCircleOutline,
  MdDelete,
  MdDeleteOutline,
  MdOutlineEdit,
} from "react-icons/md";
import { RiCloseCircleLine } from "react-icons/ri";
import { viewGifts, viewProducts } from "../../../../../services/products";
import { viewSampleGiftData } from "../../../../../services/utilities";
import { serialiseSSG } from "../../../../../utils/serialiseResponse";
import { useMemo } from "react";
import Table from "../../../../../components/Table";
import moment from "moment-timezone";
const DatePickers = lazy(() => import("../../../../../components/DatePicker"))


const AdminAllotGift = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const loggedIn = useSelector(({ user }) => user);
  const nameRef = useRef("");
  const totalQt = useRef(0);

  const [month, setMonth] = useState(moment().month());
  const [year, setYear] = useState(moment().year());
  const [editing, setEditing] = useState(false);// used to re-render the table
  const [edit, setEdit] = useState(false);
  const [gift, setGift] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [showEdit, setShowEdit] = useState(false);
  const [giftId, setGiftId] = useState("");
  const [gifts, setGifts] = useState([]);
  const [serial, setSerial] = useState(1);
  const [giftArray, setGiftArray] = useState([]);
  const [alreadyAlloted, setAlreadyAlloted] = useState([]);
  const [type, setType] = useState({ value: 1, label: "Already Allotted" });
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isDeleted, setIsDeleted] = useState(false);
  const typeOptions = [
    { value: 1, label: "Already Allotted" },
    { value: 2, label: "Allot More" },
  ];

  useEffect(() => {
    // viewProducts("gift").then((response) => {
    viewGifts().then((response) => {
      setGiftArray(response?.data);
    });
  }, []);


  const handleAddGift = (productInfo) =>{
    let oldGifts = gifts;
    const newProduct = {
      submitter: loggedIn?.jwt?.id,
      user: params?.userID ? params?.userID : loggedIn?.user?._id,
      month: month,
      year: Number(year),
      serial,
      productName: productInfo?.gift,
      gift: productInfo?._id,
      issued: totalQt.current,
      balance: totalQt.current,
      amount: totalQt.current * Number(productInfo?.price),
    };

    setSerial((old) => old + 1);
    setGiftId("");
    setGifts([...oldGifts, newProduct]);
    setGiftArray(giftArray.filter((el) => el._id !== productInfo?._id));
    setQuantity(0);
    setShowEdit(false);
  };

  useEffect(() => {
  if(type?.value === 1){
    const alreadyAllotedGift = async () => {
      let data = {
        month: month,
        year: Number(year),
        selectedUser: location?.state?._id,
      };
      await viewSampleGiftData("gift", data).then((res) =>
        setAlreadyAlloted(res.data)
      );
    };
    alreadyAllotedGift();
  }
  }, [month, isDeleted, type,year]);


  const previewColumn = useMemo(
    () =>[
      {
        Header:"Product Name",
        accessor:"productName",
        showTotal: true,
        placeholderTotal: "Total"
      },
      {
        Header:"Quantity",
        accessor:"issued",
        showTotal:true,
        totalAccessor: "issued"
      },
      {
        Header:"Amount",
        accessor:"amount",
        showTotal:true,
        totalAccessor: "amount"
      }
    ],[gifts]
  );

  const EditPopup = ({ data }) => {
    const handleSubmit = (e) => {
      e.preventDefault();
      try {
        dispatch(allotUserGifts({ allotData: data }));
        setGifts([]);
        if(loggedIn?.user?.des === 101){
          customToast.success("Gifts approved successfully")
        }else{
          customToast.success("Gifts sent for Approval");
        }
      } catch (error) {
        customToast.error("Unable to allot gifts");
      }
      setShowEdit(false);
    };

    return (
      <section className="edit-popup edit-popup__sales">
        <div className="edit-content__sales">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Sample Allotment</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form onSubmit={handleSubmit}>
            <div className="filter-table" style={{minHeight:"150px" ,maxHeight:"350px", overflow:"auto"}}>
            <Table data={gifts} columns={previewColumn}/>
            </div>

            <div className="edit-form-button-container">
              <button type="submit" className="button-submit-green mt-4">
                <span className="button-submit-green__icon-container">
                  <MdCheckCircleOutline className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Allot Gift</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  const DeletePopup = ({ data }) => {
    const dispatch = useDispatch();
    const handleDelete = (e) => {
      e.preventDefault();
      // dispatch(deleteHoliday(data?._id));
      try{
        dispatch(deleteUserGifts({id:data?._id}))
      setIsDeleted(!isDeleted);

      setDeletePopup(false);
      customToast.success("Allotment deleted successfully")
      }
      catch(err){
      customToast.error("We encounterd an error !")
      }
    };

    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete Alloted Gift</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setDeletePopup(false)}
            />
          </div>

          <form onSubmit={handleDelete}>
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  This will PERMANENTLY delete the alloted Gift !
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button type="submit" className="button-delete mt-4">
                <span className="button-delete__icon-container">
                  <MdDeleteOutline className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Yes</span>
              </button>

              <button
                type="button"
                className="button-submit-green mt-4"
                onClick={() => setDeletePopup(false)}
              >
                <span className="button-submit-green__icon-container">
                  <MdCheckCircleOutline className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">No</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };
  
  const columns = useMemo(() => {
  
    if (loggedIn?.user?.des === 101) {
      return [
        // {
        //   Header: "Sr. No",
        //   accessor: "sr",
        //   disableFilters: true,
        //   minWidth: 40,
        //   maxWidth: 40,
        // },
        {
          Header: "Gift",
          accessor: "gift.gift",
          minWidth: 80,
          maxWidth: 80,
        },
        {
          Header: "Alotted Quantity",
          accessor: "issued",
          disableSortBy: true,
          minWidth: 85,
          maxWidth: 85,
        },
        {
          Header: "Distributed Quantity",
          accessor: "",
          disableFilters: true,
          disableSortBy: true,
          minWidth: 85,
          maxWidth: 85,
          Cell: (props) => {
            return (
              <span>
                {props?.row?.original?.issued - props?.row?.original?.balance
                  ? props?.row?.original?.issued - props?.row?.original?.balance
                  : 0}
              </span>
            );
          },
        },
        {
          Header: "Available Quantity",
          accessor: "balance",
          minWidth: 85,
          maxWidth: 85,
        },
        {
          Header: "View",
          accessor: "4",
          disableFilters: true,
          disableSortBy: true,
          minWidth: 50,
          maxWidth: 50,
          Cell: (props) => {
            return (
              <span
                className="react-table-view-link"
                onClick={() => {
                  setDeleteData(props?.row?.original);
                  setDeletePopup(true);
                }}
              >
                <MdDelete className="icon-color-error" />
              </span>
            );
          },
        },
      ];
    } else {
      return [
        // {
        //   Header: "Sr. No",
        //   accessor: "sr",
        //   disableFilters: true,
        //   minWidth: 40,
        //   maxWidth: 40,
        // },
        {
          Header: "Gift",
          accessor: "gift.gift",
          minWidth: 80,
          maxWidth: 80,
        },
        {
          Header: "Alotted Quantity",
          accessor: "issued",
          disableSortBy: true,
          minWidth: 85,
          maxWidth: 85,
        },
        {
          Header: "Distributed Quantity",
          accessor: "",
          disableFilters: true,
          disableSortBy: true,
          minWidth: 85,
          maxWidth: 85,
          Cell: (props) => {
            return (
              <span>
                {props?.row?.original?.issued - props?.row?.original?.balance
                  ? props?.row?.original?.issued - props?.row?.original?.balance
                  : 0}
              </span>
            );
          },
        },
        {
          Header: "Available Quantity",
          accessor: "balance",
          minWidth: 85,
          maxWidth: 85,
        },
      ];
    }
  }, []);
  const tableData = useMemo(
    () => serialiseSSG(alreadyAlloted),
    [alreadyAlloted]
  );

  const handleEditRow = (value) =>{
    nameRef.current = value;
    setEdit(true);
    setEditing(!editing);
  };

  const giftColumns = useMemo(
    () => [
      {
        Header: "Product Name",
        accessor: "gift",
      },
      {
        Header: "Price",
        accessor:"price",
      },
      {
        Header:"Quantity",
        accessor:"",
        Cell: (props) =>{
          const editable = String(props?.row?.original?.gift) === String(nameRef.current);
          return (editable) ?
            <input
            type="number"
            defaultValue={0}
            className="sales-table__input"
            onChange={(e) =>{
              totalQt.current = e.target.value;
            }}/>
          : 0
        }
      },
      {
        Header: "Actions",
        accessor:"",
        Cell: (props) =>{
          const editable = String(props?.row?.original?.gift) === String(nameRef.current);
          return ( edit && editable) ?(
            <button
            onClick={() =>{
              handleAddGift(props?.row?.original);
              setEdit(false);
              setEditing(!editing);
            }}>
              Save
            </button>
          )
          :
          (
            <div className="d-flex gap-1 justify-content-center">
              <MdOutlineEdit type="button"
              onClick={() =>{
                handleEditRow(props?.row?.original?.gift)
              }}/>
            </div>
          )
        }
      },
    ],[giftArray,editing]
  ); 

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle
          title={`Allot Gifts to ${location.state?.name}`}
          hierarchy={true}
        />

        <section className="area-creation-content__form">
          <form>
            <div className="d-flex align-items-center">
              <div className="util-tp-filter me-5">
                <p className="">
                  Select Month <span className="asterisk-imp">*</span>
                </p>
                <br />
                <Suspense>
                  <DatePickers
                    placeholder={(month && year ) ? moment({ year, month }).format('MMM, YYYY') : "Select Month"}
                    setMonth={setMonth}
                    setYear={setYear}
                  />
                </Suspense>
              </div>
              <div className="util-tp-filter me-5">
                <p className="">
                  Select Type <span className="asterisk-imp">*</span>
                </p>
                <br />
                <Select
                  styles={dcrStyles}
                  placeholder="Select Type"
                  value={type}
                  options={typeOptions}
                  onChange={(e) => {
                    setType({ ...e });
                  }}
                />
              </div>
            </div>
          </form>
        </section>

        <section className="area-creation-content__info">
          <h2 className="web-app__heading">Add Gifts</h2>

          <div className="filter-table">
            {type?.value === 1 ? (
              <Table columns={columns} data={tableData} />
            ) : (
              <Table data={giftArray} columns={giftColumns}/>
            )}
          </div>
        </section>

        <section
          className="area-creation-content__info"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {type?.value !== 1 ? (
            <button
              type="submit"
              className="button-blue-gradient"
              onClick={() => setShowEdit(true)}
            >
              Preview
            </button>  
          ) : null}

          {showEdit ? <EditPopup data={gifts} /> : null}
          {deletePopup ? <DeletePopup data={deleteData} /> : null}
        </section>
      </div>
    </div>
  );
};

export default AdminAllotGift;
