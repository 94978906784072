import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import logoImage from './medorn-logo.png';
import desginImage from "./des.png" ;
import icon from "./Group 11 (1).png" ;
import rect from "./Rectangle.png" ;
import { formatDate } from '../../utils/helper';
import { monthOptions } from '../../utils/helper';
import background from "./Group 12.png"
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'col',
    backgroundColor: '#fff',
    paddingRight:"10px",
    position:'relative',
    paddingBottom:"40px"
  },
  header: {
    width:"100%",
    position:"relative",
    display:"flex",
    flexDirection:"row"
  },
  logo: {
    width:"20%"
  },
  logo2:{
    marginTop:"-30px",
    width:400,
    height:250
  },
  invText: {
    color: "#0066cc",
    fontSize: "30px",
    fontWeight: "heavy",
    lineHeight:"1.6",
    marginTop:'2px'
  },
  compText:{
    color: "#000",
    fontSize:"22px",
    fontWeight: "heavy"
  },
  address:{
    paddingLeft:"30px",
    paddingRight:"10px",
    marginTop:"40px",
    display:"flex",
    flexDirection:'row',
    justifyContent:'space-between',
    alignItems:"center",
  },
  to:{
    textAlign:'left',
    // fontSize:"12px"
  },
  from:{
    textAlign:'right',
    // fontSize:"12px"
  },
  addHead:{
    fontWeight:"extrabold",
    fontSize:"15px",
    color:"#000000",
    marginBottom:"5px"
  },
  text:{
    color:"#686868",
    fontSize:"12px"
  },
  date:{
    marginTop:"20px"
  },
  thanks:{
    fontWeight:"extralight",
    fontSize:"16px"
  },
  thanksnote:{
    fontWeight:"extralight",
    fontSize:"16px"
  },
  celltop:{
    backgroundColor:"#777676",
    display:"flex",
    flexDirection:"row",
    gap:'5',
    alignItems:'center',
    fontWeight:"extrabold",
    fontSize:'20px',
    justifyContent:'space-between',
    padding:"4px",
    paddingHorizontal:"10px",
    marginTop:"8px"
  },
  celltotal:{
    backgroundColor:"#69BBF2",
    display:"flex",
    flexDirection:"row",
    alignItems:'center',
    fontWeight:"extrabold",
    fontSize:'20px',
    justifyContent:'space-between',
    padding:"4px",
    paddingHorizontal:"10px",
    marginTop:"3px"
  },
  taxes:{
    backgroundColor:"#D9D9D9",
    display:"flex",
    flexDirection:"row",
    gap:'5',
    alignItems:'center',
    fontWeight:"extrabold",
    fontSize:'20px',
    justifyContent:'space-between',
    padding:"4px",
    paddingHorizontal:"10px",
    marginTop:"3px"
  },
  cellbot:{
    marginTop:"3px",
    backgroundColor:"#D9D9D9"
  },
  cell:{
    padding:"1px",
    fontSize:"14px"
  }

});

// Create Document Component
const PdfDocument = ({billObj, compDetails}) => (

  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <View >
          <Image src={icon} style={{width:"160px", height:"120px"}}></Image>
        </View>
        <View style={{display:"flex",alignItems:'center', justifyContent:"center", marginTop:"30px"}}>
          <Text style={{color:"#000000", fontSize:"16px", fontWeight:"heavy"}}>MEDORN VENTURES PRIVATE LIMITED</Text>
          <Text style={{color:"#000000", fontSize:"12px", marginTop:"5px", textAlign:'center'}}>Invoice for Medorn ERP - MR Reporting App</Text>
          <View style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", marginTop:"8px"}}>
            <Text style={{color:"#000000", fontSize:"16px", fontWeight:"heavy", display:"inline-block"}}>CIN - </Text>
            <Text style={{color:"#000000", fontSize:"12px", display:"inline-block"}}>U72900MP2021PTC058566</Text>
          </View>
        </View>
      </View>
      <View style={styles.address}>
        <View>
          <Text style={styles.addHead}>Bill To:</Text>
          <Text style={[styles.text, {fontWeight:"heavy"}]}>{compDetails?.companyName}</Text>
          <Text style={styles.text}>{compDetails?.street1}</Text>
          <Text style={styles.text}>{compDetails?.street2}</Text>
          <Text style={styles.text}>{compDetails?.street3}</Text>
          <Text style={styles.text}>{compDetails?.street4}</Text>
          <Text style={styles.text}>{compDetails?.mobile}</Text>
        </View>
        <View >
          <Text style={styles.addHead}>From: </Text>
          <Text style={[styles.text, {fontWeight:"heavy"}]}>Medorn Ventures Pvt. Ltd.</Text>
          <Text style={styles.text}>S-03, Tirupati Abhinav Homes, Ayodhya</Text>
          <Text style={styles.text}>Bypass Rd, Bhopal, MP, 462041</Text>
          <Text style={styles.text}>CIN: U72900MP2021PTC058566</Text>
          <Text style={styles.text}>+91 9893456088</Text>
        </View>
      </View>
      <View style={{display:"flex", alignItems:"center", justifyContent:"space-between", flexDirection:"row", marginTop:"20px", paddingLeft:"30px", paddingRight:"10px", marginBottom:"20px"}}>
        <Text style={{color:"#686868",fontWeight:"demibold", fontSize:"14px"}}>Date: {formatDate(billObj?.paidOn ? billObj.paidOn : new Date())}</Text>
        <Text style={{color:"#686868", fontWeight:"demibold", fontSize:"14px"}}>Monthly Subscription</Text>
      </View>
      <View style={{ textAlign:'center', paddingLeft:"30px", paddingRight:"10px"}}>
        <View style={styles.celltop}>
          <Text style={{color:"#FFFFFF", fontSize:"14px", fontWeight:"heavy"}}>Description</Text>
          <Text style={{color:"#FFFFFF", fontSize:"14px", fontWeight:"heavy"}}>Active Users</Text>
          <Text style={{color:"#FFFFFF", fontSize:"14px", fontWeight:"heavy"}}>Amount/User</Text>
          <Text style={{color:"#FFFFFF", fontSize:"14px", fontWeight:"heavy"}}>Total</Text>
        </View>
        <View style={[{display:"flex",flexDirection:"row", gap:'5', alignItems:'center', fontWeight:"bold", fontSize:'16px', justifyContent:'space-between', paddingTop:"2px", paddingLeft:"10px", paddingRight:"10px"}, styles.cellbot]}>
          <Text style={styles.cell}>{monthOptions[billObj?.month]?.label}</Text>
          <Text style={styles.cell}>{billObj?.activeUsers}</Text>
          <Text style={styles.cell}>{billObj?.plan}</Text>
          <Text style={styles.cell}>{billObj?.amount}</Text>
        </View>
        <View style={styles.celltotal}>
          <Text style={{color:"#100E0E", fontSize:"14px"}}>Total</Text>
          <Text style={{color:"#100E0E", fontSize:"14px"}}>{billObj?.amount}</Text>
        </View>
        <View style={styles.taxes}>
          <Text style={{color:"#100E0E", fontSize:"14px"}}>Taxes</Text>
          <Text style={{color:"#100E0E", fontSize:"14px"}}></Text>
          <Text style={{color:"#100E0E", fontSize:"14px"}}>0%</Text>
          <Text style={{color:"#100E0E", fontSize:"14px"}}>0.0</Text>
        </View>
      </View>
      <View style={{marginLeft:"auto", display:"flex",flexDirection:"row", gap:'10', justifyContent:"center", alignItems:"center", marginTop:"15px", paddingRight:"10px"}}>
        <Text style={{fontSize:"14px", fontWeight:"bold", color:"#2497E3"}}>Total Amount {billObj?.status === 'PAID' ? 'Paid' : "Due"}:</Text>
        <Text style={{fontSize:"14px", fontWeight:"medium", color:'#2497E3'}}> Rs. {billObj?.amount}</Text>
      </View>
      <View style={{border:"0.4px",borderColor:"#2497E3", marginTop:"100px", marginHorizontal:"auto",  width:"60%", marginBottom:"25px" }}></View>
      <View style={{marginLeft:"auto", display:"flex",flexDirection:"row", gap:'10', justifyContent:"center", alignItems:"center", marginTop:"15px", paddingRight:"10px"}}>
        <Text style={{fontSize:"16px", fontWeight:"heavy", color:"#28A513"}}>Status:</Text>
        <Text style={{fontSize:"16px", fontWeight:"heavy", color:'#28A513'}}>{billObj?.status}</Text>
      </View>
      <View style={{marginLeft:"auto", display:"flex",flexDirection:"row", gap:'10', justifyContent:"center", alignItems:"center", marginTop:"15px", paddingRight:"10px"}}>
        <Text style={{fontSize:"12px", fontWeight:"heavy", color:"#100E0E"}}>Payment Id -</Text>
        <Text style={{fontSize:"12px", fontWeight:"heavy", color:'#100E0E'}}>{billObj?.razorpayPaymentId}</Text>
      </View>
      <View style={{marginLeft:"auto", display:"flex",flexDirection:"row", gap:'10', justifyContent:"center", alignItems:"center", marginTop:"15px", paddingRight:"10px"}}>
        <Text style={{fontSize:"12px", fontWeight:"heavy", color:"#100E0E"}}>Order Id -</Text>
        <Text style={{fontSize:"12px", fontWeight:"heavy", color:'#100E0E'}}>{billObj?.razorpayOrderId}</Text>
      </View>
      <View style={{position:"absolute", bottom:0, left:0,}}>
        <Image src={background} style={{width:"200px", height:"250px"}}></Image>
      </View>
      <View style={{position:"absolute", bottom:40, left:30,}}>
        <Text style={[styles.thanks, styles.text]}> In case of any assistance, please reach out to support@medorn.com or call us
          at +91 9893456088
        </Text>
        <Text style={[styles.thanks, styles.text, {marginTop:"10px"}]}>Thank you!</Text>
      </View>
    </Page>
  </Document>
);

export default PdfDocument ;