import { MdArrowBackIosNew } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCallback, useState, useMemo, useEffect } from "react";
import {
  serialiseChemStk,
} from "../../../../../../utils/serialiseResponse";
import Table from "../../../../../../components/Table";
import { FaEye } from "react-icons/fa";
import customToast from "../../../../../../components/CustomToast";
import { viewSTKWiseSales } from "../../../../../../services/utilities";

const StockistSS = ({data , month , year}) => {
  const [reports, setReports] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.data ;
  const mon = month ? month : location?.state?.month ;
  const yea = year ? year : location?.state?.year
  const fetchStk = useCallback(
    async()=>{
      try {
        const filter = {
          id,
          month:mon,
          year:yea
        };
        const {data} = await viewSTKWiseSales(filter);
        setReports(data);
      } catch (error) {
        customToast.error("error occured Please Try Again");
      }
    },[id , mon,yea]
  )
  useEffect(()=>{
    if(id){
      fetchStk();
    }else if(data){
      setReports(data);
    }
  },[fetchStk , id , data]);

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   minWidth:50,
      //   maxWidth:50,
      //   disableFilters:true,
      // },
      {
        Header: "Stockist",
        accessor: "name",
        showTotal:true,
        placeholderTotal:"Total",
      },
      {
        Header: "Total Secondary Sales",
        accessor: "salesValue",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"salesValue",
        Cell:(props) => {
          return <div>
            {`₹ ${Number(props?.row?.original?.salesValue).toFixed(2)}`}
          </div>
        }
      },
      {
        Header: "View",
        accessor: "details",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          
          return (
            <Link
              to={`./${props?.row?.original?._id}`}
              state={{ data: props?.row?.original?._id, month:mon , year:yea}}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      },
    ],
    [mon,yea]
  );
  const columns2 = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   minWidth:50,
      //   maxWidth:50,
      //   disableSortBy:true,
      // },
      {
        Header: "Stockist",
        accessor: "name",
        showTotal:true,
        placeholderTotal:"Total",
      },
      {
        Header: "Headquarter",
        accessor: "headquarter",
        showTotal:true,
        placeholderTotal:"Total",
      },
      {
        Header: "Total Secondary Sales",
        accessor: "salesValue",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"salesValue",
        Cell:(props) => {
          return <div>
            {`₹ ${Number(props?.row?.original?.salesValue).toFixed(2)}`}
          </div>
        }
      },
      {
        Header: "View",
        accessor: "details",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          
          return (
            <Link
              to={`./${props?.row?.original?._id}`}
              state={{ data: props?.row?.original?._id, month:mon , year:yea}}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      },
    ],
    [mon,yea]
  );
  const tableData = useMemo(() => serialiseChemStk(reports), [reports]);
  
  return (
    <div className={!data?"expense":""}>
      {!data && (<h2
        className="web-app__heading"
        style={{ display: "flex", alignItems: "center" }}
      >
        <MdArrowBackIosNew
          style={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
        <span style={{ display: "inline-block", margin: "0 1rem" }}>
          Stockist Wise Sales Reports
        </span>
      </h2>)}
      <div className="filter-table">
          {reports.length > 0 && (
              <h2 className="web-app__heading mb-4 ps-3">
                  Showing ({reports.length}) Entries
              </h2>
          )}
          {tableData.length > 0 ? (
              <Table columns={data ? columns2 : columns } data={tableData} fileSrc="Secondary Sales Reports (Stockist)" />
          ) : (
              <h3 className="mt-2 no-results-message">No results found</h3>
          )}
      </div> 
    </div>
  );
};

export default StockistSS ;
