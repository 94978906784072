import { useEffect, useMemo, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import customToast from "../../../../components/CustomToast";
import {
  addIndex,
  generateSelectData,
} from "../../../../utils/serialiseResponse";
import {
  setProductCategories,
  clearProductCategories,
} from "../../../../reducers/products/categoryReducer";
import {
  setProductTypes,
  clearProductTypes,
} from "../../../../reducers/products/typeReducer";
import {
  addProductRange,
  setProductRanges,
  clearProductRanges,
  deleteProductRange
} from "../../../../reducers/products/rangeReducer";
import {
  clearDivisions,
  setDivisions,
} from "../../../../reducers/users/divisionReducer";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import PageTitle from "../../../../components/PageTitle";
import { useNavigate } from "react-router-dom";
import Table from "../../../../components/Table";
import { RiEdit2Fill, RiCloseCircleLine } from "react-icons/ri";
import { FaEye } from "react-icons/fa";
import { MdClear, MdDoneAll, MdCheckBoxOutlineBlank,MdDelete } from "react-icons/md";
import {ImCheckboxUnchecked, ImCheckboxChecked} from "react-icons/im" ;
import { BsCheck2Circle, BsXCircle } from "react-icons/bs";


const AdminCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const category = useSelector(({ productCategory }) => productCategory);
  const types = useSelector(({ productType }) => productType);
  const ranges = useSelector(({ productRange }) => productRange);
  const divisionData = useSelector(({ division }) => division);
  const [isSection, SetIsSection] = useState(true);
  const [prodCategory, setProdCategory] = useState("");
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [composition, setComposition] = useState("");
  const [packaging, setPackaging] = useState("");
  const [mrp, setMrp] = useState("");
  const [pts, setPts] = useState("");
  const [ptr, setPtr] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [gst, setGst] = useState("");
  const [division, setDivision] = useState(null);
  const [deleteMany, setDeleteMany] = useState(false);
  const [allSelected, setAllSelected] = useState(false);  
  const [showEdit, setShowEdit] = useState(false);

  const categorySelect = useMemo(
    () => generateSelectData(category.data, "name"),
    [category]
  );
  const typeSelect = useMemo(
    () => generateSelectData(types.data, "name"),
    [types]
  );
  const divisionSelect = useMemo(
    () => generateSelectData(divisionData, "name"),
    [divisionData]
  );
  useEffect(() => {
    dispatch(setProductCategories());
    dispatch(setProductTypes());
    dispatch(setProductRanges());
    dispatch(setDivisions());

    return () => {
      dispatch(clearProductCategories());
      dispatch(clearProductTypes());
      dispatch(clearProductRanges());
      dispatch(clearDivisions());
    };
  }, [dispatch]);
  const SelectColumnFilter = useCallback(
    ({ column, columns }) =>{
      return (
        <div className="search-filter__container" onClick={(e)=>{e.stopPropagation()}}>
          {<span className="d-flex gap-3 align-items-center">{column.render("Header")}
            {allSelected ? (
                <ImCheckboxChecked onClick={(e) => {
                  e.preventDefault();
                  column?.filteredRows?.forEach((el,idx)=>{
                    el.original.isSelected = false ;
                  });
                  setAllSelected(false);
                }}/>
              ):(
                <ImCheckboxUnchecked onClick={(e) => {
                  e.preventDefault();
                  column?.filteredRows?.forEach((el,idx)=>{
                    el.original.isSelected = true ;
                  });
                  setAllSelected(true);
                }}/>
              )
              }
          </span>
          }
        </div>
  );},[allSelected])
  
  const handleDelete = useCallback(async (data) => {
    if(data.length === 0)return customToast.error("Please select atleast one product");
    const payload = {ids:data , deleteTyp:"bulk"} ;
    await dispatch(deleteProductRange(1, payload));
    setAllSelected(false);
    setShowEdit(false);
    setDeleteMany(false);
    customToast.success("Products deleted successfully");
  },[dispatch]) ;

  const tableData = useMemo(() => addIndex(ranges.data), [ranges]);
  const EditPopup = () => {
    const data = [];
    tableData.forEach((el,idx)=>{
      if(el?.isSelected){
        data.push(el?._id);
      }
    })
    return (
      <section className="edit-popup" onClick={(e) =>
        showEdit
          ? e.target.className === "edit-popup"
            ? setShowEdit(false)
            : null
          : null
      }>
      {/* <section className="edit-popup"> */}
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              setShowEdit(false);
            }}
          >
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  {`Are you sure you want to delete ${data.length} products`}
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button
                type="button"
                className="button-delete mt-4"
                onClick={() => handleDelete(data)}
              >
                <span className="button-delete__icon-container">
                  <BsXCircle className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Delete</span>
              </button>
              <button
                type="button"
                className="button-submit-green mt-4"
                onClick={() => setShowEdit(false)}
              >
                <span className="button-submit-green__icon-container">
                  <BsCheck2Circle className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Cancel</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };
  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "UID",
        accessor: "uid",
        minWidth: 70,
        maxWidth: 70,
      },
      {
        Header: "Stock",
        accessor: "stock",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 50,
        maxWidth: 50,
        Cell:(props)=><span className="fw-bolder">{props?.row?.original?.stock ? props?.row?.original?.stock:0}</span>
      },
      {
        Header: "Division",
        accessor: "division.name",
        disableSortBy: true,
        minWidth: 100,
        maxWidth: 100,
      },
      {
        Header: "Packaging",
        accessor: "packaging",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "MRP",
        accessor: "mrp",
        disableFilters: true,
        minWidth: 50,
        maxWidth: 50,
        wordWrap:"break-word",
      },
      {
        Header: "PTS",
        accessor: "pts",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 50,
        maxWidth: 50,
        wordWrap:"break-word",
      },
      {
        Header: "PTR",
        accessor: "ptr",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 50,
        maxWidth: 50,
        wordWrap:"break-word",
      },
      {
        Header: "View",
        accessor: "details",
        Cell: (props) => (
          <span
            className="react-table-view-link"
            onClick={() =>
              navigate("/admin-panel/products/range/details", {
                state: props?.row?.original,
              })
            }
          >
            <FaEye className="icon-color-tertiary" />
          </span>
        ),
        disableFilters: true,
        disableSortBy: true,
        minWidth: 40,
        maxWidth: 40,
      },
      {
        Header: deleteMany ? "Select" : "Actions",
        accessor: "",
        Cell: (props) => {
          const [local,setLocal] = useState(props?.row?.original?.isSelected || false);
          return deleteMany ? (
            <span
              className="react-table-view-link display-6"
            >    
              {local ? (
                <ImCheckboxChecked onClick={() => {
                  props.row.original.isSelected = false ;
                  setLocal(false); 
                }}/>
              ):(
                <MdCheckBoxOutlineBlank onClick={() => {
                  props.row.original.isSelected = true ;
                  setLocal(true);
                }}/>
              )
              }
            </span>
          ):(
            <span
              className="react-table-view-link"
              onClick={() =>
                navigate(`/admin-panel/products/range/edit`, {
                  state: props?.row?.original,
                })
              }
            >
              <RiEdit2Fill className="icon-color-green" />
            </span>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: deleteMany ? false : true,
        disableSortBy: true,
        Filter:SelectColumnFilter,
      },
    ],
    [deleteMany,navigate, SelectColumnFilter,tableData]
  );
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !name ||
      !packaging ||
      !pts ||
      !mrp ||
      !ptr ||
      !composition ||
      !division
    )
      return customToast.error("Please fill all details");

      var index = tableData.findIndex(p => (p.name).toLocaleLowerCase() == name.toLocaleLowerCase());
   
    if(index === -1){
      let error = false;
      try {
        await dispatch(
          addProductRange({
            name: name,
            categoryId: prodCategory?.value,
            typeId: type?.value,
            packaging: packaging,
            mrp: mrp,
            pts: pts,
            ptr: ptr,
            composition,
            manufacturer,
            gst,
            division: division?.value,
          })
        );
      } catch (e) {
        error = true;
        customToast.error("We Encountered an Error");
      }
  
      if (!error) {
        customToast.success("Product Range Successfully Created");
        setProdCategory(null);
        setComposition(null);
        setType(null);
        setDivision(null);
        setName("");
        setPackaging("");
        setMrp("");
        setPtr("");
        setPts("");
        setComposition("");
        setManufacturer("");
        setGst("");
      }
    }
    else{
      customToast.error("Product Already Exists");
    }

    
  };
  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Create Product"  isDropup={isSection} isDropdown={!isSection} toggle={SetIsSection}/>
        {isSection && <section className={`area-creation-content__form form_hidden`}>
          <form onSubmit={handleSubmit}>
            <div className="admin-form">
              <div>
                <label htmlFor="prod-name">
                  Product Name <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="prod-name"
                  placeholder="Enter Product Name"
                  value={name}
                  onChange={({ target }) => setName(target.value)}
                />
              </div>
              <div>
                <label htmlFor="prodComposition">
                  Product Composition <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="prodComposition"
                  placeholder="Enter Product Composition"
                  value={composition}
                  onChange={({ target }) => setComposition(target.value)}
                />
              </div>
              <div>
                <label htmlFor="prod-type">Select Product Category</label>
                <br />
                <Select
                  name="prodCategory"
                  id="prodCategory"
                  value={prodCategory}
                  options={categorySelect}
                  onChange={(e) =>
                    setProdCategory({ value: e.value, label: e.label })
                  }
                  styles={adminStyles}
                  placeholder="Select Category"
                />
              </div>
              <div>
                <label htmlFor="prod-type">Select Product Type</label>
                <br />
                <Select
                  name="prod-type"
                  id="prod-type"
                  value={type}
                  options={typeSelect}
                  onChange={(e) => setType({ value: e.value, label: e.label })}
                  styles={adminStyles}
                  placeholder="Select Product Type"
                />
              </div>
              <div>
                <label htmlFor="prodManufac">Manufacturer Name</label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="prodManufac"
                  placeholder="Enter Product Manufacturer"
                  value={manufacturer}
                  onChange={({ target }) => setManufacturer(target.value)}
                />
              </div>
              <div>
                <label htmlFor="prod-pack">
                  Product Packaging <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="prod-pack"
                  placeholder="Enter Product Packaging Details"
                  value={packaging}
                  onChange={({ target }) => setPackaging(target.value)}
                />
              </div>
              <div>
                <label htmlFor="mrp">
                  Max Retail Price <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="number"
                  className="me-5"
                  id="mrp"
                  placeholder="Enter Product MRP"
                  value={mrp}
                  onChange={({ target }) => setMrp(target.value)}
                />
              </div>
              <div>
                <label htmlFor="pts">
                  Price To Stockist <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="number"
                  className="me-5"
                  id="pts"
                  placeholder="Enter Product PTS"
                  value={pts}
                  onChange={({ target }) => setPts(target.value)}
                />
              </div>
              <div>
                <label htmlFor="ptr">
                  Price To Retailers <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="number"
                  className="me-5"
                  id="ptr"
                  placeholder="Enter Product PTR"
                  value={ptr}
                  onChange={({ target }) => setPtr(target.value)}
                />
              </div>
              <div>
                <label htmlFor="division">
                  Division <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="division"
                  id="division"
                  value={division}
                  options={divisionSelect}
                  onChange={({ value, label }) => setDivision({ value, label })}
                  styles={adminStyles}
                  placeholder="Select Division"
                />
              </div>
              <div>
                <label htmlFor="gst">GST %</label>
                <br />
                <input
                  type="number"
                  className="me-5"
                  id="gst"
                  placeholder="Enter Product GST %"
                  value={gst}
                  onChange={({ target }) => setGst(target.value)}
                />
              </div>
              <div className="pt-3">
                <button type="submit" className="button-blue-gradient mt-5">
                  Add Product
                </button>
              </div>
            </div>
          </form>
        </section>}

        <section className="area-creation-content__info">
          <div className="">
            {!isSection && deleteMany && (
              <div className="d-flex justify-content-between align-items-end flex-row-reverse">
              <span
                className="react-table-view-link"
                onClick={() => {
                  setDeleteMany(false);
                }}
              >
                <RiEdit2Fill className="icon-color-green" />
              </span>
              <button
                type="submit"
                className="button-delete"
                onClick={(e) => {
                  e.preventDefault();
                  setShowEdit(true);
                }}
              >
                <span className="button-delete__icon-container">
                  <BsXCircle className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Delete</span>
              </button></div>
            )}
            {!isSection && !deleteMany && (
              <span className="icon-color-error h-100 d-flex justify-content-end">
                <MdDelete className="cursor-pointer" onClick={(e)=>{
                  e.preventDefault();
                  setDeleteMany(true);
                }}/>
              </span>
            )}
          </div>
          <div className="filter-table">
            <Table columns={columns} data={tableData} />
          </div>
        </section>
        {showEdit ? <EditPopup /> : null}
      </div>
    </main>
  );
};

export default AdminCategory;
