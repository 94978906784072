import { useEffect, useState, useMemo, Suspense, lazy } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import customToast from "../../../../components/CustomToast";
import serialise, {
  generateSelectData,
  generateSelectUser,
} from "../../../../utils/serialiseResponse";
import {
  selectUserStyles,
  dcrStyles,
  adminStyles,
} from "../../../../views/public/stylesheets/selectStyles.js";
import {
  formatDate,
  monthOptions,
  yearOptions,
} from "../../../../utils/helper";
import { MdDeleteOutline, MdOutlineAdd, MdOutlineGroup } from "react-icons/md";
import { viewUsersForUtilities } from "../../../../services/utilities";
import { IoIosAddCircleOutline, IoIosDoneAll } from "react-icons/io";
import Table from "../../../../components/Table/index.jsx";
import {
  addFinalCallPlanning,
  deleteCallPlanning,
  getMonthlyCallPlanning,
} from "../../../../services/callPlanning.js";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { AiOutlineStop } from "react-icons/ai";
import {
  RiCapsuleFill,
  RiCloseCircleLine,
  RiDeleteBin6Line,
  RiGiftFill,
  RiNurseFill,
} from "react-icons/ri";
import { viewTargets } from "../../../../services/targets.js";
import { viewGifts, viewProductV1 } from "../../../../services/products.js";
import { Accordion, Button } from "react-bootstrap";
import { LuLoader2 } from "react-icons/lu";
import { PiDotsThreeCircleLight } from "react-icons/pi";
import moment from "moment-timezone";
import {
  clearUsers,
  setUsersForUtilities,
} from "../../../../reducers/users/employeeReducer.js";
const DatePickers = lazy(() => import("../../../../components/DatePicker"));

const CallPlanningForm = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(({ user }) => user);
  const employees = useSelector(({ employee }) => employee);

  const [month, setMonth] = useState(moment().month());
  const [year, setYear] = useState(moment().year());
  // const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const sundayWorking = useSelector((state) => state.settings["sundayWorking"]);
  const [cpData, setCPData] = useState([]);
  const [addPopup, setAddPopup] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [data, setData] = useState();

  const [doctors, setDoctors] = useState([]);
  const [chemists, setChemists] = useState([]);
  const [stockists, setStockists] = useState([]);
  const [products, setProducts] = useState([]);
  const [gifts, setGifts] = useState([]);

  const setAddForm = (data, selectedUser) => {
    setData({
      date: data.date,
      selectedUser: selectedUser?.value,
      id: data._id,
    });
  };

  const iconStyle = {
    fontSize: "2.3rem",
    cursor: "pointer",
  };
  const iconStyle2 = {
    fontSize: "2rem",
    cursor: "pointer",
  };

  const AddPopup = ({ data }) => {
    const [loading, setLoading] = useState(false);
    const [addPGPopup, setAddPGPopup] = useState(false);
    const [showCheckbox, setShowCheckbox] = useState(false);
    const [activeTab, setActiveTab] = useState("Doctor");
    const [selectedDoctor, setSelectedDoctor] = useState();
    const [selectedDoctorProduct, setSelectedDoctorProduct] = useState([]);
    const [selectedDoctorGift, setSelectedDoctorGift] = useState([]);
    const [doctorsArray, setDoctorsArray] = useState([]);

    const [selectedChemist, setSelectedChemist] = useState(null);
    const [selectedChemistProduct, setSelectedChemistProduct] = useState([]);
    const [selectedChemistGift, setSelectedChemistGift] = useState([]);
    const [chemistsArray, setChemistsArray] = useState([]);

    const [selectedStockist, setSelectedStockist] = useState(null);
    const [selectedStockistProduct, setSelectedStockistProduct] = useState([]);
    const [selectedStockistGift, setSelectedStockistGift] = useState([]);
    const [stockistsArray, setStockistsArray] = useState([]);

    const [currentEntity, setCurrentEntity] = useState(null);
    const [toggle, setToggle] = useState(false);

    const handleAdd = () => {
      if (activeTab === "Doctor" && selectedDoctor) {
        doctorsArray.find((doc) => doc.doctor._id === selectedDoctor._id)
          ? customToast.error("Doctor already added")
          : setDoctorsArray((prev) => [
              ...prev,
              {
                doctor: selectedDoctor,
                products: [],
                gifts: [],
              },
            ]);
        setSelectedDoctor(null);
        setShowCheckbox(false);
      } else if (activeTab === "Chemist" && selectedChemist) {
        chemistsArray.find((doc) => doc.chemist._id === selectedChemist._id)
          ? customToast.error("Chemist already added")
          : setChemistsArray((prev) => [
              ...prev,
              {
                chemist: selectedChemist,
                products: [],
                gifts: [],
              },
            ]);
        setSelectedChemist(null);
        setShowCheckbox(false);
      } else if (activeTab === "Stockist" && selectedStockist) {
        stockistsArray.find((doc) => doc.stockist._id === selectedStockist._id)
          ? customToast.error("Stockist already added")
          : setStockistsArray((prev) => [
              ...prev,
              {
                stockist: selectedStockist,
                products: [],
                gifts: [],
              },
            ]);
        setSelectedStockist(null);
        setShowCheckbox(false);
      }
    };

    const handleSubmitFinalCP = async (e) => {
      e.preventDefault();
      setLoading(true);

      if (
        doctorsArray.length === 0 &&
        chemistsArray.length === 0 &&
        stockistsArray.length === 0
      ) {
        customToast.error("Please add atleast one entity");
        setLoading(false);
        return;
      }

      try {
        await addFinalCallPlanning({
          doctors: doctorsArray,
          chemists: chemistsArray,
          stockists: stockistsArray,
          date: data.date,
          selectedUser: data.selectedUser,
        });
        const res = await getMonthlyCallPlanning({
          month: month,
          year: year,
          selectedUser: selectedUser?.value,
        });
        setCPData([...res.data]);
      } catch (error) {
        customToast.error("Error in adding call planning");
      } finally {
        setLoading(false);
        setAddPopup(false);
      }
    };

    const handleAddPG = async () => {
      let updateArray,
        setUpdateArray,
        selectedProducts,
        selectedGifts,
        entityKey;

      if (activeTab === "Doctor") {
        updateArray = doctorsArray;
        setUpdateArray = setDoctorsArray;
        selectedProducts = selectedDoctorProduct;
        selectedGifts = selectedDoctorGift;
        entityKey = "doctor";
      } else if (activeTab === "Chemist") {
        updateArray = chemistsArray;
        setUpdateArray = setChemistsArray;
        selectedProducts = selectedChemistProduct;
        selectedGifts = selectedChemistGift;
        entityKey = "chemist";
      } else if (activeTab === "Stockist") {
        updateArray = stockistsArray;
        setUpdateArray = setStockistsArray;
        selectedProducts = selectedStockistProduct;
        selectedGifts = selectedStockistGift;
        entityKey = "stockist";
      }

      if (
        (selectedProducts?.length > 0 || selectedGifts?.length > 0) &&
        updateArray
      ) {
        setUpdateArray((prev) =>
          prev.map((item) =>
            item?.[entityKey]._id === currentEntity
              ? {
                  ...item,
                  products: [
                    ...new Map(
                      [...item?.products, ...selectedProducts].map((p) => [
                        p._id,
                        p,
                      ])
                    ).values(),
                  ],
                  gifts: [
                    ...new Map(
                      [...item?.gifts, ...selectedGifts].map((g) => [g._id, g])
                    ).values(),
                  ],
                }
              : item
          )
        );
      }

      setAddPGPopup(false);
    };

    const handleRemove = (id) => {
      if (activeTab === "Doctor")
        setDoctorsArray((prev) => prev.filter((doc) => doc.doctor._id !== id));
      else if (activeTab === "Chemist")
        setChemistsArray((prev) =>
          prev.filter((doc) => doc.chemist._id !== id)
        );
      else if (activeTab === "Stockist")
        setStockistsArray((prev) =>
          prev.filter((doc) => doc.stockist._id !== id)
        );
    };
    const toggleHandler = () => {
      setToggle(true);
    };

    const handleDoctorCheckboxChange = (doctor) => {
      setDoctorsArray((prevSelected) =>
        prevSelected.some((d) => d.doctor._id === doctor._id)
          ? prevSelected.filter((d) => d.doctor._id !== doctor._id)
          : [
              ...prevSelected,
              {
                doctor: doctor,
                products: [],
                gifts: [],
              },
            ]
      );
    };
    const handleChemistCheckboxChange = (chemist) => {
      setChemistsArray((prevSelected) =>
        prevSelected.some((d) => d.chemist._id === chemist._id)
          ? prevSelected.filter((d) => d.chemist._id !== chemist._id)
          : [
              ...prevSelected,
              {
                chemist: chemist,
                products: [],
                gifts: [],
              },
            ]
      );
    };
    const handleStockistCheckboxChange = (stockist) => {
      setStockistsArray((prevSelected) =>
        prevSelected.some((d) => d.stockist._id === stockist._id)
          ? prevSelected.filter((d) => d.stockist._id !== stockist._id)
          : [
              ...prevSelected,
              {
                stockist: stockist,
                products: [],
                gifts: [],
              },
            ]
      );
    };

    return (
      <>
        <section className="edit-popup edit-popup-top">
          <div className="edit-content edit-content-top">
            <RiCloseCircleLine
              className="edit-popup__close position-absolute"
              style={{ top: "10px", right: "10px" }}
              onClick={() => setAddPopup(false)}
            />
            <div className="edit-popup__heading">
              <h2 className="web-app__heading">Add Call Planning</h2>

              <div className="edit-form-button-container">
                <button className="button-submit-green py-2 rounded">
                  {/* <span className="button-submit-green__icon-container">
                  {
                  loading ? <LuLoader2 className="button-submit-green__icon" style={{ ...iconStyle, animation: "spin 1s linear infinite" }} />
                  : <IoIosDoneAll className="button-submit-green__icon" />
                  }
                  </span> */}
                  <span
                    className="button-submit-green__text"
                    onClick={handleSubmitFinalCP}
                  >
                    {loading ? "Loading..." : "Submit"}
                  </span>
                </button>
              </div>
            </div>

            {/* Tab Selection */}
            <div
              className="web-app__heading d-flex justify-content-between rounded mt-4 p-3"
              style={{
                backgroundColor: "var(--color-primary)",
                color: "var(--color-white)",
                height: "100%",
              }}
            >
              <div
                className={`tab-item d-flex justify-content-center align-items-center cursor-pointer rounded gap-2 py-2 px-3`}
                style={{
                  backgroundColor:
                    activeTab === "Doctor"
                      ? "var(--color-success)"
                      : "var(--color-primary)",
                }}
                onClick={() => {
                  setActiveTab("Doctor");
                  setShowCheckbox(false);
                }}
              >
                Doctor
                {/* <span className="d-flex align-items-center justify-content-center bg-tertiary rounded-circle"
            style={{ height:"20px", width:"20px", backgroundColor: "var(--color-success)" }}>{doctorsArray.length}</span> */}
                <span>({doctorsArray?.length})</span>
              </div>
              <div
                className={`tab-item d-flex justify-content-center align-items-center cursor-pointer rounded gap-2 py-2 px-3`}
                style={{
                  backgroundColor:
                    activeTab === "Chemist"
                      ? "var(--color-success)"
                      : "var(--color-primary)",
                }}
                onClick={() => {
                  setActiveTab("Chemist");
                  setShowCheckbox(false);
                }}
              >
                Chemist
                {/* <span className="d-flex align-items-center justify-content-center bg-tertiary rounded-circle"
            style={{ height:"20px", width:"20px", backgroundColor: "var(--color-success)" }}>{chemistsArray.length}</span> */}
                <span>({chemistsArray?.length})</span>
              </div>
              <div
                className={`tab-item d-flex justify-content-center align-items-center cursor-pointer rounded gap-2 py-2 px-3`}
                style={{
                  backgroundColor:
                    activeTab === "Stockist"
                      ? "var(--color-success)"
                      : "var(--color-primary)",
                }}
                onClick={() => {
                  setActiveTab("Stockist");
                  setShowCheckbox(false);
                }}
              >
                Stockist
                {/* <span className="d-flex align-items-center justify-content-center bg-tertiary rounded-circle"
            style={{ height:"20px", width:"20px", backgroundColor: "var(--color-success)" }}>{stockistsArray.length}</span> */}
                <span>({stockistsArray?.length})</span>
              </div>
            </div>

            {/* Form */}
            {/* <form> */}
            <div style={{ height: "100%", width: "100%" }}>
              <div
                className="d-flex align-items-center justify-content-start gap-4 mt-3"
                style={{ width: "50%" ,
                
                }}
              >
                <button
                  onClick={() => {
                    toggleHandler(false);
                    setShowCheckbox(true);
                  }}
                  className="button py-2 px-4 cursor-pointer d-flex justify-content-center gap-2 align-items-center"
                  style={{
                    backgroundColor: "var(--color-primary)",
                    color: "var(--color-white) ",
                  }}
                >
                  <IoIosAddCircleOutline className="button-submit-green__icon " />
                  Add{" "}
                  {activeTab === "Doctor"
                    ? "Doctor"
                    : activeTab === "Chemist"
                    ? "Chemist"
                    : "Stockist"}
                </button>

                <IoIosAddCircleOutline
                  className="button-submit-green__icon cursor-pointer "
                  style={{ width: "20%" }}
                  onClick={() => {
                    handleAdd();
                    setShowCheckbox(false);
                  }}
                />
              </div>

              {toggle && (
                <>
                  {showCheckbox && (
                    <p className="mb-2 mt-2">
                      {activeTab === "Doctor"
                        ? "Select a doctor"
                        : activeTab === "Chemist"
                        ? "Select a chemist"
                        : "Select a stockist"}
                    </p>
                  )}

                  <div
                    className={`scrollable-container${
                      toggle
                        ? "opacity-100 translate-y-0"
                        : "opacity-0 -translate-y-4"
                    }`}
                    style={{
                      width: "100%",
                      minHeight: "360px",
                      maxHeight: "300px",
                      overflow: "auto",
                    }}
                  >
                    {activeTab === "Doctor" && (
                      <div>
                        {showCheckbox ? (
                          <div
                            className="d-flex gap-4 align-items-center "
                            style={{ width: "100%" }}
                          >
                            <div>
                              {doctorSelect?.map((doctor) => (
                                <div
                                  key={doctor._id}
                                  className="d-flex"
                                  style={{ width: "500px" }}
                                >
                                  <div
                                    className="d-flex gap-2 align-items-center justify-content-between mb-2 px-4 py-2"
                                    style={{
                                      backgroundColor: "var(--color-primary)",
                                      width: "90%",
                                    }}
                                  >
                                    <span>{doctor?.name}</span>
                                    <input
                                      type="checkbox"
                                      checked={doctorsArray.some(
                                        (d) => d?.doctor?._id === doctor?._id
                                      )}
                                      onChange={() =>
                                        handleDoctorCheckboxChange(doctor)
                                      }
                                      style={{
                                        display: showCheckbox
                                          ? "block"
                                          : "none",
                                      }}
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{ maxHeight: "50vh", paddingRight: "20px" }}
                          >
                            <Accordion className="" alwaysOpen>
                              {doctorsArray?.map((e, i) => (
                                <div className="d-flex gap-4 align-items-center">
                                  {e?.products?.length > 0 ||
                                  e?.gifts?.length > 0 ? (
                                    <Accordion.Item
                                      key={e?.doctor?._id}
                                      eventKey={i}
                                    >
                                      <Accordion.Header>
                                        {" "}
                                        <span
                                          className="d-flex align-items-center"
                                          onMouseEnter={(e) => {
                                            e.currentTarget.querySelector(
                                              ".nurse-icon"
                                            ).style.display = "none";
                                            e.currentTarget.querySelector(
                                              ".delete-icon"
                                            ).style.display = "inline-block";
                                          }}
                                          onMouseLeave={(e) => {
                                            e.currentTarget.querySelector(
                                              ".nurse-icon"
                                            ).style.display = "inline-block";
                                            e.currentTarget.querySelector(
                                              ".delete-icon"
                                            ).style.display = "none";
                                          }}
                                        >
                                          <RiNurseFill
                                            className="me-3 fs-2 nurse-icon"
                                            style={iconStyle2}
                                          />
                                          <MdDeleteOutline
                                            className="me-3 fs-2 button-delete__icon delete-icon"
                                            style={{
                                              ...iconStyle2,
                                              display: "none",
                                            }}
                                            onClick={() =>
                                              handleRemove(e?.doctor?._id)
                                            }
                                          />
                                          {e?.doctor?.name}
                                        </span>
                                      </Accordion.Header>
                                      <Accordion.Body
                                        style={{
                                          backgroundColor: `var(--color-primary-dark)`,
                                        }}
                                      >
                                        <h5 className="web-app__heading-blue">
                                          {e?.products?.length > 0
                                            ? "Samples DETAILS"
                                            : null}
                                        </h5>
                                        {e?.products.map((elem, i) => (
                                          <div
                                            key={i}
                                            className="acc-body-card d-flex mt-4"
                                          >
                                            <RiCapsuleFill className="acc-body-card-icon acc-i-green" />
                                            <div className="acc-body-card-content d-flex align-items-center px-4">
                                              {elem?.label}
                                            </div>
                                          </div>
                                        ))}

                                        <h5 className="web-app__heading-blue mt-4">
                                          {e?.gifts?.length > 0
                                            ? "Gift Details"
                                            : null}
                                        </h5>
                                        {e?.gifts.map((elem, i) => (
                                          <div
                                            key={i}
                                            className="acc-body-card d-flex mt-4"
                                          >
                                            <RiGiftFill className="acc-body-card-icon acc-i-yellow" />
                                            <div className="acc-body-card-content d-flex align-items-center px-4">
                                              {elem?.label}
                                            </div>
                                          </div>
                                        ))}
                                        {/* <div className="d-flex justify-content-end">
                            <MdDeleteOutline className="button-delete__icon cursor-pointer " 
                            onClick={() => handleRemove(e?.doctor?._id)}
                            />
                              </div> */}
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  ) : (
                                    <Accordion.Item
                                      key={e?.doctor?._id}
                                      eventKey={i}
                                      className=""
                                    >
                                      <Accordion.Header
                                        key={e._id}
                                        className="custom-accordion-header"
                                      >
                                        <span
                                          className="d-flex align-items-center"
                                          onMouseEnter={(e) => {
                                            e.currentTarget.querySelector(
                                              ".nurse-icon"
                                            ).style.display = "none";
                                            e.currentTarget.querySelector(
                                              ".delete-icon"
                                            ).style.display = "inline-block";
                                          }}
                                          onMouseLeave={(e) => {
                                            e.currentTarget.querySelector(
                                              ".nurse-icon"
                                            ).style.display = "inline-block";
                                            e.currentTarget.querySelector(
                                              ".delete-icon"
                                            ).style.display = "none";
                                          }}
                                        >
                                          <RiNurseFill
                                            className="me-3 fs-2 nurse-icon"
                                            style={iconStyle2}
                                          />
                                          <MdDeleteOutline
                                            className="me-3 fs-2 button-delete__icon delete-icon"
                                            style={{
                                              ...iconStyle2,
                                              display: "none",
                                            }}
                                            onClick={() =>
                                              handleRemove(e?.doctor?._id)
                                            }
                                          />
                                          {e?.doctor?.name}
                                        </span>
                                      </Accordion.Header>
                                    </Accordion.Item>
                                  )}
                                  <IoIosAddCircleOutline
                                    className="button-submit-green__icon cursor-pointer"
                                    onClick={() => {
                                      setCurrentEntity(e?.doctor?._id);
                                      setSelectedDoctorProduct(
                                        e?.products || []
                                      );
                                      setSelectedDoctorGift(e?.gifts || []);
                                      setAddPGPopup(true);
                                    }}
                                  />
                                </div>
                              ))}
                            </Accordion>
                          </div>
                        )}
                      </div>
                    )}
                    {activeTab === "Chemist" && (
                      <div>
                        {showCheckbox ? (
                          <div
                            className="d-flex gap-4 align-items-center "
                            style={{ width: "100%" }}
                          >
                            <div>
                              {chemistSelect?.map((chemist) => (
                                <div
                                  key={chemist._id}
                                  className="d-flex"
                                  style={{ width: "500px" }}
                                >
                                  <div
                                    className="d-flex gap-2 align-items-center justify-content-between mb-2 px-4 py-2"
                                    style={{
                                      backgroundColor: "var(--color-primary)",
                                      width: "90%",
                                    }}
                                  >
                                    <span>{chemist.name}</span>
                                    <input
                                      type="checkbox"
                                      checked={chemistsArray.some(
                                        (d) => d.chemist._id === chemist._id
                                      )}
                                      onChange={() =>
                                        handleChemistCheckboxChange(chemist)
                                      }
                                      style={{
                                        display: showCheckbox
                                          ? "block"
                                          : "none",
                                      }}
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{ maxHeight: "50vh", paddingRight: "20px" }}
                          >
                            <Accordion className="" alwaysOpen>
                              {chemistsArray?.map((e, i) => (
                                <div className="d-flex gap-4 align-items-center">
                                  {e?.products?.length > 0 ||
                                  e?.gifts?.length > 0 ? (
                                    <Accordion.Item
                                      key={e?.chemist?._id}
                                      eventKey={i}
                                    >
                                      <Accordion.Header>
                                        {" "}
                                        <span
                                          className="d-flex align-items-center"
                                          onMouseEnter={(e) => {
                                            e.currentTarget.querySelector(
                                              ".nurse-icon"
                                            ).style.display = "none";
                                            e.currentTarget.querySelector(
                                              ".delete-icon"
                                            ).style.display = "inline-block";
                                          }}
                                          onMouseLeave={(e) => {
                                            e.currentTarget.querySelector(
                                              ".nurse-icon"
                                            ).style.display = "inline-block";
                                            e.currentTarget.querySelector(
                                              ".delete-icon"
                                            ).style.display = "none";
                                          }}
                                        >
                                          <RiNurseFill
                                            className="me-3 fs-2 nurse-icon"
                                            style={iconStyle2}
                                          />
                                          <MdDeleteOutline
                                            className="me-3 fs-2 button-delete__icon delete-icon"
                                            style={{
                                              ...iconStyle2,
                                              display: "none",
                                            }}
                                            onClick={() =>
                                              handleRemove(e?.chemist?._id)
                                            }
                                          />
                                          {e?.chemist?.name}
                                        </span>
                                      </Accordion.Header>
                                      <Accordion.Body
                                        style={{
                                          backgroundColor: `var(--color-primary-dark)`,
                                        }}
                                      >
                                        <h5 className="web-app__heading-blue">
                                          {e?.products?.length > 0
                                            ? "Samples DETAILS"
                                            : null}
                                        </h5>
                                        {e?.products.map((elem, i) => (
                                          <div
                                            key={i}
                                            className="acc-body-card d-flex mt-4"
                                          >
                                            <RiCapsuleFill className="acc-body-card-icon acc-i-green" />
                                            <div className="acc-body-card-content d-flex align-items-center px-4">
                                              {elem?.label}
                                            </div>
                                          </div>
                                        ))}

                                        <h5 className="web-app__heading-blue mt-4">
                                          {e?.gifts?.length > 0
                                            ? "Gift Details"
                                            : null}
                                        </h5>
                                        {e?.gifts.map((elem, i) => (
                                          <div
                                            key={i}
                                            className="acc-body-card d-flex mt-4"
                                          >
                                            <RiGiftFill className="acc-body-card-icon acc-i-yellow" />
                                            <div className="acc-body-card-content d-flex align-items-center px-4">
                                              {elem?.label}
                                            </div>
                                          </div>
                                        ))}
                                        {/* <div className="d-flex justify-content-end">
                  <MdDeleteOutline className="button-delete__icon cursor-pointer " 
                  onClick={() => handleRemove(e?.doctor?._id)}
                  />
                    </div> */}
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  ) : (
                                    <Accordion.Item
                                      key={e?.chemist?._id}
                                      eventKey={i}
                                      className=""
                                    >
                                      <Accordion.Header
                                        key={e._id}
                                        className="custom-accordion-header"
                                      >
                                        <span
                                          className="d-flex align-items-center"
                                          onMouseEnter={(e) => {
                                            e.currentTarget.querySelector(
                                              ".nurse-icon"
                                            ).style.display = "none";
                                            e.currentTarget.querySelector(
                                              ".delete-icon"
                                            ).style.display = "inline-block";
                                          }}
                                          onMouseLeave={(e) => {
                                            e.currentTarget.querySelector(
                                              ".nurse-icon"
                                            ).style.display = "inline-block";
                                            e.currentTarget.querySelector(
                                              ".delete-icon"
                                            ).style.display = "none";
                                          }}
                                        >
                                          <RiNurseFill
                                            className="me-3 fs-2 nurse-icon"
                                            style={iconStyle2}
                                          />
                                          <MdDeleteOutline
                                            className="me-3 fs-2 button-delete__icon delete-icon"
                                            style={{
                                              ...iconStyle2,
                                              display: "none",
                                            }}
                                            onClick={() =>
                                              handleRemove(e?.chemist?._id)
                                            }
                                          />
                                          {e?.chemist?.name}
                                        </span>
                                      </Accordion.Header>
                                    </Accordion.Item>
                                  )}
                                  <IoIosAddCircleOutline
                                    className="button-submit-green__icon cursor-pointer"
                                    onClick={() => {
                                      setCurrentEntity(e?.chemist?._id);
                                      setSelectedDoctorProduct(
                                        e?.products || []
                                      );
                                      setSelectedDoctorGift(e?.gifts || []);
                                      setAddPGPopup(true);
                                    }}
                                  />
                                </div>
                              ))}
                            </Accordion>
                          </div>
                        )}
                      </div>
                    )}
                    {activeTab === "Stockist" && (
                      <div>
                        {showCheckbox ? (
                          <div
                            className="d-flex gap-4 align-items-center "
                            style={{ width: "100%" }}
                          >
                            <div>
                              {stockistSelect?.map((stockist) => (
                                <div
                                  key={stockist._id}
                                  className="d-flex"
                                  style={{ width: "500px" }}
                                >
                                  <div
                                    className="d-flex gap-2 align-items-center justify-content-between mb-2 px-4 py-2"
                                    style={{
                                      backgroundColor: "var(--color-primary)",
                                      width: "90%",
                                    }}
                                  >
                                    <span>{stockist.name}</span>
                                    <input
                                      type="checkbox"
                                      checked={stockistsArray?.some(
                                        (d) => d?.stockist._id === stockist._id
                                      )}
                                      onChange={() =>
                                        handleStockistCheckboxChange(stockist)
                                      }
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{ maxHeight: "50vh", paddingRight: "20px" }}
                          >
                            <Accordion className="" alwaysOpen>
                              {stockistsArray.map((e, i) => (
                                <div className="d-flex gap-4 align-items-center">
                                  {e?.products?.length > 0 ||
                                  e?.gifts?.length > 0 ? (
                                    <Accordion.Item
                                      key={e.stockist._id}
                                      eventKey={i}
                                      className=""
                                    >
                                      <Accordion.Header>
                                        {" "}
                                        <span
                                          className="d-flex align-items-center"
                                          onMouseEnter={(e) => {
                                            e.currentTarget.querySelector(
                                              ".nurse-icon"
                                            ).style.display = "none";
                                            e.currentTarget.querySelector(
                                              ".delete-icon"
                                            ).style.display = "inline-block";
                                          }}
                                          onMouseLeave={(e) => {
                                            e.currentTarget.querySelector(
                                              ".nurse-icon"
                                            ).style.display = "inline-block";
                                            e.currentTarget.querySelector(
                                              ".delete-icon"
                                            ).style.display = "none";
                                          }}
                                        >
                                          <RiNurseFill
                                            className="me-3 fs-2 nurse-icon"
                                            style={iconStyle2}
                                          />
                                          <MdDeleteOutline
                                            className="me-3 fs-2 button-delete__icon delete-icon"
                                            style={{
                                              ...iconStyle2,
                                              display: "none",
                                            }}
                                            onClick={() =>
                                              handleRemove(e?.stockist?._id)
                                            }
                                          />
                                          {e?.stockist?.businessName}
                                        </span>
                                      </Accordion.Header>
                                      <Accordion.Body
                                        style={{
                                          backgroundColor: `var(--color-primary-dark)`,
                                        }}
                                      >
                                        <h5 className="web-app__heading-blue mt-4">
                                          {e?.products.length > 0
                                            ? "Samples DETAILS"
                                            : null}
                                        </h5>
                                        {e?.products.map((elem, i) => (
                                          <div
                                            key={i}
                                            className="acc-body-card d-flex mt-4"
                                          >
                                            <RiCapsuleFill className="acc-body-card-icon acc-i-green" />
                                            <div className="acc-body-card-content d-flex align-items-center px-4">
                                              {elem?.label}
                                            </div>
                                          </div>
                                        ))}

                                        <h5 className="web-app__heading-blue">
                                          {e?.gifts.length > 0
                                            ? "Gift Details"
                                            : null}
                                        </h5>
                                        {e?.gifts.map((elem, i) => (
                                          <div
                                            key={i}
                                            className="acc-body-card d-flex mt-4"
                                          >
                                            <RiGiftFill className="acc-body-card-icon acc-i-yellow" />
                                            <div className="acc-body-card-content d-flex align-items-center px-4">
                                              {elem?.label}
                                            </div>
                                          </div>
                                        ))}
                                        {/* <div className="d-flex justify-content-end">
                              <MdDeleteOutline className="button-delete__icon cursor-pointer " 
                              onClick={() => handleRemove(e?.stockist?._id)}
                              />
                                </div> */}
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  ) : (
                                    <Accordion.Item
                                      key={e?.stockist?._id}
                                      eventKey={i}
                                      className=""
                                    >
                                      <Accordion.Header
                                        key={e._id}
                                        className="custom-accordion-header"
                                      >
                                        <span
                                          className="d-flex align-items-center"
                                          onMouseEnter={(e) => {
                                            e.currentTarget.querySelector(
                                              ".nurse-icon"
                                            ).style.display = "none";
                                            e.currentTarget.querySelector(
                                              ".delete-icon"
                                            ).style.display = "inline-block";
                                          }}
                                          onMouseLeave={(e) => {
                                            e.currentTarget.querySelector(
                                              ".nurse-icon"
                                            ).style.display = "inline-block";
                                            e.currentTarget.querySelector(
                                              ".delete-icon"
                                            ).style.display = "none";
                                          }}
                                        >
                                          <RiNurseFill
                                            className="me-3 fs-2 nurse-icon"
                                            style={iconStyle2}
                                          />
                                          <MdDeleteOutline
                                            className="me-3 fs-2 button-delete__icon delete-icon"
                                            style={{
                                              ...iconStyle2,
                                              display: "none",
                                            }}
                                            onClick={() =>
                                              handleRemove(e?.stockist?._id)
                                            }
                                          />
                                          {e?.stockist?.businessName}
                                        </span>
                                      </Accordion.Header>
                                    </Accordion.Item>
                                  )}
                                  <IoIosAddCircleOutline
                                    className="button-submit-green__icon cursor-pointer"
                                    onClick={() => {
                                      setCurrentEntity(e?.stockist?._id);
                                      setSelectedStockistProduct(
                                        e?.products || []
                                      );
                                      setSelectedStockistGift(e?.gifts || []);
                                      setAddPGPopup(true);
                                    }}
                                  />
                                </div>
                              ))}
                            </Accordion>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
        {addPGPopup ? (
          <section className="edit-popup edit-popup-top">
            <div className="edit-content edit-content-top">
              <div className="edit-popup__heading">
                <h2 className="web-app__heading">Add Details</h2>
                <RiCloseCircleLine
                  className="edit-popup__close"
                  onClick={() => {
                    setAddPGPopup(false);
                  }}
                />
              </div>

              {activeTab === "Doctor" && (
                <EntitySelect
                  entityType="Doctor"
                  selectedProduct={selectedDoctorProduct}
                  setSelectedProduct={setSelectedDoctorProduct}
                  selectedGift={selectedDoctorGift}
                  setSelectedGift={setSelectedDoctorGift}
                />
              )}

              {activeTab === "Chemist" && (
                <EntitySelect
                  entityType="Chemist"
                  selectedProduct={selectedChemistProduct}
                  setSelectedProduct={setSelectedChemistProduct}
                  selectedGift={selectedChemistGift}
                  setSelectedGift={setSelectedChemistGift}
                />
              )}

              {activeTab === "Stockist" && (
                <EntitySelect
                  entityType="Stockist"
                  selectedProduct={selectedStockistProduct}
                  setSelectedProduct={setSelectedStockistProduct}
                  selectedGift={selectedStockistGift}
                  setSelectedGift={setSelectedStockistGift}
                />
              )}

              <div className="edit-form-button-container">
                <button
                  type="submit"
                  className="button-submit-green mt-4"
                  onClick={handleAddPG}
                >
                  <span className="button-submit-green__icon-container">
                    <MdOutlineAdd
                      className="button-submit-green__icon"
                      style={{ fontSize: "1.5em" }}
                    />
                  </span>
                  <span className="button-submit-green__text">Add</span>
                </button>
              </div>
            </div>
          </section>
        ) : null}
      </>
    );
  };

  const EntitySelect = ({
    entityType,
    selectedProduct,
    setSelectedProduct,
    selectedGift,
    setSelectedGift,
  }) => {
    return (
      <div className="d-flex justify-content-between gap-4">
        <div className="w-50">
          <p className="mb-2">Product</p>
          <Select
            isMulti
            name="product"
            id="product"
            options={productsSelect}
            styles={adminStyles}
            value={selectedProduct}
            placeholder="Select Product"
            onChange={(e) => setSelectedProduct(e)}
            className="multi-select-dropdown d-flex justify-content-center"
          />
        </div>
        <div className="w-50">
          <p className="mb-2">Gift</p>
          <Select
            isMulti
            name="gift"
            id="gift"
            options={giftsSelect}
            styles={adminStyles}
            value={selectedGift}
            placeholder="Select Gift"
            onChange={(e) => setSelectedGift(e)}
            className="multi-select-dropdown d-flex justify-content-center"
          />
        </div>
      </div>
    );
  };

  const DeletePopup = ({ data }) => {
    const handleDelete = async () => {
      try {
        await deleteCallPlanning(data?.id);
        customToast.success("Call plan deleted successfully");
      } catch (error) {
        customToast.error("Error in deleting call plan!");
      }
    };

    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete Call Plan</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowDelete(false)}
            />
          </div>

          <div className="edit-form">
            <div>
              <label htmlFor="distance">
                Do you really want to delete this call plan?
              </label>
            </div>
          </div>

          <div className="edit-form-button-container">
            {/* <button
                    type="submit"
                    
                    className="button-submit-green mt-4"
                  >
                    <span className="button-submit-green__icon-container">
                      <MdOutlineEdit className="button-submit-green__icon" />
                    </span>
                    <span className="button-submit-green__text">Edit</span>
                  </button> */}
            <button
              onClick={() => handleDelete()}
              className="button-delete mt-4"
            >
              <span className="button-delete__icon-container">
                <MdDeleteOutline className="button-delete__icon" />
              </span>
              <span className="button-delete__text">Delete</span>
            </button>
          </div>
        </div>
      </section>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        disableFilters: true,
        Cell: (props) => {
          let day = new Date(props?.row?.original?.date).toLocaleString(
            "en-us",
            { weekday: "long" }
          );

          return props.row.original?.isApproved === 1 ? (
            <span className="approved-text">
              {formatDate(props.row.original?.date)}
            </span>
          ) : props.row.original?.isApproved === 0 ? (
            <span className="pending-text">
              {formatDate(props.row.original?.date)}
            </span>
          ) : props.row.original?.isApproved === -1 ? (
            <span className="rejected-text">
              {formatDate(props.row.original?.date)}
            </span>
          ) : props.row.original?.leave ? (
            <span className="text-opacity">
              {formatDate(props.row.original?.date)}
            </span>
          ) : props.row.original?.holiday ? (
            <span className="text-opacity">
              {formatDate(props.row.original?.date)}
            </span>
          ) : day === "Sunday" ? (
            props?.row?.original._id ? (
              <span className="notsubmitted-text">
                {formatDate(props.row.original?.date)}
              </span>
            ) : (
              <span className="text-opacity">
                {formatDate(props.row.original?.date)}
              </span>
            )
          ) : (
            <span className="notsubmitted-text">
              {formatDate(props.row.original?.date)}
            </span>
          );
        },
      },
      {
        Header: "Day",
        accessor: "day",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props) => {
          let day = new Date(props?.row?.original?.date).toLocaleString(
            "en-us",
            { weekday: "long" }
          );
          // if(day === "Sunday") return <span className="text-opacity">{day}</span>

          return props.row.original?.leave ? (
            <span className="text-opacity">Leave</span>
          ) : props.row.original?.holiday ? (
            <span className="text-opacity">Holiday</span>
          ) : day === "Sunday" ? (
            props?.row?.original._id ? (
              <span className="notsubmitted-text">{day}</span>
            ) : (
              <span className="text-opacity">{day}</span>
            )
          ) : (
            <span className="notsubmitted-text">{day}</span>
          );
          // return new Date(props?.row?.original?.date).toLocaleString("en-us", {weekday:"long"})
        },
      },
      {
        Header: "Doctors",
        accessor: "doctors",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props) => {
          return props?.row?.original?.doctors?.length ?? "-";
        },
      },
      {
        Header: "Chemists",
        accessor: "chemists",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props) => {
          return props?.row?.original?.chemists?.length ?? "-";
        },
      },
      {
        Header: "Stockists",
        accessor: "stockists",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props) => {
          return props?.row?.original?.stockists?.length ?? "-";
        },
      },
      {
        Header: "View",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          let day = new Date(props?.row?.original?.date).toLocaleString(
            "en-us",
            { weekday: "long" }
          );
          return props.row.original?.leave ? (
            <AiOutlineStop className="icon-opacity" />
          ) : props.row.original?.holiday ? (
            <AiOutlineStop className="icon-opacity" />
          ) : day === "Sunday" ? (
            props?.row?.original._id ? (
              <Link
                to={props?.row?.original?._id}
                state={props?.row?.original}
                className="util-table-view-details-link"
              >
                <FaEye className="icon-color-tertiary" />
              </Link>
            ) : (
              <AiOutlineStop className="icon-opacity" />
            )
          ) : props?.row?.original._id ? (
            <Link
              to={props?.row?.original?._id}
              state={props?.row?.original}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          ) : (
            <AiOutlineStop className="icon-opacity" />
          );
        },
      },
      {
        Header: "Action",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props) => {
          let day = new Date(props?.row?.original?.date).toLocaleString(
            "en-us",
            { weekday: "long" }
          );
          return props?.row?.original._id ? (
            <PiDotsThreeCircleLight
              style={iconStyle}
              onClick={() => {
                setAddForm(props.row.original, selectedUser);
                setShowDelete(true);
              }}
            />
          ) : props.row.original?.leave ? (
            <AiOutlineStop className="icon-opacity" />
          ) : props.row.original?.holiday ? (
            <AiOutlineStop className="icon-opacity" />
          ) : day === "Sunday" ? (
            sundayWorking === "true" ? (
              <IoIosAddCircleOutline
                style={iconStyle}
                onClick={() => {
                  setAddForm(props.row.original, selectedUser);
                  setAddPopup(true);
                }}
              />
            ) : (
              <AiOutlineStop className="icon-opacity" />
            )
          ) : (
            <IoIosAddCircleOutline
              style={iconStyle}
              onClick={() => {
                setAddForm(props.row.original, selectedUser);
                setAddPopup(true);
              }}
            />
          );
        },
      },
    ],
    [selectedUser]
  );
  // useEffect(() => {
  //   try {
  //     viewUsersForUtilities().then((res) => {
  //       setEmployees([...res.data]);
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, []);

  useEffect(() => {
    dispatch(setUsersForUtilities({ includeMe: true }));
    return () => {
      dispatch(clearUsers());
    };
  }, [dispatch]);

  useEffect(() => {
    viewTargets("doctor", { showApproved: true })
      .then((response) => {
        setDoctors(response?.data);
      })
      .catch((e) => console.log(e));
    viewTargets("chemist", { showApproved: true })
      .then((response) => {
        setChemists(response?.data);
      })
      .catch((e) => console.log(e));
    viewTargets("stockist", { showApproved: true })
      .then((response) => {
        setStockists(response?.data);
      })
      .catch((e) => console.log(e));

    viewProductV1("").then((response) => {
      setProducts(response?.data);
    });
    viewGifts().then((response) => {
      setGifts(response?.data);
    });
  }, [selectedUser]);

  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );
  const doctorSelect = useMemo(
    () => generateSelectData(doctors, "name"),
    [doctors]
  );
  const chemistSelect = useMemo(
    () => generateSelectData(chemists, "businessName"),
    [chemists]
  );
  const stockistSelect = useMemo(
    () => generateSelectData(stockists, "businessName"),
    [stockists]
  );
  const productsSelect = useMemo(
    () => generateSelectData(products, "name"),
    [products]
  );

  const giftsSelect = useMemo(() => generateSelectData(gifts, "gift"), [gifts]);

  useEffect(() => {
    const response = getMonthlyCallPlanning({
      month: month,
      year: year,
      selectedUser: selectedUser?.value,
    }).then((res) => {
      setCPData([...res.data]);
    });
  }, [month, year, selectedUser]);

  const tableData = useMemo(() => serialise(cpData), [cpData]);

  return (
    <>
      <form>
        <div className="expense__filters">
          <div className="expense__filters-duration">
            <div className="me-4">
              <p className="mb-2">
                Select Month <span className="asterisk-imp">*</span>
              </p>
              <Suspense>
                <DatePickers
                  placeholder={
                    month && year
                      ? moment({ year, month }).format("MMM, YYYY")
                      : "Select Month"
                  }
                  setMonth={setMonth}
                  setYear={setYear}
                />
              </Suspense>
            </div>
            {/* <div className="me-4">
              <p className="mb-2">
                Select Year <span className="asterisk-imp">*</span>
              </p>
              <Select
                styles={dcrStyles}
                options={yearOptions}
                placeholder="Select Year"
                value={year}
                onChange={(e) => setYear({ ...e })}
              />
            </div> */}
          </div>
          <div className="me-4">
            {
              loggedIn?.user?.des !== 121 && (
                <div>
                  <p className="util-select-user__label mb-2">Select User</p>
                  <div className="util-select-user">
                    <MdOutlineGroup className="util-select-user__icon" />
                    <Select
                      styles={selectUserStyles}
                      options={employeeSelect}
                      placeholder="Select User"
                      className=""
                      value={selectedUser}
                      onChange={(e) => setSelectedUser({ ...e })}
                    />
                  </div>
                </div>
              )
              // : (
              //   <button
              //     type="button"
              //     className="mt-5 ms-auto button-blue-gradient"
              //     onClick={(e) => {
              //       if (!month) return customToast.error("Please select a month");
              //       else if (!year)
              //         return customToast.error("Please select a year");
              //     }}
              //   >
              //     Submit
              //   </button>)
            }
          </div>
        </div>
        <div className="tp-tags d-flex mt-4 gap-md-3 gap-lg-5">
          <h4>
            <span className="tp-indicator-9 me-2"></span>Approved
          </h4>
          <h4>
            <span className="tp-indicator-10 me-2"></span>Rejected
          </h4>
          <h4>
            <span className="tp-indicator-5 me-2"></span>Pending
          </h4>
          <h4>
            <span className="tp-indicator-12 me-2"></span>Leave
          </h4>
        </div>
      </form>
      <div className="filter-table">
        <Table
          defaultPageSize={50}
          columns={columns}
          data={tableData}
          empl={selectedUser ? selectedUser : loggedIn?.user}
          month={month}
          fileSrc="CallPlanning"
        />
        {addPopup ? <AddPopup data={data} /> : null}
        {showDelete ? <DeletePopup data={data} /> : null}
      </div>
    </>
  );
};

export default CallPlanningForm;
