import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Link } from "react-router-dom";

import Table from "../../../../components/Table";
import customToast from "../../../../components/CustomToast";
import PageTitle from "../../../../components/PageTitle";

import serialise, {
  generateSelectData, getDesignation, serialiseSSG,
} from "../../../../utils/serialiseResponse";

import {
  clearUsers,
  setUsers,
} from "../../../../reducers/users/employeeReducer";
import {
  clearStates,
  setStates,
} from "../../../../reducers/locations/stateReducer";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../../reducers/locations/hqReducer";
import {
  clearDivisions,
  setDivisions,
} from "../../../../reducers/users/divisionReducer";

import { adminStyles } from "../../../public/stylesheets/selectStyles";
import { BiTargetLock } from "react-icons/bi";
import { RiCloseCircleLine } from "react-icons/ri";
import { monthOptions, returnMonthFromIndex , yearOptions} from "../../../../utils/helper";
import { samplesForMonth } from "../../../../reducers/reports/sampleAllotmentReducer";
import { allotmentsForMonth, sampleAllotmentForMonth } from "../../../../services/reports";
import { viewUsersForTarget } from "../../../../services/utilities";

const AdminAllotSample = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(({ user }) => user);
  const states = useSelector(({ state }) => state);
  const headquarters = useSelector(({ headquarter }) => headquarter);
  // const userData = useSelector(({ employee }) => employee);
  const [ userData, setUserData] = useState([])
  const stateSelect = useMemo(
    () => generateSelectData(states.data, "name"),
    [states]
  );
  const headquarterSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );

  const [state, setState] = useState(null);
  const [year, setYear] = useState("");
  const [headquarter, setHeadquarter] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [month, setMonth] = useState({
    value: new Date().getMonth(),
    label: returnMonthFromIndex(new Date().getMonth()),
  });
  const [alreadyAlloted, setAlreadyAlloted] = useState([]);
  const [tableToggle, setTableToggle] = useState(false);
  useEffect(() => {
    dispatch(setDivisions());
    dispatch(setStates());
    return () => {
      dispatch(clearDivisions());
      dispatch(clearStates());
      dispatch(clearUsers());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!state) return;

    dispatch(setHeadquarters(state.value));
    return () => {
      dispatch(clearHeadquarters());
    };
  }, [dispatch, state]);

  const EditPopup = ({ data }) => {
    const queryHandler = async () => {
      if (!year)
      return customToast.error("Please select year");
      setShowEdit(false);
      setTableToggle(true);
      // let { data } = await allotmentsForMonth("sample", {
      //   month: month?.value,
      //   year: year?.label
      // });
      let {data} = await sampleAllotmentForMonth({
        month: month?.value,
        year: year?.label
      })
      setAlreadyAlloted(data);
    };

    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Select Month</h2>
            <h2 className="web-app__heading">Select Year</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-6">
              <br />
              <Select
                name="month"
                id="month"
                value={month}
                options={monthOptions}
                onChange={(e) => setMonth({ ...e })}
                styles={adminStyles}
                placeholder="Select Month"
              />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-6">
              <br />
              <Select
                name="year"
                id="year"
                value={year}
                options={yearOptions}
                onChange={(e) => setYear({ ...e })}
                styles={adminStyles}
                placeholder="Select Year"
              />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-5 area-creation-content__form-submit">
              <button
                type="button"
                className="button-blue-gradient"
                style={{marginTop:"5%"}}
                onClick={queryHandler}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </section>
    );
  };

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Name",
        accessor: "name",
        Cell: (props) => (
          props?.row?.original?.firstName + " " + props?.row?.original?.middleName + " " + props?.row?.original?.lastName
        ),
      },
      {
        Header: "Designation",
        accessor: "des",
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 80,
        Cell: (props) => {
          return <span>{getDesignation(props?.row?.original?.des)}</span>;
        },
      },
      {
        Header: "Headquarter",
        accessor: "headquarter",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Division",
        accessor: "division",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Allot Sample",
        accessor: "details",
        Cell: (props) => (
          <Link
            className="table-link"
            to={props.row.original._id}
            state={props.row.original}
          >
            <BiTargetLock className="icon-color-green" />
          </Link>
        ),
        disableFilters: true,
        disableSortBy: true,
        minWidth: 70,
        maxWidth: 70,
      },
    ],
    []
  );
  const allotedColumns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Sample",
        accessor: "productName",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Employee",
        accessor: "user.firstName",
        minWidth: 80,
        maxWidth: 80,
      },
     
      {
        Header: "Alotted Quantity",
        accessor: "issued",
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Distributed Quantity",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
        Cell: (props) => {
          return (
            <span>
              {props?.row?.original?.issued - props?.row?.original?.balance
                ? props?.row?.original?.issued - props?.row?.original?.balance
                : 0}
            </span>
          );
        },
      },
      {
        Header: "Available Quantity",
        accessor: "balance",
        minWidth: 85,
        maxWidth: 85,
      },
    ],
    []
  );
  const tableData = useMemo(() => serialise(userData), [userData]);
  // console.log(tableData)
  const allotedTableData = useMemo(
    () => serialiseSSG(alreadyAlloted),
    [alreadyAlloted]
  );

  const handleFilter = async (e) => {
    e.preventDefault();

    if (!headquarter)
      return customToast.error("Please Choose a Headquarter to Filter Users");

    const data = {
      hqId: headquarter.value,
      divisionId: loggedIn?.user?.des !== 101 && loggedIn?.user?.division 
    };

    try {
      // dispatch(setUsers("user", data));
      const response = await viewUsersForTarget({ hqId: headquarter.value, divisionId: loggedIn?.user?.des !== 101 ? loggedIn?.user?.division : null, includeMe: true})
      setUserData(response.data)
      
      setTableToggle(false);
    } catch (e) {
      customToast.error("We Encountered an Error");
    }
  };
  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Allot Sample" />

        <section className="area-creation-content__form">
          <form onSubmit={handleFilter}>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">
                  Select State <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="state"
                  id="state"
                  value={state}
                  options={stateSelect}
                  onChange={(e) => setState({ ...e })}
                  styles={adminStyles}
                  placeholder="Select State"
                />
              </div>

              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">
                  Select HQ <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="headquarter"
                  id="headquarter"
                  value={headquarter}
                  options={headquarterSelect}
                  onChange={(e) => setHeadquarter({ ...e })}
                  styles={adminStyles}
                  placeholder="Select Headquarter"
                />
              </div>

              <div className="col-sm-12 col-md-4 col-lg-4 area-creation-content__form-submit">
                <button type="submit" className="button-blue-gradient">
                  Filter
                </button>
                <button
                  type="button"
                  className="button-blue-gradient ms-5"
                  onClick={() => setShowEdit(true)}
                >
                  <BiTargetLock className="icon-color-green" />
                  All Allotments
                </button>
              </div>
            </div>
          </form>
        </section>

        <section className="area-creation-content__info">
          {!tableToggle?userData.length > 0 && (
            <h2 className="web-app__heading">
              Showing ({userData.length}) Users
            </h2>
          ):allotedTableData.length > 0 && (
            <h2 className="web-app__heading">
              Showing ({allotedTableData.length}) Entries
            </h2>
          )}
          <div className="filter-table">
            {!tableToggle ? (
              <Table columns={columns} data={tableData} />
            ) : (
              <Table columns={allotedColumns} data={allotedTableData} />
            )}
          </div>
        </section>
        {showEdit ? <EditPopup /> : null}
      </div>
    </div>
  );
};

export default AdminAllotSample;
