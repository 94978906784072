export const title = "Manage Doctors, Stockists & Chemists";

export const links = [
  {
    name: "Create Doctors",
    route: "create-doctors",
  },
  {
    name: "Create Chemists",
    route: "create-chemists",
  },
  {
    name: "Create Stockists",
    route: "create-stockists",
  },
  {
    name: "View Doc / Chem / Stk",
    route: "view-dcs",
  },
  
  {
    name: "Edit / Delete",
    route: "edit-delete",
  },
  {
    name: "Doctor Wise Products List",
    route: "doctor-wise-products-list",
  },
];
