import { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import { adminStyles } from "../../../public/stylesheets/selectStyles";
import serialise, {
  generateSelectData,
} from "../../../../utils/serialiseResponse";
import {
  clearStates,
  setStates,
} from "../../../../reducers/locations/stateReducer";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../../reducers/locations/hqReducer";
import {
  clearCities,
  setCities,
} from "../../../../reducers/locations/cityReducer";
import customToast from "../../../../components/CustomToast";
import PageTitle from "../../../../components/PageTitle";
import {
  addRoutes,
  clearRoutes,
  deleteRoutes,
  editRoute,
  setRoutes,
} from "../../../../reducers/locations/routeReducer";
import { MdDelete, MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import Table from "../../../../components/Table";
import { RiCloseCircleLine, RiEdit2Fill } from "react-icons/ri";

const areaTypeOptions = [
  { value: 1, label: "Ex-Station" },
  { value: 2, label: "Out-Station" },
];

const AdminCity = () => {
  const dispatch = useDispatch();
  const states = useSelector(({ state }) => state);
  const headquarters = useSelector(({ headquarter }) => headquarter);
  const cities = useSelector(({ city }) => city);
  const routes = useSelector(({ route }) => route);

  const stateSelect = useMemo(
    () => generateSelectData(states.data, "name"),
    [states]
  );
  const hqSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );
  const citySelect = useMemo(
    () => generateSelectData(cities.data, "name"),
    [cities]
  );

  const [state, setState] = useState(null);
  const [headquarter, setHeadquarter] = useState();
  const [fromCity, setFromCity] = useState();
  const [toCity, setToCity] = useState();
  const [areaType, setAreaType] = useState();
  const [distance, setDistance] = useState(0);
  const [showEdit, setShowEdit] = useState(false);
  const [routeData, setRouteData] = useState();
  const [isEdited, setIsEdited] = useState(false)
  const [updateDataId, setUpdateDataId] = useState(false)

  const EditPopup = ({ data }) => {
    // const dispatch = useDispatch();
    // const [distance, setDistance] = useState(0);

    // let updatedData = { ...data, distance: distance };
    // const handleEdit = async (e) => {
    //   e.preventDefault();
    //   await dispatch(editRoute(updatedData?._id, updatedData));
    //   customToast.success("Route Successfully Updated");
    //   setShowEdit(false);
    // };

    const handleDelete = () => {
      dispatch(deleteRoutes(data?._id));
      customToast.success("Route Successfully Deleted");
    };

    // useEffect(() => {
    //   setDistance(data.distance);
    // }, [data]);

    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete Details</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form>
            <div className="edit-form">
              <div>
                <label htmlFor="distance">
                  Do you really want to delete this route? : ({data.fromCity.name} - {data.toCity.name})
                  <span className="asterisk-imp">*</span>
                </label>
                <br />
                {/* <input
                  type="number"
                  id="distance"
                  className="me-5"
                  placeholder="Enter distance"
                  value={distance}
                  onChange={({ target }) => setDistance(target.value)}
                /> */}
              </div>
            </div>

            <div className="edit-form-button-container">
              {/* <button
                type="submit"
                
                className="button-submit-green mt-4"
              >
                <span className="button-submit-green__icon-container">
                  <MdOutlineEdit className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Edit</span>
              </button> */}
              <button
                
                onClick={() => handleDelete()}
                className="button-delete mt-4"
              >
                <span className="button-delete__icon-container">
                  <MdDeleteOutline className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Delete</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  const setEditForm = (data) => {
    setIsEdited(true)
    setUpdateDataId(data._id)
    setState({ value: data.state._id, label: data.state.name })
    setHeadquarter({ value: data.headquarter._id, label: data.headquarter.name })
    setFromCity({ value: data.fromCity._id, label: data.fromCity.name })
    setToCity({ value: data.toCity._id, label: data.toCity.name })
    setAreaType(areaTypeOptions.find((e) => e.label === data.areatype))
    setDistance(data.distance)
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const filteredFromCity = citySelect.filter(
    (el) => el?.headquarter?.name === headquarter?.label
  );

  

  useEffect(() => {
    dispatch(setStates());
    dispatch(setRoutes());
    dispatch(setCities( "","city"));

    return () => {
      dispatch(clearStates());
      dispatch(clearRoutes());
      dispatch(clearCities());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!state) return;

    dispatch(setHeadquarters(state.value));
    return () => {
      dispatch(clearHeadquarters());
    };
  }, [dispatch, state]);

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 60,
      //   maxWidth: 60,
      // },
      {
        Header: "Area Type",
        accessor: "areatype",
        disableSortBy: true,
      },
      {
        Header: "From City",
        accessor: "fromCity.name",
        disableSortBy: true,
        
      },
      {
        Header: "To City",
        accessor: "toCity.name",
        disableSortBy: true,
      },
      {
        Header: "HQ",
        accessor: "headquarter.name",
      },
      {
        Header: "State",
        accessor: "state.name",
        disableSortBy: true,
        Cell: ({ value }) => value ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() : "",
      },
      {
        Header: "Distance",
        accessor: "distance",
      },
      {
        Header: "Actions",
        accessor: "",
        Cell: (props) => {
          return (
            <div className='d-flex'>
              <span
                className="react-table-view-link"
                onClick={() => {
                  setEditForm(props.row.original);
                  // setShowEdit(true);
                }}
              >
                <RiEdit2Fill className="icon-color-green" />
              </span>
              <span
                className="react-table-view-link mx-3"
                onClick={() => {
                  setRouteData(props.row.original);
                  setShowEdit(true)
                }}
              >
                <MdDelete type="button"
                  className="button-delete__icon" size={25} />
              </span>
            </div>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );
 


  const handleSubmit = async (e) => {
    e.preventDefault();
   

    if (
      !state ||
      !headquarter ||
      !fromCity ||
      !toCity ||
      !areaType ||
      !distance
    )
      return customToast.error("Please fill all details!");

      if(fromCity.value === toCity.value) return customToast.error("From and To city must be different!");
      
      let index = -1;
      if(!isEdited) {
        index = tableData.findIndex(p => (p.fromCity.name).toLocaleLowerCase() == (fromCity.label).toLocaleLowerCase() && (p.toCity.name).toLocaleLowerCase() == (toCity.label).toLocaleLowerCase());
      } 
      
    if(index === -1){
      try {
        
        if(isEdited) {
          await dispatch(editRoute(updateDataId, {
            stateId: state.value,
            headquarterId: headquarter.value,
            fromCityId: fromCity.value,
            toCityId: toCity.value,
            areatype: areaType.label,
            distance,
          }));
          customToast.success("Route Successfully Updated");
        } else {
          await dispatch(
            addRoutes({
              stateId: state.value,
              headquarterId: headquarter.value,
              fromCityId: fromCity.value,
              toCityId: toCity.value,
              areatype: areaType.label,
              distance,
            })
          );
          customToast.success("Route Successfully Created");
        }
        setState(null);
        setHeadquarter(null);
        setFromCity(null);
        setToCity(null);
        setAreaType(null);
        setDistance(0);
      } catch (e) {
        customToast.error("We Encountered an Error");
      }
    }
    else{
      customToast.error("Route Already Exists!");
    }
    
  };

   const tableData = routes.data

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Create Route" />

        <section className="area-creation-content__form">

          <div className="admin-panel__alert" style={{ marginTop: "1rem", marginBottom: "2rem" }}>
            {/* <h2>Edit without hesitation</h2> */}
            <p>
              Note: If the distance of a route is edited, the updated distance will not be reflected in the tour programs. However, the new distance will be applied to newly created tour programs.
            </p>
          </div>

          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="admin-form">
              <div className="admin-form__content">
                <label htmlFor="state">
                  Select State <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="state"
                  id="state"
                  value={state}
                  options={stateSelect}
                  onChange={(e) => setState({ value: e.value, label: e.label })}
                  styles={adminStyles}
                  placeholder="Select State"
                />
              </div>
              <div className="admin-form__content">
                <label htmlFor="headquarter">
                  Select HQ <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="headquarter"
                  id="headquarter"
                  value={headquarter}
                  options={hqSelect}
                  onChange={(e) =>
                    setHeadquarter({ value: e.value, label: e.label })
                  }
                  styles={adminStyles}
                  placeholder="Select Headquarter"
                />
              </div>
              <div className="admin-form__content">
                <label htmlFor="fromCity">
                  From City <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="fromCity"
                  id="fromCity"
                  value={fromCity}
                  options={filteredFromCity}
                  onChange={(e) =>
                    setFromCity({ value: e.value, label: e.label })
                  }
                  styles={adminStyles}
                  placeholder="Select From City"
                />
              </div>
              <div className="admin-form__content">
                <label htmlFor="toCity">
                  To City <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="toCity"
                  id="toCity"
                  value={toCity}
                  options={citySelect}
                  onChange={(e) =>
                    setToCity({ value: e.value, label: e.label })
                  }
                  styles={adminStyles}
                  placeholder="Select To City"
                />
              </div>
              <div className="admin-form__content">
                <label htmlFor="areaType">
                  Select Area Type <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="areaType"
                  id="areaType"
                  value={areaType}
                  options={areaTypeOptions}
                  onChange={(e) =>
                    setAreaType({ value: e.value, label: e.label })
                  }
                  styles={adminStyles}
                  placeholder="Select Area Type"
                />
              </div>
              <div className="admin-form__content">
                <label htmlFor="distance">
                  Enter Distance (one side) <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  value={distance}
                  onChange={(e) => setDistance(e.target.value)}
                  type="number"
                  className="me-5"
                  id="distance"
                  placeholder="Enter Distance"
                  min={0}
                />
              </div>
              {
                isEdited ?
                  <button type="submit" className="button-blue-gradient">
                    Edit Route
                  </button> :
                  <button type="submit" className="button-blue-gradient">
                    Add Route
                  </button>
              }
            </div>
          </form>
        </section>

        <section className="area-creation-content__info">
        {tableData && tableData.length > 0 && (
            <h2 className="web-app__heading mb-4 ms-2">
              Showing ({tableData.length}) Entries
            </h2>
          )}
          {
            tableData && 
            <div className="filter-table">
            <Table columns={columns} data={tableData} />
          </div>
          }
          
          {showEdit ? <EditPopup data={routeData} /> : null}
        </section>
      </div>
    </div>
  );
};

export default AdminCity;
