import { useCallback, useEffect, useMemo, useState } from "react";
import { BsCheck2Circle, BsXCircle } from "react-icons/bs";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import { IoMdEye } from "react-icons/io";
import { MdCheckBoxOutlineBlank, MdInfoOutline, MdOutlineGroup} from "react-icons/md";
import { RiCloseCircleLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import customToast from "../../../../components/CustomToast";
import PageTitle from "../../../../components/PageTitle";
import Table from "../../../../components/Table";
import { updateTrigger } from "../../../../reducers/approveLengthReducer";
import {
  approveTP,
  clearTourProgram,
  rejectTP,
  setTourProgramForApproval,
} from "../../../../reducers/tpReducer";
import { viewUsersWithPendingTP } from "../../../../services/tourProgram";
import { formatDate, monthOptions } from "../../../../utils/helper";
import { addIndex, generateSelectUser, getDesignation } from "../../../../utils/serialiseResponse";
import { selectUserStyles } from "../../../../views/public/stylesheets/selectStyles.js";
import Select from "react-select";
import { viewUsersForUtilities } from "../../../../services/utilities";

const ApprovalTP = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const tpData = useSelector(({ tp }) => tp);
  const [allSelected, setAllSelected] = useState(false);
  const [tps, setTps] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [showRemark, setShowRemark] = useState(false);
  const [remarkData, setRemarkData] = useState();
  const [toggle, setToggle] = useState(false);
  const [allUsersData, setAllUsersData] = useState([]);
  const [users, setUsers] = useState([])
  const [selectedUser, setselectedUser] = useState(null)
  const userSelect = useMemo(
    () => generateSelectUser(users, "fullName"),
    [users]
  );
  
  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setUsers(res?.data);
      });
    } catch (error) {
      customToast.error("error occured Please Try Again");
    }
  }, []);

  const fetchData = useCallback(async() => {
    try {
      const res = await viewUsersWithPendingTP();
      setAllUsersData(res?.data);
    } catch (error) {
      console.log(error);
      customToast.error("Error occurred !");
    }
  },[]);

  useEffect(() =>{
    fetchData();
  },[]);


  useEffect(()=>{
    setTps(tpData?.data);
  },[tpData?.data]);

  const SearchColumnFilter = useCallback(
    ({ column, columns }) =>{
      return (
        <div className="search-filter__container" onClick={(e)=>{e.stopPropagation()}}>
          {<span className="d-flex gap-3 align-items-center">{column.render("Header")}
            {allSelected ? (
                <ImCheckboxChecked onClick={(e) => {
                  e.preventDefault();
                  column?.filteredRows?.forEach((el,idx)=>{
                    el.original.isSelected = false ;
                  });
                  setAllSelected(false);
                }}/>
              ):(
                <ImCheckboxUnchecked onClick={(e) => {
                  e.preventDefault();
                  column?.filteredRows?.forEach((el,idx)=>{
                    el.original.isSelected = true ;
                  });
                  setAllSelected(true);
                }}/>
              )
              }
          </span>
          }
        </div>
  );},[allSelected])
  
  
  let tableData = useMemo(() => addIndex(tps), [tps]);
  // const handleSelect = useCallback(
  //   (sr) => {
  //     const obj = tableData[sr-1];
  //     obj.isSelected = !obj.isSelected ;
  //     setTps([...tableData]);
  //   },[tableData],
  // )
  const handleApprove = async (data) => {
    if(data.length === 0)return customToast.error("Please select a Tour Program");
    const payload = {ids:data , type:"bulk"} ;
    await dispatch(approveTP(data?._id, payload));
    customToast.success("TPs approved successfully");
    setShowEdit(false);
    setAllSelected(false);
    //since previous implementation requires id so we send undefined because the new implementation handles 
    // it gracefully
  };
  const handleReject = async (data) => {
    if(data.length === 0)return customToast.error("Please select a Tour Program");
    const payload = {ids:data , type:"bulk"} ;
    await dispatch(rejectTP(data?._id, payload));
    setShowEdit(false);
    setAllSelected(false);
  };
  const EditPopup = () => {
    const data = [];
    tableData.forEach((el,idx)=>{
      if(el?.isSelected){
        data.push(el?._id);
      }
    })
    return (
      <section className="edit-popup" onClick={(e) =>
        showEdit
          ? e.target.className === "edit-popup"
            ? setShowEdit(false)
            : null
          : null
      }>
      {/* <section className="edit-popup"> */}
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Confirm Details</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              setShowEdit(false);
            }}
          >
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  {`Do you want to approve the ${data.length} tour programs?`}
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button
                type="submit"
                className="button-submit-green mt-4"
                onClick={() => handleApprove(data)}
              >
                <span className="button-submit-green__icon-container">
                  <BsCheck2Circle className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Approve</span>
              </button>

              <button
                type="submit"
                className="button-delete mt-4"
                onClick={() => handleReject(data)}
              >
                <span className="button-delete__icon-container">
                  <BsXCircle className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Reject</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  const RemarkPopup = ({ data }) => {
    return (
      <section
        className="edit-popup"
        onClick={(e) =>
          showRemark
            ? e.target.className === "edit-popup"
              ? setShowRemark(false)
              : null
            : null
        }
      >
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Remarks</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowRemark(false)}
            />
          </div>
          {data.remarks}
        </div>
      </section>
    );
  };
  
  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Date",
        accessor: "date",
        disableFilters: true,
        Cell: (props) => {
          return <span>{formatDate(props?.row?.original?.date)}</span>;
        },
        minWidth: 100,
        maxWidth: 100,
      },
      {
        Header: "Employee Name",
        accessor: "userName",
        // Cell: (props) => <span>{`${props.row.original.submitter.firstName} ${props.row.original.submitter.lastName}`}</span>
      },
      {
        Header: "Designation",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 90,
        maxWidth: 90,
        Cell: (props) => <span>{getDesignation(props?.row?.original?.submitter?.des)}</span>
      },
      {
        Header: "Area Type",
        accessor: "areaType",
        disableSortBy: true,
        minWidth: 90,
        maxWidth: 90,
      },
      {
        Header: "Location",
        accessor: "",
        disableSortBy: true,
        disableFilters:true,
        Cell: (props) => {
          return props?.row?.original?.workAreas?.map((el) => (
            <span key={el?.location?.value}>
              {el?.location?.label} <br />
            </span>
          ));
        },
      },
      {
        Header:"Remarks",
        accessor:"",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 100,
        maxWidth: 100,
        Cell:(props) =>{
          return (
            <button
            disabled={!props?.row?.original?.remarks?.length}
            className="react-table-view-link bg-transparent border-0"
            onClick={() => {
              setRemarkData(props?.row?.original);
              setShowRemark(true);
            }}
          >
            <MdInfoOutline
              className={`icon-color-tertiary ${
                !props?.row?.original?.remarks?.length && "opacity-25"
              }`}
            />
          </button>
          )
        }
      },
      {
        Header:"Activity",
        accessor:"",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 100,
        maxWidth: 100,
        Cell:(props) =>{
          return props?.row?.original?.activity ? 
          props?.row?.original.activity :
          <span>--</span>
        }
      },
      {
        Header:"Worked with",
        accessor:"",
        minWidth: 150,
        maxWidth: 150,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return props?.row?.original?.workedWith?.map((el) => (
            <li key={el?._id}>
              {`${el?.firstName} ${el?.lastName}`}
              <br />
            </li>
          ));
        },
      },
      {
        Header: "Select",
        accessor:"select",
        Cell: (props) => {
          const [local,setLocal] = useState(props?.row?.original?.isSelected || false);
          return (
            <span
              className="react-table-view-link display-6"
            >    
              {local ? (
                <ImCheckboxChecked onClick={() => {
                  // handleSelect(props?.row?.original?.sr);
                  props.row.original.isSelected = false ;
                  setLocal(false); 
                }}/>
              ):(
                <MdCheckBoxOutlineBlank onClick={() => {
                  // handleSelect(props?.row?.original?.sr);
                  props.row.original.isSelected = true ;
                  setLocal(true);
                }}/>
              )
              }
            </span>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: false,
        disableSortBy: true,
        Filter:SearchColumnFilter
      }
    ],
    [SearchColumnFilter,tableData]
  );

  const columns2 = useMemo(
    () => [
      {
        Header: "Month",
        accessor:"month",
        maxWidth:"100px",
        Cell:(props) => {
          let tp = props?.row?.original;
          let month = monthOptions.find((el) => Number(el?.value) === Number(tp?.month));

          return <div>{month?.label}</div>
        }
      },
      {
        Header: "Year",
        accessor: "year"
      },
      {
        Header: "Name",
        accessor:"user.firstName",
        Cell:(props) => {
          let data = props?.row?.original;
          return <div>
            {`${data?.user?.firstName} ${data?.user?.lastName}`}
          </div>
        }
      },
      {
        Header: "Designation",
        accessor:"user.des.name",
        Cell:(props) => {
          let data = props?.row?.original;
          return <div>
            {`${data?.user?.des?.name}`}
          </div>
        }
      },
      {
        Header: "Reporting Manager",
        accessor:"user.reportingManager.firstName",
        Cell:(props) => {
          let data = props?.row?.original;
          return <div>
            {`${data?.user?.reportingManager?.firstName} ${data?.user?.reportingManager?.lastName}`}
          </div>
        }
      },
      {
        Header:"View",
        accessor:"",
        Cell:(props) => {
          return (
            <div style={{cursor:"pointer"}}>
              <IoMdEye className="icon-color-tertiary" size={20} onClick={() => navigate("./view-user-tp", {state: props?.row?.original})}/>
            </div>
          )
        }
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(setTourProgramForApproval({
      selectedUser:selectedUser?.value
    }));
    return () => {
      dispatch(clearTourProgram());
    };
  }, [dispatch, selectedUser]);
  
  useEffect(()=>{
    dispatch(
      updateTrigger({ index: location?.state?.index, data: tps })
    );
  },[tps])
  
  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Approve Tour Program" />
        <section className="area-creation-content__info">
          <div className="col-md-4 col-lg-3 util-tp-filter">
              <div>
                <p className="util-select-user__label mb-2">Select User</p>
                <div className="util-select-user">
                  <MdOutlineGroup className="util-select-user__icon" />
                  <Select
                    styles={selectUserStyles}
                    options={userSelect}
                    value={selectedUser}
                    onChange={(selectedUser)=>setselectedUser(selectedUser)}
                    placeholder="Select User"
                  />
                </div>
              </div>
          </div>
          <div className="d-flex justify-content-between gap-5 pe-2 align-items-center mb-5 mt-4">
          <div>
              <div className="d-flex align-items-center">
                <div className="form__control pe-3" style={{ width: "fit-content", color:"#C7C4E9" }}>
                  <label className="toggle-label2 me-3 mb-0">
                    <input
                      type="checkbox"
                      checked={toggle}
                      onClick={(e) => {setToggle(!toggle);
                      }
                      }
                    />
                    <span />
                  </label>
                  <span className="ml-0">View Monthly Tour Program</span>
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="button-submit-green"
              onClick={(e) => {
                e.preventDefault();
                setShowEdit(true);
              }}
            >
              <span className="button-submit-green__icon-container">
                <BsCheck2Circle className="button-submit-green__icon" />
              </span>
              <span className="button-submit-green__text">Actions</span>
            </button>

            
          </div>
          {tableData?.length > 0 && (
            <h2 className="web-app__heading mb-4 ms-2">
              Showing ({tableData?.length}) Entries
            </h2>
          )}
          <div className="filter-table">
          {toggle && allUsersData?.length > 0 ? <Table columns={columns2} data={allUsersData} /> :<Table columns={columns} data={tableData} />} 
           </div>
          {showEdit ? <EditPopup  /> : null}
          {showRemark ? <RemarkPopup data={remarkData}  /> : null}
        </section>
      </div>
    </div>
  );
};

export default ApprovalTP;
