import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseURLV1 = `${apiURL}/client/v1/utilities/`;

export const getAllDeletionRequest = async (filters) => {
  const {data} = await axios.get(baseURLV1 + "deletionRequest", {
    headers: setAuth(),
    params:filters
  })
  return data;
}

export const deleteRequest = async (payload) => {
  const {data} = await axios.delete(baseURLV1 + "delete-entity", {
    params: payload,
    headers: setAuth(),
  })
  return data;
}

export const rejectRequest = async (payload) => {
  const {data} = await axios.delete(baseURLV1 + "reject-entity", {
    params: payload,
    headers: setAuth(),
  })
  return data;
}