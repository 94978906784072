import React, { useEffect, useMemo, useState } from 'react'
import PageTitle from "../../../../../components/PageTitle";
import Table from '../../../../../components/Table';
import { activateCampaign, deactivateCampaign, deleteCampaign, getCampaign } from '../../../../../services/campaign';
import { FaEye } from "react-icons/fa";
import { IoMdMore } from "react-icons/io";
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import customToast from '../../../../../components/CustomToast';
import { useSelector } from 'react-redux';




const AllCampaign = () => {
    const [allcampaigns, setAllcampaigns] = useState([])
    const userId = useSelector((state) => state.user.user._id);
    const navigate = useNavigate()
    const columns = useMemo(() => {
        return [
          {
            Header: "Campaign Name",
            accessor: "name",
            minWidth: 80,
            maxWidth: 80,
          },
          {
            Header: "Status",
            accessor: "isActive",
            minWidth: 80,
            maxWidth: 80,
            Cell: ({ value }) => (
                <span
                style={{
                    color: value ? "green" : "red",
                }}
                >
                    {value ? "Active" : "Inactive"}
                </span>
            ),
          },
         {
            Header: "View",
            accessor: "details",
            disableFilters: true,
            disableSortBy: true,
            minWidth: 60,
            maxWidth: 60,
            Cell: (props) => {
              return (
                <Link
                  to={`${props?.row?.original?._id}`}
                  state={props?.row?.original}
                
                  className="util-table-view-details-link"
                >
                  <FaEye className="icon-color-tertiary" />
                </Link>
              );
            }
        },
        {
            Header: "Action",
            accessor: "action",
            minWidth: 80,
            maxWidth: 80,
            Cell: (props) =>{
              const campaignId = props.row.original._id;
              const handleDelete = async (campaignId) =>{
                try{
                  const response = await deleteCampaign(campaignId)
                  console.log(response)
                  setAllcampaigns((prevCampaigns) =>
                  prevCampaigns.filter((campaign) => campaign._id !== campaignId)
                  );
                  customToast.success("Campaign Deleted Successfully")
                }catch(err){
                  console.log(err.message)
                  customToast.error("Something went Wrong")
                }
              }
                // return(
                //     <IoMdMore className="icon-color-tertiary" 
                //     style={{ fontSize: "3rem", fontWeight: "bold" }}  />
                // )
                return (
                  // <Dropdown >
                  //   <Dropdown.Toggle className="icon-color-tertiary">
                  //     <IoMdMore style={{ fontSize: "1rem", fontWeight: "bold" }} />
                  //   </Dropdown.Toggle>
      
                  //   <Dropdown.Menu className="w-100 text-center">
                  //   <Dropdown.Item className="w-100" onClick={() => handleStatusChange(campaignId, props.row.original.isActive)} >
                  //   {props.row.original.isActive ? "Deactivate" : "Activate"}
                  //    </Dropdown.Item>
                  //     <Dropdown.Item className="w-100" onClick={() => handleDelete(campaignId)}>
                  //       Delete
                  //     </Dropdown.Item>
                  //     <Dropdown.Item className="w-100">
                  //     <Link
                  //       to={`edit-campaign/${campaignId}`} 
                  //       className=" text-white text-decoration-none hover-text-dark"  
                  //        >
                  //        Edit Campaign
                  //     </Link>
                  //     </Dropdown.Item>
                  //   </Dropdown.Menu>
                  // </Dropdown>
                  <Dropdown>
      <Dropdown.Toggle className="p-0 border-0 bg-transparent shadow-none "
      style={{ background: "none", border: "none", boxShadow: "none" }} >
        <IoMdMore size={18} style={{color:"#36ABF9"}} />
      </Dropdown.Toggle>

      <Dropdown.Menu className='w-100 text-center'>
        <Dropdown.Item onClick={() => handleStatusChange(campaignId, props.row.original.isActive)}>
          {props.row.original.isActive ? "Deactivate" : "Activate"}
        </Dropdown.Item>
        <Dropdown.Item className='w-100 text-center' onClick={() => handleDelete(campaignId)}>Delete</Dropdown.Item>
        <Dropdown.Item className='w-100 text-center' as={Link} to={`edit-campaign/${campaignId}`}>
          Edit Campaign
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
                );
            }
        },
          
        ];
      }, []);

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const {data} = await getCampaign();
        setAllcampaigns(data); 
      } catch (error) {
        console.error("Error fetching campaigns:", error);
        }
      };
    fetchCampaigns();
  }, [])

    const handleStatusChange = async (campaignId, isActive) => {
      try {
        if (isActive) {
          await deactivateCampaign(campaignId); 
          customToast.success("Campaign Deactivated Successfully");
        } else {
          await activateCampaign(campaignId);
          customToast.success("Campaign Activated Successfully");
        }
    
        setAllcampaigns((prevCampaigns) =>
          prevCampaigns.map((campaign) =>
            campaign._id === campaignId ? { ...campaign, isActive: !isActive } : campaign
          )
        );
      } catch (error) {
        console.error("Error updating campaign status:", error);
        customToast.error("Something went wrong");
      }
    };
      
    
  return (
    <main className="main-content admin-content">
    <div className="area-creation-content" style={{position:"relative"}}>
      
      <PageTitle title=" Campaigns" />

     <div className='d-flex gap-4' style={{position:"absolute", top:"2rem" , right:"3rem"}}>
     
     <div className="admin-content-submit-button mt-2" >
        <button  onClick={() => navigate('/admin-panel/holidays/allcampaign/user-campaign')} type="submit" className="button-blue-gradient">
          Alloted Campaign
        </button>
      </div>
      <div className="admin-content-submit-button mt-2" >
        <button  onClick={() => navigate("/admin-panel/holidays/campaign")} type="submit" className="button-blue-gradient">
          Create Campaign
        </button>
      </div>
     </div>
    
      <section className="area-creation-content__form">
      <div className="filter-table mt-5">
        <Table columns={columns} data={allcampaigns} />
      </div>
      </section>


      
     
    </div>
  </main>

  )
}

export default AllCampaign