import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { MdArrowBackIosNew} from "react-icons/md";
import Accordion from "react-bootstrap/Accordion";
import { getCampaignDetails } from '../../../../../services/campaign';
import {RiNurseFill,} from "react-icons/ri";
import { FaUser } from "react-icons/fa";
import { AiFillMedicineBox } from "react-icons/ai";

const CompaignDetail = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [campaignData, setCampaignData] = useState({})
    const campaignId = location?.state?._id

    useEffect(() => {
      
        if (!campaignId) {
          console.error("Campaign ID is missing!");
          return;
        }
      const fetchCampaignData = async () => {

          try {
              const {data} = await getCampaignDetails(campaignId);
              setCampaignData(data);
          } catch (error) {
              console.error("Error fetching campaigns:", error);
          }
      };

      fetchCampaignData();
    }, [])
    
    
  return (
    <div className="utility-inner-tp">
        <div className="row">
        <h2
          className="web-app__heading"
          style={{ display: "flex", alignItems: "center" , justifyContent:"space-between" }}
        >
          <div>
            <MdArrowBackIosNew
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <span style={{ display: "inline-block", margin: "0 1rem" }}>
              Campaign Details
            </span>
          </div>
        </h2>
        <div className="util-inner-details">
          <div className="util-inner-info-box-container">
            <div className="util-inner-info-box">
              <h3>Campaign Name</h3>
              <p>{location?.state?.name}</p>
            </div>

            <div className="util-inner-info-box">
              <h3>State</h3>
              <p>
                {location?.state?.isActive ? "Active" : "Deactive"}
              </p>
            </div>

            <div className="util-inner-info-box">
              <h3>Deadline</h3>
              <p>{location?.state?.deadline}</p>
            </div>

        
          </div>
        </div>
        <div style={{display:"flex"}}>
          <div >
          <div className="doctor-call-div">
              <h2 className="web-app__heading mt-5">
                {location?.state?.users?.length > 0 ? "Slected User" : null} 
              </h2>

              <Accordion className="mt-4" alwaysOpen>
              {campaignData?.users?.length > 0 && campaignData?.users?.map((e, i) => ( 
                  <Accordion.Item key={e._id} eventKey={i} className="mb-4">
                    <Accordion.Header>
                    {" "}
                    <span className="d-flex align-items-start flex-column">
                      <span>{<FaUser className="me-3 fs-2" />} {e.firstName} {e.lastName}</span>
                      <div style={{fontSize:"1.5rem"}} className="password-icon-show"></div>

                    </span>
                  </Accordion.Header>
                  </Accordion.Item>
                
                ))}
                <Accordion.Body>
                  
                </Accordion.Body>
              </Accordion>
            </div>
            <div className="doctor-call-div" >
              <h2 className="web-app__heading mt-5">
                {location?.state?.doctors?.length > 0 ? "Selected Doctor" : null} 
              </h2>

              <Accordion className="mt-4" alwaysOpen>
                {location?.state?.doctors?.length > 0 && location?.state?.doctors?.map((e, i) => ( 
                  <Accordion.Item key={e._id} eventKey={i} className="mb-4">
                    <Accordion.Header>
                    {" "}
                    <span className="d-flex align-items-start flex-column">
                      <span>{<RiNurseFill className="me-3 fs-2" />} {e.name}</span>
                      <div style={{fontSize:"1.5rem"}} className="password-icon-show"></div>

                    </span>
                  </Accordion.Header>
                  </Accordion.Item>
                
                ))}
              
                <Accordion.Body>
                   
                </Accordion.Body>
              </Accordion>
            </div>
          
            <div className="doctor-call-div">
              <h2 className="web-app__heading mt-5">
                {location?.state?.chemists?.length > 0 ? "Selected Chemists" : null} 
              </h2>

              <Accordion className="mt-4" alwaysOpen>
              {location?.state?.chemists?.length > 0 && location?.state?.chemists?.map((e, i) => ( 
                  <Accordion.Item key={e._id} eventKey={i} className="mb-4">
                    <Accordion.Header>
                    {" "}
                    <span className="d-flex align-items-start flex-column">
                      <span>{<FaUser className="me-3 fs-2" />} {e.name}</span>
                      <div style={{fontSize:"1.5rem"}} className="password-icon-show"></div>

                    </span>
                  </Accordion.Header>
                  </Accordion.Item>
                
                ))}
                <Accordion.Body>
                
                </Accordion.Body>
              </Accordion>
            </div>
          
            <div className="doctor-call-div">
              <h2 className="web-app__heading mt-5">
               {location?.state?.stockists?.length > 0 ? "Selected Stockist" : null} 
              </h2>

              <Accordion className="mt-4" alwaysOpen>
              {location?.state?.stockists?.length > 0 && location?.state?.stockists?.map((e, i) => ( 
                  <Accordion.Item key={e._id} eventKey={i} className="mb-4">
                    <Accordion.Header>
                    {" "}
                    <span className="d-flex align-items-start flex-column">
                      <span>{<AiFillMedicineBox className="me-3 fs-2" />} {e.name}</span>
                      <div style={{fontSize:"1.5rem"}} className="password-icon-show"></div>

                    </span>
                  </Accordion.Header>
                  </Accordion.Item>
                
                ))}
                <Accordion.Body>
                  
                </Accordion.Body>
              </Accordion>
            </div>

            {/* <div className="doctor-call-div">
              <h2 className="web-app__heading mt-5">
               {location?.state?.users?.length > 0 ? "Selected Users" : null} 
              </h2>

              <Accordion className="mt-4" alwaysOpen>
              {location?.state?.users?.length > 0 && location?.state?.users?.map((e, i) => ( 
                  <Accordion.Item key={e._id} eventKey={i} className="mb-4">
                    <Accordion.Header>
                    {" "}
                    <span className="d-flex align-items-start flex-column">
                      <span>{<RiNurseFill className="me-3 fs-2" />} {e.firstName}</span>
                      <div style={{fontSize:"1.5rem"}} className="password-icon-show"></div>

                    </span>
                  </Accordion.Header>
                  </Accordion.Item>
                
                ))}
                <Accordion.Body>
                  
                </Accordion.Body>
              </Accordion>
            </div> */}

           
          </div>
        </div>
      </div> 
    </div>
  )
}

export default CompaignDetail