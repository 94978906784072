import { useEffect, useState, useMemo , useCallback} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PageTitle from "../../../../../components/PageTitle";
import { BsCheck2Circle, BsXCircle } from "react-icons/bs";
import {
  approveRoutes,
  clearRoutes,
  rejectRoutes,
  setRoutesForApproval,
} from "../../../../../reducers/locations/routeReducer";
import serialise, { generateSelectUser } from "../../../../../utils/serialiseResponse";
import Table from "../../../../../components/Table";
import { RiCloseCircleLine } from "react-icons/ri";
import { MdOutlinePendingActions, MdCheckBoxOutlineBlank, MdOutlineGroup } from "react-icons/md";
import { updateTrigger } from "../../../../../reducers/approveLengthReducer";

import customToast from "../../../../../components/CustomToast";
import {ImCheckboxUnchecked, ImCheckboxChecked} from "react-icons/im" ;
import { selectUserStyles } from "../../../../public/stylesheets/selectStyles";
import { viewUsersForUtilities } from "../../../../../services/utilities";
import Select from "react-select";


const ApprovalRoute = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const routes = useSelector(({ route }) => route);

  const [showEdit, setShowEdit] = useState(false);
  const [approvalData, setApprovalData] = useState();
  const [allSelected, setAllSelected] = useState(false);
  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)


  const userSelect = useMemo(
    () => generateSelectUser(users, "fullName"),
    [users]
  );
  
  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setUsers(res?.data);
      });
    } catch (error) {
      customToast.error("error occured Please Try Again");
    }
  }, []);

  const SelectColumnFilter = useCallback(
    ({ column, columns }) =>{
      return (
        <div className="search-filter__container" onClick={(e)=>{e.stopPropagation()}}>
          {<span className="d-flex gap-3 align-items-center">{column.render("Header")}
            {allSelected ? (
                <ImCheckboxChecked onClick={(e) => {
                  e.preventDefault();
                  column?.filteredRows?.forEach((el,idx)=>{
                    el.original.isSelected = false ;
                  });
                  setAllSelected(false);
                }}/>
              ):(
                <ImCheckboxUnchecked onClick={(e) => {
                  e.preventDefault();
                  column?.filteredRows?.forEach((el,idx)=>{
                    el.original.isSelected = true ;
                  });
                  setAllSelected(true);
                }}/>
              )
              }
          </span>
          }
        </div>
  );},[allSelected])

  const handleApprove = async (data) => {
    if(data.length === 0)return customToast.error("Please select a Route");
    const payload = {ids:data , approvalTyp:"bulk"} ;
    await dispatch(approveRoutes(1, payload));
    setShowEdit(false);
    setAllSelected(false);
  };

  const handleReject = async (data) => {
    if(data.length === 0)return customToast.error("Please select a Route");
    const payload = {ids:data , rejectionTyp:"bulk"} ;
    await dispatch(rejectRoutes(1, payload));
    setShowEdit(false);
    setAllSelected(false);
  };

  const tableData = useMemo(() => serialise(routes.data), [routes]);

  const EditPopup = () => {
    const data = [];
    tableData.forEach((el,idx)=>{
      if(el?.isSelected){
        data.push(el?._id);
      }
    })

    return (
      <section className="edit-popup" onClick={(e) =>
        showEdit
          ? e.target.className === "edit-popup"
            ? setShowEdit(false)
            : null
          : null
      }>
       {/* <section className="edit-popup"> */}
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Edit Details</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form>
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  Do you want to approve the route?
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button
                type="button"
                className="button-submit-green mt-4"
                onClick={() => handleApprove(data)}
              >
                <span className="button-submit-green__icon-container">
                  <BsCheck2Circle className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Approve</span>
              </button>

              <button
                type="button"
                className="button-delete mt-4"
                onClick={() => handleReject(data)}
              >
                <span className="button-delete__icon-container">
                  <BsXCircle className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Reject</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  const setEditForm = (data) => {
    setShowEdit(false);
    setApprovalData(data);
  };

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   minWidth: 60,
      //   maxWidth: 60,
      // },
      {
        Header: "Created By",
        accessor: ``,
        accessor: (row) => `${row.submitter.firstName} ${row.submitter.lastName}`, // Combine first and last name
         Cell: (props) => <div>{props.value}</div>,
      },
      {
        Header: "From City",
        accessor: "fromCity",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "To City",
        accessor: "toCity",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Area Type",
        accessor: "areatype",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Distance",
        accessor: "distance",
      },
      {
        Header: "HQ",
        accessor: "headquarter",
      },
      {
        Header: "State",
        accessor: "state",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Select",
        accessor: "none",
        Cell: (props) => {
          const [local,setLocal] = useState(props?.row?.original?.isSelected || false);
          return (
            <span
              className="react-table-view-link display-6"
            >    
              {local ? (
                <ImCheckboxChecked onClick={() => {
                  // handleSelect(props?.row?.original?.sr);
                  props.row.original.isSelected = false ;
                  setLocal(false); 
                }}/>
              ):(
                <MdCheckBoxOutlineBlank onClick={() => {
                  // handleSelect(props?.row?.original?.sr);
                  props.row.original.isSelected = true ;
                  setLocal(true);
                }}/>
              )
              }
            </span>
          );
        },
        minWidth: 80,
        maxWidth: 80,
        disableFilters: false,
        disableSortBy: true,
        Filter:SelectColumnFilter
      },
    ],
    [SelectColumnFilter,tableData]
  );

  useEffect(() => {
    dispatch(setRoutesForApproval({selectedUser:selectedUser?.value}));

    return () => {
      dispatch(clearRoutes());
    };
  }, [dispatch, selectedUser]);
  
  useEffect(() => {
    dispatch( updateTrigger({index:location?.state?.index,data:routes?.data}))

  }, [routes?.data]);

  

  
  
  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Approve Route" />

        <section className="area-creation-content__info">
        <div className="d-flex justify-content-between align-items-center">
          <div className="col-md-4 col-lg-3 util-tp-filter">
              <div>
                <p className="util-select-user__label mb-2">Select User</p>
                <div className="util-select-user">
                  <MdOutlineGroup className="util-select-user__icon" />
                  <Select
                    styles={selectUserStyles}
                    options={userSelect}
                    value={selectedUser}
                    onChange={(selectedUser)=>setSelectedUser(selectedUser)}
                    placeholder="Select User"
                  />
                </div>
              </div>
          </div>
          <div className="d-flex justify-content-end gap-5 pe-2">
          <button
            type="submit"
            className="button-submit-green"
            onClick={(e) => {
              e.preventDefault();
              setShowEdit(true);
            }}
          >
            <span className="button-submit-green__icon-container">
              <BsCheck2Circle className="button-submit-green__icon" />
            </span>
            <span className="button-submit-green__text">Actions</span>
          </button>
          </div>
        </div>
        {tableData.length > 0 && (
            <h2 className="web-app__heading mb-4 ms-2 mt-5">
              Showing ({tableData.length}) Entries
            </h2>
          )}
          <div className="filter-table">
            <Table columns={columns} data={tableData} />
          </div>
          {showEdit ? <EditPopup data={approvalData} /> : null}
        </section>
      </div>
    </div>
  );
};

export default ApprovalRoute;
