import { useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { LuEqual } from "react-icons/lu";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx-js-style";
import { fetchCallReportAnalysisforDate } from "../../../services/reports";
import {
  viewMonthlyPunchingReports,
  viewTodaysActivity,
  viewUsersForUtilitiesWithTP,
} from "../../../services/utilities";
import { formatTime } from "../../../utils/helper";
import { ReactComponent as chemCallsSvg } from "../../public/img/SVG/icons/chemCalls.svg";
import { ReactComponent as doctorCallSvg } from "../../public/img/SVG/icons/doctorCall.svg";
import { ReactComponent as gifts } from "../../public/img/SVG/icons/gifts.svg";
import { ReactComponent as stockCallsSvg } from "../../public/img/SVG/icons/stockCalls.svg";
import { ReactComponent as totalPobSvg } from "../../public/img/SVG/icons/totalPob.svg";
import { ReactComponent as totalSamplesSvg } from "../../public/img/SVG/icons/totalSamples.svg";
import "./index.css";

const DashboardCard = ({
  title,
  total,
  yesterday,
  percentage,
  Icon,
  bgColor,
  changeIcon,
  changeIconColor,
}) => (
  <div className={`dashboard-card ${bgColor}`}>
    <div className="dashboard-card-header">
      <div className="icon">
        <Icon />
      </div>
      <div className="title">{title}</div>
    </div>
    <div className="dashboard-card-content">
      <div className="total-container">
        <div className="total-text">Total</div>
        <div className="total">{total}</div>
      </div>
      <div className="stats-container">
        <div className="stats">
          <div className={`change-icon ${changeIconColor}`}>{changeIcon}</div>
          <div>{yesterday} Yesterday</div>
          <div>({percentage})</div>
        </div>
      </div>
    </div>
  </div>
);

const ConsolidateReport = () => {
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [data, setData] = useState(null);
  const [usersResponse, setUsersResponse] = useState([]);
  const [loading, setLoading] = useState(true);
  const currentDate = new Date().toISOString().split("T")[0];
  const [isCurrentDate, setIsCurrentDate] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await viewTodaysActivity(date);
      setData(response.data);

      const usersResponse = await viewUsersForUtilitiesWithTP(date);
      const filteredUsers = usersResponse?.data.filter(
        (user) =>
          user.chemreports > 0 || user.docreports > 0 || user.stocreports > 0
      );
      setUsersResponse(filteredUsers);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    setIsCurrentDate(date === currentDate);
  }, [date, currentDate]);

  const parsePunchingReport = (punchingReport) => {
    return punchingReport.map((report) => {
      
      return {
        "Employee Name": report?.user?.name || "",
        "Check-In Count": report.checkInCount,
        checkInTime: report?.checkInTime?.reduce(
          (acc, item) =>
            acc + (item !== "N/A" ? formatTime(item).toUpperCase() + ", " : ""),
          ""
        ),
        "Check Out Count": report.checkOutCount,
        "Check-Out": report?.checkOutTime?.reduce(
          (acc, item) =>
            acc + (item !== "N/A" ? formatTime(item).toUpperCase() + ", " : ""),
          ""
        )
      };
    });
  };
  
  const parseTpReports = (tps) =>{
    return tps.map((entry) => {
      const user = entry.user;
      const tp = entry.tps[0] || null;

      return {
        "Employee Name": user.name,
        Email: user.email,
        Date: tp?.dateString || "",
        "Area Type": tp?.areaType || "",
        "Work Location":
          (tp &&  
            tp.workAreas.reduce(
              (acc, el) => acc + (el.location?.name || "") + ", ",
              ""
            )) ||
          "",
        Activity: tp?.activity || "",
        "Approval Status": tp
          ? tp.isApproved === 1
            ? "Approved"
            : "Pending"
          : "",
        "Approved By": tp?.authorizedBy?.fullName || "",
        "Doc Reports": entry.docreports,
        "Chem Reports": entry.chemreports,
        "Stoc Reports": entry.stocreports,
        "Is On Leave": entry.isLeave ? "Yes" : "No",
        "Leave Reason": entry.leaveReason,
      };
    });
  }
  
  const exportToExcel = async () => {
    setLoading(true);
    try {
      const response = await fetchCallReportAnalysisforDate({
        date: date,
      });
      const punchingReport = await viewMonthlyPunchingReports({
        date: date,
        type: "dailyReport",
      });
     
      const workbook = XLSX.utils.book_new();
      
      // Create all sheets
      const docSheet = XLSX.utils.json_to_sheet(response.docsData);
      const chemSheet = XLSX.utils.json_to_sheet(response.chemsData);
      const stocSheet = XLSX.utils.json_to_sheet(response.stocksData);
      const samplesSheet = XLSX.utils.json_to_sheet(response.samplesData);
      const giftsSheet = XLSX.utils.json_to_sheet(response.giftsData);
      const tpSheet = XLSX.utils.json_to_sheet(parseTpReports(usersResponse));
      const punchingReportSheet = XLSX.utils.json_to_sheet(
        parsePunchingReport(punchingReport.data)
      );
      
      // Function to add header row to each sheet with proper styling
      const addHeaderRow = (sheet) => {
        // Get current range
        const range = XLSX.utils.decode_range(sheet['!ref']);
        
        // Shift all cells down by one row
        for (let r = range.e.r; r >= range.s.r; r--) {
          for (let c = range.e.c; c >= range.s.c; c--) {
            const oldCellAddress = XLSX.utils.encode_cell({r: r, c: c});
            const newCellAddress = XLSX.utils.encode_cell({r: r + 1, c: c});
            if (sheet[oldCellAddress]) {
              sheet[newCellAddress] = sheet[oldCellAddress];
            }
          }
        }
        
        // Create header cell with styling
        const headerText = `These reports are fetched from only approved call reports for ${date}`;
        
        // Add the header cell to first row with styling
        sheet['A1'] = { 
          t: 's', 
          v: headerText,
          s: {
            font: { 
              name: 'Arial',
              sz: 14,
              color: { rgb: "FF0000" },
              bold: true
            },
            fill: { 
              patternType: "solid", 
              fgColor: { rgb: "F5F5F5" } 
            },
            alignment: { 
              horizontal: "center", 
              vertical: "center",
              wrapText: true
            },
            border: {
              top: { style: "thin", color: { rgb: "000000" } },
              bottom: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } }
            }
          }
        };
        
        // Merge cells across the first row
        sheet['!merges'] = sheet['!merges'] || [];
        sheet['!merges'].push({ s: {r: 0, c: 0}, e: {r: 0, c: range.e.c} });
        
        // Set row height for header row
        if (!sheet['!rows']) sheet['!rows'] = [];
        sheet['!rows'][0] = { hpt: 30 }; // Set height in points
        
        // Update sheet range
        sheet['!ref'] = XLSX.utils.encode_range({
          s: { r: 0, c: range.s.c },
          e: { r: range.e.r + 1, c: range.e.c }
        });
        
        return sheet;
      };
      
      // Add header to each sheet
      addHeaderRow(docSheet);
      addHeaderRow(chemSheet);
      addHeaderRow(stocSheet);
      addHeaderRow(samplesSheet);
      addHeaderRow(giftsSheet);
      
      // Add column width for better readability
      const setColumnWidths = (sheet) => {
        if (!sheet['!cols']) sheet['!cols'] = [];
        // Set default width for all columns
        const range = XLSX.utils.decode_range(sheet['!ref']);
        for (let c = range.s.c; c <= range.e.c; c++) {
          sheet['!cols'][c] = { width: 20 }; // Adjust width as needed
        }
        return sheet;
      };
      
      // Apply column widths to all sheets
      // setColumnWidths(docSheet);
      // setColumnWidths(chemSheet);
      // setColumnWidths(stocSheet);
      // setColumnWidths(samplesSheet);
      // setColumnWidths(giftsSheet);
      // setColumnWidths(tpSheet);
      // setColumnWidths(punchingReportSheet);
      
      // Append sheets to workbook
      XLSX.utils.book_append_sheet(workbook, docSheet, "Doctor Call Reports");
      XLSX.utils.book_append_sheet(workbook, chemSheet, "Chemist Call Reports");
      XLSX.utils.book_append_sheet(workbook, stocSheet, "Stockist Call Reports");
      XLSX.utils.book_append_sheet(workbook, samplesSheet, "Sample And POB Reports");
      XLSX.utils.book_append_sheet(workbook, giftsSheet, "Gift Reports");
      XLSX.utils.book_append_sheet(workbook, punchingReportSheet, "Punching Report");
      XLSX.utils.book_append_sheet(workbook, tpSheet, "Tour Programs");
      
      // Write file with styles
      XLSX.writeFile(
        workbook,
        `call_report_analysis_${new Date(date).toISOString().split("T")[0]}.xlsx`
      );
  
    } catch (error) {
      console.error("Error exporting data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getChangeIcon = (current, previous) => {
    if (current > previous) return <IoIosArrowUp />;
    if (current < previous) return <IoIosArrowDown />;
    return <LuEqual />;
  };

  const getChangeIconColor = (current, previous) => {
    if (current > previous) return "green";
    if (current < previous) return "red";
    return "primary";
  };

  const getPercentageChange = (previous, current) => {
    if (previous === 0) {
      return current === 0 ? "0%" : "-100%";
    }

    let difference =
      current > previous
        ? ((current - previous) / previous) * 100
        : -((previous - current) / previous) * 100;
    difference = -difference; // Negate the difference
    difference =
      Math.abs(difference) > 100 ? 100 * Math.sign(difference) : difference;

    // Check if there is a fractional component
    const hasFractionalComponent = difference % 1 !== 0;

    return hasFractionalComponent
      ? `${difference.toFixed(2)}%`
      : `${difference.toFixed(0)}%`;
  };

  const cards = [
    {
      title: "Doctor Visited",
      total: data?.docCalls || 0,
      yesterday: data?.yestReport?.docCalls || 0,
      percentage: getPercentageChange(
        data?.docCalls || 0,
        data?.yestReport?.docCalls || 0
      ),
      Icon: doctorCallSvg,
      bgColor: "bg-blue",
      changeIcon: getChangeIcon(
        data?.docCalls || 0,
        data?.yestReport?.docCalls || 0
      ),
      changeIconColor: getChangeIconColor(
        data?.docCalls || 0,
        data?.yestReport?.docCalls || 0
      ),
    },
    {
      title: "Chemist Visited",
      total: data?.chemCalls || 0,
      yesterday: data?.yestReport?.chemCalls || 0,
      percentage: getPercentageChange(
        data?.chemCalls || 0,
        data?.yestReport?.chemCalls || 0
      ),
      Icon: chemCallsSvg,
      bgColor: "bg-purple",
      changeIcon: getChangeIcon(
        data?.chemCalls || 0,
        data?.yestReport?.chemCalls || 0
      ),
      changeIconColor: getChangeIconColor(
        data?.chemCalls || 0,
        data?.yestReport?.chemCalls || 0
      ),
    },
    {
      title: "Stockist Visited",
      total: data?.stockCalls || 0,
      yesterday: data?.yestReport?.stockCalls || 0,
      percentage: getPercentageChange(
        data?.stockCalls || 0,
        data?.yestReport?.stockCalls || 0
      ),
      Icon: stockCallsSvg,
      bgColor: "bg-purple",
      changeIcon: getChangeIcon(
        data?.stockCalls || 0,
        data?.yestReport?.stockCalls || 0
      ),
      changeIconColor: getChangeIconColor(
        data?.stockCalls || 0,
        data?.yestReport?.stockCalls || 0
      ),
    },
    {
      title: "Total POB",
      total: data?.pobs || 0,
      yesterday: data?.yestReport?.pobs || 0,
      percentage: getPercentageChange(
        data?.pobs || 0,
        data?.yestReport?.pobs || 0
      ),
      Icon: totalPobSvg,
      bgColor: "bg-darkblue",
      changeIcon: getChangeIcon(data?.pobs || 0, data?.yestReport?.pobs || 0),
      changeIconColor: getChangeIconColor(
        data?.pobs || 0,
        data?.yestReport?.pobs || 0
      ),
    },
    {
      title: "Total Samples",
      total: data?.samples || 0,
      yesterday: data?.yestReport?.samples || 0,
      percentage: getPercentageChange(
        data?.samples || 0,
        data?.yestReport?.samples || 0
      ),
      Icon: totalSamplesSvg,
      bgColor: "bg-blue",
      changeIcon: getChangeIcon(
        data?.samples || 0,
        data?.yestReport?.samples || 0
      ),
      changeIconColor: getChangeIconColor(
        data?.samples || 0,
        data?.yestReport?.samples || 0
      ),
    },
    {
      title: "Total Gifts",
      total: data?.gifts || 0,
      yesterday: data?.yestReport?.gifts || 0,
      percentage: getPercentageChange(
        data?.gifts || 0,
        data?.yestReport?.gifts || 0
      ),
      Icon: gifts,
      bgColor: "bg-purple",
      changeIcon: getChangeIcon(data?.gifts || 0, data?.yestReport?.gifts || 0),
      changeIconColor: getChangeIconColor(
        data?.gifts || 0,
        data?.yestReport?.gifts || 0
      ),
    },
  ];

  return (
    <div className="app-container">
      <header className="header1">
        <div className="header-content">
          <div className="date-picker">
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="date-input"
            />
            <button
              type="button"
              className="refresh-button"
              onClick={fetchData}
            >
              Refresh
            </button>

            <p className="note">
              {isCurrentDate
                ? "Note: Today's reports are directly being fetched from the User Portal & can change if the user decides to delete an entry. The numbers will be fixed once the call reports are approved."
                : "Note: Reports are being fetched from approved call reports."}
            </p>
            <button
              type="button"
              disabled={loading}
              onClick={exportToExcel}
              className="button-blue-gradient ms-3 me-2"
              style={{ width: "300px" }}
            >
              {loading ? "processing..." : "Export Excel"}
            </button>
          </div>
        </div>
      </header>
      <main className="main-content">
        <div className={`dashboard ${loading ? "loading" : ""}`}>
          {loading && (
            <div className="loading-animation">
              <div className="loading-spinner"></div>
              <div className="loading-text">Loading...</div>
            </div>
          )}
          {!loading &&
            cards.map((card, index) => <DashboardCard key={index} {...card} />)}
        </div>

        <div className={`user-list ${loading ? "loading" : ""}`}>
          {!loading &&
            usersResponse.map((user, index) => {
              if (!user || !user.user) return null;
              const totalReports =
                (user.chemreports || 0) +
                (user.docreports || 0) +
                (user.stocreports || 0);
              const areaType =
                user.tps.length > 0 && user.tps[0].areaType
                  ? `(${user.tps[0].areaType})`
                  : "";
              return (
                <div key={index} className="user-card">
                  <div className="user-info">
                    <span className="status-indicator"></span>
                    <span className="name">
                      {user.user.firstName} {user.user.lastName}{" "}
                      <span className="area-type">{areaType}</span>
                    </span>
                  </div>
                  <Link
                    to={
                      isCurrentDate
                        ? `/today's-activity`
                        : `/utilities/call-reports`
                    }
                    className="reports-circle"
                  >
                    {totalReports}
                  </Link>
                </div>
              );
            })}
        </div>
      </main>
    </div>
  );
};

export default ConsolidateReport;