import { MdArrowBackIosNew, MdOutlineGroup } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import react, { useMemo } from "react";
import serialise from "../../../../../../../utils/serialiseResponse";
import Table from "../../../../../../../components/Table";
import { FaEye } from "react-icons/fa";

const HeadquarterTarget = ({ employeeDataForExcel, reports, month, year }) => {

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   minWidth: 50,
      //   maxWidth: 50,
      //   disableFilters: true,
      // },
      {
        Header: "Headquarter",
        accessor: "hq[0].name",
        disableSortBy: true,
        showTotal: true,
        placeholderTotal: "Total",
      },
      {
        Header: "Alloted Target",
        accessor: "amount",
        disableFilters: true,
        showTotal: true,
        totalAccessor: "amount"
      },
      // {
      //   Header: "View",
      //   accessor: "details",
      //   disableFilters: true,
      //   disableSortBy: true,
      //   Cell: (props) => {

      //     return (
      //       <Link
      //         to={"../hq-user"}
      //         state={{ data: props?.row?.original?.hq, month: month, year: year }}
      //         className="util-table-view-details-link"
      //       >
      //         {console.log(props)}
      //         <FaEye className="icon-color-tertiary" />
      //       </Link>
      //     );
      //   },
      // },
    ],
    [month, year]
  );
  const tableData = useMemo(() => serialise(reports), [reports]);
  
  return (
    <div className="filter-table">
      {reports.length > 0 && (
        <h2 className="web-app__heading mb-4 ps-3">
          Showing ({reports.length}) Entries
        </h2>
      )}
      {tableData.length > 0 ? (
        <Table columns={columns} data={tableData} fileSrc="Target Report" empl={employeeDataForExcel} month={month} />
      ) : (
        <h3 className="mt-2 no-results-message " style={{ paddingLeft: "5rem", paddingBottom:"4rem" }}>No HQ's results found</h3>
      )}
    </div>
  );
};

export default HeadquarterTarget;