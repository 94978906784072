import { MdArrowBackIosNew, MdShareLocation, MdTimelapse } from "react-icons/md";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  RiCapsuleFill,
  RiGiftFill,
  RiNurseFill,
  RiUser3Fill,
  RiCloseCircleLine
} from "react-icons/ri";
import {
  MdDeleteOutline,
  MdCheckCircleOutline,
  MdDelete,
} from "react-icons/md";
import { formatDate, formatTime } from "../../../../../utils/helper";
import { monthOptions } from "../../../../../utils/helper";
import { useEffect, useState } from "react";
import { viewTPForDate } from "../../../../../services/tourProgram";
import moment from "moment-timezone";
import Accordion from "react-bootstrap/Accordion";
import { deleteCallReport } from "../../../../../services/dcr";
import customToast from "../../../../../components/CustomToast";
import { setCities } from "../../../../../reducers/locations/cityReducer";
import { GoogleMap, Marker, Polyline, useJsApiLoader } from "@react-google-maps/api";
import { viewGeoLocationData } from "../../../../../services/geoFencing";
import DocMarker from "../../../../public/img/DocMarker.png";
import ChemMarker from "../../../../public/img/ChemMarker.png";
import StkMarker from "../../../../public/img/StockMarker.png";
import { FaStar } from "react-icons/fa";
import { LuTimerReset } from "react-icons/lu";
import { viewPunchingReportDetails } from "../../../../../services/utilities";

const JointCRDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const loggedIn = useSelector(({ user }) => user);
  const cities = useSelector(({ city }) => city);

  const [tpData, setTpData] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const images = [];
  const [geoLocReports, setGeoLocReports] = useState([]);
  const [workingHours, setWorkingHours] = useState([]);
  const [userIdsArray, setUserIdsArray] = useState([]);
  const [allGeoLocData, setAllGeoLocData] = useState([]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_API_KEY
  });

  const containerStyle = {
    width: '100%',
    height: '300px',
    position: 'relative'
  };

  useEffect(() => {
    
    let setId = new Set();
    let data = location?.state?.taggedReport;

      if(data){
        data?.doctors?.map((item) =>{
          setId.add(item?.submitter?._id);
        });
  
        data?.stockists?.map((item) =>{
          setId.add(item?.submitter?._id);
        })
  
        data?.chemists?.map((item) =>{
          setId.add(item?.submitter?._id);
        });
      }

      setUserIdsArray([...setId]);

  },[location]) 

  
  useEffect(() => {
    const fetchData = async() => { 
      try {
        
        const {month, year, date} = location?.state?.taggedReport;

        const payloads = userIdsArray?.map(userId => ({
          month,
          year,
          date,
          userId
        }));

        const requests = payloads?.map(item => viewGeoLocationData(item));
        const responses = await Promise.all(requests);

        const data = responses?.map(res => res?.data || []);

        setAllGeoLocData(data?.flat());

      } catch (error) {
        customToast.error("Error occurred !");
        console.log(error);
      }
    };

    fetchData();
  },[location, userIdsArray]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        let payload = {
          month: location?.state?.month,
          year: location?.state?.year,
          userId: location?.state?.submitter?._id,
          date: location?.state?.date
        };

        let res = await viewGeoLocationData(payload);
        setGeoLocReports(res?.data);
        let data = await viewPunchingReportDetails({date: location?.state?.date, selectedUser: location?.state?.submitter?._id})
                        setWorkingHours(data?.data)
      } catch (error) {
        console.log(error)
      }
    };
    if(location?.state?.submitter?._id){
      fetchData();
    }
  }, []);

  const ViewMap = ({ longitude, latitude, latitude2, longitude2, type }) => {

    return isLoaded ? (
      <div style={{ width: '100%', height: '300px' }}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={{ lat: latitude, lng: longitude }}
          zoom={16}
        >
          <Marker position={{ lat: latitude, lng: longitude }} />

          {latitude && longitude && latitude2 && longitude2 && (
            <div>
              <Marker
                position={{ lat: latitude2, lng: longitude2 }}
                icon={{
                  url: type === "doc" ? DocMarker : type === 'stk' ? StkMarker : ChemMarker,
                  scaledSize: { width: 30, height: 40 }
                }}
              />
              <Polyline
                path={[{ lat: latitude, lng: longitude }, { lat: latitude2, lng: longitude2 }]}
                options={{ strokeColor: "red", strokeOpacity: 1.0, strokeWeight: 2 }} />
            </div>
          )}

        </GoogleMap>
      </div>
    ) : null;
  }

  useEffect(() => {
    dispatch(setCities());
  }, [dispatch]);


  useEffect(() => {
    if (!location?.state?.deviated) {
      let formattedDate = moment
        .tz(location?.state?.date, "Asia/Kolkata")
        .format()
        .toString()
        .substring(0, 10);
      const asyncFetch = async () => {
        await viewTPForDate({ date: formattedDate, selectedUser: location.state?.selectedUser }).then((res) => {
          setTpData(res.data);
        });
      };
      asyncFetch();
    }
  }, [location]);

  const EditPopup = () => {

    const handleDelete = (e) => {
      e.preventDefault();
      deleteCallReport(location?.state?._id).then((data) => {
        customToast.success("Deleted successfully")
        navigate(-1);
        setShowEdit(false);
      }).catch((err) => {
        customToast.error("We encountered an error")
      })


    };

    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete Call Report</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form onSubmit={handleDelete}>
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  Caution! This call report will be permanently deleted.
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button type="submit" className="button-delete mt-4">
                <span className="button-delete__icon-container">
                  <MdDeleteOutline className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Yes</span>
              </button>

              <button
                type="button"
                className="button-submit-green mt-4"
                onClick={() => setShowEdit(false)}
              >
                <span className="button-submit-green__icon-container">
                  <MdCheckCircleOutline className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">No</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  const cityName = (target) => {
    const citi = cities?.data?.find((ele) => ele._id === target?.workingArea);
    return citi?.name;
  }

  return (
    <div className="utility-inner-tp">
      <div className="row">
        <h2
          className="web-app__heading"
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
        >
          <div>
            <MdArrowBackIosNew
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <span style={{ display: "inline-block", margin: "0 1rem" }}>
              Call Details
            </span>
          </div>

          {/* <button>delete</button> */}
          {loggedIn?.user?.isAdmin ? <span
            className="react-table-view-link"
            onClick={() => {
              setShowEdit(true);
            }}
          >
            <MdDelete className="icon-color-error" />
          </span> : null}
        </h2>

        <div className="util-inner-details">
          <div className="util-inner-info-box-container">
            <div className="util-inner-info-box">
              <h3>Date</h3>
              {location?.state?.taggedReport?.date ? 
               <p>{formatDate(location?.state?.taggedReport?.date)}</p> 
              :
              <p>{formatDate(location?.state?.date)}</p> 
            }
              {/* <p>{formatDate(location?.state?.date)}</p> */}
            </div>

            <div className="util-inner-info-box">
              <h3>Month</h3>
              {location?.state?.taggedReport?.date ? 
               <p>
               {
                 monthOptions[new Date(`${location?.state?.taggedReport?.date}`).getMonth()]
                   ?.label
               }
             </p>
              :
              <p>
                {
                  monthOptions[new Date(`${location?.state?.date}`).getMonth()]
                    ?.label
                }
              </p>
            }
              
            </div>

            <div className="util-inner-info-box">
              <h3>Activity Type</h3>
              {location?.state?.taggedReport ? 
               <p>{location?.state?.taggedReport?.activity?.charAt(0).toUpperCase() + location?.state?.taggedReport.activity?.slice(1)}</p>
              :
              <p>{location?.state?.activity?.charAt(0).toUpperCase() + location?.state?.activity?.slice(1)}</p> 
            }
              
            </div>

            <div className="util-inner-info-box">
              <h3>Area Type</h3>
              {location?.state?.taggedReport ? 
               <p>
               {location?.state?.taggedReport?.deviated
                 ? tpData?.areaType : location?.state?.taggedReport?.areaType}
             </p> 
              :
              <p>
                {location?.state?.deviated
                  ? tpData?.areaType : location?.state?.areaType}
              </p>
            }
              
            </div>
          </div>
        </div>
        <div>
          {location?.state?.activity !== "working" ? (
            <div className="util-inner-info-box mt-5">
              <h3>Remarks</h3>
              {location?.state?.taggedReport?.remarks ? 
               <p>{location?.state?.taggedReport?.remarks}</p>
              :
              <p>{location?.state?.remarks}</p>
            }
              
            </div>
          ) : null}
        </div>

        <div className="util-inner-location-details">
          {location?.state?.deviated
            ? location?.state?.workAreas?.map((el, index) => (
              <div className="util-inner-location-box" key={index}>
                <MdShareLocation className="util-inner-location-box-icon" />
                <p>{el?.location?.label}</p>
              </div>
            ))
            : tpData?.workAreas?.map((el, index) => (
              <div className="util-inner-location-box" key={index}>
                <MdShareLocation className="util-inner-location-box-icon" />
                <p>{el?.location?.label}</p>
              </div>
            ))}
        </div>

        <div style={{ display: "flex", gap: "50px" }}>
          <div className="w-50" >
            
          <div className="doctor-call-div">
              <h2 className="web-app__heading mt-5">
                {workingHours?.length > 0 ? "Working Hours" : null}
              </h2>

              <Accordion className="mt-4" alwaysOpen>
                      <Accordion.Header>
                        {" "}
                        <span className="d-flex align-items-center">
                          {<LuTimerReset className="me-3 fs-2" />} Working Hours
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        {workingHours?.map((elem, i) => (
                          <div key={i} className="acc-body-card d-flex mt-4">
                            <MdTimelapse className="acc-body-card-icon acc-i-green" />
                            <div className="acc-body-card-content d-flex align-items-center px-4">
                              {formatTime(elem?.checkInTime)} - {formatTime(elem?.checkOutTime)}
                            </div>
                          </div>
                        ))}
                      </Accordion.Body>                
              </Accordion>
            </div>

            <div className="doctor-call-div">
              <h1 className="web-app__heading-blue2 mt-5">Single Calls</h1>
              <h2 className="web-app__heading mt-5">
                {location?.state?.doctors?.length > 0 ? "Doctor Calls" : null}
              </h2>

              <Accordion className="mt-4" alwaysOpen>
                {location?.state?.doctors?.length > 0 && location?.state?.doctors?.map((e, i) => (
                  <Accordion.Item key={e._id} eventKey={i} className="mb-4">
                    <Accordion.Header>
                      {" "}
                      <span className="d-flex align-items-start flex-column">
                        <span>{<RiNurseFill className="me-3 fs-2" />} {e?.target?.name}</span>
                        <div style={{ fontSize: "1.5rem" }} className="password-icon-show">{cityName(e?.target)}</div>

                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <h5 className="web-app__heading-blue">
                        {e?.productsDetailed?.length > 0 ? "PRODUCTS DETAILED" : null}
                      </h5>
                      {e?.productsDetailed?.length > 0 && e?.productsDetailed.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiCapsuleFill className="acc-body-card-icon acc-i-blue" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.name}
                          </div>
                        </div>
                      ))}

                      <h5 className="web-app__heading-blue mt-5">
                        {e?.products?.length > 0 ? "POB DETAILS" : null}
                      </h5>
                      {e?.products?.length > 0 && e?.products.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiCapsuleFill className="acc-body-card-icon acc-i-green" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.productName} ---{" "}
                            {elem.quantity ? elem.quantity : 0} --- ₹
                            {elem.amount ? elem.amount : 0} | Sample:{" "}
                            {elem.sampleQuantity ? elem.sampleQuantity : 0}
                          </div>
                        </div>
                      ))}

                      <h5 className="web-app__heading-blue mt-5">
                        {e?.gifts?.length > 0 ? "Gift Details" : null}
                      </h5>
                      {e?.gifts?.length > 0 && e?.gifts?.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiGiftFill className="acc-body-card-icon acc-i-yellow" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.giftName} ---
                            {elem.giftQuantity ? elem.giftQuantity : 0} ---- ₹
                            {elem.giftAmount ? elem.giftAmount : 0}
                          </div>
                        </div>
                      ))}

                      <h5 className="web-app__heading-blue mt-5">
                        {e?.workedWith?.length > 0 ? "Worked With" : null}
                      </h5>
                      {e?.workedWith?.length > 0 && e?.workedWith.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiUser3Fill className="acc-body-card-icon acc-i-light-yellow" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.firstName} {elem?.lastName}
                            {/* ({elem?.designation?.name}) */}
                          </div>
                        </div>
                      ))}

                      {e.remarks ? (
                        <>
                          <h5 className="web-app__heading-blue mt-5">Remarks</h5>
                          <h3 className="acc-body-remarks">{e?.remarks}</h3>
                        </>
                      ) : null}


                      <h5 className="web-app__heading-blue mt-5">Report Time</h5>
                      <h3 className="acc-body-remarks">{formatTime(e?.cdt)}</h3>

                      {geoLocReports && geoLocReports?.length > 0 && (() => {
                        const element = geoLocReports?.find((item) => item?.target?.value === e?.target?._id);
                        return element ?
                          <div>
                            <h5 className="web-app__heading-blue mt-5">Geo-tagging</h5>
                            <ViewMap latitude={element?.geolocation?.latitude} longitude={element?.geolocation?.longitude} latitude2={e?.target?.geolocation?.latitude} longitude2={e?.target?.geolocation?.longitude} type={e?.type} />
                          </div>
                          :
                          null
                      })
                        ()
                      }
                      {e?.rating ? (
                        <>
                          <h5 className="web-app__heading-blue mt-5">Rating</h5>
                          <h3 className="acc-body-remarks">
                            {Array.from({ length: e.rating }, (_, i) => (
                              <FaStar key={i} className="icon-rating" />
                            ))}
                          </h3>
                        </>
                      ) : null}

                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <div className="chemist-call-div">
              <h2 className="web-app__heading report-heading mt-5">
                {location?.state?.chemists?.length > 0 ? "Chemist Calls" : null}
              </h2>
              <Accordion className="mt-4" alwaysOpen>
                {location?.state?.chemists?.length > 0 && location?.state?.chemists?.map((e, i) => (

                  <Accordion.Item key={e._id} eventKey={i} className="mb-4">
                    <Accordion.Header>
                      {" "}
                      <span className="d-flex align-items-start flex-column">
                        <span>{<RiNurseFill className="me-3 fs-2" />} {e?.target?.businessName}</span>
                        <div style={{ fontSize: "1.5rem" }} className="password-icon-show">{cityName(e?.target)}</div>
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <h5 className="web-app__heading-blue">
                        {e?.productsDetailed?.length > 0 ? "PRODUCTS DETAILED" : null}
                      </h5>
                      {e?.productsDetailed?.length > 0 && e?.productsDetailed.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiCapsuleFill className="acc-body-card-icon acc-i-blue" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.name}
                          </div>
                        </div>
                      ))}

                      <h5 className="web-app__heading-blue mt-5">
                        {e?.products?.length > 0 ? "POB DETAILS" : null}
                      </h5>
                      {e?.products?.length > 0 && e?.products?.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiCapsuleFill className="acc-body-card-icon acc-i-green" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.productName} ---{" "}
                            {elem.quantity ? elem.quantity : 0} --- ₹
                            {elem.amount ? elem.amount : 0} | Sample:{" "}
                            {elem.sampleQuantity ? elem.sampleQuantity : 0}
                          </div>
                        </div>
                      ))}

                      <h5 className="web-app__heading-blue mt-5">
                        {e?.gifts?.length > 0 ? "Gift Details" : null}
                      </h5>
                      {e?.gifts?.length > 0 && e?.gifts.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiGiftFill className="acc-body-card-icon acc-i-yellow" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.giftName} ---
                            {elem.giftQuantity ? elem.giftQuantity : 0} ---- ₹
                            {elem.giftAmount ? elem.giftAmount : 0}
                          </div>
                        </div>
                      ))}

                      <h5 className="web-app__heading-blue mt-5">
                        {e?.workedWith?.length > 0 ? "Worked With" : null}
                      </h5>
                      {e?.workedWith?.length > 0 && e?.workedWith?.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiUser3Fill className="acc-body-card-icon acc-i-light-yellow" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.firstName} {elem?.lastName}
                            {/* ({elem?.designation?.name}) */}
                          </div>
                        </div>
                      ))}

                      {e.remarks ? (
                        <>
                          <h5 className="web-app__heading-blue mt-5">Remarks</h5>
                          <h3 className="acc-body-remarks">{e?.remarks}</h3>
                        </>
                      ) : null}

                      <h5 className="web-app__heading-blue mt-5">Report Time</h5>
                      <h3 className="acc-body-remarks">{formatTime(e?.cdt)}</h3>

                      {geoLocReports && geoLocReports?.length > 0 && (() => {
                        const element = geoLocReports?.find((item) => item?.target?.value === e?.target?._id);
                        return element ?
                          <div>
                            <h5 className="web-app__heading-blue mt-5">Geo-tagging</h5>
                            <ViewMap latitude={element?.geolocation?.latitude} longitude={element?.geolocation?.longitude} latitude2={e?.target?.geolocation?.latitude} longitude2={e?.target?.geolocation?.longitude} type={e?.type} />
                          </div>
                          :
                          null
                      })
                        ()
                      }
                       {e?.rating ? (
                        <>
                          <h5 className="web-app__heading-blue mt-5">Rating</h5>
                          <h3 className="acc-body-remarks">
                            {Array.from({ length: e.rating }, (_, i) => (
                              <FaStar key={i} className="icon-rating" />
                            ))}
                          </h3>
                        </>
                      ) : null}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <div className="stockist-call-div">
              <h2 className="web-app__heading mt-5">
                {location?.state?.stockists?.length > 0 ? "Stockist Calls" : null}
              </h2>
              <Accordion className="mt-4" alwaysOpen>
                {location?.state?.stockists?.length > 0 && location?.state?.stockists?.map((e, i) => (
                  <Accordion.Item key={e._id} eventKey={i} className="mb-4">
                    <Accordion.Header>
                      {" "}
                      <span className="d-flex align-items-start flex-column">
                        <span>{<RiNurseFill className="me-3 fs-2" />} {e?.target?.businessName}</span>
                        <div style={{ fontSize: "1.5rem" }} className="password-icon-show">{cityName(e?.target)}</div>

                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <h5 className="web-app__heading-blue">
                        {e?.productsDetailed?.length > 0 ? "PRODUCTS DETAILED" : null}
                      </h5>
                      {e?.productsDetailed?.length > 0 && e?.productsDetailed.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiCapsuleFill className="acc-body-card-icon acc-i-blue" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.name}
                          </div>
                        </div>
                      ))}

                      <h5 className="web-app__heading-blue mt-5">
                        {e?.products?.length > 0 ? "POB DETAILS" : null}
                      </h5>
                      {e?.products?.length > 0 && e?.products?.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiCapsuleFill className="acc-body-card-icon acc-i-green" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.productName} ---{" "}
                            {elem.quantity ? elem.quantity : 0} --- ₹
                            {elem.amount ? elem.amount : 0} | Sample:{" "}
                            {elem.sampleQuantity ? elem.sampleQuantity : 0}
                          </div>
                        </div>
                      ))}

                      <h5 className="web-app__heading-blue mt-5">
                        {e?.gifts?.length > 0 ? "Gift Details" : null}
                      </h5>
                      {e?.gifts?.length > 0 && e?.gifts?.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiGiftFill className="acc-body-card-icon acc-i-yellow" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.giftName} ---
                            {elem.giftQuantity ? elem.giftQuantity : 0} ---- ₹
                            {elem.giftAmount ? elem.giftAmount : 0}
                          </div>
                        </div>
                      ))}

                      <h5 className="web-app__heading-blue mt-5">
                        {e?.workedWith?.length > 0 ? "Worked With" : null}
                      </h5>
                      {e?.workedWith?.length > 0 && e?.workedWith?.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiUser3Fill className="acc-body-card-icon acc-i-light-yellow" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.firstName} {elem?.lastName}
                            {/* ({elem?.designation?.name}) */}
                          </div>
                        </div>
                      ))}

                      {e.remarks ? (
                        <>
                          <h5 className="web-app__heading-blue mt-5">Remarks</h5>
                          <h3 className="acc-body-remarks">{e?.remarks}</h3>
                        </>
                      ) : null}

                      <h5 className="web-app__heading-blue mt-5">Report Time</h5>
                      <h3 className="acc-body-remarks">{formatTime(e?.cdt)}</h3>

                      {geoLocReports && geoLocReports?.length > 0 && (() => {
                        const element = geoLocReports?.find((item) => item?.target?.value === e?.target?._id);
                        return element ?
                          <div>
                            <h5 className="web-app__heading-blue mt-5">Geo-tagging</h5>
                            <ViewMap latitude={element?.geolocation?.latitude} longitude={element?.geolocation?.longitude} latitude2={e?.target?.geolocation?.latitude} longitude2={e?.target?.geolocation?.longitude} type={e?.type} />
                          </div>
                          :
                          null
                      })
                        ()
                      }
                       {e?.rating ? (
                        <>
                          <h5 className="web-app__heading-blue mt-5">Rating</h5>
                          <h3 className="acc-body-remarks">
                            {Array.from({ length: e.rating }, (_, i) => (
                              <FaStar key={i} className="icon-rating" />
                            ))}
                          </h3>
                        </>
                      ) : null}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>

          {location?.state?.taggedReport && (

            <div className="w-50" >
              <div className="doctor-call-div">
                <h2 className="web-app__heading-blue2 mt-5">Joint Calls</h2>
                <h2 className="web-app__heading mt-5">
                  {location?.state?.taggedReport?.doctors.length > 0 ? "Doctor Calls" : null}
                </h2>

                <Accordion className="mt-4" alwaysOpen>
                  {location?.state?.taggedReport?.doctors?.length > 0 && location?.state?.taggedReport?.doctors?.map((e, i) => (
                      <Accordion.Item key={e._id} eventKey={i} className="mb-4">
                            <Accordion.Header>
                              {" "}
                              <span className="d-flex justify-content-between flex-column  w-100">
                                <div className="d-flex justify-content-between">
                                  <span className="me-3 fs-2">
                                    {<RiNurseFill />} {e?.target?.name}
                                  </span>
                                  <div style={{ fontSize: "1rem" }} className="me-2">
                                    {`${e?.submitter?.firstName} ${e?.submitter?.lastName}`}
                                    <div>
                                      {e?.workAreas?.length > 0 && e.workAreas?.map((loc) => {
                                        return <span>
                                        {`${loc?.location?.label} `}
                                        <span style={{color:"green"}}>
                                        {`(${e?.areaType})`}
                                          </span>  
                                      </span>
                                      })}
                                    </div>
                                  </div>

                                </div>
                              </span>


                            </Accordion.Header>
                            <Accordion.Body>
                                <h5 className="web-app__heading-blue">
                                  {e?.productsDetailed?.length > 0 ? "PRODUCTS DETAILED" : null}
                                </h5>
                                {e?.productsDetailed?.length > 0 && e?.productsDetailed.map((elem, i) => (
                                  <div key={i} className="acc-body-card d-flex mt-4">
                                    <RiCapsuleFill className="acc-body-card-icon acc-i-blue" />
                                    <div className="acc-body-card-content d-flex align-items-center px-4">
                                      {elem?.name}
                                    </div>
                                  </div>
                                ))}

                              <h5 className="web-app__heading-blue mt-5">
                                {e?.products?.length > 0 ? "POB DETAILS" : null}
                              </h5>
                              {e?.products?.length > 0 && e?.products.map((elem, i) => (
                                <div key={i} className="acc-body-card d-flex mt-4">
                                  <RiCapsuleFill className="acc-body-card-icon acc-i-green" />
                                  <div className="acc-body-card-content d-flex align-items-center px-4">
                                    {elem?.productName} ---{" "}
                                    {elem.quantity ? elem.quantity : 0} --- ₹
                                    {elem.amount ? elem.amount : 0} | Sample:{" "}
                                    {elem.sampleQuantity ? elem.sampleQuantity : 0}
                                  </div>
                                </div>
                              ))}

                              <h5 className="web-app__heading-blue mt-5">
                                {e?.gifts?.length > 0 ? "Gift Details" : null}
                              </h5>
                              {e?.gifts?.length > 0 && e?.gifts?.map((elem, i) => (
                                <div key={i} className="acc-body-card d-flex mt-4">
                                  <RiGiftFill className="acc-body-card-icon acc-i-yellow" />
                                  <div className="acc-body-card-content d-flex align-items-center px-4">
                                    {elem?.giftName} ---
                                    {elem.giftQuantity ? elem.giftQuantity : 0} ---- ₹
                                    {elem.giftAmount ? elem.giftAmount : 0}
                                  </div>
                                </div>
                              ))}

                              <h5 className="web-app__heading-blue mt-5">
                                {e?.workedWith?.length > 0 ? "Worked With" : null}
                              </h5>
                              {e?.workedWith?.length > 0 && e?.workedWith.map((elem, i) => (
                                <div key={i} className="acc-body-card d-flex mt-4">
                                  <RiUser3Fill className="acc-body-card-icon acc-i-light-yellow" />
                                  <div className="acc-body-card-content d-flex align-items-center px-4">
                                    {elem?.firstName} {elem?.lastName}
                                    {/* ({elem?.designation?.name}) */}
                                  </div>
                                </div>
                              ))}

                              {e.remarks ? (
                                <>
                                  <h5 className="web-app__heading-blue mt-5">Remarks</h5>
                                  <h3 className="acc-body-remarks">{e?.remarks}</h3>
                                </>
                              ) : null}


                              <h5 className="web-app__heading-blue mt-5">Report Time</h5>
                              <h3 className="acc-body-remarks">{formatTime(e?.cdt)}</h3>

                              {allGeoLocData && allGeoLocData?.length > 0 && (() => {
                                const element = allGeoLocData?.find((item) => item?.target?.value === e?.target?._id);
                                return element ?
                                  <div>
                                    <h5 className="web-app__heading-blue mt-5">Geo-tagging</h5>
                                    <ViewMap latitude={element?.geolocation?.latitude} longitude={element?.geolocation?.longitude} latitude2={e?.target?.geolocation?.latitude} longitude2={e?.target?.geolocation?.longitude} type={e?.type} />
                                  </div>
                                  :
                                  null
                              })
                                ()
                              }
                        {e?.rating ? (
                          <>
                            <h5 className="web-app__heading-blue mt-5">Rating</h5>
                            <h3 className="acc-body-remarks">
                              {Array.from({ length: e.rating }, (_, i) => (
                                <FaStar key={i} className="icon-rating" />
                              ))}
                            </h3>
                          </>
                        ) : null}

                            </Accordion.Body>
                          </Accordion.Item>
                      ))}
                    </Accordion>
              </div>
              <div className="chemist-call-div">
                <h2 className="web-app__heading report-heading mt-5">
                  {location?.state?.taggedReport?.chemists?.length > 0 ? "Chemist Calls" : null}
                </h2>
                <Accordion className="mt-4" alwaysOpen>
                  {location?.state?.taggedReport?.chemists?.length > 0 && location?.state?.taggedReport?.chemists?.map((e, i) => (
                      <Accordion.Item key={e._id} eventKey={i} className="mb-4">
                            <Accordion.Header>
                              {" "}
                              <span className="d-flex justify-content-between flex-column  w-100">
                                <div className="d-flex justify-content-between">
                                  <span className="me-3 fs-2">
                                    {<RiNurseFill />} {e?.target?.businessName}
                                  </span>
                                  <div style={{ fontSize: "1rem" }} className="me-2">
                                    {`${e?.submitter?.firstName} ${e?.submitter?.lastName}`}
                                    <div>
                                      {e?.workAreas?.length > 0 && e?.workAreas?.map((loc) => {
                                        return <span>
                                          {`${loc?.location?.label}`}
                                          <span style={{ color: "green" }}>
                                            {`(${e?.areaType})`}
                                          </span>
                                        </span>
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </span>
                            </Accordion.Header>
                            <Accordion.Body>
                              <h5 className="web-app__heading-blue">
                                {e?.productsDetailed?.length > 0 ? "PRODUCTS DETAILED" : null}
                              </h5>
                              {e?.productsDetailed?.length > 0 && e?.productsDetailed.map((elem, i) => (
                                <div key={i} className="acc-body-card d-flex mt-4">
                                  <RiCapsuleFill className="acc-body-card-icon acc-i-blue" />
                                  <div className="acc-body-card-content d-flex align-items-center px-4">
                                    {elem?.name}
                                  </div>
                                </div>
                              ))}

                              <h5 className="web-app__heading-blue mt-5">
                                {e?.products?.length > 0 ? "POB DETAILS" : null}
                              </h5>
                              {e?.products?.length > 0 && e?.products?.map((elem, i) => (
                                <div key={i} className="acc-body-card d-flex mt-4">
                                  <RiCapsuleFill className="acc-body-card-icon acc-i-green" />
                                  <div className="acc-body-card-content d-flex align-items-center px-4">
                                    {elem?.productName} ---{" "}
                                    {elem.quantity ? elem.quantity : 0} --- ₹
                                    {elem.amount ? elem.amount : 0} | Sample:{" "}
                                    {elem.sampleQuantity ? elem.sampleQuantity : 0}
                                  </div>
                                </div>
                              ))}

                              <h5 className="web-app__heading-blue mt-5">
                                {e?.gifts?.length > 0 ? "Gift Details" : null}
                              </h5>
                              {e?.gifts?.length > 0 && e?.gifts.map((elem, i) => (
                                <div key={i} className="acc-body-card d-flex mt-4">
                                  <RiGiftFill className="acc-body-card-icon acc-i-yellow" />
                                  <div className="acc-body-card-content d-flex align-items-center px-4">
                                    {elem?.giftName} ---
                                    {elem.giftQuantity ? elem.giftQuantity : 0} ---- ₹
                                    {elem.giftAmount ? elem.giftAmount : 0}
                                  </div>
                                </div>
                              ))}

                              <h5 className="web-app__heading-blue mt-5">
                                {e?.workedWith?.length > 0 ? "Worked With" : null}
                              </h5>
                              {e?.workedWith?.length > 0 && e?.workedWith?.map((elem, i) => (
                                <div key={i} className="acc-body-card d-flex mt-4">
                                  <RiUser3Fill className="acc-body-card-icon acc-i-light-yellow" />
                                  <div className="acc-body-card-content d-flex align-items-center px-4">
                                    {elem?.firstName} {elem?.lastName}
                                    {/* ({elem?.designation?.name}) */}
                                  </div>
                                </div>
                              ))}

                              {e.remarks ? (
                                <>
                                  <h5 className="web-app__heading-blue mt-5">Remarks</h5>
                                  <h3 className="acc-body-remarks">{e?.remarks}</h3>
                                </>
                              ) : null}

                              <h5 className="web-app__heading-blue mt-5">Report Time</h5>
                              <h3 className="acc-body-remarks">{formatTime(e?.cdt)}</h3>

                              {allGeoLocData && allGeoLocData?.length > 0 && (() => {
                                const element = allGeoLocData?.find((item) => item?.target?.value === e?.target?._id);
                                return element ?
                                  <div>
                                    <h5 className="web-app__heading-blue mt-5">Geo-tagging</h5>
                                    <ViewMap latitude={element?.geolocation?.latitude} longitude={element?.geolocation?.longitude} latitude2={e?.target?.geolocation?.latitude} longitude2={e?.target?.geolocation?.longitude} type={e?.type} />
                                  </div>
                                  :
                                  null
                              })
                                ()
                              }
                        {e?.rating ? (
                          <>
                            <h5 className="web-app__heading-blue mt-5">Rating</h5>
                            <h3 className="acc-body-remarks">
                              {Array.from({ length: e.rating }, (_, i) => (
                                <FaStar key={i} className="icon-rating" />
                              ))}
                            </h3>
                          </>
                        ) : null}
                            </Accordion.Body>
                      </Accordion.Item>
                  ))}
                </Accordion>
              </div>
              <div className="stockist-call-div">
                <h2 className="web-app__heading mt-5">
                  {location?.state?.taggedReport?.stockists?.length > 0 ? "Stockist Calls" : null}
                </h2>
                <Accordion className="mt-4" alwaysOpen>
                  {location?.state?.taggedReport?.stockists?.length > 0 && location?.state?.taggedReport?.stockists?.map((e, i) => (
                    <Accordion.Item key={e._id} eventKey={i} className="mb-4">
                            <Accordion.Header>
                              {" "}
                              <span className="d-flex justify-content-between flex-column  w-100">
                                <div className="d-flex justify-content-between">
                                  <span className="me-3 fs-2">
                                    {<RiNurseFill />}{e?.target?.businessName}
                                  </span>
                                  <div style={{ fontSize: "1rem" }} className="me-2">
                                    {`${e?.submitter?.firstName} ${e?.submitter?.lastName}`}
                                    <div>
                                      {e?.workAreas?.length > 0 && e?.workAreas?.map((loc) => {
                                        return <span>
                                          {`${loc?.location?.label}`}
                                          <span style={{ color: "green" }}>
                                            {`(${e?.areaType})`}
                                          </span>
                                        </span>
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </span>
                            </Accordion.Header>
                            <Accordion.Body>
                              <h5 className="web-app__heading-blue">
                                {e?.productsDetailed?.length > 0 ? "PRODUCTS DETAILED" : null}
                              </h5>
                              {e?.productsDetailed?.length > 0 && e?.productsDetailed.map((elem, i) => (
                                <div key={i} className="acc-body-card d-flex mt-4">
                                  <RiCapsuleFill className="acc-body-card-icon acc-i-blue" />
                                  <div className="acc-body-card-content d-flex align-items-center px-4">
                                    {elem?.name}
                                  </div>
                                </div>
                              ))}
                                                  
                              <h5 className="web-app__heading-blue mt-5">
                                {e?.products?.length > 0 ? "POB DETAILS" : null}
                              </h5>
                              {e?.products?.length > 0 && e?.products?.map((elem, i) => (
                                <div key={i} className="acc-body-card d-flex mt-4">
                                  <RiCapsuleFill className="acc-body-card-icon acc-i-green" />
                                  <div className="acc-body-card-content d-flex align-items-center px-4">
                                    {elem?.productName} ---{" "}
                                    {elem.quantity ? elem.quantity : 0} --- ₹
                                    {elem.amount ? elem.amount : 0} | Sample:{" "}
                                    {elem.sampleQuantity ? elem.sampleQuantity : 0}
                                  </div>
                                </div>
                              ))}

                              <h5 className="web-app__heading-blue mt-5">
                                {e?.gifts?.length > 0 ? "Gift Details" : null}
                              </h5>
                              {e?.gifts?.length > 0 && e?.gifts?.map((elem, i) => (
                                <div key={i} className="acc-body-card d-flex mt-4">
                                  <RiGiftFill className="acc-body-card-icon acc-i-yellow" />
                                  <div className="acc-body-card-content d-flex align-items-center px-4">
                                    {elem?.giftName} ---
                                    {elem.giftQuantity ? elem.giftQuantity : 0} ---- ₹
                                    {elem.giftAmount ? elem.giftAmount : 0}
                                  </div>
                                </div>
                              ))}

                              <h5 className="web-app__heading-blue mt-5">
                                {e?.workedWith?.length > 0 ? "Worked With" : null}
                              </h5>
                              {e?.workedWith?.length > 0 && e?.workedWith?.map((elem, i) => (
                                <div key={i} className="acc-body-card d-flex mt-4">
                                  <RiUser3Fill className="acc-body-card-icon acc-i-light-yellow" />
                                  <div className="acc-body-card-content d-flex align-items-center px-4">
                                    {elem?.firstName} {elem?.lastName}
                                    {/* ({elem?.designation?.name}) */}
                                  </div>
                                </div>
                              ))}

                              {e.remarks ? (
                                <>
                                  <h5 className="web-app__heading-blue mt-5">Remarks</h5>
                                  <h3 className="acc-body-remarks">{e?.remarks}</h3>
                                </>
                              ) : null}

                              <h5 className="web-app__heading-blue mt-5">Report Time</h5>
                              <h3 className="acc-body-remarks">{formatTime(e?.cdt)}</h3>

                              {/* {geolocationWorkedWith && geolocationWorkedWith?.length > 0 && (() => {
                                const element = geolocationWorkedWith?.find((item) => item?.target?.value === e?.target?._id);
                                return element ?
                                  <div>
                                    <h5 className="web-app__heading-blue mt-5">Geo-tagging</h5>
                                    <ViewMap latitude={element?.geolocation?.latitude} longitude={element?.geolocation?.longitude} latitude2={e?.target?.geolocation?.latitude} longitude2={e?.target?.geolocation?.longitude} type={e?.type} />
                                  </div>
                                  :
                                  null
                              })
                                ()
                              } */}
                              {allGeoLocData && allGeoLocData?.length > 0 && (() => {
                                const element = allGeoLocData?.find((item) => item?.target?.value === e?.target?._id);
                                return element ?
                                  <div>
                                    <h5 className="web-app__heading-blue mt-5">Geo-tagging</h5>
                                    <ViewMap latitude={element?.geolocation?.latitude} longitude={element?.geolocation?.longitude} latitude2={e?.target?.geolocation?.latitude} longitude2={e?.target?.geolocation?.longitude} type={e?.type} />
                                  </div>
                                  :
                                  null
                              })
                                ()
                              }
                        {e?.rating ? (
                          <>
                            <h5 className="web-app__heading-blue mt-5">Rating</h5>
                            <h3 className="acc-body-remarks">
                              {Array.from({ length: e.rating }, (_, i) => (
                                <FaStar key={i} className="icon-rating" />
                              ))}
                            </h3>
                          </>
                        ) : null}
                            </Accordion.Body>
                          </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </div>
          )}

        </div>
        {showEdit ? <EditPopup /> : null}
      </div>
    </div>
  );
};

export default JointCRDetails;
