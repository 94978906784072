import React, { useEffect, useMemo, useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import customToast from "../../../components/CustomToast";
import Table from "../../../components/Table";
import { FaEye } from "react-icons/fa";
import serialise from "../../../utils/serialiseResponse";
import {
  clearDesignations,
  setDesignations,
} from "../../../reducers/users/designationReducer";
import { viewUserCount } from "../../../services/users";
import { createPayment, verifyPayment, viewBill, getInvoices } from "../../../services/payment";
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import PdfDocument from "../../../components/PDFDownloader";
import { monthOptions, formatDate } from "../../../utils/helper";
import { getCompanyName } from "../../../services/settings";
const Billing = () => {
  const dispatch = useDispatch();
  const designationData = useSelector(({ designation }) => designation);
  const navigate = useNavigate();
  
  const [usersCount, setUsersCount] = useState([]);
  const [bill , setBill] = useState({});
  const [invoices, setInvoices] = useState([]) ;
  const [companyName, setCompName] = useState({}) ;


  useEffect(() => {
    getCompanyName().then(({data}) => {
      setCompName(data.res);
    })
    viewBill({month : new Date().getMonth() , year : new Date().getFullYear()}).then(({data})=>{
      setBill(data);
      getInvoices({year : new Date().getFullYear()}).then(({data})=>{
        setInvoices(data);
      }).catch((err)=>{
        customToast.error("Failed to fetch Invoices.");
      })
    }).catch((err)=>{
      customToast.error("An error occured please try again");
    })
    viewUserCount().then(({data})=>{
      setUsersCount(data);
    }).catch(err =>{
      customToast.error("err occured please try again");
    })
    
    return ()=>{
      setUsersCount([]);
    }
  }, []);
  useEffect(() => {
    dispatch(setDesignations());
    return ()=> {dispatch(clearDesignations());}
  }, [dispatch]);

 const paymentHandler = useCallback(async(bill)=>{
    function loadScript(src) {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          resolve(false);
        };
        document.body.appendChild(script);
      });
    }
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert(
        "Payment Gateway unavailable. Please check yout Internet connection."
      );
      return;
    }
    const paymentData = await createPayment({month: bill.month, year:bill.year});
    if (!paymentData) {
      alert("Server error!");
      return;
    }
    const { amount,order_id, currency } = paymentData.data;
    
    const options = {
      key: "rzp_live_jkhhql8mjXpxUS",
      amount:amount,
      currency:currency,
      name: "Medorn_Ventures",
      order_id:order_id,
      prefill: {
        name: companyName?.companyName,
        email: companyName?.email,
        contact: companyName?.mobile,
      },
      handler: async function (response) {
        try {
          const {data} = await verifyPayment({
            orderCreationId: order_id,
            paymentFor: "ERP Subscription",
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
            bill
          });
          alert(data.message);
          getInvoices({year : new Date().getFullYear()}).then(({data})=>{
            setInvoices(data);
          }).catch((err)=>{
            customToast.error("Failed to fetch Invoices.");
          })
          } catch (err) {
          
          alert("Some error occured while payment!");
        }
      },
      theme: {
        color: "teal",
      },
      notes: {
        paymentFor: "MOCK",
      },
    };
    // setConfModal(false);
    const paymentObject = await new window.Razorpay(options);
    paymentObject.open();
    
  },[companyName]);
  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr no.",
      //   accessor: "sr",
      //   disableFilters: true,
      //   disableSortBy: true,
      //   minWidth: 80,
      //   maxWidth: 80,
      // },
      {
        Header: "Designation",
        accessor: "des",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const desired = designationData.filter((el)=> el.value == props?.row?.original?.des);
          return (<span>
            {desired[0]?.name || "NA"}
          </span>)
        },
      },
      {
        Header: "Total Users",
        accessor: "totalUsers",
        disableFilters: true,
        disableSortBy: true,
        showTotal:true,
        totalAccessor:"totalUsers"
      },
      {
        Header:"Total Price",
        accessor:"totalPrice",
        disableFilters: true,
        disableSortBy: true,
        showTotal:true,
        totalAccessor:'totalPrice'
      }
    ],
    [designationData]
  );
  const columns2 = useMemo(
    () => [
      {
        Header: "Active Users",
        accessor: "activeUsers",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Payment Date",
        accessor: "paidOn",
        disableFilters: true,
        disableSortBy: true,
        Cell : (props)=>{
          return <span>{props?.row?.original?.paidOn ? formatDate(props?.row?.original?.paidOn) : 'NA'}</span>
        }
      },
      {
        Header: "Month",
        accessor: "month",
        disableFilters: true,
        disableSortBy: true,
        Cell : (props)=>{
          return <span>{monthOptions[props?.row?.original?.month]?.label}</span>
        }
      },
      {
        Header: "Amount",
        accessor: "amount",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "View",
        accessor: "",
        Cell: (props) => {
          return (
            <PDFDownloadLink style={{"textDecoration":"none"}} document={<PdfDocument billObj={props?.row?.original} compDetails={companyName}/>} fileName="invoice.pdf">
              {({ blob, url, loading, error }) =>
                loading ? 'Loading document...' : 'Download Invoice'
              }
            </PDFDownloadLink>
          );
        },
        minWidth: 80,
        maxWidth: 80,
        disableFilters: true,
        disableSortBy: true,
      },
      { 
        Header: "Action",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        Cell:(props)=>{
          return props?.row?.original?.status === 'Unpaid' ?
          <span className="px-2 py-2 ms-auto button-submit-green button-blue-gradient m-auto"
            type="button"
            onClick={(e)=> {paymentHandler(props?.row?.original)}}
          >
            Pay Now
          </span> : <span>Paid</span>
        }
      }
    ],
    [companyName, paymentHandler]
  );
  const invoiceData = useMemo(()=>serialise(invoices),[invoices]);

  return (
    <main className="main-content ">
      <div className="expense">
        <div className="row">
          <h3 className="web-app__heading">Billing</h3>
          
          <div className="e-detailing-parent row">
            <div className="col-md-6 util-tp-filter">
              <label className="web-app__heading">Month : <span className="password-icon-show">{new Date().toLocaleString('default', { month: 'long' })} </span></label><br />
              <label className="web-app__heading">Total Amount : <span className="password-icon-show"> {bill?.amount}</span></label>
            </div>
            <div className="col-md-6 util-tp-filter m-auto text-end">
              <a href='https://youtu.be/2okdiJTdPWU' target="_blank" rel="noreferrer" className="mb-2 cursor-pointer" style={{textDecoration:"none"}}>
                <span className="py-3 table-link"
                // type="button"
                // onClick={paymentHandler}
                // href={bill?.url}
                >
                 How to pay monthly subscription fee ?
                </span>
              </a>
            </div>
            {/* {
              bill?.url && 
              <div className="col-md-4 util-tp-filter">
              <a href={bill?.url} className="mb-2">
                <span className="px-5 py-3 ms-auto button-submit-green button-blue-gradient "
                // type="button"
                // onClick={paymentHandler}
                href={bill?.url}
                >
                 Pay Now
                </span>
              </a>
            </div>
            } */}
          </div>
        </div>
        <div className="filter-table mt-5">
          <div className="e-detailing-parent">
            <div className="edetailing-prod-images">
              <label className="web-app__heading">Invoices</label>
            </div>
          </div>
          <Table columns={columns2} data={invoiceData} />
        </div>
        {/* <PDFViewer width="1200" height="600"> 
          <PdfDocument billObj={invoices[0]} compDetails={companyName}/>
        </PDFViewer> */}
        {/* <div className="filter-table">
          <div className="e-detailing-parent">
            <div className="edetailing-prod-images">
                <label className="web-app__heading">Current Users</label>
            </div>
          </div>
          <Table columns={columns} data={[]} />
        </div> */}
      </div>
    </main>
  );
};

export default Billing;
