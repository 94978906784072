import { MdArrowBackIosNew, MdOutlineGroup, MdOutlineRefresh } from "react-icons/md";
import { useNavigate, Link } from "react-router-dom";
import Select from "react-select";
import { formatDate } from "../../../../utils/helper";
import {
  dcrStyles,
  selectUserStyles,
} from "../../../public/stylesheets/selectStyles";
import { useCallback, useState, useMemo, useEffect, Suspense } from "react";
import { useSelector } from "react-redux";
import { generateSelectData, generateSelectUser } from "../../../../utils/serialiseResponse";
import { viewUsersForUtilities } from "../../../../services/utilities";
import { viewMonthlyPOBReports, viewPOBReports } from "../../../../services/analytics";
import Table from "../../../../components/Table";
import { FaEye } from "react-icons/fa";
import { addDays } from "date-fns";
import DateRange from "../../../../components/DateRange";
import DatePickers from "../../../../components/DatePicker";
import moment from "moment-timezone";

const typeOptions = [
  { value: 1, label: "Doctor" },
  { value: 2, label: "Chemist" },
  { value: 3, label: "Stockist" },
];

const showArr = [
  { value: "pobReport", label: "POB Report" },
  { value: "monthlyReport", label: "Monthly Report" },
  { value: "detailedReport", label: "Detailed Report" },
]

const PobWiseReports = () => {
  const navigate = useNavigate();
  const loggedIn = useSelector(({ user }) => user);

  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [month, setMonth] = useState(moment().month());
  const [year, setYear] = useState(moment().year());
  const [reports, setReports] = useState([]);
  const [type, setType] = useState({ value: 1, label: "Doctor" });
  const [employeeDataForExcel, setEmployeeDataForExcel] = useState();
  const [reportType, setReportType] = useState({ value: "pobReport", label: "POB Report" },)

  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
  });

  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );

  const employeeData = useMemo(
    () => generateSelectData(employees, "fullName"),
    [employees]
  );

  const pobColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "",
        showTotal:true,
        placeholderTotal:"Total",
        Cell : (props) =>{
          return( type?.value === 1 ? <p>{props?.row?.original?.targetData?.target?.name} </p> :
          <p> {props?.row?.original?.targetData?.target?.businessName} </p>
          )
        }
      },
      {
        Header: "POB Amount",
        accessor: "amount",
        showTotal:true,
        totalAccessor:"amount"
      },
      {
        Header: "View",
        accessor: "",
        Cell: (props) => {
          return (
            <Link
              to={`${props?.row?.original?.targetData?.target._id}`}
              state={{
                month: Number(month?.value),
                year: Number(year?.label),
                startDate: dateRange.startDate.getTime(),
                endDate:dateRange.endDate.getTime(),
                type: type?.label?.toLowerCase(),
                userId: selectedUser ? selectedUser?.value : loggedIn?.jwt?.id,
                data: props.row.original,
              }}
              className="util-table-view-details-icon"
            >
              <FaEye />
            </Link>
          );
        },
        minWidth: 80,
        maxWidth: 80,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [month, year, dateRange, loggedIn, selectedUser, type]
  );

  const monthColumns = useMemo(() => [
    {
      Header: "Employee Name",
      accessor: "submitter.name",
    },
    {
      Header: "Doctor",
      accessor: "doctors",
      disableFilters: true,
      Cell: (props) => (
        <span>
          {props.row.original?.doctors}
        </span>
      )
    },
    {
      Header: "Chemist",
      accessor: "chemists",
      disableFilters: true,
      Cell: (props) => (
        <span>
          {props.row.original?.chemists}
        </span>
      )
    },
    {
      Header: "Stockist",
      accessor: "stockists",
      disableFilters: true,
      Cell: (props) => (
        <span>
          {props.row.original?.stockists}
        </span>
      )
    },
    {
      Header: "POB Amount",
      accessor: "totalPOB",
      showTotal:true,
      totalAccessor:"amount",
      disableFilters: true,
    },
    {
      Header: "View",
      accessor: "",
      Cell: (props) => {
        return (
          <Link
            to="monthly-pobReport-details"
            state={{
              submitter: props?.row?.original?.submitter,
              month: month,
              year: year
            }}
            className="util-table-view-details-link"
          >
            <FaEye className="icon-color-tertiary" />
          </Link>
        );
      },
      minWidth: 80,
      maxWidth: 80,
      disableFilters: true,
      disableSortBy: true,
    },
  ],[month, year])

  const detailedColumns = useMemo(() => [
    {
      Header: "Date",
      accessor: "date",
      disableFilters: true,
      Cell: (props) => {
        return ( formatDate(props?.row?.original?.date))
      },
    },
    {
      Header: "Employee Name",
      accessor: "employeeName",
    },
    {
      Header: "Name",
      accessor: "entityName",
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: (props) => {
        const type = props?.row?.original?.type
        return type === "doc" ? <span className='approved-text'>Doctor</span> :
          type === "chem" ? <span className='pending-text'>Chemist</span> :
            type === "stk" ? <span className='rejected-text'>Stockist</span> :
              "N/A"
      },
    },
    {
      Header: "Product",
      accessor: "productName",
    },
    {
      Header: "Quantity",
      accessor: "quantity",
      disableFilters: true,
      disableSortBy: true,
      showTotal:true,
      totalAccessor:"quantity"
    },
    {
      Header: "Amount",
      accessor: "amount",
      disableFilters: true,
      disableSortBy: true,
      showTotal:true,
      totalAccessor:"amount"
    },
  ],[month, year])

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      let data = {
        selectedUser: selectedUser?.value,
        month: month?.value,
        year: Number(year?.label),
      };

      await viewPOBReports(type?.label?.toLowerCase(), data).then((res) =>{
        setReports(res.data)
      }
      );
    },
    [month, year, type, selectedUser]
  );

  useEffect(() => {
    
    let data = {
      selectedUser: selectedUser?.value,
      startDate: new Date(dateRange?.startDate).getTime(),
      endDate: addDays(new Date(dateRange?.endDate), 1).getTime(),
      betweenDates:true
    };

    const aysncFetch = async () => {
      try{

        if(reportType?.value === "pobReport") {
          const res = await viewPOBReports(type?.label?.toLowerCase(), data);
          setReports(res.data)
        } else if(reportType?.value === "detailedReport") {
          const res = await viewMonthlyPOBReports({
            detailedReport: true,
            month: Number(month),
            year: Number(year)
          })
          setReports(res.data)
        } else {
          const res = await viewMonthlyPOBReports({
            month: Number(month),
            year: Number(year)
          })
          setReports(res.data)
        }
      }
      catch(err){
      console.log(err);
      }
    };
   
    aysncFetch();
  }, [month, year, dateRange, selectedUser, type, reportType]);
   
  useEffect(() => {
    try {
      viewUsersForUtilities({ dcr: true }).then((res) => {
        setEmployees(res?.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    try {
      let tempdata = employeeData.filter((empData) => {
        if (empData.label === selectedUser.label) return true;
      })
      setEmployeeDataForExcel(tempdata[0]);
    } catch (error) {
      console.log(error);
    }
  }, [selectedUser]);

  return (
    <div className="expense">
      <div className="row">
        <h2
          className="web-app__heading"
          style={{ display: "flex", alignItems: "center" }}
        >
          <MdArrowBackIosNew
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <span style={{ display: "inline-block", margin: "0 1rem" }}>
            POB Reports
          </span>
        </h2>

        <form onSubmit={handleSubmit}>
          <div className="util-tp-query d-flex flex-wrap gap-4">
    
            <div className="util-tp-filter">
              <p className="mb-3">Select Report Type</p>
              <Select
                options={showArr}
                styles={dcrStyles}
                value={reportType}
                onChange={(e) => {
                  setReportType(e)
                  setReports([])
                }}
              />
            </div>

            { reportType?.value === "pobReport" ?
              <>
                <div className="util-tp-filter">
                  <p>Select Doc / Chem / Stk</p>
                  <Select
                    styles={dcrStyles}
                    placeholder="Select Type"
                    className="mt-3"
                    options={typeOptions}
                    value={type}
                    onChange={(e) => setType({ ...e })}
                  />
                </div>

                {loggedIn?.user?.des < 121 ||
                  loggedIn?.user?.designation?.priority > 1 ||
                  loggedIn?.user?.isAdmin ? (
                  <div className="util-tp-filter">
                    <p className="util-select-user__label">Select User</p>
                    <div className="util-select-user">
                      <MdOutlineGroup className="util-select-user__icon" />
                      <Select
                        styles={selectUserStyles}
                        options={employeeSelect}
                        placeholder="Select User"
                        className="mt-3"
                        value={selectedUser}
                        onChange={(e) => setSelectedUser({ ...e })}
                      />
                      <MdOutlineRefresh size={25} className="icon-color-tertiary mt-4 ms-2" style={{ cursor: "pointer" }} onClick={() => {
                        setSelectedUser(null)
                      }} />
                    </div>
                  </div>
                ) : null}
              </> : (
                <div className="util-tp-filter">
                  <p className="mb-3">Select Month</p>
                  <Suspense>
                    <DatePickers
                      placeholder={(month && year ) ? moment({ year, month }).format('MMM, YYYY') : "Select Month"}
                      setMonth={setMonth}
                      setYear={setYear}
                    />
                  </Suspense>
                </div>
              )
            }
          </div>
        </form>

        {
          reportType.value === "pobReport" && (
            <div className="date-range-container my-3">
              <DateRange setDateRange={setDateRange} />
            </div>
          )
        }

        <div className="filter-table">
        {reports.length > 0 && (
          <h2 className="web-app__heading mb-4">
            Showing ({reports.length}) Entries
          </h2>
        )}
          {reports.length>0?<Table 
          columns={ reportType.value === "pobReport" ? pobColumns: (reportType.value === "monthlyReport" ? monthColumns : detailedColumns)} 
          data={reports} empl={selectedUser ? selectedUser : loggedIn?.user} fileSrc="POB Report" />:<h3 className="mt-2 no-results-message">No results found</h3>}
        </div>
      </div>
    </div>
  );
};

export default PobWiseReports;
