import React, { useCallback, useEffect, useState } from "react";
import { useMemo } from "react";
import serialise, { generateSelectData } from "../../../../../../utils/serialiseResponse";
import Table from "../../../../../../components/Table";
import { viewUserWisePrimarySales, viewUsersForUtilities } from "../../../../../../services/utilities";
import { formatDate, monthOptions } from "../../../../../../utils/helper";
import customToast from "../../../../../../components/CustomToast";
import { useSelector } from "react-redux";

const UserPS = ({dateRange, reports, user}) => {
  const [employees, setEmployees] = useState([]);
  const [employeeDataForExcel, setEmployeeDataForExcel] = useState();
  const [month, setMonth] = useState({});
  const [toggle, setToggle] = useState(false)

  const employeeData = useMemo(
    () => generateSelectData(employees, "fullName"),
    [employees]
  );

  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees(res?.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    try {
      let tempdata = employeeData.filter((empData) => {
        if (empData.label === user.label) return true;
      })
      console.log(tempdata);
      setEmployeeDataForExcel(tempdata[0]);
    } catch (error) {
      console.log(error);
    }
  }, [user]);

  useEffect(() => {
    const monthIndex = new Date(dateRange.startDate).getMonth();
    setMonth(monthOptions.at(monthIndex))
  }, [dateRange])
  
  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   showTotal:true,
      //   placeholderTotal:"Total",
      //   minWidth:50,
      //   maxWidth:50,
      //   disableFilters:true,
      //   disableSortBy:true,
      // },
      {
        Header: "Submitted By",
        accessor: "user",
        disableSortBy: true,
      },
      {
        Header: "Stockist",
        accessor: "stockist",
        disableSortBy: true,
      },
      {
        Header: "Headquarter",
        accessor: "headquarter",
        disableSortBy: true,
      },
      {
        Header: "Total Primary Sales",
        accessor: "salesValue",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"salesValue",
        Cell:(props) => {
          return <div>
            {`₹ ${Number(props?.row?.original?.salesValue).toFixed(2)}`}
          </div>
        }
      },
    ],
    []
  );
  const columns2 = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   showTotal:true,
      //   placeholderTotal:"Total",
      //   minWidth:50,
      //   maxWidth:50,
      //   disableFilters:true,
      //   disableSortBy:true,
      // },
      {
        Header: "Employee Name",
        accessor: "user",
        disableSortBy: true,
      },
      {
        Header: "Email",
        accessor: "email",
        disableSortBy: true,
      },
      {
        Header: "Total Primary Sales",
        accessor: "salesValue",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"salesValue",
        Cell:(props) => {
          return <div>
            {`₹ ${Number(props?.row?.original?.salesValue).toFixed(2)}`}
          </div>
        }
      },
    ],
    []
  );

  const columns3 = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        minWidth: 100,
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return formatDate(props?.row?.original?.date)
        },
      },
      
      {
        Header: "Submitted By",
        accessor: "user",
        disableSortBy: true,
      },
      {
        Header: "Stockist",
        accessor: "stockist",
        disableSortBy: true,
      },
      {
        Header: "Headquarter",
        accessor: "headquarter",
        disableSortBy: true,
      },
      {
        Header: "Total Primary Sales",
        accessor: "salesValue",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"salesValue",
        Cell:(props) => {
          return <div>
            {`₹ ${Number(props?.row?.original?.salesValue).toFixed(2)}`}
          </div>
        }
      },
    ],
    []
  );
  const tableData = useMemo(() => serialise(reports), [reports]);
  return (
    <div className="filter-table">
        {/* {reports.length > 0 && (
            <>
            <h2 className="web-app__heading mb-4 ps-3">
                Showing ({reports?.length}) Entries
            </h2>
            <div className="form__control pe-3" style={{ width: "fit-content" }}>
            <label className="toggle-label me-3">
              <input
                type="checkbox"
                defaultChecked={toggle}
                onClick={(e)=>setToggle(!toggle)}
              />
              <span/>
            </label>
            <span>View Date Wise</span>
          </div>
          </>
        )}  */}
        {tableData?.length > 0 ? (
          <>
            <h2 className="web-app__heading mb-4 ps-3 d-flex justify-content-between">
              Showing ({reports?.length}) Entries
              {user && (
                <div className="form__control pe-3" style={{ width: "fit-content" }}>
                <label className="toggle-label me-3">
                  <input
                  type="checkbox"
                  defaultChecked={toggle}
                  onClick={(e)=>setToggle(!toggle)}
                  />
                  <span/>
                </label>
                <span>View Date Wise</span>
              </div>
              )}
           </h2>
      
            {/* <Table columns={user ? columns : columns2} data={tableData} empl={employeeDataForExcel} fileSrc="Primary Sales Report (User)" month={month} /></> */}
            <Table   columns={toggle ? columns3 : (user ? columns : columns2)}   data={tableData} empl={employeeDataForExcel} fileSrc="Primary Sales Report (User)" month={month} /></>
        ) : (
            <h3 className="mt-2 no-results-message">No results found</h3>
        )}
    </div> 
  );
};
export default UserPS ;