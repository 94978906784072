import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { clearUsers, setUsers } from '../../../../../reducers/users/employeeReducer';
import serialise, { generateSelectData } from '../../../../../utils/serialiseResponse';
import { selectStyles, selectUserStyles } from '../../../../public/stylesheets/selectStyles';
import { useJsApiLoader } from '@react-google-maps/api';
import {
  Map,
  APIProvider,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import { getDcsData } from '../../../../../services/doctor';
import ProgressAverage from '../../../../../components/ProgressAverage';
import { MapAdvanceMarker } from '../../../../../components/MapAdvanceMarker';
import { viewUsersForUtilities } from '../../../../../services/utilities';
import Table from '../../../../../components/Table';
import { MdDelete, MdDeleteOutline, MdOutlineEdit, MdOutlineRefresh, MdArrowBackIosNew } from 'react-icons/md';
import customToast from '../../../../../components/CustomToast';
import { RiCloseCircleLine } from 'react-icons/ri';
import { untagGeolocationDCS } from '../../../../../services/geoFencing';
import { useNavigate } from 'react-router-dom';


const typeParameters = [
  { value: "doctor", label: "Doctor" },
  { value: "chemist", label: "Chemist" },
  { value: "stockist", label: "Stockist" },
]

const GeoFencing = () => {

  const data2 = useSelector(({ geoFencingList }) => geoFencingList);
  // console.log('data here ', data2);

  const dispatch = useDispatch();
  const loggedIn = useSelector(({ user }) => user);
  const designation = useSelector(({ user }) => user.user.des);
  const users = useSelector(({ employee }) => employee);
  const [selectedUser, setSelectedUser] = useState();
  const [employees, setEmployees] = useState([]);
  const [typeParameter, setTypeParameter] = useState();
  const [showMap, setShowMap] = useState(false);
  const [center, setCenter] = useState({
    lat: 0,
    lng: 0
  });
  const [DcsData, setDcsData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [percentageTagged, setPercentageTagged] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [editForm, setEditForm] = useState({})
  const [showMyList, setShowMyList] = useState()
  const navigate = useNavigate()
  const [markerRef, marker] = useAdvancedMarkerRef();

  const [infoWindowShown, setInfoWindowShown] = useState(false);

  const handleMarkerClick = () => {
    setInfoWindowShown(isShown => !isShown);
  }

  const handleClose = useCallback(() => setInfoWindowShown(false), []);

  const containerStyle = {
    width: '1040px',
    height: '470px'
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_API_KEY
  });

  useEffect(() => {
    dispatch(setUsers('user'));

    return () => {
      dispatch(clearUsers());
    }
  }, [dispatch]);

  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees(res?.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const SubmitHandler = async () => {
    try {
      if (selectedUser && typeParameter) {
        setLoading(true);
        setShowMap(false);
        const data = await getDcsData({ userId: selectedUser.value, type: typeParameter.value })
        setDcsData(data);
        let arrayWithGeoLocation = [];
        let minLat = 0, maxLat = 0, minLng = 0, maxLng = 0;
        data?.data?.filter(elem => elem?.geolocation?.isApproved === 1)
       ?.map((val, index) => {
          if (val?.geolocation) {
            const lat = val?.geolocation?.latitude, long = val?.geolocation?.longitude;
            if (arrayWithGeoLocation.length === 0){
              maxLat = lat;
              minLat = lat;
              maxLng = long;
              minLng = long;
            } else {
              maxLat = Math.max(maxLat, lat);
              minLat = Math.min(minLat, lat);
              maxLng = Math.max(maxLng, long);
              minLng = Math.min(minLng, long);
            }
            const dcsData = {
              _id: val?._id,
              name: val?.name || val?.businessName,
              address: val?.address || "",
              latitude: val?.geolocation?.latitude,
              longitude: val?.geolocation?.longitude,
              city: val?.city?.name,
              workingArea: val?.workingArea?.name,
            }
            arrayWithGeoLocation = [...arrayWithGeoLocation, dcsData]
          }
        });
        setCenter({
          lat: (maxLat + minLat)/2,
          lng: (maxLng + minLng)/2
        })
        setLocationData(arrayWithGeoLocation);
        setLoading(false);
        setShowMap(true);
      } else if (designation === 121 && typeParameter){
        setLoading(true);
        setShowMap(false);
        const data = await getDcsData({ userId: loggedIn.user._id, type: typeParameter.value })
        setDcsData(data);
        let arrayWithGeoLocation = [];
        let minLat = 0, maxLat = 0, minLng = 0, maxLng = 0;
        data?.data?.filter(elem => elem?.geolocation?.isApproved === 1)
        ?.map((val, index) => {
          if (val.geolocation) {
            const lat = val?.geolocation?.latitude, long = val?.geolocation?.longitude;
            if (arrayWithGeoLocation.length === 0){
              maxLat = lat;
              minLat = lat;
              maxLng = long;
              minLng = long;
            } else {
              maxLat = Math.max(maxLat, lat);
              minLat = Math.min(minLat, lat);
              maxLng = Math.max(maxLng, long);
              minLng = Math.min(minLng, long);
            }
            const dcsData = {
              name: val?.name || val?.businessName,
              address: val?.address || "",
              latitude: val?.geolocation?.latitude,
              longitude: val?.geolocation?.longitude,
              city: val?.city?.name,
              workingArea: val?.workingArea?.name,
            }
            arrayWithGeoLocation = [...arrayWithGeoLocation, dcsData]
          }
        });
        setCenter({
          lat: (maxLat + minLat)/2,
          lng: (maxLng + minLng)/2
        })
        setLocationData(arrayWithGeoLocation);
        setLoading(false);
        setShowMap(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    setPercentageTagged(locationData?.length / DcsData?.data?.length * 100);
  }, [locationData, DcsData])

  useEffect(() => {
    SubmitHandler();
  }, [selectedUser, typeParameter])

  const employeeSelectForAdmin = useMemo(
    () => generateSelectData(users, "fullName"),
    [users]
  );

  const employeeSelect = useMemo(
    () => generateSelectData(employees, "fullName"),
    [employees]
  );

  const [zoom, setZoom] = useState(7);

  const DeletePopup = ({ data }) => {
    const [state, setState] = useState("");

    let updatedData = { ...data, name: state };

    const handleDelete = async () => {
      await untagGeolocationDCS({
        type: typeParameter.value, targetId: updatedData?._id
      })
      
      SubmitHandler();

      customToast.success("Geolocation Deleted Successfully");
      setShowDelete(false);
    };

    useEffect(() => {
      setState(data.name);
    }, [data]);

    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete Details</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowDelete(false)}
            />
          </div>

          <div>
            <h3>Are you sure you want to delete geolocation of {state?.charAt(0).toUpperCase()}{state?.slice(1)}?</h3>
          </div>

          {
            !loading && showDelete === true && (
            <button type="submit" className="button-delete mt-4" onClick={handleDelete}>
              <span className="button-delete__icon-container">
                <MdDeleteOutline className="button-delete__icon" />
              </span>
              <span className="button-delete__text">Delete</span>
            </button>
            )
          }
        </div>
      </section>
    );
  };

  const ViewMap = () => {

    return isLoaded ?
      <APIProvider
        apiKey={process.env.REACT_APP_API_KEY}
        version="beta"
      >
        <div slot="main">
          <Map
            id="gmap"
            mapId="google-map-geo-fencing"
            defaultZoom={zoom}
            defaultCenter={center}
            scaleControl={true}
            gestureHandling={"greedy"}
            disableDefaultUI={true}
            fullscreenControl={true}
            streetViewControl={true}
            clickableIcons={true}
            scrollwheel={true}
            zoomControl={true}
            style={containerStyle}
          >
            {
              locationData.map((location, index) => {
                return (
                  <MapAdvanceMarker location={location} typeParameter={typeParameter} key={index} />
                )
              })
            }
          </Map>
        </div>
      </APIProvider>
      :
      <div style={{ color: "white" }}>
        ....Loading Map
      </div>
  }

  const round = (value, precision) => {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  };

  const columns = useMemo(
    () => [
      {
        Header: `${typeParameter?.label ? typeParameter?.label : "Type"}`,
        accessor: "name"
      },
      {
        Header: "Working area",
        accessor: "workingArea",
        Cell: (props) => {
          return (
            <span>
              <span className="">
                {props?.row?.original?.workingArea?.charAt(0).toUpperCase()} 
                {props?.row?.original?.workingArea?.slice(1)}
              </span>
            </span>
          );
        },
      },
      {
        Header: "Headquarter",
        accessor: "city",
        Cell: (props) => {
          return (
            <span>
              <span className="">
                {props?.row?.original?.city?.charAt(0).toUpperCase()} 
                {props?.row?.original?.city?.slice(1)}
              </span>
            </span>
          );
        },
      },
      {
        Header: "Latitude",
        accessor: "latitude",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Longitude",
        accessor: "longitude",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Delete",
        accessor: "",
        Cell: (props) => {
          return (
            <span>
              <span
                className="react-table-view-link mx-3"
                onClick={() => {
                  // console.log('props.row.original', props.row.original);
                  setEditForm(props.row.original);
                  setShowDelete(true);
                  // setLoading(true);
                }}
              >
                <MdDelete type="button" className="button-delete__icon" size={25} />
              </span>
            </span>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
      }
    ],
    [typeParameter]
  );

  const tableData = useMemo(() => serialise(locationData), [locationData]);

 
  return (
    <div className='main-content'>
      <div className='expense area-creation-content'>
        <div className=''>
        <section className="admin-creation-content__heading justify-content-between py-2">
          <h2
            className="web-app__heading"
            style={{ display: "flex", alignItems: "center" }}
          >
            <MdArrowBackIosNew
              className="admin-creation-content__back-button"
              onClick={() => navigate("/utilities")}
            />
            <span style={{ display: "inline-block", margin: "0 1rem" }}>
              Geo Fancing
            </span>
            </h2>
          <div className="d-flex gap-4 align-items-center pt-4" >
            <div className="form__controll " style={{ width: "fit-content", color: "#c7c4e9" }}>
              <span className="">Individual List</span>
              <label className="toggle-label2 mx-3" >
                <input
                  type="checkbox"
                  checked={showMyList}
                  onClick={() => {
                    setShowMyList(!showMyList);
                    // setSelectedUser(null);
                  }}
                />
                <span />
              </label>
            </div>
          </div>
        </section>


          <div >
            <div className="util-tp-query d-flex justify-content-between">
              <div className="d-flex justify-content-between w-100">

                <div className=' d-flex'>
                  {
                    designation === 121 ?
                    null :
                    designation === 120 ?
                    
                    <div className="util-tp-filter  me-5">
                      <p className='util-select-user__label'>
                        Select User <span style={{ color: "#e74c3caa" }}>*</span>
                      </p>
                      <div className="util-select-user">
                      <Select
                        options={employeeSelect}
                        styles={selectUserStyles}
                        placeholder="Select User"
                        onChange={(e) => setSelectedUser(e)}
                        className='mt-3'
                      />
                      </div>
                    </div> :
                    <div className="util-tp-filter me-5">
                      <p className='util-select-user__label'>
                        Select User <span style={{ color: "#e74c3caa" }}>*</span>
                      </p>
                      <Select
                        options={employeeSelectForAdmin}
                        value={selectedUser}
                        styles={selectUserStyles}
                        placeholder="Select User"
                        onChange={(e) => setSelectedUser(e)}
                        className='mt-3'
                      />
                    </div>
                  }

                  <div className="util-tp-filter me-5">
                    <p className='util-select-user__label'>
                      Select Type <span style={{ color: "#e74c3caa" }}>*</span>
                    </p>
                  <div className="util-select-user">
                    <Select
                      styles={selectUserStyles}
                      className='mt-3'
                      options={typeParameters}
                      value={typeParameter}
                      onChange={(e) => setTypeParameter(e)}
                    />
                  <MdOutlineRefresh size={25} className="icon-color-tertiary mt-4 ms-2" style={{cursor:"pointer"}} onClick={() => {
                    setSelectedUser(null)
                    setTypeParameter(null)
                    setDcsData([])
                    setLocationData([])
                  }
                  }/>
                  </div>
                  </div>
                </div>

                <div className=' d-flex justify-content-center align-items-center'>
                  <h3 className='util-tp-filter m-5 d-flex p-3' >
                    {locationData?.length} out of {DcsData?.data?.length ? DcsData?.data?.length : "0"} tagged
                  </h3>

                  <div style={{
                    width: '10rem',
                    height: '10rem',
                  }}>
                    <ProgressAverage
                      value={percentageTagged}
                      text={percentageTagged ? `${round(percentageTagged, 1)}%` : 0}
                      fontSize={"2.5rem"}
                    />
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
        {
          loading ? 
          <div className='d-flex justify-content-center p-5'> ... Loading ...
          </div> : 
          locationData.length === 0 ?
          <div className='d-flex justify-content-center p-5'> No Tagged Data Found
          </div> :
          showMap ?
          <div className='d-flex flex-column'>
            <div className='mt-5 d-flex justify-content-center' style={{ border: "15px solid #2b2c47", borderRadius: "10px" }}>
              <ViewMap />
            </div>
            <div className='d-flex flex-column'>
              <div className="filter-table ">
                <Table columns={columns} data={tableData} />
              </div>
            </div>
          </div>
          :
          null
        }

        {showDelete ? <DeletePopup data={editForm} /> : null}

      </div>
    </div>
  )
}

export default GeoFencing;