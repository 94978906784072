import {
  MdOutlineAlarmOn,
  MdOutlineSchedule,
  MdOutlineVerified,
} from "react-icons/md";
import Select from "react-select";
import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { viewReminder, clearReminder } from "../../../reducers/reminder";
import StyledCalendar from "../../../components/Calendar";
import customToast from "../../../components/CustomToast";
import { addReminder } from "../../../reducers/reminder";
import { getRandomQuote } from "./quotes";
import Reminder from "./Reminder";
import { generateSelectUser } from "../../../utils/serialiseResponse";
import { multiSelectStyles } from "../../public/stylesheets/selectStyles";
import { clearUsers, setUsers } from "../../../reducers/users/employeeReducer";

const quote = getRandomQuote();

const Reminders = () => {
  const dispatch = useDispatch();
  const reminders = useSelector(({ reminder }) => reminder);
  const userData = useSelector(({ employee }) => employee);
  const loggedIn = useSelector(({ user }) => user);
  useEffect(() => {
    dispatch(viewReminder());
    dispatch(setUsers("user"));

    return () => {
      dispatch(clearReminder());
      dispatch(clearUsers());
    };
  }, [dispatch]);

  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [workedWith, setWorkedWith] = useState([]);

  let filteredUserData = userData.filter(
    (el) => el._id !== loggedIn.user._id
  );

  const employeeSelect = useMemo(
    () => generateSelectUser(filteredUserData, "name"),
    [filteredUserData]
  );

  const resetForm = () => {
    // setDate("");
    setTime("");
    setTitle("");
    setDescription("");
    setWorkedWith([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!date || !time || !title)
      return customToast.error("Please fill in the required fields");

    try {
      const data = {
        date,
        time,
        title,
        description,
        jointReminder: workedWith.map((el) => el?.value),
      };
      await dispatch(addReminder(data));
      customToast.success("Reminder Added SuccessFully");
      resetForm();
      
    } catch (error) {
      console.log(error?.message);
      customToast.error("We encountered an error");
    }
  };
  const filterTodayReminders = (reminders) => {
    const today = new Date().toISOString().slice(0, 10); // Get the current date in "yyyy-MM-dd" format
    return (reminders || []).filter((data) => data.date.slice(0, 10) === today);
  };
  const todayReminders = filterTodayReminders(reminders?.data);
  return (
    <div className="main-content">
      <h2 className="web-app__heading dcr__heading">Add Reminder</h2>
      <div className="reminders">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="calendar-container col-sm-12 col-md-6 col-lg-6">
              <StyledCalendar date={date} onChange={setDate} />
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="reminders-schedule">
                <input
                  type="text"
                  name="reminderTitle"
                  placeholder="Reminder Title"
                  value={title}
                  onChange={({ target }) => setTitle(target.value)}
                />

                <textarea
                  name="reminderDesc"
                  cols="20"
                  rows="3"
                  placeholder="Reminder Description"
                  value={description}
                  onChange={({ target }) => setDescription(target.value)}
                ></textarea>

                <Select
                  isMulti
                  options={employeeSelect}
                  styles={multiSelectStyles}
                  className="multi-select-dropdown"
                  placeholder="Call for a Meeting / Joint Reminder"
                  value={workedWith}
                  onChange={(e) => setWorkedWith([...e])}
                />

                <div className="reminderDate mx-3">
                  <div className="reminderDateLabel">Time for Reminder</div>
                  <div className="reminderDateInput">
                    <div>
                      <input
                        value={time}
                        onChange={({ target }) => setTime(target.value)}
                        name="time"
                        type="time"
                      />
                      <span>
                        <MdOutlineSchedule />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button type="submit" className="button-submit">
            Add Reminder
          </button>
        </form>

        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 reminders-rhs">
            <div className="row">
              <div className="col-md-6 reminders-upcoming">
                <MdOutlineVerified />
                <h3>
                  {/* '{reminderData[0].title}'{" "}
                  {getRelativeTime(new Date(reminderData[0].date))} */}
                  {reminders?.data?.length === 0 ? 'No upcomming reminders' : reminders?.data?.map((el, idx) =>
                    idx === reminders?.data?.length - 1 ? el?.title : null
                  )}
                </h3>
                <p>
                  {reminders?.data?.map((el, idx) =>
                    idx === reminders?.data?.length - 1 ? el?.description : null
                  )}
                </p>
              </div>
              <div className="col-md-6 reminders-quote">
                <MdOutlineAlarmOn />
                <p>{quote}</p>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="reminders-list">
                {todayReminders.length > 0 ?
                <ul>
                  {todayReminders.map((data, idx) => (
                      <Reminder
                        title={data.title}
                        description={data.description}
                        jointReminder={data.jointReminder}
                        date={data.date.slice(0, 10)}
                        key={idx}
                        id={data._id}
                      />
                    )
                  )}
                </ul> : 
                  <h1 className="no-reminder-heading">No reminders for today</h1>
                }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reminders;
