import React, { useEffect, useMemo, useState } from "react";
import { ImCheckboxChecked } from "react-icons/im";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { useDispatch } from "react-redux";
import Select from "react-select";
import customToast from "../../../../components/CustomToast";
import PageTitle from "../../../../components/PageTitle";
import Table from "../../../../components/Table";
import { createCampaign, getCampaignDetails, updateCampaign } from "../../../../services/campaign";
import { viewTargets } from "../../../../services/targets";
import serialise from "../../../../utils/serialiseResponse";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
const AdminCampaign = () => {
  const dispatch = useDispatch();
  const {campaignId} = useParams()
  const [campaignData, setCampaignData] = useState({})
  const navigate = useNavigate()
  const [title, setTitle] = useState("");
  const [type, setType] = useState({ value: "doctor", label: "Doctor" },);
  const [selectedDoctor, setSelectedDoctor] = useState([]);
  const [selectedChemist, setSelectedChemist] = useState([]);
  const [selectedStockist, setSelectedStockist] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [chemists, setChemists] = useState([]);
  const [stockists, setStockists] = useState([]);
  const [deadline, setDeadline] = useState("")

  const options = [
    { value: "doctor", label: "Doctor" },
    { value: "chemist", label: "Chemist" },
    { value: "stockist", label: "Stockist" },
  ];

  useEffect(() => {
    // console.log("hellll", campaignId)
    // if (!campaignId) {
    //     console.error("Campaign ID is missing!");
    //     return;
    // }

    const fetchCampaignData = async () => {
        try {
            const { data } = await getCampaignDetails(campaignId);

            // if (!data) {
            //     console.error("No campaign data received!");
            //     return;
            // }

            const { name, deadline, doctors = [], chemists = [], stockists = [] } = data;

            const updatedDoctors = doctors.map(dr => ({ ...dr._id, isSelected: true }));
            const updatedChemists = chemists.map(ch => ({ ...ch._id, isSelected: true }));
            const updatedStockists = stockists.map(st => ({ ...st._id, isSelected: true }));

            setCampaignData({ name, deadline, doctors: updatedDoctors, chemists: updatedChemists, stockists: updatedStockists });

           
            setSelectedDoctor(updatedDoctors);
            setSelectedChemist(updatedChemists);
            setSelectedStockist(updatedStockists);

        } catch (error) {
            console.error("Error fetching campaigns:", error);
        }
    };

    fetchCampaignData();
}, []);


  useEffect(() => {
    
    if (campaignData?.name) setTitle(campaignData.name);
    if (campaignData?.deadline) setDeadline(campaignData.deadline);
    if (campaignData?.doctors) setSelectedDoctor(campaignData.doctors);
    if (campaignData?.chemists) setSelectedChemist(campaignData.chemists);
    if (campaignData?.stockists) setSelectedStockist(campaignData.stockists);
  }, [campaignData]);
 


  useEffect(() => {
    async function fetchData() {
      const filter = {
        showApproved: true,
        admin: true,
      };

      try {
        const docs = await viewTargets("doctor", filter);
        setDoctors(docs?.data || []);
        const chems = await viewTargets("chemist", filter);
        setChemists(chems?.data || []);
        const stocks = await viewTargets("stockist", filter);
        setStockists(stocks?.data || []);
      } catch (error) {}
    }

    fetchData();
  }, []);

  const columns = useMemo(() => {
    return [
      {
        Header: "Business Name",
        accessor: "businessName",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Propreitor Name",
        accessor: "name",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Address",
        accessor: "address",
        minWidth: 85,
        maxWidth: 85,
        wordWrap: "break-word",
        Cell: (props) => {
          const address = props?.row?.original?.address;
          if (!address) {
            return null;
          }
          const words = address.split(" ");
          const truncateWords = words.slice(0, 5).join(" ");

          const capitalize = (text) =>
            text
              .split(" ")
              .map(
                (word) =>
                  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
              )
              .join(" ");

          return <span>{capitalize(truncateWords)}</span>;
        },
      },
      {
        Header: "Contact",
        accessor:
          type?.value === "stockist" ? "stockistContact" : "chemistContact",
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "HQ",
        accessor: "city.name",
        minWidth: 70,
        maxWidth: 70,
      },
      {
        Header: "Working Area",
        accessor: "workingArea.name",
        minWidth: 85,
        maxWidth: 85,
        wordWrap: "break-word",
      },
      {
        Header: "Select",
        accessor: "",
        Cell: (props) => {
          const rowId = props.row.original._id;
          const isAlreadySelected =
          campaignData?.stockists?.some(
            (stockist) => stockist._id?.toString() === rowId.toString()
          ) ||
          campaignData?.chemists?.some(
            (chemist) => chemist._id?.toString() === rowId.toString()
          );
          const [local, setLocal] = useState(
            props?.row?.original?.isSelected || isAlreadySelected || false
          );
        

          const handleSelection = () => {
           
            if (local) {
              
              setCampaignData((prev) => ({
                ...prev,
                chemists: prev?.chemists?.filter((chemist) => chemist._id !== rowId),
                stockists: prev?.stockists?.filter((stockist) => stockist._id !== rowId),
              }));
            } else {
            
              // setCampaignData((prev) => ({
              //   ...prev,
              //   chemists: [...prev.chemists, props.row.original],
              //   stockists: [...prev.stockists, props.row.original],
              // }));
              setCampaignData((prev) => ({
                ...prev,
                chemists: type.value === "chemist" ? [...prev.chemists, props.row.original] : prev.chemists,
                stockists: type.value === "stockist" ? [...prev.stockists, props.row.original] : prev.stockists,
              }));
            }

            setLocal(!local);
          };

      
          return (
            <span className="react-table-view-link display-6">
              {local ? (
                <ImCheckboxChecked
                  onClick={() => {
                    props.row.original.isSelected = false;
                    setLocal(false);
                    {Object.keys(campaignData).length > 0 && handleSelection()}
                    handleRowSelection();
                  }}
                />
              ) : (
                <MdCheckBoxOutlineBlank
                  onClick={() => {
                    props.row.original.isSelected = true;
                    setLocal(true);
                    {Object.keys(campaignData).length > 0 && handleSelection()}
                    handleRowSelection();
                  }}
                />
              )}
            </span>
          );
        },
      }
      
    ];
  }, [type]);

  const columns2 = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "name",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Degree",
        accessor: "degree",
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Specialization",
        accessor: "specialization",
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Contact",
        accessor: "mobile",
        minWidth: 90,
        maxWidth: 90,
      },
      {
        Header: "HQ",
        accessor: "city.name",
        minWidth: 70,
        maxWidth: 70,
      },
      {
        Header: "Working Area",
        accessor: "workingArea.name",
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Select",
        accessor: "",
        Cell: (props) => {
          const rowId = props.row.original._id;
          const isAlreadySelected = campaignData?.doctors?.some((doc) => doc._id?.toString() === rowId.toString() && doc.isSelected) 

          // if(isAlreadySelected) {
          // console.log("isalreadyselected"+isAlreadySelected)
          // console.log("rowId"+rowId)
          // }
          const [local, setLocal] = useState(
            props?.row?.original?.isSelected || isAlreadySelected || false
          );

          const handleDoctorSelection = () => {
            if (local) {
              
              setCampaignData((prev) => ({
                ...prev,
                doctors: prev?.doctors?.filter((dr) => dr._id !== rowId),
              }));
            } else {
            
              setCampaignData((prev) => ({
                ...prev,
                doctors: [...prev.doctors, props.row.original],
              }));
            }
            setLocal(!local);
          };
          return (
            <span className="react-table-view-link display-6">
              {local ? (
                <ImCheckboxChecked
                  onClick={() => {
                  props.row.original.isSelected = false;  
                  {Object.keys(campaignData).length > 0 && handleDoctorSelection()}
                  setLocal(false);
                  handleRowSelection()
                  }}
                />
              ) : (
                <MdCheckBoxOutlineBlank
                  onClick={() => {
                    props.row.original.isSelected = true;
                    setLocal(true);
                    {Object.keys(campaignData).length > 0 && handleDoctorSelection()}
                    handleRowSelection();
                  }}
                />
              )}
            </span>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
      },
    ];
  }, [type]);

  // const doctorsTableData = useMemo(() => serialise(doctors), [doctors]);
  const doctorsTableData = doctors
  // const stockistTableData = useMemo(() => serialise(stockists), [stockists]);
  const stockistTableData = stockists
  // const chemistTableData = useMemo(() => serialise(chemists), [chemists]);
  const chemistTableData = chemists
  // const doctor = useMemo(() => serialise(selectedDoctor), [selectedDoctor]);
 
  

  const handleRowSelection = () => {
    if (type.value === "doctor")
    setSelectedDoctor([...selectedDoctor, ...doctorsTableData.filter((el) => el?.isSelected)]);
    if (type.value === "chemist")
      setSelectedChemist([...selectedChemist, ...chemistTableData.filter((el) => el?.isSelected)]);

    if (type.value === "stockist")
      setSelectedStockist([...selectedStockist, ...stockistTableData.filter((el) => el?.isSelected)]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data  = await createCampaign({
        name: title,
        doctors: selectedDoctor,
        stockists: selectedStockist,
        chemists: selectedChemist,
        deadline:deadline
      });

      const campaignId = data?.data?._id;
      // if(!campaignId){
      //   console.log("compaign id is not available")
      // }
      customToast.success(data?.message || "Campaign created successfully!");
      navigate(`/admin-panel/holidays/campaign/campaign/${campaignId}/add-user`);
    } catch (error) {
      customToast.error(
        error?.response?.data?.message || "Sorry! something went wrong."
      );
    }
  };

  const handleUpdateCampaign = async (e) =>{
    e.preventDefault();
    try {
      const data  = ({
        doctors:  selectedDoctor,
        stockists:  selectedStockist,
        chemists:  selectedChemist,
     
      });
      const response = await updateCampaign(campaignId, data);
      navigate(`/admin-panel/holidays/allcampaign/edit-campaign/${campaignId}/remove-user`);
      customToast.success(data?.message || "Campaign Updated successfully!");
    } catch (error) {
      customToast.error(
        error?.response?.data?.message || "Sorry! something went wrong."
      );
    }
  
  }

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
      <PageTitle title={campaignData && Object.keys(campaignData).length > 0 ? "Edit Campaign" : "Create Campaign"} />



        <section className="area-creation-content__form">
          <form onSubmit={handleSubmit} >
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="title">
                  Campaign Name <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="title"
                  placeholder="Enter Campaign Name"
                  style={adminStyles}
                  value={title}
                  onChange={({ target }) => setTitle(target.value)}
                />
              </div>

             
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="">
                  Deadline <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  className="me-5"
                  id="date"
                  type="date"
                  placeholder="Enter Deadline"
                  styles={adminStyles}
                  value={deadline}
                  onChange={(e) => setDeadline(e.target.value)}
                />
              </div>
              
            </div>

            <div className="d-flex col-auto  mt-4" style={{alignItems:"end"}}>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="">
                  Select Type <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  className="me-5"
                  id="selectType"
                  placeholder="Select Type"
                  styles={adminStyles}
                  options={options}
                  value={type}
                  onChange={(selected) => setType(selected)}
                />
              </div>
              <div style={{display:"flex", gap:"2rem"}}>
              <div
                className="col-auto px-6 py-2 "
                style={{
                  backgroundColor: "#2B2C47",
                  fontSize: "1.7rem",
                  fontWeight: "500",
                  paddingLeft: "4rem",
                  paddingRight: "4rem",
                  height:"4.6rem" ,
                  display:"flex", 
                  justifyContent:"center",
                  alignItems:"center", 
                  textAlign: "center"
                }}
              >
                <p style={{ color: "#26C682", margin:"0" }}>
                  {selectedDoctor.length || 0} Doctors
                </p>
              </div>
              <div
                className="col-auto px-6 py-2 "
                style={{
                  backgroundColor: "#2B2C47",
                  fontSize: "1.7rem",
                  fontWeight: "500",
                  paddingLeft: "4rem",
                  paddingRight: "4rem",
                  display:"flex",
                  height:"4.6rem" ,
                  justifyContent:"center",
                  alignItems:"center", 
                  textAlign: "center"
                }}
              >
                <p style={{ color: "#36AAF9", margin: "0" }}>
                  {selectedChemist.length || 0} Chemist
                </p>
              </div>
              <div
                className="col-auto px-6 py-2"
                style={{
                  backgroundColor: "#2B2C47",
                  fontSize: "1.7rem",
                  fontWeight: "500",
                  paddingLeft: "4rem",
                  paddingRight: "4rem",
                  height:"4.6rem" ,
                  display:"flex", 
                  justifyContent:"center",
                  alignItems:"center", 
                  textAlign: "center"
                }}
              >
                <p style={{ color: "#EA9D19", margin:"0" }}>
                  {selectedStockist.length || 0} Stockist
                </p>
              </div>
            </div>
            </div>

            {/* <section className="area-creation-content__info"> */}
            <div className="filter-table mt-5">
              {
                <Table
                  columns={type?.value === "doctor" ? columns2 : columns}
                  data={
                    type?.value === "doctor"
                      ? doctorsTableData
                      : type?.value === "chemist"
                      ? chemistTableData
                      : type?.value === "stockist"
                      ? stockistTableData
                      : []
                  }
                />
              }
            </div>

            {/* {(selectedDoctor.length > 0 || selectedChemist.length > 0 || selectedStockist.length > 0) && (
              <div className="admin-content-submit-button d-flex justify-content-center mt-2">
                <button type="submit" className="button-blue-gradient">
                  Next
                </button>
              </div>
            )} */}
            {Object.keys(campaignData || {}).length > 0 ? ( // Check if campaignData has any keys
  (selectedDoctor.length > 0 || selectedChemist.length > 0 || selectedStockist.length > 0) && (
    <div className="admin-content-submit-button d-flex justify-content-center mt-2">
      <button onClick={handleUpdateCampaign} type="submit" className="button-blue-gradient">
        Update Campaign
      </button>
    </div>
  )
) : (
  (selectedDoctor.length > 0 || selectedChemist.length > 0 || selectedStockist.length > 0) && (
    <div className="admin-content-submit-button d-flex justify-content-center mt-2">
      <button type="submit" className="button-blue-gradient">
        Next Step
      </button>
    </div>
  )
)}


            

            

 {/* {(selectedDoctor.length > 0 || selectedChemist.length > 0 || selectedStockist.length > 0) && ( 
  <div className="admin-content-submit-button d-flex justify-content-center mt-2">
    <button onClick={handleNext} type="submit" className="button-blue-gradient">
      Next
    </button>
  </div>
 )}  */}
          </form>
        </section>
      </div>
    </main>
  );
};

export default AdminCampaign;
