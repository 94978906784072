import React from 'react';
import styles from './style.module.css'
import { DatePicker, Space } from "antd";
import moment from 'moment-timezone';

const DatePickers = ({setMonth, setYear, placeholder }) => {
    // const year = moment().year()
    // const month = moment().month()

    const handleChange = (date, dateString) => {
        // console.log(date, dateString);
        // console.log(moment())
        setMonth(moment(dateString).month());
        setYear(moment(dateString).year());
    };

    return (
        <Space direction="vertical">
            <DatePicker 
                format={'MMM, YYYY'}
                onChange={handleChange} 
                picker="month" 
                className={styles.datePicker} 
                placeholder={placeholder}
                allowClear={false}
                // defaultValue={moment({ year, month })} 
                />
        </Space>
    )
}

export default DatePickers
