import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import customToast from "../../../components/CustomToast";
import {
  clearProductRanges,
  setProductRanges,
} from "../../../reducers/products/rangeReducer";
import {
  clearStockists,
  setStockists,
} from "../../../reducers/targets/stockist";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../reducers/locations/hqReducer";
import { viewProducts } from "../../../services/products";
import { generateSelectData } from "../../../utils/serialiseResponse";
import { MdCheckCircleOutline, MdDelete, MdCancel } from "react-icons/md";
import { AiFillSave, AiFillFolderOpen } from "react-icons/ai";
import Table from "../../../components/Table";
import {
  adminStyles,
  selectStyles,
} from "../../public/stylesheets/selectStyles";
import { formatDate, getDiscountedPrice } from "../../../utils/helper";
import { RiEdit2Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { addPrimarySale } from "../../../reducers/Sales/primarySales";
import { viewInventoryAlerts } from "../../../reducers/products/MinimumStockReducer";
import { clearPrimarySalesError } from "../../../reducers/Sales/primarySales";
import { IoIosArrowDropdown } from "react-icons/io";
import { fileToBase64 } from "../../../utils/helper";
import { clearDivisions, setDivisions } from "../../../reducers/users/divisionReducer";

const PrimarySales = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null); // for upload file input
  const stockistData = useSelector(({ stockist }) => stockist);
  const headquarters = useSelector(({ headquarter }) => headquarter);
  const { error } = useSelector(({ primarySales }) => primarySales);
  const loggedIn = useSelector(({ user }) => user);
  const divisionData = useSelector(({ division }) => division);
  const productOptions = useSelector(({ productRange }) => productRange);
  const [isReturnSale, setIsReturnSale] = useState(false);
  const [date, setDate] = useState("");
  const [stk, setStk] = useState("");
  const [headquarter, setHeadquarter] = useState("");
  const [uploadData, setUploadData] = useState(""); //this will be used to upload the image file

  const [selectedProduct, setSelectedProduct] = useState("");
  const [finalArray, setFinalArray] = useState([]); //this is the array that will get submitted
  const [editRow, setEditRow] = useState("");
  const [editing, setEditing] = useState(false);
  const [division, setDivision] = useState(null);


  const upQuantityRef = useRef(0);
  const upCustomPriceRef = useRef(0);
  const upFreeStocksRef = useRef(0);
  const upDiscountRef = useRef(0);
  const upPriceTypeRef = useRef("pts");


  const stockistselectData = useMemo(() => {
    return stockistData?.data?.filter((e) => headquarter?._id === e?.city?._id);
  }, [headquarter]);
  const targetSelectProductData = useMemo(() => {
    if (loggedIn?.user?.des === 101) {
      return productOptions?.data?.filter((e) => division?.value === e?.division?._id);
    }
    return productOptions?.data;
  }, [productOptions, division, loggedIn]);


  const targetSelectProduct = useMemo(
    () => generateSelectData(targetSelectProductData, "name"),
    [targetSelectProductData]
  );
  const stockistSelect = useMemo(
    () => generateSelectData(stockistselectData.filter(item => item.isApproved === 1), "businessName"),
    [stockistselectData]
  );
  const headquarterSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );
  const divisionSelect = useMemo(
    () => generateSelectData(divisionData, "name"),
    [divisionData]
  );


  let changeHandler = (e) => {
    const validFiles = Array.from(e.target.files);
    setUploadData([]);
    Promise.all(validFiles.map(fileToBase64))
      .then((base64Images) => {
        setUploadData(base64Images);
      })
      .catch((error) => {
        customToast.error('Error adding images');
      });
  };


  const resetForm = () => {
    setDate("");
    setHeadquarter("");
    setStk("");
    setUploadData("");
    setFinalArray([]);
    setEditing(false);
    setEditRow("");
  };

  useEffect(() => {
    dispatch(setStockists());
    dispatch(setProductRanges());
    dispatch(setHeadquarters());
    dispatch(viewInventoryAlerts());
    dispatch(setDivisions());
    return () => {
      dispatch(clearStockists());
      dispatch(clearProductRanges());
      dispatch(clearHeadquarters());
      // dispatch(clearDivisions());
    };
  }, [dispatch]);



  const deleteHandler = (product) => {
    setEditRow("");
    setEditing(false);
    let sr = 0;
    const newfinalArray = finalArray.filter((ele, idx) => {
      if (ele?.product?._id !== product?._id) {
        sr += 1;
        ele.sr = sr;
        return true;
      } else return false;
    });
    setFinalArray(newfinalArray);
  };
  const handleEditRow = (original) => {
    if (editing) {
      customToast.error("Please save the changes you just made");
      return;
    }
    setEditing(true);
    setEditRow(original?.sr);
    upQuantityRef.current = Number(original?.quantity);
    upFreeStocksRef.current = Number(original?.freeStocks);
    upDiscountRef.current = Number(original?.discount);
    upCustomPriceRef.current = Number(original?.price);
    upPriceTypeRef.current = original?.priceType;
  };
  const submitEditedRow = (sr) => {
    const editedObj = finalArray[sr - 1];
    editedObj.quantity = Number(upQuantityRef.current);
    editedObj.freeStocks = Number(upFreeStocksRef.current);
    editedObj.discount = Number(upDiscountRef.current);
    editedObj.price = Number(upCustomPriceRef.current);
    editedObj.priceType = upPriceTypeRef.current;

    const total = Number(
      Number(editedObj.quantity) * Number(editedObj.price)
    );
    const dis = Number(editedObj.discount);
    editedObj.finalPrice = Number(Number(getDiscountedPrice(total, dis)).toFixed(2));
    setEditing(false);
    setEditRow("");
  };
  const cancelHandler = (sr) => {
    setEditing(false);
    setEditRow("");
  };

  useEffect(() => {
    if (error) {
      dispatch(clearPrimarySalesError());
      return customToast.error(error);
    }
  }, [error]);

  const primarySalesSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (editing) {
        customToast.error("Please save the changes you just made");
        return;
      }
      if (!date || !headquarter || !stk)
        return customToast.error("Please fill the required fields");

      const payload = {
        date,
        headquarter,
        stockist: stk,
        products: finalArray,
        images: uploadData,
        divisionId: division ? division.value : loggedIn?.user?.division,
        isReturnSale
      };
      try {

        dispatch(addPrimarySale(payload));
        dispatch(viewInventoryAlerts());
        resetForm();
        return customToast.success("Primary sale added successfully")
      }
      catch (err) {
        return customToast.error("Sorry ! We encountered an error")
      }
    },
    [date, headquarter, stk, division, finalArray, dispatch, editing, uploadData, isReturnSale]
  );
  const priceAccToHeader = (original, selection) => {

    upPriceTypeRef.current = selection;
    const priceAccToHead = original?.product[selection] || 0;
    upCustomPriceRef.current = priceAccToHead;
    return priceAccToHead;
  }

  const handleProductAdd = () => {
    if (!selectedProduct) {
      setSelectedProduct("");
      customToast.error("Please select a product");
      return;
    }
    if (editing) {
      customToast.error("Please save the changes before adding new product");
      setSelectedProduct("");
      return;
    }
    const temp = finalArray.filter(
      (ele) => ele.product?._id === selectedProduct?._id
    );
    if (temp.length > 0) {
      customToast.error(`Product already present in row "${temp[0].sr}"`);
      setSelectedProduct("");
      return;
    }
    const nextsr = finalArray.length + 1;
    const newObj = {
      sr: nextsr,
      product: selectedProduct,
      quantity: 0,
      freeStocks: 0,
      discount: 0,
      price: selectedProduct?.pts || 0,
      priceType: "pts",
      finalPrice: 0
    };
    setSelectedProduct("");
    setFinalArray([...finalArray, newObj]);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Product",
        accessor: "product.name",
        disableSortBy: true,
        minWidth: 160,
        maxWidth: 160,
        showTotal: true,
        placeholderTotal: "Total",
      },
      {
        Header: "Price",
        accessor: "product.mrp",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "price",
        Cell: (props) => {
          const editable = Number(props?.row?.original?.sr) === Number(editRow);
          const [pr, setPr] = useState(props?.row?.original?.price);
          // useEffect(()=>{upCustomPriceRef.current = pr} , [pr]);
          return (editable) ? (
            <div className="d-flex gap-2 align-items-center justify-center">
              <div className="d-flex gap-1 justify-center flex-column">
                <button type='button'
                  onClick={e => setPr(priceAccToHeader(props?.row?.original, "ptr"))}
                  className={`primary-sales-button ${upPriceTypeRef.current === 'ptr' && "primary-sales-button-bg"}`}
                >PTR</button>
                <button type='button'
                  onClick={e => setPr(priceAccToHeader(props?.row?.original, "pts"))}
                  className={`primary-sales-button ${upPriceTypeRef.current === 'pts' && "primary-sales-button-bg"}`}
                >PTS</button>
                <button type='button'
                  onClick={e => setPr(priceAccToHeader(props?.row?.original, "mrp"))}
                  className={`primary-sales-button ${upPriceTypeRef.current === 'mrp' && "primary-sales-button-bg"}`}
                >MRP</button>
                <button type='button'
                  onClick={e => setPr(priceAccToHeader(props?.row?.original, "cus"))}
                  className={`primary-sales-button ${upPriceTypeRef.current === 'cus' && "primary-sales-button-bg"}`}
                >Cus..</button>
              </div>
              <input
                defaultValue={props?.row?.original?.price}
                onChange={(e) => {
                  upCustomPriceRef.current = e.target.value;
                  upPriceTypeRef.current = 'cus'
                  setPr(e.target.value)
                }}
                value={pr}
                type="number"
                className="sales-table__input h-25"
              />
            </div>
          ) : (
            <span>{`₹ ${props?.row?.original?.price}`}</span>
          );
        },
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 100,
        maxWidth: 100,
        showTotal: true,
        totalAccessor: "quantity",
        Cell: (props) => {
          const editable = Number(props?.row?.original?.sr) === Number(editRow);
          return editable ? (
            <input
              defaultValue={props?.row?.original?.quantity}
              onChange={(e) => {
                upQuantityRef.current = e.target.value;
              }}
              type="number"
              className="sales-table__input h-25"
            />
          ) : (
            <span>{props?.row?.original?.quantity}</span>
          );
        },
      },
      {
        Header: "Free Stocks",
        accessor: "freeStocks",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 90,
        showTotal: true,
        totalAccessor: "freeStocks",
        Cell: (props) => {
          const editable = Number(props?.row?.original?.sr) === Number(editRow);
          return editable ? (
            <input
              defaultValue={props?.row?.original?.freeStocks}
              onChange={(e) => {
                upFreeStocksRef.current = e.target.value;
              }}
              type="number"
              className="sales-table__input h-25"
            />
          ) : (
            <span>{props?.row?.original?.freeStocks}</span>
          );
        },
      },
      {
        Header: "Total Quantity",
        accessor: "totalQuantity",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 90,
        Cell: (props) =>
          Number(
            Number(props?.row?.original?.quantity) +
            Number(props?.row?.original?.freeStocks)
          ),
      },
      {
        Header: "Discount %",
        accessor: "discount",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 100,
        maxWidth: 100,
        Cell: (props) => {
          const editable = Number(props?.row?.original?.sr) === Number(editRow);
          return editable ? (
            <input
              defaultValue={props?.row?.original?.discount}
              onChange={(e) => {
                upDiscountRef.current = e.target.value;
              }}
              type="number"
              className="sales-table__input h-25"
            />
          ) : (
            <span>{props?.row?.original?.discount}</span>
          );
        },
      },
      {
        Header: "Final Price",
        accessor: "finalPrice",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 90,
        showTotal: true,
        totalAccessor: "finalPrice",
        Cell: (props) => {
          return <div>
            {`₹ ${props?.row?.original?.finalPrice}`}
          </div>
        }
      },
      {
        Header: "Actions",
        accessor: "action",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 90,
        Cell: (props) => {
          return editing && Number(props?.row?.original?.sr) === editRow ? (
            <React.Fragment>
              <AiFillSave
                onClick={(e) => {
                  submitEditedRow(props?.row?.original?.sr);
                }}
                type="button"
                className="icon-color-green"
              />
              <MdDelete
                onClick={() => {
                  deleteHandler(props?.row?.original?.product);
                }}
                type="button"
                className="button-delete__icon"
              />
              <MdCancel
                type="button"
                className="icon-color-yellow"
                onClick={() => {
                  cancelHandler(props?.row?.original?.sr);
                }}
              />
            </React.Fragment>
          ) : (
            <span
              className="react-table-view-link"
              onClick={() => {
                handleEditRow(props?.row?.original);
              }}
            >
              <RiEdit2Fill className="icon-color-green" />
            </span>
          );
        },
      },
    ],
    [finalArray, editing]
  );
  return (
    <main className="main-content admin-content">
      <div className="expense">
        <div className="d-flex justify-content-between">
          <h3 className="web-app__heading">Primary Sales</h3>
          <div className="justify-content-between d-flex align-items-center gap-5">
            <div className="d-flex align-items-center justify-items-center gap-2 ">
              <span className="tp__activity-types-icon-8">Return Sale</span>
              <label className="toggle-label mb-0">
                <input
                  type="checkbox"
                  checked={isReturnSale}
                  onClick={()=>{setIsReturnSale((isReturn)=> !isReturn)}}
                />
                <span />
              </label>
            </div>
            <button className="button-blue-gradient2" onClick={() => navigate("./upload-ps")}>
              Upload Primary Sales
            </button>
          </div>
        </div>
        <form onSubmit={primarySalesSubmit}>
          <div className="primarysales-filter">
            <div className="util-tp-filter">
              <p className="mb-2">
                Date <span className="asterisk-imp">*</span>
              </p>
              <input
                type="date"
                className=""
                id="date-input"
                placeholder="Date"
                style={selectStyles}
                value={date}
                onChange={({ target }) => setDate(target.value)}
              />
            </div>

            <div className="util-tp-filter">
              <p className="mb-2">
                Select Headquarter <span className="asterisk-imp">*</span>
              </p>
              <Select
                name="headquarter"
                id="headquarter"
                value={headquarter}
                options={headquarterSelect}
                onChange={(e) => setHeadquarter({ ...e })}
                styles={adminStyles}
                placeholder="Select Headquarter"
              />
            </div>

            <div className="util-tp-filter">
              <p className="mb-2">
                Select Stockist <span className="asterisk-imp">*</span>
              </p>
              <Select
                styles={adminStyles}
                options={stockistSelect}
                placeholder="Select Stockist"
                value={stk}
                onChange={(e) => setStk({ ...e })}
              />
            </div>

            <div className="util-tp-filter">
              <div className="mb-2">
                <button
                  type="button"
                  className="button-submit-green mt-5 ms-auto"
                  onClick={() => navigate(`./all`)}
                >
                  <span className="button-submit-green__icon-container">
                    <AiFillFolderOpen className="button-submit-green__icon" />
                  </span>
                  <span className="button-submit-green__text ">
                    All Primary Sales
                  </span>
                </button>
              </div>
            </div>
            <div className="util-tp-filter">
              <p className="mb-2">Upload File</p>
              <input
                ref={inputRef}
                type="file"
                placeholder="you can upload image, excel or pdf"
                onChange={changeHandler}
              />
            </div>
            {
              loggedIn?.user?.des === 101 &&
              <div className="util-tp-filter">
                <p className="mb-2">
                  Select Division <span className="asterisk-imp">*</span>
                </p>
                <Select
                  name="division"
                  id="division"
                  value={division}
                  options={divisionSelect}
                  onChange={({ value, label }) => setDivision({ value, label })}
                  styles={adminStyles}
                  placeholder="Select Division"
                />
              </div>
            }
            <div className="util-tp-filter">
              <p className="mb-2">
                Select Product <span className="asterisk-imp">*</span>
              </p>
              <Select
                styles={adminStyles}
                options={targetSelectProduct}
                placeholder="Select Product"
                value={selectedProduct}
                onChange={(e) => setSelectedProduct({ ...e })}
              />
            </div>
            <div className="util-tp-filter ms-auto">
              <div className="mb-2">
                <button
                  type="button"
                  onClick={handleProductAdd}
                  className="button-blue-gradient  mt-5"
                >
                  Add Product
                </button>
              </div>
            </div>
          </div>
          <div className="filter-table" style={{ minHeight: "300px" }}>
            <Table columns={columns} data={finalArray} />
          </div>
          <button type="submit" className="button-submit">
            Submit Primary Sales Data
          </button>
        </form>
      </div>
    </main>
  );
};

export default PrimarySales;
